import { FunctionComponent, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next'

import { fetch_one } from '../../lib/server_helper';
import { loginAtom, renew_sign_in } from '../../lib/auth';

import {
  Avatar,
  Box,
  CircularProgress,
  Divider,
  Grid,
  LinearProgress
} from '@mui/material';

import IconItsme from "../../images/itsme_logo.svg";
import MainLogo from '../../images/MainLogo.png';

import {
  Link,
  Typography,
  StyledButton,
  StyledDateField,
  StyledSelect,
  StyledSwitch,
  StyledTextField
} from '../../components/interface';

import { AlertSaved } from '../../components/alerts/Saved';
import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import { User } from '../../models/User';

type PubProfileProps = {};

export const PubProfile: FunctionComponent<PubProfileProps> = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const [login, setLogin] = useAtom(loginAtom);

  const fields = ['id', 'first_name', 'last_name', 'zip', 'city', 'birth_date', 'phone', 'email', 'is_itsme', 'gender', 'province', 'is_mail_invitation', 'is_mail_keep_posted', 'is_anon'];

  const [currentUser, setCurrentUser] = useState<User|null>(null);
  const [loader, setLoader] = useState<boolean>(true);
  const [userSavedSnackOpen, setUserSavedSnackOpen] = useState<boolean>(false);
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);
  const [serverError, setServerError] = useState<string>();

  const [showAccountDeletion, setShowAccountDeletion] = useState<boolean>(false);

  const calculateProvince = (zip:number) => {
    // – Brussels Hoofdstedelijk Gewest: 1000-1299
    // – Provincie Waals-Brabant: 1300-1499
    // – Provincie Vlaams-Brabant: 1500-1999, 3000-3499
    // – Provincie Antwerpen: 2000-2999
    // – Provincie Limburg: 3500-3999
    // – Provincie Luik: 4000-4999
    // – Provincie Namen: 5000-5999
    // – Provincie Henegouwen: 6000-6599,7000-7999
    // – Provincie Luxemburg: 6600-6999
    // – Provincie West-Vlaanderen: 8000-8999
    // – Provincie Oost-Vlaanderen: 9000-9999

    let province = "";
    
    if (zip >= 1000 && zip < 1300) {
      province = "Brussels Capital Region"
    } else {
      if (zip >= 1300 && zip < 1500) {
        province = "Walloon Brabant"
      } else {
        if (zip >= 1500 && zip < 2000) {
          province = "Flemish Brabant"
        } else {
          if (zip >= 2000 && zip < 3000) {
            province = "Antwerp"
          } else {
            if (zip >= 3000 && zip < 3500) {
              province = "Flemish Brabant"
            } else {
              if (zip >= 3500 && zip < 4000) {
                province = "Limburg"
              } else {
                if (zip >= 4000 && zip < 5000) {
                  province = "Liège"
                } else {
                  if (zip >= 5000 && zip < 6000) {
                    province = "Namur"
                  } else {
                    if (zip >= 6000 && zip < 6600) {
                      province = "Hainaut"
                    } else {
                      if (zip >= 6600 && zip < 7000) {
                        province = "Luxembourg"
                      } else {
                        if (zip >= 7000 && zip < 8000) {
                          province = "Hainaut"
                        } else {
                          if (zip >= 8000 && zip < 9000) {
                            province = "West Flanders"
                          } else {
                            if (zip >= 9000 && zip < 10000) {
                              province = "East Flanders"
                            } else {
                              
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    return province;
  }

  const anonUser = () => {
    if (!!login) {
      setLoader(true);
      fetch_one<User>('/nl/v3/objects/custom_action', {
        object: 'user',
        class_action: 'custom_api_anon_user',
        id: login.id
      }, (ob, complete_data) => {
        window.location.reload();
      }, (z) => {
        
      }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
    }
  };

  const unanonUser = () => {
    if (!!login) {
      setLoader(true);
      fetch_one<User>('/nl/v3/objects/custom_action', {
        object: 'user',
        class_action: 'custom_api_unanon_user',
        id: login.id
      }, (ob, complete_data) => {
        window.location.reload();
      }, (z) => {
        
      }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
    }
  };

  const deleteUser = () => {
    if (!!login) {
      setLoader(true);
      fetch_one<User>('/nl/v3/objects/custom_action', {
        object: 'user',
        class_action: 'custom_api_delete_user',
        id: login.id
      }, (ob, complete_data) => {
        setLogin(undefined);
        window.location.reload();
      }, (z) => {
        
      }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
    }
  };

  const reloadUser = () => {
    if (!!login) {
      fetch_one<User>('/nl/v3/objects/fetch_all', {
        object: 'user',
        fields: fields,
        id: login.id
      }, (ob, complete_data) => {
        setCurrentUser(ob);
        setLoader(false);
        // renew_sign_in((x) => {
        //   setLogin(x);
        //   console.log('renew_sign_in from profile');
        // });
      }, (z) => {
        
      }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
    }
  };

  const saveUser = () => {
    let formData = new FormData();
    formData.append('object', 'user');
    formData.append('handler_id', (login?.id || "").toString());
    fields.map(field => {
      formData.append('fields[]', field);
    });
    formData.append('ob[id]', (login?.id || "").toString());
    formData.append('id', (login?.id || "").toString());
    // questionDetail
    // if (!!item) formData.append(`ob[item]`, item);
    // if (!!itemUrl) formData.append(`ob[set_item_url]`, itemUrl);

    formData.append(`ob[first_name]`, (currentUser?.first_name || ''));
    formData.append(`ob[last_name]`, (currentUser?.last_name || ''));
    formData.append(`ob[gender]`, (currentUser?.gender || ''));
    formData.append(`ob[zip]`, (currentUser?.zip || ''));
    formData.append(`ob[province]`, (calculateProvince(parseInt(currentUser?.zip || '', 10)) || ''));
    formData.append(`ob[city]`, (currentUser?.city || ''));
    formData.append(`ob[email]`, (currentUser?.email || ''));
    if (!currentUser?.is_itsme) {
      formData.append(`ob[phone]`, (currentUser?.phone || ''));
      formData.append(`ob[birth_date]`, (currentUser?.birth_date || ''));
    }

    formData.append(`ob[is_mail_invitation]`, (currentUser?.is_mail_invitation ? '1' : '0'));
    formData.append(`ob[is_mail_keep_posted]`, (currentUser?.is_mail_keep_posted ? '1' : '0'));

    fetch_one<User>('/nl/v3/objects/save', formData, (ob, complete_data) => {
      reloadUser();
      setUserSavedSnackOpen(true);
    }, (z) => {
      reloadUser();
      setServerError(z);
      setServerErrorSnackOpen(true);
    }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
  };

  useEffect(() => {
    reloadUser();
  }, [login]);

  return (<>
    <Grid container spacing="8">
      <Grid item xs={12} sx={{textAlign: 'center'}}>
        <Typography variant="h3" sx={{textDecoration: 'underline'}}>{t("public.profile.title")}</Typography>
      </Grid>

      {!!loader && <Grid item xs={12} sx={{textAlign: 'center'}}><CircularProgress /></Grid>}
      {!loader && <>
        <Grid container item xs={12} md={6} spacing="4" sx={{alignContent: 'baseline'}}>
          <Grid item xs={12} sx={{textAlign: 'center'}}>
            <Typography variant="h4" sx={{textDecoration: 'underline', fontStyle: 'italic'}}>{t("public.profile.sections.personal.title")}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledTextField
              label={t("public.profile.fields.first_name")}
              value={currentUser?.first_name || ''}
              id="first_name"
              anonymous={currentUser?.is_anon}
              onChange={(v) => {if (!!currentUser) setCurrentUser({
                ...currentUser,
                first_name: v});
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledTextField
              label={t("public.profile.fields.last_name")}
              value={currentUser?.last_name || ''}
              id="last_name"
              anonymous={currentUser?.is_anon}
              onChange={(v) => {if (!!currentUser) setCurrentUser({
                ...currentUser,
                last_name: v});
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledDateField
              label={t("public.profile.fields.birth_date")}
              value={currentUser?.birth_date || ''}
              id="birth_date"
              disabled={!!currentUser?.is_itsme}
              onChange={(v) => {
                if (!!currentUser) setCurrentUser({
                ...currentUser,
                birth_date: v.toString()});
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledSelect
              label={t("public.profile.fields.gender")}
              value={currentUser?.gender || 'M'}
              id='gender'
              onChange={(v) => {if (!!currentUser) setCurrentUser({
                ...currentUser,
                gender: v});
              }}
              list={[
                {id: 'M', name: t("public.profile.fields.genders.male")},
                {id: 'F', name: t("public.profile.fields.genders.female")},
                {id: 'X', name: t("public.profile.fields.genders.other")}
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {!!currentUser?.is_itsme && <Avatar src={IconItsme} sx={{marginTop: 0.5, marginLeft: 1}} />}
          </Grid>
        </Grid>

        <Grid container item xs={12} md={6} spacing="4" sx={{alignContent: 'baseline'}}>
          <Grid item xs={12} sx={{textAlign: 'center'}}>
            <Typography variant="h4" sx={{textDecoration: 'underline', fontStyle: 'italic'}}>{t("public.profile.sections.location.title")}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledTextField
              label={t("public.profile.fields.zip")}
              value={currentUser?.zip || ''}
              id="zip"
              onChange={(v) => {if (!!currentUser) setCurrentUser({
                ...currentUser,
                zip: v});
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledTextField
              label={t("public.profile.fields.city")}
              value={currentUser?.city || ''}
              id="city"
              onChange={(v) => {if (!!currentUser) setCurrentUser({
                ...currentUser,
                city: v});
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledTextField
              label={t("public.profile.fields.province")}
              value={calculateProvince(parseInt((currentUser?.zip || ''), 10))}
              id="city"
              disabled
              onChange={(v) => {}}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container item xs={12} md={6} spacing="4" sx={{alignContent: 'baseline'}}>
          <Grid item xs={12} sx={{textAlign: 'center'}}>
            <Typography variant="h4" sx={{textDecoration: 'underline', fontStyle: 'italic'}}>{t("public.profile.sections.contact.title")}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledTextField
              label={t("public.profile.fields.phone")}
              value={currentUser?.phone || ''}
              id="phone"
              disabled={!!currentUser?.is_itsme}
              onChange={(v) => {
                if (!!currentUser) setCurrentUser({
                ...currentUser,
                phone: v});
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledTextField
              label={t("public.profile.fields.email")}
              value={currentUser?.email || ''}
              id="email"
              anonymous={currentUser?.is_anon}
              onChange={(v) => {if (!!currentUser) setCurrentUser({
                ...currentUser,
                email: v});
              }}
              fullWidth
            />
          </Grid>
          {!currentUser?.is_anon && <Grid item xs={12} md={6}>
            <StyledSwitch
              label={t("public.profile.fields.mail_preference.invite_for_panel")}
              offLabel={t("public.sendable.do_not_send")}
              onLabel={t("public.sendable.send")}
              value={!!currentUser?.is_mail_invitation}
              onChange={(v) => {if (!!currentUser) setCurrentUser({
                ...currentUser,
                is_mail_invitation: !!v});
              }}
            />
          </Grid>}
          {!currentUser?.is_anon && <Grid item xs={12} md={6}>
            <StyledSwitch
              label={t("public.profile.fields.mail_preference.keep_posted")}
              offLabel={t("public.sendable.do_not_send")}
              onLabel={t("public.sendable.send")}
              value={!!currentUser?.is_mail_keep_posted}
              onChange={(v) => {if (!!currentUser) setCurrentUser({
                ...currentUser,
                is_mail_keep_posted: !!v});
              }}
            />
          </Grid>}
        </Grid>

        {!currentUser?.is_anon && <Grid container item xs={12} md={6} spacing="4" sx={{alignContent: 'baseline'}}>
          <Grid item xs={12} sx={{textAlign: 'center'}}>
            <Typography variant="h4" sx={{textDecoration: 'underline', fontStyle: 'italic'}}>{t("public.profile.sections.anonymous.title")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography html>{t("public.profile.sections.anonymous.explanation")}</Typography>
          </Grid>
          <Grid item xs={12} sx={{textAlign: 'center', marginTop: 1}}>
            {!loader && <StyledButton
              label={t("public.buttons.anon")}
              id='anon'
              contained
              onClick={(v) => {
                anonUser();
              }}
            />}
          </Grid>
        </Grid>}

        {!!currentUser?.is_anon && <Grid container item xs={12} md={6} spacing="4" sx={{alignContent: 'baseline'}}>
          <Grid item xs={12} sx={{textAlign: 'center'}}>
            <Typography variant="h4" sx={{textDecoration: 'underline', fontStyle: 'italic'}}>{t("public.profile.sections.anonymous.title")}</Typography>
          </Grid>
          <Grid item xs={12} sx={{textAlign: 'center', marginTop: 1}}>
            {!loader && <StyledButton
              label={t("public.buttons.unanon")}
              id='unanon'
              contained
              onClick={(v) => {
                unanonUser();
              }}
            />}
          </Grid>
        </Grid>}

        <Grid item xs={12} sx={{alignContent: 'baseline', textAlign: 'center', position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 1201}}>
          <Divider sx={{marginTop: 1, marginBottom: 2}} />

          {!!loader && <CircularProgress />}
          {!loader && <StyledButton
            label={t("public.buttons.cancel")}
            id='cancel'
            // contained
            onClick={(v) => {
              navigate(`/`);
            }}
            // sx={{marginLeft: 1}}
          />}
          {!loader && <StyledButton
            label={t("public.buttons.save")}
            id='save'
            contained
            onClick={(v) => {
              saveUser();
            }}
            sx={{marginLeft: 1}}
          />}
        </Grid>
        <Grid item xs={12} sx={{textAlign: 'center'}}>
          {!loader && <StyledButton
            label={t("public.buttons.delete_account")}
            id='delete'
            secondary
            text
            onClick={(v) => {
              setShowAccountDeletion(!showAccountDeletion);
            }}
          />}
        </Grid>
        {!!showAccountDeletion && <Grid item xs={12} sx={{textAlign: 'center', padding: 2}}>
          <Box sx={{padding: 2, border: '1px solid transparent', borderColor: theme.palette.secondary.main, borderRadius: 2}}>
            <Typography html sx={{color: theme.palette.secondary.main}}>{t("public.profile.sections.delete_account.explanation")}</Typography>
            <StyledButton
              label={t("public.buttons.delete_account_confirm")}
              id='delete_real'
              contained
              secondary
              onClick={(v) => {
                deleteUser();
              }}
              sx={{marginTop: 3}}
            />
          </Box>
        </Grid>}

      </>}

      {!!userSavedSnackOpen && <AlertSaved open={userSavedSnackOpen} setOpen={(b) => {setUserSavedSnackOpen(b);}} />}
      {!!serverErrorSnackOpen && <AlertSomethingWentWrong extra={serverError} open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
    </Grid>
  </>);
}
