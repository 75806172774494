import { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

import { fetch_all, fetch_one } from '../../lib/server_helper';

import {
  CircularProgress
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import LinkIcon from '@mui/icons-material/Link';
import PieChartIcon from '@mui/icons-material/PieChart';
import SettingsIcon from '@mui/icons-material/Settings';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
// import VisibilityIcon from '@mui/icons-material/Visibility';

import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import {
  DataGrid,
  Page,
  SearchBox,
  StyledButton,
  StyledIconButton,
  Typography
} from '../../components/interface';

import { Project } from '../../models/Project';
import { loginAtom } from '../../lib/auth';
import { checkAllowed } from '../../lib/permission';

// const { DateTime } = require("luxon");

type AdminProjectsProps = {};

export const AdminProjects: FunctionComponent<AdminProjectsProps> = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  const [login, setLogin] = useAtom(loginAtom);

  const [templates, setTemplates] = useState<Project[]>([]);
  const [templatesCount, setTemplatesCount] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(true);
  const [removeLoader, setRemoveLoader] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("id DESC");
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);

  // Settings


  useEffect(() => {
    onSearch('');
  }, [page, order]);

  const onRemove = (id: number, c_id: number) => {
    if (checkAllowed(login, c_id.toString(), null, "Projects", 'd', true)) {
      setRemoveLoader(true);
      fetch_one<Project>('/nl/v3/objects/remove', {
        object: 'project',
        ob: {
          id: id
        }
      }, (obs, complete_data) => {
        onSearch('');
        setRemoveLoader(false);
      }, (z) => {

      }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
    }
  };

  const onSearch = (s: string) => {
    if (checkAllowed(login, null, null, "Projects", 'r', true)) {
      // console.log(filters);
      setLoader(true);
      fetch_all<Project>('/nl/v3/objects/fetch_all', {
        object: 'project',
        fields: ['id', 'created_at', 'updated_at', 'name', 'extra_data', 'enabled', 'company|id/name', 'questions|count', 'pages|count', 'embeddables|count', 'alternate_domains|count'],
        per_page: 20,
        page: page,
        order: order,
        filter: {
          search: s,
          advanced: {
            fallback: s
          }
        }
      }, (obs, complete_data) => {
        setTemplates(obs);

        let count:number = 0;
        if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
        setTemplatesCount(count);

        setLoader(false);
      }, (z) => {
        setServerErrorSnackOpen(true);
      }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
    }
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 100, sortable: true },
    { field: 'company_name', headerName: 'Company', width: 200, sortable: true },
    { field: 'name', headerName: 'Name', width: 200, sortable: true },
    { field: 'enabled', headerName: 'Is Active', width: 120, sortable: false, renderCell: params => <>
      {!!params.row.enabled && <CheckIcon sx={{color: theme.palette.tertiary?.main, marginRight: 0.5}} />}
      {!params.row.enabled && <CloseIcon sx={{color: theme.palette.secondary.main, marginRight: 0.5}} />}
    </> },
    { field: 'questions_count', headerName: 'Questions', width: 100, sortable: false, renderCell: params => <>
      <Typography sx={{
        fontSize: '0.9rem',
        ...(checkAllowed(login, params.row.company_id?.toString() || '0', params.row.id.toString(), "ProjectQuestions", 'r') ? {cursor: 'pointer', textDecoration: 'underline'} : {})
      }} onClick={() => {
        if (checkAllowed(login, params.row.company_id?.toString() || '0', params.row.id.toString(), "ProjectQuestions", 'r')) navigate(`/questions/${params.row.id}`);
      }}>{params.row.questions_count}</Typography>
    </> },
    { field: 'pages_count', headerName: 'Pages', width: 100, sortable: false, renderCell: params => <>
      <Typography sx={{
        fontSize: '0.9rem',
        ...(checkAllowed(login, params.row.company_id?.toString() || '0', params.row.id.toString(), "ProjectPages", 'r') ? {cursor: 'pointer', textDecoration: 'underline'} : {})
      }} onClick={() => {
        if (checkAllowed(login, params.row.company_id?.toString() || '0', params.row.id.toString(), "ProjectPages", 'r')) navigate(`/pages/${params.row.id}`);
      }}>{params.row.pages_count}</Typography>
    </> },
    { field: 'embeddables_count', headerName: 'Embed.', width: 100, sortable: false, renderCell: params => <>
      <Typography sx={{
        fontSize: '0.9rem',
        ...(checkAllowed(login, params.row.company_id?.toString() || '0', params.row.id.toString(), "ProjectEmbeddables", 'r') ? {cursor: 'pointer', textDecoration: 'underline'} : {})
      }} onClick={() => {
        if (checkAllowed(login, params.row.company_id?.toString() || '0', params.row.id.toString(), "ProjectEmbeddables", 'r')) navigate(`/embeddables/${params.row.id}`);
      }}>{params.row.embeddables_count}</Typography>
    </> },
    { field: 'alternate_domains_count', headerName: 'Domains', width: 100, sortable: false, renderCell: params => <>
      <Typography sx={{
        fontSize: '0.9rem',
        ...(checkAllowed(login, params.row.company_id?.toString() || '0', params.row.id.toString(), "ProjectDomains", 'r') ? {cursor: 'pointer', textDecoration: 'underline'} : {})
      }} onClick={() => {
        if (checkAllowed(login, params.row.company_id?.toString() || '0', params.row.id.toString(), "ProjectDomains", 'r')) navigate(`/domains/${params.row.id}`);
      }}>{params.row.alternate_domains_count}</Typography>
    </> },
  ];

  return (
    <Page sx={{}} title={t("objects.projects.title")} actions={<>
      {checkAllowed(login, null, null, "Projects", 'c', true) && <StyledButton
        label="Create new"
        id='create_new'
        contained
        path={'/project/new'}
        sx={{marginRight: 3}}
        startIcon={<AddCircleOutlineIcon />}
      />}
    </>}>
      <SearchBox
        simple={true}
        onSearch={(s) => {
          setPage(0);
          onSearch(s);
        }}
      />
      <DataGrid
        action_count={5}
        actions={(params) => {
          return (<>
            {checkAllowed(login, params.row.company_id?.toString() || '0', params.row.id.toString(), "Projects", 'u') && <StyledIconButton title="Edit" color="primary" path={`/project/${params.row.id}`}>
              <EditIcon />
            </StyledIconButton>}
            {!!removeLoader && <CircularProgress />}
            {!removeLoader && checkAllowed(login, params.row.company_id?.toString() || '0', params.row.id.toString(), "Projects", 'd') && <StyledIconButton title="Destroy" destroyBtn color="primary" onClick={(e) => {
              onRemove(params.row.id, params.row.company_id || 0);
            }}>
              <DeleteForeverIcon />
            </StyledIconButton>}
            {checkAllowed(login, params.row.company_id?.toString() || '0', params.row.id.toString(), "ProjectTheme", 'r') && <StyledIconButton title="Theme & Menu items" color="primary" path={`/theme/${params.row.id}`}>
              <SettingsIcon />
            </StyledIconButton>}
            {checkAllowed(login, params.row.company_id?.toString() || '0', params.row.id.toString(), "Projects", 'r') && <StyledIconButton title="PWA Settings" color="primary" path={`/pwa/${params.row.id}`}>
              <SmartphoneIcon />
            </StyledIconButton>}
            {checkAllowed(login, params.row.company_id?.toString() || '0', params.row.id.toString(), "Projects", 'r') && <StyledIconButton title="Internal URL" color="primary" onClick={() => {
              window.open(`https://pnlmtrcs${params.row.id}.panelmetrics.com/`, "_BLANK");
            }}>
              <LinkIcon />
            </StyledIconButton>}
          </>);
        }}
        getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
        objectCount={templatesCount}
        objects={(templates || []).map(u => ({
          ...u
        }))}
        columns={columns}
        loader={loader}
        onSortModelChange={(a, b) => {
          let a_0_field:string = a[0].field;
          setOrder([a_0_field, a[0].sort].join(' '));
        }}
        page={page}
        setPage={(p) => {setPage(p);}}
      />

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
    </Page>
  );
}
