import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';

import {
  Avatar,
  Box,
  Grid,
  InputAdornment,
  TextField,
  Tooltip
} from '@mui/material';

import { loginAtom } from '../../lib/auth';

import ErrorIcon from '@mui/icons-material/Error';

var he = require('he');

type AvailableLang = 'nl' | 'fr' | 'en';
type StyledTextFieldTranslProps = {
  sx?: object;
  label: string;
  value: {[z:string]: string};
  activeLocale: AvailableLang;
  fullWidth?: boolean;
  error?: boolean;
  id: string;
  helperText?: string|React.ReactNode;
  type?: string;
  multiline?: boolean;
  errorArray?: string[];
  onChange: (z:{[y:string]: string}) => void;
};

export const StyledTextFieldTransl: FunctionComponent<StyledTextFieldTranslProps> = ({label, onChange, sx, error, value, id, type, multiline, fullWidth, errorArray, helperText, activeLocale}) => {
  const theme = useTheme();

  const [login, setLogin] = useAtom(loginAtom);
  const [usedActiveLocale, setUsedActiveLocale] = useState<AvailableLang>(activeLocale);

  useEffect(() => {
    setUsedActiveLocale(activeLocale);
  }, [activeLocale]);

  // const [editorContent, setEditorContent] = useState<string>(value[activeLocale] || '');
  // const [editorContentNl, setEditorContentNl] = useState<string>(value.nl);
  // const [editorContentFr, setEditorContentFr] = useState<string>(value.fr);
  // const [inited, setInited] = useState<boolean>(false);

  let setting_languages_split_raw = '0';
  // if (!!login && !!login.extra_data?.settings?.languages_split) setting_languages_split_raw = login.extra_data.settings.languages_split;
  let setting_languages_split = (setting_languages_split_raw === "1");

  return (<>
    {!!setting_languages_split && <>
      {/* <Grid item xs={12}>
        <TextField
          value={value.nl}
          helperText={helperText}
          id={id}
          error={!!errorArray && errorArray.indexOf(id) > -1}
          type={type}
          variant="outlined"
          multiline={multiline}
          label={`${he.decode(label)} (NL)`}
          onChange={(e) => {onChange({...value, nl: e.target.value})}}
          sx={{
            ...(fullWidth ? {
              width: '100%',
              paddingTop: 1,
              paddingBottom: 1
            } : {
              width: 'auto',
              padding: 1
            }),
            'input': {
              padding: 1
            },
            'label[data-shrink=true]': {
              left: fullWidth ? 0 : 8,
              top: 8
            },
            'label[data-shrink=false]': {
              opacity: 0.4
            },
            ...sx
          }}
          InputProps={{
            endAdornment: !!error ?(<InputAdornment position="end">
              <ErrorIcon style={{color: '#d32f2f'}} />
            </InputAdornment>) : null
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={value.fr}
          helperText={helperText}
          id={id}
          error={!!errorArray && errorArray.indexOf(id) > -1}
          type={type}
          variant="outlined"
          multiline={multiline}
          label={`${he.decode(label)} (FR)`}
          onChange={(e) => {onChange({...value, fr: e.target.value})}}
          sx={{
            ...(fullWidth ? {
              width: '100%',
              paddingTop: 1,
              paddingBottom: 1
            } : {
              width: 'auto',
              padding: 1
            }),
            'input': {
              padding: 1
            },
            'label[data-shrink=true]': {
              left: fullWidth ? 0 : 8,
              top: 8
            },
            'label[data-shrink=false]': {
              opacity: 0.4
            },
            ...sx
          }}
          InputProps={{
            endAdornment: !!error ?(<InputAdornment position="end">
              <ErrorIcon style={{color: '#d32f2f'}} />
            </InputAdornment>) : null
          }}
        />
      </Grid> */}
    </>}
    
    {!setting_languages_split && <Box sx={{position: 'relative', width: '100%'}}>
      <Tooltip title={(value['en'] || '').replace(/<\/?[^>]+(>|$)/g, "")}><Avatar
        sx={{position: 'absolute', cursor: 'pointer', opacity: (usedActiveLocale === 'en' ? 1 : 0.6), top: -4, right: 60, zIndex: 999, width: 24, height: 24, fontSize: '0.8rem', backgroundColor: ((value['en'] || '').replace(/<\/?[^>]+(>|$)/g, "") === '' ? 'red' : theme.palette.tertiary?.main)}}
        onClick={() => {setUsedActiveLocale('en');}}
      >EN</Avatar></Tooltip>
      <Tooltip title={(value['nl'] || '').replace(/<\/?[^>]+(>|$)/g, "")}><Avatar
        sx={{position: 'absolute', cursor: 'pointer', opacity: (usedActiveLocale === 'nl' ? 1 : 0.6), top: -4, right: 32, zIndex: 999, width: 24, height: 24, fontSize: '0.8rem', backgroundColor: ((value['nl'] || '').replace(/<\/?[^>]+(>|$)/g, "") === '' ? 'red' : theme.palette.tertiary?.main)}}
        onClick={() => {setUsedActiveLocale('nl');}}
      >NL</Avatar></Tooltip>
      <Tooltip title={(value['fr'] || '').replace(/<\/?[^>]+(>|$)/g, "")}><Avatar
        sx={{position: 'absolute', cursor: 'pointer', opacity: (usedActiveLocale === 'fr' ? 1 : 0.6), top: -4, right: 4, zIndex: 999, width: 24, height: 24, fontSize: '0.8rem', backgroundColor: ((value['fr'] || '').replace(/<\/?[^>]+(>|$)/g, "") === '' ? 'red' : theme.palette.tertiary?.main)}}
        onClick={() => {setUsedActiveLocale('fr');}}
      >FR</Avatar></Tooltip>
      <TextField
        value={value[usedActiveLocale] || ''}
        helperText={helperText}
        id={id}
        error={!!errorArray && errorArray.indexOf(id) > -1}
        type={type}
        variant="outlined"
        multiline={multiline}
        label={he.decode(label.replace(/<[^>]*>?/gm, ''))}
        onChange={(e) => {onChange({...value, [usedActiveLocale]: e.target.value})}}
        sx={{
          ...(fullWidth ? {
            width: '100%',
            paddingTop: 1,
            paddingBottom: 1
          } : {
            width: 'auto',
            padding: 1
          }),
          'input': {
            padding: 1
          },
          'label[data-shrink=true]': {
            left: fullWidth ? 0 : 8,
            top: 8
          },
          'label[data-shrink=false]': {
            opacity: 0.4
          },
          ...sx
        }}
        InputProps={{
          endAdornment: !!error ?(<InputAdornment position="end">
            <ErrorIcon style={{color: '#d32f2f'}} />
          </InputAdornment>) : null
        }}
      />
    </Box>}
  </>);
}