import React, { FunctionComponent } from 'react';

// import {
//   Grid
// } from '@mui/material';

import { StyledTextField } from '../StyledTextField';

type SearchFieldProps = {
  name: string;
  value: string;
  setValue: (v:string) => void;
  setEnterPressed?: (v:boolean) => void;
};

export const SearchField: FunctionComponent<SearchFieldProps> = ({name, value, setValue, setEnterPressed}) => {

  return (<StyledTextField
    label={name}
    value={value}
    id={`search_${name.replace(/^[a-zA-Z0-9]/g, '_')}`}
    onChange={(v) => {
      setValue(v);
    }}
    setEnterPressed={setEnterPressed}
    sx={{width: '100%', "input": {backgroundColor: '#a9c2c0', padding: 1}}}
  />);
}