import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import InputMask from 'react-input-mask';
import { useTheme } from '@mui/material/styles';

import {
  InputAdornment,
  TextField
} from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

var he = require('he');

type BankAccountProps = {
  sx?: object;
  label: string;
  value: string;
  fullWidth?: boolean;
  error?: boolean;
  id: string;
  type?: string;
  errorArray?: string[];
  onChange: (z:string, y:boolean) => void;
};

export const BankAccount: FunctionComponent<BankAccountProps> = ({label, onChange, sx, error, value, id, type, fullWidth, errorArray}) => {
  const theme = useTheme();

  const [okay, setOkay] = useState<boolean>(false);
  const [realValue, setRealValue] = useState<string>('');

  if (!realValue && !!value) {
    let checked = false;
    if (!!value) {
      let iban = value.toUpperCase().replace(/[^A-Z0-9]/g, '');
      let code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);
      let digits;

      if (!!code && !!code[1] && !!code[2] && !!code[3]) {
        digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
          return (letter.charCodeAt(0) - 55).toString();
        });
        var checksum = digits.slice(0, 2), fragment;
        for (var offset = 2; offset < digits.length; offset += 7) {
          fragment = String(checksum) + digits.substring(offset, offset + 7);
          checksum = (parseInt(fragment, 10) % 97).toString();
        }
        if (checksum === "1") checked = true;
      }
    }

    setOkay(checked);
    setRealValue(value);
  }

  return (
    <InputMask
      mask={["B", "E", /[0-9]/, /[0-9]/, " ", /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, " ", /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, " ", /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
      value={realValue}
      onChange={(v) => {
        let iban = v.target.value.toUpperCase().replace(/[^A-Z0-9]/g, '');
        let code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);
        let digits;
        let checked = false;

        if (!!code && !!code[1] && !!code[2] && !!code[3]) {
          digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
            return (letter.charCodeAt(0) - 55).toString();
          });
          var checksum = digits.slice(0, 2), fragment;
          for (var offset = 2; offset < digits.length; offset += 7) {
            fragment = String(checksum) + digits.substring(offset, offset + 7);
            checksum = (parseInt(fragment, 10) % 97).toString();
          }
          if (checksum === "1") checked = true;
        }
        setOkay(checked);
        setRealValue(v.target.value);
        if (!!onChange) onChange(v.target.value, checked);
      }}
    >
      <TextField
        // value={value}
        id={id}
        error={!!errorArray && errorArray.indexOf(id) > -1}
        type={type}
        variant="outlined"
        label={he.decode(label)}
        // onChange={(e) => {onChange(e.target.value)}}
        sx={{
          ...(fullWidth ? {
            width: '100%',
            paddingTop: 1,
            paddingBottom: 1
          } : {
            width: 'auto',
            padding: 1
          }),
          'input': {
            padding: 1
          },
          'label[data-shrink=true]': {
            left: fullWidth ? 0 : 8,
            top: 8
          },
          'label[data-shrink=false]': {
            opacity: 0.4
          },
          ...sx
        }}
        InputProps={{
          endAdornment: (<InputAdornment position="end">
            {!okay && <CloseIcon sx={{color: theme.palette.secondary.main}} />}
            {!!okay && <CheckIcon sx={{color: theme.palette.tertiary?.main}} />}
          </InputAdornment>)
        }}
      />
    </InputMask>
  );
}