import { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
// import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

import { fetch_all, fetch_one } from '../../lib/server_helper';
import { checkAllowed } from '../../lib/permission';

import {
  CircularProgress
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import SettingsIcon from '@mui/icons-material/Settings';

import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import {
  DataGrid,
  Page,
  SearchBox,
  StyledButton,
  StyledIconButton
} from '../../components/interface';

import { User } from '../../models/User';
import { loginAtom } from '../../lib/auth';
import { parseCommandLine } from 'typescript';

const { DateTime } = require("luxon");

type AdminUsersProps = {};

export const AdminUsers: FunctionComponent<AdminUsersProps> = () => {
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  const [login, setLogin] = useAtom(loginAtom);

  const [templates, setTemplates] = useState<User[]>([]);
  const [templatesCount, setTemplatesCount] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(true);
  const [removeLoader, setRemoveLoader] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("id DESC");
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);

  // Settings


  useEffect(() => {
    if ((!!login && checkAllowed(login, null, null, "Users", 'r'))) {
      onSearch('');
    }
  }, [page, order]);

  const onRemove = (id: number) => {
    setRemoveLoader(true);
    fetch_one<User>('/nl/v3/objects/remove', {
      object: 'user',
      ob: {
        id: id
      }
    }, (obs, complete_data) => {
      onSearch('');
      setRemoveLoader(false);
    }, (z) => {

    }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
  };

  const onSearch = (s: string) => {
    // console.log(filters);
    setLoader(true);
    fetch_all<User>('/nl/v3/objects/fetch_all', {
      object: 'user',
      fields: ['id', 'created_at', 'updated_at', 'first_name', 'last_name', 'extra_data', 'enabled', 'is_admin', 'email', 'permission_map', 'invite_sent'],
      per_page: 20,
      page: page,
      order: order,
      filter: {
        search: s,
        advanced: {
          fallback: s
        }
      }
    }, (obs, complete_data) => {
      setTemplates(obs);

      let count:number = 0;
      if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
      setTemplatesCount(count);

      setLoader(false);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 100, sortable: true },
    { field: 'full_name', headerName: 'Name', width: 200, sortable: false },
    { field: 'email', headerName: 'Email', width: 200, sortable: true },
    { field: 'created_at', headerName: 'Created', width: 120, valueFormatter: params => DateTime.fromISO(params?.value).toFormat("D") },
    { field: 'updated_at', headerName: 'Updated', width: 120, valueFormatter: params => DateTime.fromISO(params?.value).toFormat("D") },
    { field: 'enabled', headerName: 'Is Active', width: 120, sortable: false, renderCell: params => <>
      {!!params.row.enabled && <CheckIcon sx={{color: theme.palette.tertiary?.main, marginRight: 0.5}} />}
      {!params.row.enabled && <CloseIcon sx={{color: theme.palette.secondary.main, marginRight: 0.5}} />}
    </> },
    { field: 'invite_sent', headerName: 'Invite sent', width: 120, valueFormatter: params => (!!params?.value ? DateTime.fromISO(params?.value).toFormat("D") : '') },
  ];

  return (
    <Page sx={{}} title={t("objects.users.title")} actions={<>
      {(!!login && checkAllowed(login, null, null, "Users", 'r')) && <StyledButton
        label="Invite admin"
        id='create_new'
        contained
        path={`/admin/new`}
        sx={{marginRight: 3}}
        startIcon={<AddCircleOutlineIcon />}
      />}
    </>}>
      <SearchBox
        simple={true}
        onSearch={(s) => {
          setPage(0);
          if ((!!login && checkAllowed(login, null, null, "Users", 'r'))) {
            onSearch(s);
          } else {
            setLoader(false);
          }
        }}
      />
      <DataGrid
        action_count={1}
        actions={(params) => {
          return (<>
            {checkAllowed(login, null, null, "Users", 'u') && login?.id !== params.row.id && <StyledIconButton color="primary" path={`/permissions/${params.row.id}`}>
              <SettingsIcon />
            </StyledIconButton>}
            {/* {!!removeLoader && <CircularProgress />}
            {!removeLoader && <StyledIconButton title="Destroy" destroyBtn color="primary" onClick={(e) => {
              onRemove(params.row.id);
            }}>
              <DeleteForeverIcon />
            </StyledIconButton>} */}
          </>);
        }}
        getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
        objectCount={templatesCount}
        objects={(templates || []).map(u => ({
          ...u,
          full_name: [u.first_name, u.last_name].join(' ')
        }))}
        columns={columns}
        loader={loader}
        onSortModelChange={(a, b) => {
          let a_0_field:string = a[0].field;
          setOrder([a_0_field, a[0].sort].join(' '));
        }}
        page={page}
        setPage={(p) => {setPage(p);}}
      />

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
    </Page>
  );
}
