import { FunctionComponent, useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
// import { useNavigate } from 'react-router-dom';
// import { useTranslation } from 'react-i18next'

import { fetch_all } from '../../lib/server_helper';

import {
  StyledSelect
} from '../../components/interface';

import { Company } from '../../models/Company';
import { loginAtom } from '../../lib/auth';

type PickersCompanyPickerProps = {
  value: string;
  onChange: (x:string) => void;
};

export const PickersCompanyPicker: FunctionComponent<PickersCompanyPickerProps> = ({value, onChange}) => {
  // const theme = useTheme();
  // const navigate = useNavigate();
  // const {t, i18n} = useTranslation(['translations']);

  const [login, setLogin] = useAtom(loginAtom);

  const [companies, setCompanies] = useState<Company[]>([]);
  const [loader, setLoader] = useState<boolean>(true);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("name ASC");

  useEffect(() => {
    onSearch('');
  }, [page, order]);

  const onSearch = (s: string) => {
    // console.log(filters);
    setLoader(true);
    fetch_all<Company>('/nl/v3/objects/fetch_all', {
      object: 'company',
      fields: ['id', 'name'],
      per_page: 1000,
      page: page,
      order: order
    }, (obs, complete_data) => {
      setCompanies(obs);
      setLoader(false);
    }, (z) => {
      
    }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
  };

  return (<>
    <StyledSelect
      label="Companies"
      value={value}
      id='companies'
      onChange={(v) => {
        onChange(v);
      }}
      list={companies.map(company => ({id: company.id.toString(), name: company.name}))}
    />
  </>);
}




