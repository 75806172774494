import { FunctionComponent, useState, useEffect } from 'react';
// import { useTheme } from '@mui/material/styles';
// import { useAtom, useAtomValue } from 'jotai';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next'

import {
  Grid
} from '@mui/material';

import { SharedPoll } from '../shared/Poll';

type PubPollProps = {};

export const PubPoll: FunctionComponent<PubPollProps> = () => {
  // const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  return (<SharedPoll
    notLoggedIn={<Grid container spacing="8">
      <Grid item xs={12} sx={{textAlign: 'center'}}>
        {t("general.errors.not_logged_in")}
      </Grid>
    </Grid>}
    endPoll={() => {navigate("/poll_outro");}}
  />);
}
