import React, { FunctionComponent, useEffect, useState } from 'react';

import {
  Grid
} from '@mui/material';

import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

import { StyledButton } from '../StyledButton';
import { StyledIconButton } from '../StyledIconButton';
import { StyledTextField } from '../StyledTextField';

type SearchGeneralProps = {
  collapsable?: boolean;
  general_visible: boolean;
  resetSearch: () => void;
  searchCatchAll: string;
  setSearchCatchAll: (v:string) => void;
  setEnterPressed: (v:boolean) => void;
  collapsed: boolean;
  setCollapsed: (v:boolean) => void;
};

export const SearchGeneral: FunctionComponent<SearchGeneralProps> = ({collapsable, collapsed, setCollapsed, general_visible, resetSearch, searchCatchAll, setSearchCatchAll, setEnterPressed}) => {

  return (<>
    {(!!general_visible) && <>
      <Grid item xs={3} sx={{textAlign: 'right', paddingTop: 1}}>
        {!!collapsable && <>
          {!!collapsed && <StyledIconButton onClick={() => {
            setCollapsed(false);
          }}>
            <UnfoldMoreIcon />
          </StyledIconButton>}
          {!collapsed && <StyledIconButton onClick={() => {
            setCollapsed(true);
          }}>
            <UnfoldLessIcon />
          </StyledIconButton>}
        </>}
      </Grid>
      <Grid item xs={3}>
        <StyledButton
          label="Reset search"
          id='reset'
          // startIcon={}
          onClick={() => {
            resetSearch();
          }}
          sx={{marginBottom: -4.5, marginLeft: 1}}
        />
      </Grid>
      <Grid item xs={6} sx={{paddingRight: 1}}>
        <StyledTextField
          label="Search"
          value={searchCatchAll}
          id='search_catch_all'
          onChange={(v) => {
            setSearchCatchAll(v);
          }}
          setEnterPressed={setEnterPressed}
          sx={{width: '100%', "input": {backgroundColor: '#a9c2c0', padding: 1}}}
        />
      </Grid>
    </>}
  </>);
}