import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
// import { useTheme } from '@mui/material/styles';

import {
  InputAdornment,
  TextField
} from '@mui/material';

import ErrorIcon from '@mui/icons-material/Error';

const { DateTime } = require("luxon");
var he = require('he');

type StyledDateFieldProps = {
  sx?: object;
  label: string;
  value: string;
  fullWidth?: boolean;
  disabled?: boolean;
  error?: boolean;
  id: string;
  type?: string;
  errorArray?: string[];
  onChange: (z:string|ChangeEvent<HTMLInputElement>) => void;
  setEnterPressed?: (v:boolean) => void;
};

export const StyledDateField: FunctionComponent<StyledDateFieldProps> = ({disabled, label, onChange, sx, error, value, id, type, fullWidth, errorArray, setEnterPressed}) => {
  // const theme = useTheme();

  const [editableObject, setEditableObject] = useState<string>(DateTime.fromISO(value).toFormat("dd/LL/yyyy"));

  useEffect(() => {
    if (!!value && value.indexOf("/") > -1) setEditableObject(value);
    if (!!value && value.indexOf("-") > -1) setEditableObject(DateTime.fromISO(value).toFormat("dd/LL/yyyy"));
    if (!value) setEditableObject('');
  }, [value]);

  useEffect(() => {
    let s = editableObject;
    if (s === 'Invalid DateTime' || s === '__/__/____') s = '';
    if (!!onChange) onChange(s);
  }, [editableObject]);

  return (
    <InputMask
      mask="99/99/9999"
      disabled={disabled}
      placeholder="dd/mm/yyyy"
      value={editableObject}
      onChange={(v) => {setEditableObject(v.target.value);}}
      onKeyDown={(e) => {
        if (e.key === 'Enter' && !!setEnterPressed) {
          setEnterPressed(true);
        }
      }}
    >
      <TextField
        // value={value}
        id={id}
        error={!!errorArray && errorArray.indexOf(id) > -1}
        type={type}
        variant="outlined"
        label={he.decode(label.replace(/<[^>]*>?/gm, ''))}
        // onChange={(e) => {onChange(e.target.value)}}
        sx={{
          ...(fullWidth ? {
            width: '100%',
            paddingTop: 1,
            paddingBottom: 1
          } : {
            width: 'auto',
            padding: 1
          }),
          'input': {
            padding: 1
          },
          'label[data-shrink=true]': {
            left: fullWidth ? 0 : 8,
            top: 8
          },
          'label[data-shrink=false]': {
            opacity: 0.4
          },
          ...sx
        }}
        InputProps={{
          endAdornment: !!error ?(<InputAdornment position="end">
            <ErrorIcon style={{color: '#d32f2f'}} />
          </InputAdornment>) : null
        }}
      />
    </InputMask>
  );
}