import { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useParams } from 'react-router-dom';

// import SunEditor from 'suneditor-react';
// import 'suneditor/dist/css/suneditor.min.css';

import { fetch_all } from '../lib/server_helper';

import {
  Box,
  CircularProgress,
  Divider,
  Grid
} from '@mui/material';

import {
  Page,
  Typography
} from '../components/interface';

import { Help as HelpOb } from '../models/Help';
import { loginAtom } from '../lib/auth';

const { DateTime } = require("luxon");

type HelpProps = {};

export const Help: FunctionComponent<HelpProps> = () => {
  const theme = useTheme();
  // const { isLoading, isError, data, error } = useQuery({
  //   queryKey: ['login'],
  //   queryFn: async () => {return },
  // });

  const params = useParams();
  const slug: string | undefined = params.slug;

  const [login, setLogin] = useAtom(loginAtom);
  const [helps, setHelps] = useState<HelpOb[]>([]);
  const [loader, setLoader] = useState<boolean>(true);

  let setting_editor_height = '300px';
  if (!!login && !!login.extra_data?.settings?.editor_height) setting_editor_height = login.extra_data.settings.editor_height;

  const fetchHelp = () => {
    setLoader(true);
    
    fetch_all<HelpOb>('/nl/v3/objects/fetch_all', {
      object: 'help',
      fields: ['id', 'slug', 'user|first_name/last_name', 'content', 'created_at', 'weight'],
      filter:{
        advanced: {
          slug: slug
        }
      },
      order: "weight DESC, id DESC"
    }, (obs, complete_data) => {
      setHelps(obs);
      setLoader(false);
    }, (z) => {

    }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined))
  };

  useEffect(() => {
    fetchHelp();
  }, [slug]);

  return (
    <Page sx={{}} title="Help">
      <Grid container spacing={8}>
        <Grid container item xs={12}>
          {!!loader && <Grid item xs={12}>
            <CircularProgress />
          </Grid>}
          <Grid item xs={12}>
            <Typography variant="h3" sx={{marginBottom: 2}}>Help for '{slug}'</Typography>
          </Grid>
          <Grid item xs={12}>
            {!!helps && helps.map(ob => (<Box sx={{
              border: '2px solid transparent',
              borderColor: theme.palette.primary.main,
              padding: 1,
              marginBottom: 1
            }}>
              <Typography variant="body2" html>{ob.content}</Typography>
              <Divider sx={{marginTop: 2, marginBottom: 1}} />
              <Box sx={{display: 'inline-block', width: '49%'}}>
                <Typography variant="body1">{ob.user_first_name} {ob.user_last_name}</Typography>
              </Box>
              <Box sx={{display: 'inline-block', width: '49%', textAlign: 'right', float: 'right'}}>
                <Typography variant="body2">{DateTime.fromISO(ob.created_at).toFormat("f")}</Typography>
              </Box>
            </Box>))}
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
}
