import React, { FunctionComponent } from 'react';


import { BlockPublicImage } from './Image';
import { BlockPublicImageLeft } from './ImageLeft';
import { BlockPublicImageRight } from './ImageRight';
import { BlockPublicImageTitleText } from './ImageTitleText';
import { BlockPublicText } from './Text';
import { BlockPublicTitleText } from './TitleText';
import { BlockPublicTitleText2Col } from './TitleText2Col';
import { BlockPublicSeparator } from './Separator';

import { EmailBlock } from '../../../models/EmailBlock';

type BlockPublicBaseProps = {
  block: EmailBlock;
  activeLocale?: 'nl' | 'fr';
  marked: boolean;
};

export const BlockPublicBase: FunctionComponent<BlockPublicBaseProps> = ({block, marked, activeLocale}) => {
  
  return (<>
    {block.type === "image" && <BlockPublicImage block={block} marked={marked} activeLocale={activeLocale || 'nl'} />}
    {block.type === "text" && <BlockPublicText block={block} marked={marked} activeLocale={activeLocale || 'nl'} />}
    {block.type === "title_text" && <BlockPublicTitleText block={block} marked={marked} activeLocale={activeLocale || 'nl'} />}
    {block.type === "image_title_text" && <BlockPublicImageTitleText block={block} marked={marked} activeLocale={activeLocale || 'nl'} />}
    {block.type === "image_left" && <BlockPublicImageLeft block={block} marked={marked} activeLocale={activeLocale || 'nl'} />}
    {block.type === "image_right" && <BlockPublicImageRight block={block} marked={marked} activeLocale={activeLocale || 'nl'} />}
    {block.type === "title_text_2col" && <BlockPublicTitleText2Col block={block} marked={marked} activeLocale={activeLocale || 'nl'} />}
    {block.type === "separator" && <BlockPublicSeparator block={block} marked={marked} activeLocale={activeLocale || 'nl'} />}
  </>);
};