import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
import useMediaQuery from '@mui/material/useMediaQuery';

import { check_subdomain } from '../lib/server_helper';
import { do_logout, loginAtom } from '../lib/auth';
import { currentProjectAtom } from '../lib/project';

import {
  // Autocomplete,
  // Avatar,
  Box,
  // Divider,
  FormControl,
  MenuItem,
  Select
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MenuIcon from '@mui/icons-material/Menu';

import {Sidebar} from '../components/Sidebar';
import {
  Typography
} from '../components/interface';

import { Project } from '../models/Project';

const { DateTime } = require("luxon");

// type AvailableLang = 'nl' | 'fr' | 'en';
type LayoutProps = {
  sx?: object;
  children?: React.ReactNode;
  version?: string;
  loginId?: number;
  project?: Project;
}

export const Layout: FunctionComponent<LayoutProps> = ({sx, children, version, project}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentProject, setCurrentProject] = useAtom(currentProjectAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const nonmobile = useMediaQuery('(min-width:600px)');

  const [locale, setLocale] = useState<string>(i18n.resolvedLanguage || project?.fallback_language|| 'nl');

  const [menuVisible, setMenuVisible] = useState<boolean>(false);

  let subdomain = check_subdomain();

  useEffect(() => {
    i18n.changeLanguage(locale)
  }, [locale]);

  const fb_lang = project?.fallback_language|| 'nl';
  const lang = i18n.resolvedLanguage || fb_lang;

  const landing_enabled = (!!currentProject && !!currentProject.enabled && !!currentProject.show_landing);

  // return ((!!login && !!login.is_admin) || subdomain === 'admin') ? (<Box sx={{
  return window.location.pathname.indexOf("/embed/") === 0 ? <>{children}</> : ((subdomain === 'admin') ? (<Box sx={{
    display: 'flex',
    flexWrap: 'wrap',
    height: '100%'
  }}>
    <Box sx={{
      flexBasis: {xs: 40, md: 200},
      maxWidth: '200px',
      flexGrow: 1,
      position: 'relative',
      paddingBottom: 8,
      backgroundColor: theme?.palette?.primary_grey?.light
    }}>
      <Box sx={{
        width: 'auto',
        padding: 1
      }}>
        <Sidebar />
      </Box>

      <Box sx={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        padding: 1,
        cursor: 'pointer'
      }} onClick={() => {
        navigate(`/help/versioning`);
      }}>
        v{version}
      </Box>
    </Box>
    <Box sx={{
      flexBasis: 0,
      flexGrow: 999,
      maxWidth: "calc(100% - 200px)"
    }}>
      <Box sx={{
        width: 'auto',
        padding: 4
      }}>
        {children}
      </Box>
    </Box>
  </Box>) : (<Box sx={{
    display: 'flex',
    flexWrap: 'wrap',
    height: '100%',
    alignContent: 'baseline',
    backgroundColor: 'rgb(250, 250, 250)'
  }}>
    {((!nonmobile && !project?.theme_info?.mobile_header) || (!!nonmobile && !!project?.theme_info?.header)) && <Box sx={{marginTop: (landing_enabled ? '0px' : '40px'), height: !!nonmobile ? 300 : 150, width: '100%', maxWidth: '1200px', marginLeft: 'auto', marginRight: 'auto', backgroundImage: `url(${project?.theme_info?.header})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center center'}}>
      {/* future header content */}
    </Box>}
    {!nonmobile && !!project?.theme_info?.mobile_header && <Box sx={{marginTop: (landing_enabled ? '0px' : '40px'), height: 150, width: '100%', backgroundImage: `url(${project?.theme_info?.mobile_header})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center center'}}>
      {/* future header content */}
    </Box>}
    {!landing_enabled && <>
      <Box sx={{display: 'flex', height: 40, width: '100%', flexDirection: 'row', backgroundColor: theme.palette.primary.dark, position: 'fixed', top: 0, left: 0}}>
        <Box sx={{display: 'flex', height: 40, width: '100%', maxWidth: '1200px', flexDirection: 'row', backgroundColor: theme.palette.primary.dark, marginLeft: 'auto', marginRight: 'auto'}}>
          {!!nonmobile && <>
            <Box sx={{
              // padding: 1,
              marginRight: 1,
              display: 'flex',
              cursor: 'pointer',
              backgroundColor: 'transparent',
              color: 'black',
              '&:hover': {
                backgroundColor: theme.palette.primary.light
              }
            }} onClick={() => {
              navigate('/');
            }}>
              <img src={!!currentProject && !!currentProject.theme_info?.menu_logo ? currentProject.theme_info?.menu_logo : (!!currentProject && !!currentProject.theme_info?.logo ? currentProject.theme_info?.logo : "https://via.placeholder.com/100x40")} style={{width: 'auto', height: '40px'}} />
            </Box>
          </>}
          {!!nonmobile && (project?.theme_info?.menu_items || []).map(menu_item => <>
            <Box sx={{
              padding: 1,
              marginRight: 1,
              display: 'flex',
              cursor: 'pointer',
              backgroundColor: 'transparent',
              color: 'black',
              '&:hover': {
                backgroundColor: theme.palette.primary.light
              }
            }} onClick={() => {
              navigate(menu_item.full_url || '/');
            }}>
              <Typography sx={{display: 'inline-block', color: theme.palette.primary.contrastText}}>{menu_item.name[lang] || menu_item.name[fb_lang]|| menu_item.name['nl']}</Typography>
            </Box>
          </>)}
          {!nonmobile && <>
            {!menuVisible && <Box sx={{padding: 1, display: 'flex', backgroundColor: 'transparent'}}><MenuIcon sx={{color: theme.palette.primary.contrastText}} onClick={() => {setMenuVisible(true);}} /></Box>}
            {!!menuVisible && <Box sx={{padding: 1, display: 'flex', backgroundColor: 'transparent'}}><CloseIcon sx={{color: theme.palette.primary.contrastText}} onClick={() => {setMenuVisible(false);}} /></Box>}
          </>}

          <Box sx={{
            padding: 1,
            marginRight: 1,
            display: 'flex',
            backgroundColor: 'transparent',
            color: 'black',
            marginLeft: 'auto',
            '&:hover': {
              backgroundColor: theme.palette.primary.light
            }
          }}>
            {!!nonmobile && <Select
              labelId="lang_picker"
              id="lang_picker"
              value={locale}
              sx={{
                color: theme.palette.primary.contrastText,
                "& svg": {
                  color: theme.palette.primary.contrastText,
                },
                "& fieldset": {
                  borderColor: theme.palette.primary.contrastText,
                }
              }}
              onChange={(v) => {
                setLocale(v.target.value|| 'nl');
              }}
            >
              {(project?.languages || '').split(";").filter(lang => !!lang).map(lang => <MenuItem value={lang}>{lang.toUpperCase()}</MenuItem>)}
            </Select>}
          </Box>
          {!login && <Box sx={{
            padding: 1,
            // marginRight: 1,
            display: 'flex',
            cursor: 'pointer',
            backgroundColor: 'transparent',
            color: 'black',
            // marginLeft: 'auto',
            '&:hover': {
              backgroundColor: theme.palette.primary.light
            }
          }} onClick={() => {
            navigate('/login');
          }}>
            <Typography sx={{display: 'inline-block', color: theme.palette.primary.contrastText}}>{t("general.menu.login")}</Typography>
          </Box>}
          {!!login && <Box sx={{
            padding: 1,
            marginRight: 1,
            display: 'flex',
            cursor: 'pointer',
            backgroundColor: 'transparent',
            color: 'black',
            // marginLeft: 'auto',
            '&:hover': {
              backgroundColor: theme.palette.primary.light
            }
          }} onClick={() => {
            navigate('/profile');
          }}>
            <ManageAccountsIcon sx={{color: theme.palette.primary.contrastText}} />
          </Box>}
          {!!login && <Box sx={{
            padding: 1,
            // marginRight: 1,
            display: 'flex',
            cursor: 'pointer',
            backgroundColor: 'transparent',
            color: 'black',
            // marginLeft: 'auto',
            '&:hover': {
              backgroundColor: theme.palette.primary.light
            }
          }} onClick={() => {
            do_logout((result) => {
              setLogin(result);
              window.location.reload();
            });
          }}>
            <Typography sx={{display: 'inline-block', color: theme.palette.primary.contrastText}}>{t("general.menu.logout")}</Typography>
          </Box>}
        </Box>
      </Box>

      {!!menuVisible && <Box sx={{display: 'flex', flexDirection: 'column', width: '100%', backgroundColor: theme.palette.primary.light}}>
        {(project?.theme_info?.menu_items || []).map(menu_item => <>
          <Box sx={{
            padding: 1,
            display: 'flex',
            cursor: 'pointer',
            backgroundColor: 'transparent',
            color: 'black',
            '&:hover': {
              backgroundColor: theme.palette.primary.light
            },
            ...((project?.theme_info?.menu_items || [])[-1] !== menu_item ? {
              borderBottom: '1px solid rgba(255, 255, 255, 0.2)'
            } : {})
          }} onClick={() => {
            navigate(menu_item.full_url || '/');
          }}>
            <Typography sx={{color: theme.palette.primary.contrastText, marginLeft: 'auto', marginRight: 'auto'}}>{menu_item.name[lang] || menu_item.name[fb_lang]}</Typography>
          </Box>
        </>)}
        <Box sx={{
          padding: 1,
          backgroundColor: 'transparent',
          with: '100%',
          textAlign: 'center'
        }}>
          <FormControl size="small">
            <Select
              labelId="lang_picker"
              id="lang_picker"
              value={locale}
              sx={{
                color: theme.palette.primary.contrastText,
                "& svg": {
                  color: theme.palette.primary.contrastText,
                },
                "& fieldset": {
                  borderColor: theme.palette.primary.contrastText,
                }
              }}
              onChange={(v) => {
                setLocale(v.target.value|| 'nl');
              }}
            >
              {(project?.languages || '').split(";").filter(lang => !!lang).map(lang => <MenuItem value={lang}>{lang.toUpperCase()}</MenuItem>)}
            </Select>
          </FormControl>
        </Box>
      </Box>}

      {!!login && login.first_name === "John" && login.last_name === "Doe" && <Box sx={{display: 'flex', height: 20, width: '100%', flexDirection: 'row', backgroundColor: theme.palette.secondary.main, paddingLeft: 4, paddingRight: 4}}>
        <Typography sx={{display: 'inline-block', color: theme.palette.primary.contrastText, marginLeft: 'auto', marginRight: 'auto', fontSize: '0.8rem', cursor: 'pointer'}} onClick={() => {navigate('/profile');}}>{t("general.warning.complete_profile")}</Typography>
      </Box>}
    </>}


    <Box sx={{
      width: '100%',
      // padding: 4,
      maxWidth: '1200px',
      marginLeft: 'auto',
      marginRight: 'auto',
      backgroundColor: 'white'
    }}>
      <Box sx={{
        padding: 4,
        backgroundColor: 'white'
      }}>
        {children}
      </Box>
    </Box>


    <Box sx={{
      width: '100%',
      display: 'block',
      position: 'fixed',
      bottom: 0,
      left: 0,
      paddingTop: 0.5,
      textAlign: 'center',
      backgroundColor: theme.palette.primary.dark
    }}>
      <Typography sx={{color: theme.palette.primary.contrastText, textAlign: 'center', fontSize: '0.8rem', marginBottom: 1, width: 'auto', display: 'inline-block', marginRight: 1, opacity: 0.7}}>Powered by PanelMetrics &copy; {DateTime.now().year}</Typography>
      <Typography sx={{color: theme.palette.primary.contrastText, textAlign: 'center', fontSize: '0.8rem', marginBottom: 1, width: 'auto', display: 'inline-block', marginRight: 1, marginLeft: 1, opacity: 0.7}}>|</Typography>

      <Typography onClick={() => {
        // if (!!currentProject && !!currentProject.privacy_url) {
        //   window.open(currentProject.privacy_url, "_BLANK")
        // } else {
          navigate("/privacy");
        // }
      }} sx={{color: theme.palette.primary.contrastText, textAlign: 'center', fontSize: '0.8rem', cursor: 'pointer', marginBottom: 1, width: 'auto', display: 'inline-block', marginLeft: 1, opacity: 0.7}}>{t("page_titles.privacy_policy")}</Typography>
    </Box>
  </Box>));
}

