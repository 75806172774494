import React, { FunctionComponent, useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

import { fetch_one, fetch_all } from '../../lib/server_helper';
import { checkAllowed } from '../../lib/permission';

import {
  Box,
  CircularProgress,
  Divider,
  Grid
} from '@mui/material';

import { PieChart, Cell, Pie, Legend, Tooltip, ResponsiveContainer } from 'recharts';

import { loginAtom } from '../../lib/auth';

import { AlertCheckInputs } from '../../components/alerts/CheckInputs';
import { AlertSaved } from '../../components/alerts/Saved';
import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import {
  DivergingStackedBar,
  DivStaBarBetter,
  Page,
  StyledButton,
  StyledSelect,
  StyledTextField,
  Typography
} from '../../components/interface';

import { Project } from '../../models/Project';
import { Question } from '../../models/Question';
import { Segment } from '../../models/Segment';
import { DstValue } from '../../components/interface/DivStaBarBetter';

// const { DateTime } = require("luxon");

// type AvailableLang = 'nl' | 'fr' | 'en';
type AdminStatsDetailProps = {};
type CustResult = {
  custom_result: {voters: number};
};
type Zip = {
  id: number;
  zip: number;
  city_name: string;
  city_votes2019: number;
};

export const AdminStatsDetail: FunctionComponent<AdminStatsDetailProps> = () => {
  // const navigate = useNavigate();
  // const theme = useTheme();
  const {t, i18n} = useTranslation(['translations']);

  const params = useParams();

  const [login, setLogin] = useAtom(loginAtom);
  const [project, setProject] = useState<Project>();
  const [loader, setLoader] = useState<boolean>(true);
  const [projectId, setProjectId] = useState<string | undefined>(params.id);
  const [projectStats, setProjectStats] = useState<{
    ao_id: number;
    a_content: string;
    gender: string;
    province: string;
    birth_date: string;
    zip: number;
    age: number;
    user_id: number;
  }[]>([]);
  const [genderPieData, setGenderPieData] = useState<{name: string; value: number;}[]>([]);
  const [genderDivStaBarValues, setGenderDivStaBarValues] = useState<DstValue[]>([]);
  const [provincePieData, setProvincePieData] = useState<{name: string; value: number;}[]>([]);
  const [provinceDivStaBarValues, setProvinceDivStaBarValues] = useState<DstValue[]>([]);
  const [agePieData, setAgePieData] = useState<{name: string; value: number;}[]>([]);
  const [ageDivStaBarValues, setAgeDivStaBarValues] = useState<DstValue[]>([]);
  const [totalDivStaBarValues, setTotalDivStaBarValues] = useState<DstValue[]>([]);

  const [zips, setZips] = useState<{id: string; name: string;}[]>([]);
  const [cities, setCities] = useState<{[name: string]: number}>();

  const [iteration, setIteration] = useState<number>(1);
  const [segmentId, setSegmentId] = useState<number>();
  const [question, setQuestion] = useState<Question>();
  const [mySegments, setMySegments] = useState<Segment[]>([]);
  const [province, setProvince] = useState<string>('all');
  const [zip, setZip] = useState<number|undefined>(undefined);
  const [zipName, setZipName] = useState<string|undefined>(undefined);
  const [noId, setNoId] = useState<number>();
  const [yesId, setYesId] = useState<number>();
  const [totalCount, setTotalCount] = useState<number>();

  const [errorSnackOpen, setErrorSnackOpen] = useState<boolean>(false);
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);
  const [exitSavedSnackOpen, setDividendSavedSnackOpen] = useState<boolean>(false);

  const [showPct, setShowPct] = useState<boolean>(false);
  const [voters, setVoters] = useState<number>(0);

  const genderColours:string[] = ['#aba9f5', '#f5a9f2', '#e0e0e0'];
  const provinceColours:string[] = ['Moccasin', 'LightSkyBlue', 'PaleGreen', 'lightblue', 'Salmon', 'aliceblue', 'MediumPurple', 'orange', 'Lavender', 'YellowGreen', 'LightSteelBlue'];
  const ageColours:string[] = ['Moccasin', 'LightSkyBlue', 'PaleGreen', 'lightblue', 'Salmon', 'MediumPurple', 'orange', 'YellowGreen', 'LightSteelBlue'];

  const fetchCalculation = () => {
    if (checkAllowed(login, null, projectId?.toString() || '0', "ProjectExport", 'r', true)) {
      setLoader(true);
      
      fetch_one<Project>('/nl/v3/objects/fetch_all', {
        object: 'project',
        fields: ['id', 'name', 'iteration', 'questions_info', 'fallback_language'],
        id: projectId
      }, (ob, complete_data) => {
        setProject(ob);
        setLoader(false);
      }, (z) => {
        setServerErrorSnackOpen(true);
      }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
    } else {
      setLoader(false);
    }
  };

  const fetchMySegments = () => {
    fetch_all<Segment>('/nl/v3/objects/fetch_all', {
      object: 'segment',
      fields: ['id', 'name'],
      handler_id: login?.id
    }, (obs, complete_data) => {
      setMySegments(obs);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
  };

  const fetchVoters = () => {
    if (checkAllowed(login, null, projectId?.toString() || '0', "ProjectExport", 'r', true)) {
      fetch_one<CustResult>('/nl/v3/objects/custom_action', {
        object: 'project',
        id: projectId,
        class_action: 'custom_api_get_voters',
        province: province,
        zipname: zipName
      }, (ob, complete_data) => {
        setVoters(ob.custom_result.voters);
      }, (z) => {
        setServerErrorSnackOpen(true);
      }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
    } else {
      setLoader(false);
    }
  };

  const fetchZips = () => {
    if (checkAllowed(login, null, projectId?.toString() || '0', "ProjectExport", 'r', true)) {
      fetch_all<Zip>('/nl/v3/objects/fetch_all', {
        object: 'zip',
        fields: ['id', 'city|name/votes2019', 'zip'],
        per_page: 10000
      }, (obs, complete_data) => {
        let list: {[z:string]: number[]} = {};
        let voterList: {[z:string]: number} = {};
        let list_a: {id: string; name: string;}[] = [];
        obs.forEach(ob => {
          if (!!list[ob.city_name]) {
            if (list[ob.city_name].indexOf(ob.zip) > -1) {

            } else {
              list[ob.city_name].push(ob.zip);
            }
          } else {
            list[ob.city_name] = [];
            list[ob.city_name].push(ob.zip);
          }
          if (!!voterList[ob.city_name] && voterList[ob.city_name] > 0) {} else {
            voterList[ob.city_name] = ob.city_votes2019
          }
        });
        Object.keys(list).sort((a, b) => a.localeCompare(b)).forEach(ob_key => {
          list_a.push({
            id: ob_key,
            name: `${ob_key} (${list[ob_key].join(", ")})`
          });
        });

        setZips(list_a);
        setCities(voterList);
      }, (z) => {
        setServerErrorSnackOpen(true);
      }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
    } else {
      setLoader(false);
    }
  };

  const sendExcel = (complete: boolean) => {
    if (checkAllowed(login, null, projectId?.toString() || '0', "ProjectExport", 'r', true)) {
      fetch_one<Project>('/nl/v3/objects/custom_action', {
        object: 'project',
        id: projectId,
        class_action: 'custom_api_send_stats',
        complete: complete ? 1 : 0,
        email: login?.email,
        iteration: iteration || 1,
        question_id: question?.id,
        province: province
      }, (ob, complete_data) => {
        setDividendSavedSnackOpen(true);
      }, (z) => {
        setServerErrorSnackOpen(true);
      }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
    } else {

    }
  };

  const refreshStats = () => {
    if (checkAllowed(login, null, projectId?.toString() || '0', "ProjectExport", 'r', true)) {
      setLoader(true);
      
      fetch_one<Project>('/nl/v3/objects/custom_action', {
        object: 'project',
        id: projectId,
        class_action: 'custom_api_get_stats',
        iteration: iteration || 1,
        question_id: question?.id,
        segment_id: segmentId,
        province: province,
        zip: zip,
        zipname: zipName
      }, (ob, complete_data) => {
        // @ts-ignore
        setProjectStats(ob.custom_result?.stats || []);
        setLoader(false);
      }, (z) => {
        setServerErrorSnackOpen(true);
      }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (!!question) {
      (question.answer_options || []).map(ao => {
        if (ao.pos === 0) setNoId(ao.id);
        if (ao.pos === 1) setYesId(ao.id);
        return null;
      });
    }
  }, [question]);

  useEffect(() => {
    fetchCalculation();
  }, [projectId]);

  useEffect(() => {
    fetchMySegments();
  }, []);

  useEffect(() => {
    setProjectId(params.id);
  }, [params.id]);

  useEffect(() => {
    fetchZips();
  }, []);

  // useEffect(() => {
  //   fetchVoters();
  // }, [province]);

  useEffect(() => {
    let totalDSBStuff:{[z:string]: [number, number]} = {"Results": [0, 0]};

    let genderStuff:{[z:string]: number} = {"Male": 0, "Female": 0, "Unknown": 0};
    let genderDSBStuff:{[z:string]: [number, number]} = {"Male": [0, 0], "Female": [0, 0], "Unknown": [0, 0]};

    let provinceStuff:{[z:string]: number} = {};
    let provinceDSBStuff:{[z:string]: [number, number]} = {};

    let ageStuff:{[z:string]: number} = {"..-15": 0, "16-17": 0, "18-25": 0, "26-40": 0, "41-50": 0, "51-65": 0, "66-80": 0, "81-..": 0, "Unknown": 0};
    let ageDSBStuff:{[z:string]: [number, number]} = {"..-15": [0, 0], "16-17": [0, 0], "18-25": [0, 0], "26-40": [0, 0], "41-50": [0, 0], "51-65": [0, 0], "66-80": [0, 0], "81-..": [0, 0], "Unknown": [0, 0]};
    let tc = 0;

    projectStats.map(ps => {
      if (!!ps.birth_date) {
        tc += 1;

        if (ps.ao_id === noId) totalDSBStuff["Results"][0] += 1;
        if (ps.ao_id === yesId) totalDSBStuff["Results"][1] += 1;
        
        if (ps.gender === "M") {
          genderStuff["Male"] += 1;
          if (ps.ao_id === noId) genderDSBStuff["Male"][0] += 1;
          if (ps.ao_id === yesId) genderDSBStuff["Male"][1] += 1;
        } else {
          if (ps.gender === "F") {
            genderStuff["Female"] += 1;
            if (ps.ao_id === noId) genderDSBStuff["Female"][0] += 1;
            if (ps.ao_id === yesId) genderDSBStuff["Female"][1] += 1;
          } else {
            genderStuff["Unknown"] += 1;
            if (ps.ao_id === noId) genderDSBStuff["Unknown"][0] += 1;
            if (ps.ao_id === yesId) genderDSBStuff["Unknown"][1] += 1;
          }
        }
        provinceStuff[ps.province || "Unknown"] ||= 0;
        provinceDSBStuff[ps.province || "Unknown"] ||= [0, 0];

        provinceStuff[ps.province || "Unknown"] += 1;
        if (ps.ao_id === noId) provinceDSBStuff[ps.province || "Unknown"][0] += 1;
        if (ps.ao_id === yesId) provinceDSBStuff[ps.province || "Unknown"][1] += 1;

        if (!ps.age) {
          ageStuff["Unknown"] += 1
          if (ps.ao_id === noId) ageDSBStuff["Unknown"][0] += 1;
          if (ps.ao_id === yesId) ageDSBStuff["Unknown"][1] += 1;
        } else {
          if (ps.age < 16) {
            ageStuff["..-15"] += 1
            if (ps.ao_id === noId) ageDSBStuff["..-15"][0] += 1;
            if (ps.ao_id === yesId) ageDSBStuff["..-15"][1] += 1;
          } else {
            if (ps.age < 18) {
              ageStuff["16-17"] += 1
              if (ps.ao_id === noId) ageDSBStuff["16-17"][0] += 1;
              if (ps.ao_id === yesId) ageDSBStuff["16-17"][1] += 1;
            } else {
              if (ps.age < 26) {
                ageStuff["18-25"] += 1
                if (ps.ao_id === noId) ageDSBStuff["18-25"][0] += 1;
                if (ps.ao_id === yesId) ageDSBStuff["18-25"][1] += 1;
              } else {
                if (ps.age < 41) {
                  ageStuff["26-40"] += 1
                  if (ps.ao_id === noId) ageDSBStuff["26-40"][0] += 1;
                  if (ps.ao_id === yesId) ageDSBStuff["26-40"][1] += 1;
                } else {
                  if (ps.age < 51) {
                    ageStuff["41-50"] += 1
                    if (ps.ao_id === noId) ageDSBStuff["41-50"][0] += 1;
                    if (ps.ao_id === yesId) ageDSBStuff["41-50"][1] += 1;
                  } else {
                    if (ps.age < 66) {
                      ageStuff["51-65"] += 1
                      if (ps.ao_id === noId) ageDSBStuff["51-65"][0] += 1;
                      if (ps.ao_id === yesId) ageDSBStuff["51-65"][1] += 1;
                    } else {
                      if (ps.age < 81) {
                        ageStuff["66-80"] += 1
                        if (ps.ao_id === noId) ageDSBStuff["66-80"][0] += 1;
                        if (ps.ao_id === yesId) ageDSBStuff["66-80"][1] += 1;
                      } else {
                        ageStuff["81-.."] += 1
                        if (ps.ao_id === noId) ageDSBStuff["81-.."][0] += 1;
                        if (ps.ao_id === yesId) ageDSBStuff["81-.."][1] += 1;
                      }
                    }
                  }
                }
              }
            }
          }
        }

      }
      return null;
    });
    setTotalCount(tc);

    let totalDSBDataRaw = Object.keys(totalDSBStuff).filter(gs_key => (totalDSBStuff[gs_key][0] + totalDSBStuff[gs_key][1]) > 0).map(gs_key => {return {
      label: {[fb_lang]: gs_key},
      voters: (!!zipName && !!cities && !!cities[zipName] && cities[zipName] > 0 ? cities[zipName] : 0),
      values: [{
        color: 'lightgreen',
        tooltip: "buttons.yesno.yes",
        value: totalDSBStuff[gs_key][1],
        textColor: 'black',
      },
      {
        color: 'red',
        tooltip: "buttons.yesno.no",
        value: totalDSBStuff[gs_key][0],
        textColor: 'black',
      }]
    };})
    setTotalDivStaBarValues(totalDSBDataRaw);
    // console.log(genderDSBDataRaw);

    let genderPieDataRaw = Object.keys(genderStuff).map(gs_key => {return {name: gs_key, value: genderStuff[gs_key]};})
    setGenderPieData(genderPieDataRaw);
    // console.log(genderPieDataRaw);

    let genderDSBDataRaw = Object.keys(genderDSBStuff).filter(gs_key => (genderDSBStuff[gs_key][0] + genderDSBStuff[gs_key][1]) > 0).map(gs_key => {return {
      label: {[fb_lang]: gs_key},
      voters: (!!zipName && !!cities && !!cities[zipName] && cities[zipName] > 0 ? cities[zipName] : 0),
      values: [{
        color: 'lightgreen',
        tooltip: "buttons.yesno.yes",
        value: genderDSBStuff[gs_key][1],
        textColor: 'black',
      },
      {
        color: 'red',
        tooltip: "buttons.yesno.no",
        value: genderDSBStuff[gs_key][0],
        textColor: 'black',
      }]
    };})
    setGenderDivStaBarValues(genderDSBDataRaw);
    // console.log(genderDSBDataRaw);

    let provincePieDataRaw = Object.keys(provinceStuff).map(gs_key => {return {name: gs_key, value: provinceStuff[gs_key]};})
    setProvincePieData(provincePieDataRaw);
    // console.log(provincePieDataRaw);

    let provinceDSBDataRaw = Object.keys(provinceDSBStuff).filter(gs_key => (provinceDSBStuff[gs_key][0] + provinceDSBStuff[gs_key][1]) > 0).map(gs_key => {return {
      label: {[fb_lang]: gs_key},
      voters: (!!zipName && !!cities && !!cities[zipName] && cities[zipName] > 0 ? cities[zipName] : 0),
      values: [{
        color: 'lightgreen',
        tooltip: "buttons.yesno.yes",
        value: provinceDSBStuff[gs_key][1],
        textColor: 'black',
      },
      {
        color: 'red',
        tooltip: "buttons.yesno.no",
        value: provinceDSBStuff[gs_key][0],
        textColor: 'black',
      }]
    };})
    setProvinceDivStaBarValues(provinceDSBDataRaw);
    // console.log(provinceDSBDataRaw);

    let agePieDataRaw = Object.keys(ageStuff).map(as_key => {return {name: as_key, value: ageStuff[as_key]};})
    setAgePieData(agePieDataRaw);
    // console.log(agePieDataRaw);

    let ageDSBDataRaw = Object.keys(ageDSBStuff).filter(gs_key => (ageDSBStuff[gs_key][0] + ageDSBStuff[gs_key][1]) > 0).map(gs_key => {return {
      label: {[fb_lang]: gs_key},
      voters: (!!zipName && !!cities && !!cities[zipName] && cities[zipName] > 0 ? cities[zipName] : 0),
      values: [{
        color: 'lightgreen',
        tooltip: "buttons.yesno.yes",
        value: ageDSBStuff[gs_key][1],
        textColor: 'black',
      },
      {
        color: 'red',
        tooltip: "buttons.yesno.no",
        value: ageDSBStuff[gs_key][0],
        textColor: 'black',
      }]
    };})
    setAgeDivStaBarValues(ageDSBDataRaw);
    // console.log(ageDSBDataRaw);
  }, [projectStats]);
  
  const fb_lang = project?.fallback_language|| 'nl';
  const lang = 'en';

  return (
    <Page sx={{}} title={[t("objects.project.stats.title", "Stats"), (!!project ? project.name : '')].join(" - ")}>
      <Grid container spacing="8">

        <Grid item container xs={6} spacing="4" sx={{alignContent: 'baseline'}}>
          <Grid item xs={12}>
            <StyledTextField
              label="Iteration"
              value={iteration.toString() || '1'}
              id="pos"
              onChange={(v) => {setIteration(parseInt(v, 10));}}
              fullWidth
            />
            <Typography sx={{fontSize: '0.8rem', fontStyle: 'italic', marginTop: -0.5}}>Current iteration: {project?.iteration}</Typography>
          </Grid>
        </Grid>

        <Grid item container xs={6} spacing="4" sx={{alignContent: 'baseline'}}>
          <Grid item xs={12}>
            <StyledSelect
              label="Question"
              value={(question?.id || 0).toString()}
              id='question_id'
              onChange={(v) => {
                setQuestion(project?.questions_info?.filter(q => q.id === parseInt(v, 10))[0]);
              }}
              list={project?.questions_info?.map(q_info => {
                return {id: q_info.id.toString(), name: (q_info.body[lang] || q_info.body[fb_lang])};
              }) || []}
            />
          </Grid>
        </Grid>

        <Grid item container xs={6} md={3} spacing="4" sx={{alignContent: 'baseline'}}>
          <Grid item xs={12}>
            <StyledSelect
              label="Province"
              value={province}
              id='province'
              onChange={(v) => {
                setProvince(v);
              }}
              list={[
                {id: 'all', name: 'Belgium'},
                {id: 'flanders', name: 'Flanders'},
                {id: 'wallonia', name: 'Wallonia'},
                {id: '---', name: '---'},
                {id: 'Brussels Capital Region', name: 'Brussels Capital Region'},
                {id: 'Walloon Brabant', name: 'Walloon Brabant'},
                {id: 'Flemish Brabant', name: 'Flemish Brabant'},
                {id: 'Antwerp', name: 'Antwerp'},
                {id: 'Limburg', name: 'Limburg'},
                {id: 'Liège', name: 'Liège'},
                {id: 'Namur', name: 'Namur'},
                {id: 'Hainaut', name: 'Hainaut'},
                {id: 'Luxembourg', name: 'Luxembourg'},
                {id: 'West Flanders', name: 'West Flanders'},
                {id: 'East Flanders', name: 'East Flanders'}
              ]}
            />
          </Grid>
        </Grid>
        <Grid item container xs={6} md={3} spacing="4" sx={{alignContent: 'baseline'}}>
          <Grid item xs={12}>
            <StyledSelect
              label="Segment"
              value={!!segmentId ? segmentId.toString() : ''}
              id='segmentid'
              onChange={(v) => {
                setSegmentId(!!v ? parseInt(v, 10) : undefined);
              }}
              list={[
                {id: '', name: "None selected"},
                ...mySegments.sort((a, b) => (a.name || '').localeCompare(b.name || '')).map(c => {return {id: (c.id || '').toString(), name: c.name || ''};})
              ]}
            />
          </Grid>
        </Grid>

        <Grid item container xs={6} spacing="4" sx={{alignContent: 'baseline'}}>
          <Grid item xs={6}>
            <StyledTextField
              label="By zipcode"
              value={zip?.toString() || ''}
              id="zip_entry"
              onChange={(v) => {setZip(!!v ? parseInt(v, 10) : undefined);}}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <StyledSelect
              label="By city"
              value={zipName || ''}
              id='zip'
              onChange={(v) => {
                setZipName(!!v ? v : undefined);
              }}
              list={[
                {id: '', name: 'All'},
                ...zips
              ]}
            />
          </Grid>
        </Grid>

        <Grid item container xs={12} spacing="4" sx={{alignContent: 'baseline'}}>
          <Grid item xs={12}>
            <Typography sx={{fontStyle: 'italic', color: 'lightgrey', fontSize: '0.8rem'}}>Note: all filters work with each other, so it's your responsability to avoid excluding filters (e.g.: zip: 1000, province: Antwerp)!</Typography>
          </Grid>
        </Grid>

        <Grid item container xs={12} spacing="4" sx={{alignContent: 'baseline'}}>
          <Grid item xs={12} sx={{marginTop: 2}}>
            {checkAllowed(login, null, projectId?.toString() || '0', "ProjectExport", 'r', true) && <>
              {question?.question_type === 'yesno' && <StyledButton
                label="Fetch & Refresh"
                id='fetch'
                contained
                disabled={!iteration || !question || province === '---'}
                onClick={(v) => {
                  fetchVoters();
                  refreshStats();
                }}
                sx={{marginLeft: 0}}
              />}
            </>}
            {checkAllowed(login, null, projectId?.toString() || '0', "ProjectExport", 'r', true) && <>
              {!!login?.email && <StyledButton
                label="Send Excel"
                id='dl'
                disabled={!iteration || !question|| province === '---'}
                onClick={(v) => {
                  sendExcel(false);
                }}
                sx={{marginLeft: 1}}
              />}
              {!!login?.email && <StyledButton
                label="Send Complete Excel"
                id='dl'
                disabled={!iteration}
                onClick={(v) => {
                  sendExcel(true);
                }}
                sx={{marginLeft: 1}}
              />}
              {!login?.email && <StyledButton
                label="Send Excel"
                id='dl'
                disabled={!iteration || !question|| province === '---'}
                onClick={(v) => {
                  alert("Your account does not have an email address linked to it.")
                }}
                sx={{marginLeft: 1}}
              />}
            </>}
            {checkAllowed(login, null, projectId?.toString() || '0', "ProjectExport", 'r', true) && <>
              {!!showPct && <StyledButton
                label="Show abs"
                id='abs'
                onClick={(v) => {
                  setShowPct(false);
                }}
                sx={{marginLeft: 1}}
              />}
              {!showPct && <StyledButton
                label="Show %"
                id='pct'
                onClick={(v) => {
                  setShowPct(true);
                }}
                sx={{marginLeft: 1}}
              />}
            </>}
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{marginTop: 2, marginBottom: 2}} />
          </Grid>
        </Grid>

        {question?.question_type !== 'yesno' && <Grid item xs={12}>
          <Typography sx={{fontStyle: 'italic', marginTop: -2}}>Question is not of 'yesno' type. Only export is available</Typography>
        </Grid>}

        {question?.question_type === 'yesno' && <>
          <Grid item container xs={12} spacing="4" sx={{alignContent: 'baseline'}}>
            {!!loader && <CircularProgress />}
            {!loader && !!projectStats && <>

              <Grid item xs={12}>
                <Typography sx={{fontStyle: 'italic', marginTop: -2}}>{totalCount} rows in result</Typography>
              </Grid>

              <Grid item xs={12} sx={{marginTop: 0, marginBottom: 2}}>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h3">Total</Typography>
              </Grid>

              {totalDivStaBarValues.length > 0 && <Grid item xs={12}>
                <DivStaBarBetter showPct={showPct} disableVoters={false} data={totalDivStaBarValues} overrideVoters={voters || 0} />
              </Grid>}

              <Grid item xs={12}>
                <Typography variant="h3">Gender</Typography>
              </Grid>

              {!!genderPieData && <Grid item xs={4}>
                <Box sx={{width: '100%', height: '300px'}}>
                  <ResponsiveContainer>
                    <PieChart width={400} height={300}>
                      <Pie data={genderPieData} dataKey="value" cx="50%" cy="50%" outerRadius={90} fill="#8884d8">
                        {genderPieData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={genderColours[index % genderColours.length]} />
                        ))}
                      </Pie>
                      {/* <Pie data={data02} dataKey="value" cx="50%" cy="50%" innerRadius={70} outerRadius={90} fill="#82ca9d" label /> */}
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                </Box>
              </Grid>}

              {genderDivStaBarValues.length > 0 && <Grid item xs={8}>
                <DivStaBarBetter showPct={showPct} disableVoters data={genderDivStaBarValues} />
              </Grid>}

              {/* {genderDivStaBarValues.length > 0 && <Grid item xs={8}>
                <DivergingStackedBar
                  mdata={genderDivStaBarValues}
                />
              </Grid>} */}

              <Grid item xs={12} sx={{marginTop: 2, marginBottom: 2}}>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h3">Province</Typography>
              </Grid>

              {!!provincePieData && <Grid item xs={4}>
                <Box sx={{width: '100%', height: '300px'}}>
                  <ResponsiveContainer>
                    <PieChart width={400} height={300}>
                      <Pie data={provincePieData} dataKey="value" cx="50%" cy="50%" outerRadius={90} fill="#8884d8">
                        {provincePieData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={provinceColours[index % provinceColours.length]} />
                        ))}
                      </Pie>
                      {/* <Pie data={data02} dataKey="value" cx="50%" cy="50%" innerRadius={70} outerRadius={90} fill="#82ca9d" label /> */}
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                </Box>
              </Grid>}

              {provinceDivStaBarValues.length > 0 && <Grid item xs={8}>
                <DivStaBarBetter showPct={showPct} disableVoters data={provinceDivStaBarValues} />
              </Grid>}

              {/* {provinceDivStaBarValues.length > 0 && <Grid item xs={8}>
                <DivergingStackedBar
                  mdata={provinceDivStaBarValues}
                />
              </Grid>} */}

              <Grid item xs={12} sx={{marginTop: 2, marginBottom: 2}}>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h3">Age groups</Typography>
              </Grid>

              {!!agePieData && <Grid item xs={4}>
                <Box sx={{width: '100%', height: '300px'}}>
                  <ResponsiveContainer>
                    <PieChart width={400} height={300}>
                      <Pie data={agePieData} dataKey="value" cx="50%" cy="50%" outerRadius={90} fill="#8884d8">
                        {agePieData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={ageColours[index % ageColours.length]} />
                        ))}
                      </Pie>
                      {/* <Pie data={data02} dataKey="value" cx="50%" cy="50%" innerRadius={70} outerRadius={90} fill="#82ca9d" label /> */}
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                </Box>
              </Grid>}

              {ageDivStaBarValues.length > 0 && <Grid item xs={8}>
                <DivStaBarBetter showPct={showPct} disableVoters data={ageDivStaBarValues} />
              </Grid>}

              {/* {ageDivStaBarValues.length > 0 && <Grid item xs={8}>
                <DivergingStackedBar
                  mdata={ageDivStaBarValues}
                />
              </Grid>} */}

            </>}
          </Grid>
        </>}
      </Grid>

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
      {!!errorSnackOpen && <AlertCheckInputs open={errorSnackOpen} setOpen={(b) => {setErrorSnackOpen(b);}} />}
      {!!exitSavedSnackOpen && <AlertSaved open={exitSavedSnackOpen} setOpen={(b) => {setDividendSavedSnackOpen(b);}} />}
    </Page>
  );
}
