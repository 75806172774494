import React, { FunctionComponent } from 'react';

import {
  Grid
} from '@mui/material';

import { StyledButton } from '../../StyledButton';
import { Typography } from '../../Typography';

type SearchFilterTrueFalseProps = {
  name: string;
  activeName?: string;
  inactiveName?: string;
  activeValue?: string;
  inactiveValue?: string;
  hideName?: boolean;
  filterValue: string[];
  setFilterValue: (z:string[]) => void;
  setEnterPressed?: (v:boolean) => void;
};

export const SearchFilterTrueFalse: FunctionComponent<SearchFilterTrueFalseProps> = ({name, activeName, inactiveName, activeValue, inactiveValue, hideName, filterValue, setFilterValue, setEnterPressed}) => {
  let zero_value = inactiveValue || '0';
  let one_value = activeValue || '1';
  
  return (<>
    {!hideName && <Grid item xs={6} sx={{textAlign: 'right'}}>
      <Typography sx={{marginTop: 0.5}}>Filter ({name})</Typography>
    </Grid>}
    <Grid item xs={!!hideName ? 2 : 3}>
      <StyledButton
        label={inactiveName || "Inactive"}
        contained={filterValue.indexOf(zero_value) > -1}
        secondary={filterValue.indexOf(zero_value) < 0}
        id='filter_true_false_no'
        onClick={(v) => {
          if (filterValue.indexOf(zero_value) > -1) {
            let old_arr = [...filterValue];
            old_arr.splice(old_arr.indexOf(zero_value), 1);
            setFilterValue([...old_arr]);
            if (!!setEnterPressed) setEnterPressed(true);
          } else {
            setFilterValue([
              ...filterValue,
              zero_value
            ]);
            if (!!setEnterPressed) setEnterPressed(true);
          }
        }}
        sx={{width: '100%'}}
      />
    </Grid>
    <Grid item xs={!!hideName ? 2 : 3}>
      <StyledButton
        label={activeName || "Active"}
        contained={filterValue.indexOf(one_value) > -1}
        secondary={filterValue.indexOf(one_value) < 0}
        id='filter_true_false_yes'
        onClick={(v) => {
          if (filterValue.indexOf(one_value) > -1) {
            let old_arr = [...filterValue];
            old_arr.splice(old_arr.indexOf(one_value), 1);
            setFilterValue([...old_arr]);
            if (!!setEnterPressed) setEnterPressed(true);
          } else {
            setFilterValue([
              ...filterValue,
              one_value
            ]);
            if (!!setEnterPressed) setEnterPressed(true);
          }
        }}
        sx={{width: '100%'}}
      />
    </Grid>
  </>);
}