import React, { FunctionComponent } from 'react';

import {
  Grid
} from '@mui/material';

import {
  Typography
} from '../../interface';

import { EmailBlock } from '../../../models/EmailBlock';

type BlockPublicTextProps = {
  block: EmailBlock;
  marked: boolean;
  activeLocale: 'nl' | 'fr';
};

export const BlockPublicText: FunctionComponent<BlockPublicTextProps> = ({block, marked, activeLocale}) => {
  let string_value = block.string_value;

  return ( <Grid xs={12} sx={{
    marginTop: block.settings?.mtop || 0,
    marginBottom: block.settings?.mbot || 0,
    border: `1px solid ${block.settings?.border || 'transparent'}`,
    padding: '12px',
    ...(!!marked ? {backgroundColor: 'rgba(255, 255, 0, 0.3)'} : {})
  }}>
    {!!string_value && <Typography html>{string_value}</Typography>}
    {!string_value && <Typography sx={{fontStyle: 'italic', color: 'lightgrey !important'}}>Content goes here...</Typography>}
  </Grid>);
};
