import { FunctionComponent, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom, useAtomValue } from 'jotai';
// import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next'

import { checkConditionsForAnswerOption } from '../../../lib/poll';
import { currentProjectAtom } from '../../../lib/project';

import {
  Box,
  Grid,
} from '@mui/material';

import CircleIcon from '@mui/icons-material/Circle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

import {
  Typography,
} from '../../../components/interface';

import { Answer } from '../../../models/Answer';
import { AnswerOption } from '../../../models/AnswerOption';
import { User } from '../../../models/User';

type Props = {
  currentQuestion: {
    body: {[x:string]: string};
    nps_unlikely: {[x:string]: string};
    nps_likely: {[x:string]: string};
    question_type: string;
    id: number;
    is_optional: boolean;
    is_extended_profile: boolean;
    show_outro_type: string;
    answer_options: AnswerOption[];
    extra_data: {
      alignment: string;
      layout?: string;
      min?: number;
      max?: number;
      show_faces?: boolean;
    };
  };
  pollAnswers: Answer[];
  setPollAnswers: (a:Answer[]) => void;
  step: number;
  userProfile?: User;
  // setStep: (n:number) => void;
};

export const SlideLayoutNpsNps: FunctionComponent<Props> = ({currentQuestion, pollAnswers, setPollAnswers, step, userProfile}) => {
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const currentProject = useAtomValue(currentProjectAtom);
  const [answer, setAnswer] = useState<number>(parseInt((!!pollAnswers[step] ? (pollAnswers[step].answer_content || '0') : '0'), 10));

  const fb_lang:string = currentProject?.fallback_language|| 'nl';
  const lang:string = i18n.resolvedLanguage || fb_lang;

  let min = currentQuestion.extra_data.min || 1;
  let max = currentQuestion.extra_data.max || 10;

  useEffect(() => {
    setAnswer(parseInt((!!pollAnswers[step] ? (pollAnswers[step].answer_content || '0') : '0'), 10));
  }, [pollAnswers[step]]);

  useEffect(() => {
    let new_poll_answers = [...pollAnswers];

    new_poll_answers[step] = {
      ...pollAnswers[step],
      question_id: !!currentQuestion ? currentQuestion.id : undefined,
      iteration: currentProject?.iteration || 1,
      answer_content: answer.toString()
    };

    setPollAnswers(new_poll_answers);
  }, [answer]);

  return (<>
    <Grid item xs={12}>
      <Typography sx={{
        textAlign: (currentQuestion?.extra_data?.alignment || 'center')
      }} html>{currentQuestion.body[lang] || currentQuestion.body[fb_lang]}</Typography>
    </Grid>

    <Grid item xs={12}>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-evenly'
      }}>
        {Array(max + 1 - min).fill(0).map((_, idx) => min + idx).map(v => <>
          <Box sx={{
            border: '1px solid transparent',
            borderColor: theme.palette.primary.main,
            padding: 2,
            fontSize: '1.1rem',
            display: 'inline-block',
            cursor: 'pointer',
            margin: '-0.5px',
            // borderRadius: 4,
            width: `${100 / (max + 1 - min)}%`,
            textAlign: 'center',
            "&:hover": {
              // backgroundColor: 'lightgrey',
              backgroundColor: theme.palette.primary.light,
              borderColor: theme.palette.primary.dark
            },
            ...(answer === v ? {
              // backgroundColor: 'lightgrey',
              backgroundColor: theme.palette.primary.light,
              borderColor: theme.palette.primary.dark
            } : {})
          }} onClick={() => {
            setAnswer(v);
          }}>{v}</Box>
        </>)}
      </Box>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-evenly'
      }}>
        <Box sx={{
          display: 'inline-block',
          margin: '-0.5px',
          width: '50%',
          textAlign: 'left',
          marginTop: 2,
          fontStyle: 'italic'
        }}><Typography sx={{
          fontSize: '0.9rem',
          color: 'grey',
        }}>{currentQuestion.nps_unlikely[lang] || currentQuestion.nps_unlikely[fb_lang] || t("question.nps.unlikely_term")}</Typography></Box>
        <Box sx={{
          fontSize: '1.1rem',
          display: 'inline-block',
          margin: '-0.5px',
          width: '50%',
          textAlign: 'right',
          color: 'grey',
          marginTop: 2,
          fontStyle: 'italic'
        }}><Typography sx={{
          fontSize: '0.9rem',
          color: 'grey',
        }}>{currentQuestion.nps_likely[lang] || currentQuestion.nps_likely[fb_lang] || t("question.nps.likely_term")}</Typography></Box>
      </Box>
      {/* <Box sx={{
        // background: 'rgb(219,0,0)',
        background: "linear-gradient(90deg, rgba(219,0,0,1) 0%, rgba(255,178,13,1) 49%, rgba(0,172,10,1) 100%)",
        width: 'auto',
        height: '5px'
      }}></Box> */}
    </Grid>
    </>);
}
