import React, { FunctionComponent, useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

import { fetch_one } from '../../lib/server_helper';
import { checkAllowed } from '../../lib/permission';

import {
  CircularProgress,
  Divider,
  Grid
} from '@mui/material';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { loginAtom } from '../../lib/auth';

import { AlertCheckInputs } from '../../components/alerts/CheckInputs';
import { AlertSaved } from '../../components/alerts/Saved';
import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import {
  ImagePicker,
  LanguageFab,
  Page,
  StyledButton,
  StyledColourPicker,
  StyledDropzone,
  StyledEditor,
  StyledIconButton,
  StyledSelect,
  StyledSwitch,
  StyledTextField,
  StyledTextFieldTransl,
  Typography
} from '../../components/interface';

import { MenuItem } from '../../models/MenuItem';
import { Project } from '../../models/Project';

// const { DateTime } = require("luxon");

type AvailableLang = 'nl' | 'fr' | 'en';
type AdminThemeDetailProps = {};

export const AdminThemeDetail: FunctionComponent<AdminThemeDetailProps> = () => {
  const navigate = useNavigate();
  // const theme = useTheme();
  const {t, i18n} = useTranslation(['translations']);

  const params = useParams();

  const [login, setLogin] = useAtom(loginAtom);
  const [project, setProject] = useState<Project>();
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const [loader, setLoader] = useState<boolean>(true);
  const [saveLoader, setSaveLoader] = useState<boolean>(false);
  const [projectId, setProjectId] = useState<string | undefined>(params.id);

  const [item, setItem] = useState<File>();
  const [itemUrl, setItemUrl] = useState<string>();
  const [mobileItem, setMobileItem] = useState<File>();
  const [mobileItemUrl, setMobileItemUrl] = useState<string>();
  const [logoItem, setLogoItem] = useState<File>();
  const [logoItemUrl, setLogoItemUrl] = useState<string>();
  const [favicon, setFavicon] = useState<File>();
  const [faviconUrl, setFaviconUrl] = useState<string>();
  const [menuLogo, setMenuLogo] = useState<File>();
  const [menuLogoUrl, setMenuLogoUrl] = useState<string>();

  const [activeLocale, setActiveLocale] = useState<AvailableLang>('en');

  const [errorSnackOpen, setErrorSnackOpen] = useState<boolean>(false);
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);
  const [exitSavedSnackOpen, setDividendSavedSnackOpen] = useState<boolean>(false);

  const fields = [
    'id', 'get_item', 'get_mobile_item', 'theme_primary', 'theme_primary_light', 'theme_primary_dark', 'theme_info', 'theme_secondary', 'theme_contrast_text', 'theme_text_colour', 'extra_data', 'get_logo_item', 'get_favicon', 'get_menu_logo'
  ];

  const saveSetting = () => {
    if (checkAllowed(login, null, projectId?.toString() || '0', "ProjectTheme", 'u', true)) {
      setSaveLoader(true);

      let formData = new FormData();
      formData.append('object', 'project');
      formData.append('handler_id', (login?.id || "").toString());
      fields.map(field => {
        formData.append('fields[]', field);
      });
      if (!!projectId) formData.append('ob[id]', projectId.toString());
      if (!!projectId) formData.append('id', (projectId || '').toString());
      // questionDetail
      if (!!item) formData.append(`ob[item]`, item);
      if (!!itemUrl) formData.append(`ob[set_item_url]`, itemUrl);
      // questionDetail
      if (!!mobileItem) formData.append(`ob[mobile_item]`, mobileItem);
      if (!!mobileItemUrl) formData.append(`ob[set_mobile_item_url]`, mobileItemUrl);
      // questionDetail
      if (!!logoItem) formData.append(`ob[logo_item]`, logoItem);
      if (!!logoItemUrl) formData.append(`ob[set_logo_item_url]`, logoItemUrl);
      // questionDetail
      if (!!favicon) formData.append(`ob[favicon]`, favicon);
      if (!!faviconUrl) formData.append(`ob[set_favicon_url]`, faviconUrl);
      // questionDetail
      if (!!menuLogo) formData.append(`ob[menu_logo]`, menuLogo);
      if (!!menuLogoUrl) formData.append(`ob[set_menu_logo_url]`, menuLogoUrl);

      formData.append(`ob[theme_primary]`, (project?.theme_primary || ''));
      formData.append(`ob[theme_primary_light]`, (project?.theme_primary_light || ''));
      formData.append(`ob[theme_primary_dark]`, (project?.theme_primary_dark || ''));
      formData.append(`ob[theme_secondary]`, (project?.theme_secondary || ''));
      formData.append(`ob[theme_contrast_text]`, (project?.theme_contrast_text || ''));
      formData.append(`ob[theme_text_colour]`, (project?.theme_text_colour || ''));

      // formData.append(`ob[extra_data][show_divstabar]`, ((project?.extra_data?.show_divstabar || '0') === '1' ? '1' : '0'));
      formData.append(`ob[extra_data][divstabar_no_colour]`, (project?.extra_data?.divstabar_no_colour || ''));
      formData.append(`ob[extra_data][divstabar_no_text_colour]`, (project?.extra_data?.divstabar_no_text_colour || ''));
      formData.append(`ob[extra_data][divstabar_yes_colour]`, (project?.extra_data?.divstabar_yes_colour || ''));
      formData.append(`ob[extra_data][divstabar_yes_text_colour]`, (project?.extra_data?.divstabar_yes_text_colour || ''));

      formData.append(`ob[extra_data][show_poll_progress]`, (project?.extra_data?.show_poll_progress || '1'));
      formData.append(`ob[extra_data][hide_afterpoll_share]`, (project?.extra_data?.hide_afterpoll_share || '0'));

      menuItems.map((menu_item, i) => {
        if (!!menu_item.id) formData.append(`menu_items[${i}][id]`, menu_item.id.toString());
        formData.append(`menu_items[${i}][pos]`, i.toString());
        formData.append(`menu_items[${i}][full_url]`, (menu_item?.full_url || ''));
        for (const key in (menu_item?.name || {})) {
          formData.append(`menu_items[${i}][name][${key}]`, ((menu_item?.name || {})[key] || ''));
        };
      });

      fetch_one<Project>('/nl/v3/objects/save', formData, (ob, complete_data) => {
        openObject(ob);
        navigate(`/projects`);
        setSaveLoader(false);
      }, (z) => {
        setServerErrorSnackOpen(true);
      }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
    }
  };

  const fetchCalculation = () => {
    if (checkAllowed(login, null, projectId?.toString() || '0', "ProjectTheme", 'r', true)) {
      setLoader(true);
      
      fetch_one<Project>('/nl/v3/objects/fetch_all', {
        object: 'project',
        fields: fields,
        id: projectId
      }, (ob, complete_data) => {
        openObject(ob);
        setLoader(false);
      }, (z) => {
        setServerErrorSnackOpen(true);
      }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchCalculation();
  }, [projectId]);

  const openObject = (ob: Project) => {
    setProject(ob);
    setMenuItems(ob.theme_info?.menu_items as MenuItem[]);
  }

  return (
    <Page sx={{}} title={t("objects.project.theme.title")}>
      <LanguageFab
        activeLocale={activeLocale}
        setActiveLocale={(lc) => {setActiveLocale(lc);}}
      />
      <Grid container spacing="8">

        <Grid item container xs={6} spacing="4" sx={{alignContent: 'baseline'}}>
          <Grid item xs={12}>
            <Typography sx={{fontSize: '0.8rem'}}>Desktop header (1200x300)</Typography>
            <ImagePicker existing={project?.get_item} item={item} itemUrl={itemUrl} onSelect={(_item:File|undefined, _itemUrl:string|undefined) => {
              setItem(_item);
              setItemUrl(_itemUrl);
            }} />
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{fontSize: '0.8rem'}}>Mobile header (600x150)</Typography>
            <ImagePicker existing={project?.get_mobile_item} item={mobileItem} itemUrl={mobileItemUrl} onSelect={(_item:File|undefined, _itemUrl:string|undefined) => {
              setMobileItem(_item);
              setMobileItemUrl(_itemUrl);
            }} />
          </Grid>
          <Grid item xs={4}>
            <StyledColourPicker
              label={t("objects.project.theme.fields.primary_colour")}
              value={project?.theme_primary?.toString() || 'rgb(16, 46, 86)'}
              onChange={(v) => {if (!!project) setProject({
                ...project,
                theme_primary: v});
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <StyledColourPicker
              label={t("objects.project.theme.fields.primary_colour_light")}
              value={project?.theme_primary_light?.toString() || 'rgb(93, 146, 217)'}
              onChange={(v) => {if (!!project) setProject({
                ...project,
                theme_primary_light: v});
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <StyledColourPicker
              label={t("objects.project.theme.fields.primary_colour_dark")}
              value={project?.theme_primary_dark?.toString() || 'rgb(7, 39, 82)'}
              onChange={(v) => {if (!!project) setProject({
                ...project,
                theme_primary_dark: v});
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <StyledColourPicker
              label={t("objects.project.theme.fields.secondary_colour")}
              value={project?.theme_secondary?.toString() || 'rgb(97, 92, 191)'}
              onChange={(v) => {if (!!project) setProject({
                ...project,
                theme_secondary: v});
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <StyledColourPicker
              label={t("objects.project.theme.fields.contrast_text")}
              value={project?.theme_contrast_text?.toString() || 'rgb(255, 255, 255)'}
              onChange={(v) => {if (!!project) setProject({
                ...project,
                theme_contrast_text: v});
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <StyledColourPicker
              label={t("objects.project.theme.fields.text_colour")}
              value={project?.theme_text_colour?.toString() || 'rgb(16, 46, 86)'}
              onChange={(v) => {if (!!project) setProject({
                ...project,
                theme_text_colour: v});
              }}
            />
          </Grid>
        </Grid>

        <Grid item container xs={6} spacing="4" sx={{alignContent: 'baseline'}}>
          <Grid item xs={12}>
            <Typography sx={{fontSize: '0.8rem'}}>Logo (error/success page) (40px height)</Typography>
            <ImagePicker existing={project?.get_logo_item} item={logoItem} itemUrl={logoItemUrl} onSelect={(_item:File|undefined, _itemUrl:string|undefined) => {
              setLogoItem(_item);
              setLogoItemUrl(_itemUrl);
            }} />
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{fontSize: '0.8rem'}}>Menu Logo (40px height)</Typography>
            <ImagePicker existing={project?.get_menu_logo} item={menuLogo} itemUrl={menuLogoUrl} onSelect={(_item:File|undefined, _itemUrl:string|undefined) => {
              setMenuLogo(_item);
              setMenuLogoUrl(_itemUrl);
            }} />
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{fontSize: '0.8rem'}}>Favicon</Typography>
            <ImagePicker existing={project?.get_favicon} item={favicon} itemUrl={faviconUrl} onSelect={(_item:File|undefined, _itemUrl:string|undefined) => {
              setFavicon(_item);
              setFaviconUrl(_itemUrl);
            }} />
          </Grid>
        </Grid>
        <Grid item container xs={12} spacing="4" sx={{alignContent: 'baseline'}}>
          <Grid item xs={12} sx={{marginTop: 2, marginBottom: 2}}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Typography sx={{textDecoration: 'underline', fontStyle: 'italic', fontSize: '0.9rem'}}>{t("objects.project.theme.menu_items.title")}</Typography>
          </Grid>
          <Grid item container xs={12} spacing="4">
            {menuItems.map((menu_item, i) => <>
              <Grid item xs={1}>
                <Typography sx={{marginTop: '25%'}}>{menu_item.id}</Typography>
              </Grid>
              <Grid item xs={6}>
                <StyledTextFieldTransl
                  label={`${t("objects.project.theme.menu_items.fields.name")} ${i + 1}`}
                  activeLocale={activeLocale}
                  value={menu_item?.name || {en: ''}}
                  id="name"
                  onChange={(v) => {
                    let new_mis: MenuItem[] = [];
                    menuItems.map((mi, ii) => {
                      let new_mi = {...mi};
                      if (i === ii) {
                        new_mi.name = v;
                        new_mis.push(new_mi);
                      } else {
                        new_mis.push(new_mi);
                      }
                    });
                    setMenuItems(new_mis);
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <StyledTextField
                  label={`${t("objects.project.theme.menu_items.fields.full_url")} ${i + 1}`}
                  value={menu_item?.full_url || ''}
                  id="full_url"
                  onChange={(v) => {
                    let new_mis: MenuItem[] = [];
                    menuItems.map((mi, ii) => {
                      let new_mi = {...mi};
                      if (i === ii) {
                        new_mi.full_url = v;
                        new_mis.push(new_mi);
                      } else {
                        new_mis.push(new_mi);
                      }
                    });
                    setMenuItems(new_mis);
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={1}>
                <StyledIconButton color="primary" sx={{marginTop: 0.5}} onClick={(e) => {
                  let new_mis: MenuItem[] = [];
                  menuItems.map((mi, ii) => {
                    let new_mi = {...mi};
                    if (i === ii) {
                      
                    } else {
                      new_mis.push(new_mi);
                    }
                  });
                  setMenuItems(new_mis);
                }}>
                  <DeleteForeverIcon />
                </StyledIconButton>
              </Grid>
            </>)}
          </Grid>
          <Grid item xs={12}>
            <StyledButton
              label={t("questions.answer_options.add_more_cta")}
              id='add_answer_option'
              contained
              onClick={(v) => {
                setMenuItems([...menuItems, {}]);
              }}
              sx={{marginLeft: 1}}
            />
          </Grid>
          <Grid item xs={12} sx={{marginTop: 2, marginBottom: 2}}>
            <Divider />
          </Grid>

          {/* <Grid item xs={12}>
            <StyledSwitch
              label="Show DivergentStackBar (only with YesNo questions) on Poll Start & Exit"
              offLabel="Hide"
              onLabel="Show"
              value={parseInt(project?.extra_data?.show_divstabar || '0', 10) === 1}
              onChange={(v) => {if (!!project) setProject({
                ...project,
                extra_data: {
                  ...(project.extra_data || {}),
                  show_divstabar: !!v ? '1' : '0'
                }});
                // console.log(project);
              }}
            />
          </Grid> */}

          <Grid item xs={12} md={3}>
            <StyledColourPicker
              label="DivergentStackBar No-colour"
              value={project?.extra_data?.divstabar_no_colour || 'rgb(228, 111, 115)'}
              onChange={(v) => {if (!!project) setProject({
                ...project,
                extra_data: {
                  ...(project.extra_data || {}),
                  divstabar_no_colour: v
                }});
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <StyledColourPicker
              label="DivergentStackBar No-textColour"
              value={project?.extra_data?.divstabar_no_text_colour || 'rgb(0, 0, 0)'}
              onChange={(v) => {if (!!project) setProject({
                ...project,
                extra_data: {
                  ...(project.extra_data || {}),
                  divstabar_no_text_colour: v
                }});
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <StyledColourPicker
              label="DivergentStackBar Yes-colour"
              value={project?.extra_data?.divstabar_yes_colour?.toString() || 'rgb(151, 249, 142)'}
              onChange={(v) => {if (!!project) setProject({
                ...project,
                extra_data: {
                  ...(project.extra_data || {}),
                  divstabar_yes_colour: v
                }});
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <StyledColourPicker
              label="DivergentStackBar Yes-textColour"
              value={project?.extra_data?.divstabar_yes_text_colour?.toString() || 'rgb(0, 0, 0)'}
              onChange={(v) => {if (!!project) setProject({
                ...project,
                extra_data: {
                  ...(project.extra_data || {}),
                  divstabar_yes_text_colour: v
                }});
              }}
            />
          </Grid>
          <Grid item xs={12} sx={{marginTop: 2, marginBottom: 2}}>
            <Divider />
          </Grid>

          <Grid item xs={12} md={3}>
            <StyledSwitch
              label="Show progressbar on poll"
              offLabel="Hide"
              onLabel="Show"
              value={parseInt(project?.extra_data?.show_poll_progress || '1', 10) !== 0}
              onChange={(v) => {if (!!project) setProject({
                ...project,
                extra_data: {
                  ...(project.extra_data || {}),
                  show_poll_progress: !!v ? '1' : '0'
                }});
              }}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <StyledSwitch
              label="Show share options on AfterPoll"
              offLabel="Hide"
              onLabel="Show"
              value={parseInt(project?.extra_data?.hide_afterpoll_share || '0', 10) === 0}
              onChange={(v) => {if (!!project) setProject({
                ...project,
                extra_data: {
                  ...(project.extra_data || {}),
                  hide_afterpoll_share: !!v ? '0' : '1'
                }});
              }}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{alignContent: 'baseline', textAlign: 'center', position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 1201}}>
          <Divider sx={{marginTop: 1, marginBottom: 2}} />

          {!!loader && <CircularProgress />}
          {!loader && <StyledButton
            label={t("public.buttons.cancel")}
            id='cancel'
            // contained
            onClick={(v) => {
              navigate(`/projects`);
            }}
            sx={{marginLeft: 1}}
          />}
          {checkAllowed(login, null, projectId?.toString() || '0', "ProjectTheme", 'u', true) && !loader && <StyledButton
            label={t("public.buttons.save")}
            id='save'
            contained
            onClick={(v) => {
              saveSetting();
            }}
            sx={{marginLeft: 1}}
          />}
        </Grid>
      </Grid>

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
      {!!errorSnackOpen && <AlertCheckInputs open={errorSnackOpen} setOpen={(b) => {setErrorSnackOpen(b);}} />}
      {!!exitSavedSnackOpen && <AlertSaved open={exitSavedSnackOpen} setOpen={(b) => {setDividendSavedSnackOpen(b);}} />}
    </Page>
  );
}
