import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'

import { fetch_all, fetch_one } from '../lib/server_helper';

import {
  // Box
} from '@mui/material';

import { Page } from '../components/interface/Page';
import { GenericError } from './GenericError';

import { loginAtom } from '../lib/auth';

const { DateTime } = require("luxon");

type DashboardProps = {};

export const Dashboard: FunctionComponent<DashboardProps> = () => {
  const theme = useTheme();
  const {t, i18n} = useTranslation(['translations']);
  
  const [login, setLogin] = useAtom(loginAtom);
  const [loader, setLoader] = useState<boolean>(true);

  return (!!login ? <Page sx={{}} title={t("objects.dashboard.title")}>
    Content when logged in ({login?.is_admin ? 'admin' : 'user'})
  </Page> : <Page sx={{}} title="PanelMetrics">
    <GenericError hide_detail detail="not_signed_in" cta_label="Sign in now" cta_link="/login" />
  </Page>);
}
