import { FunctionComponent, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom, useAtomValue } from 'jotai';
// import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next'

import { fetch_one } from '../../lib/server_helper';
import { loginAtom } from '../../lib/auth';
import { fetchPage } from '../../lib/page';
import { goToStep } from '../../lib/poll';
import { currentProjectAtom } from '../../lib/project';

import {
  Box,
  Grid
} from '@mui/material';

import {
  // DivergingStackedBar,
  DivStaBarBetter,
  Typography
} from '../../components/interface';

import { SharedPollButtons } from './PollButtons';
import { SharedPollProgress } from './PollProgress';
import { SharedPollSlide } from './PollSlide';
import { SharedPollSlideYesNo } from './PollSlideYesNo';

import { Answer } from '../../models/Answer';
import { Page } from '../../models/Page';
import { Project } from '../../models/Project';
import { User } from '../../models/User';

type SharedPollProps = {
  endPoll: () => void;
  notLoggedIn: React.ReactNode;
};

export const SharedPoll: FunctionComponent<SharedPollProps> = ({endPoll, notLoggedIn}) => {
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const [login, setLogin] = useAtom(loginAtom);
  const currentProject = useAtomValue(currentProjectAtom);

  const [currentIntroPage, setCurrentIntroPage] = useState<Page|null>(null);
  const [currentOutroPage, setCurrentOutroPage] = useState<Page|null>(null);
  const [userProfile, setUserProfile] = useState<User>();
  const [progress, setProgress] = useState<number>(0);
  const [pollAnswers, setPollAnswers] = useState<Answer[]>([]);
  const [step, setStep] = useState<number>(0);
  const [divStaBarValues, setDivStaBarValues] = useState<any[]>([]);
  const [canPoll, setCanPoll] = useState<boolean>(true);

  const getAnswers = () => {
    if (!!login) {
      fetch_one<Project>('/nl/v3/objects/custom_action', {
        object: 'project',
        class_action: 'custom_api_get_answers',
        id: currentProject?.id,
        handler_id: (login?.id || "").toString(),
        iteration: currentProject?.iteration || 1
      }, (ob, complete_data) => {
        if (!!ob.custom_result) setPollAnswers([{}, ...ob.custom_result])
      }, (z, y) => {
        
      }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
    }
  };

  const saveAnswers = () => {
    if (!!login) {
      fetch_one<Answer>('/nl/v3/objects/custom_action', {
        object: 'answer',
        class_action: 'custom_api_save_answers',
        handler_id: (login?.id || "").toString(),
        answers: pollAnswers
      }, (ob, complete_data) => {
        if (ob?.custom_result?.success) {
          endPoll();
        } else {
          // error handling
        }
      }, (z, y) => {
        
      }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
    }
  };

  useEffect(() => {
    setProgress(1 / (2 + (currentProject?.questions_count || 0)) * 100);
    getAnswers();

    fetchPage('poll_intro', (ob) => {setCurrentIntroPage(ob);}, login);
    fetchPage('poll_exit', (ob) => {setCurrentOutroPage(ob);}, login);
  }, [currentProject]);

  useEffect(() => {
    setProgress((step + 1) / (2 + (currentProject?.questions_count || 0)) * 100);
  }, [step]);

  useEffect(() => {
    if (!!login) {
      fetch_one<User>('/nl/v3/objects/fetch_all', {
        object: 'user',
        id: login?.id,
        fields: ['province', 'zip']
      }, (ob, complete_data) => {
        setUserProfile(ob);
      }, (z, y) => {
        
      }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
    }
  }, [login]);

  useEffect(() => {
    if (!!login) {
      fetch_one<{custom_result: {success: boolean; data: boolean;}}>('/nl/v3/objects/custom_action', {
        object: 'user',
        id: login?.id,
        class_action: 'custom_api_check_closed_circle',
        project_id: currentProject?.id
      }, (ob, complete_data) => {
        setCanPoll(ob.custom_result.data);
      }, (z, y) => {
        
      }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
    }
  }, [currentProject]);

  useEffect(() => {
    if (!!currentProject && parseInt(currentProject?.extra_data?.show_divstabar || '0', 10) === 1 && !!pollAnswers && pollAnswers.filter(ans => !!ans.answer_option_id || !!ans.answer_content).length > 0) {
      fetch_one<Project>('/nl/v3/objects/custom_action', {
        object: 'project',
        class_action: 'custom_api_get_divstabar_values',
        id: currentProject?.id,
        handler_id: (login?.id || "").toString(),
        iteration: currentProject?.iteration || 1
      }, (ob, complete_data) => {
        // @ts-ignore
        if (!!ob.custom_result) setDivStaBarValues(ob.custom_result?.vals as any[]);
      }, (z, y) => {
        
      }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
    }
  }, [!!pollAnswers, pollAnswers]);

  const fb_lang = currentProject?.fallback_language|| 'nl';
  const lang = i18n.resolvedLanguage || fb_lang;
// console.log(divStaBarValues);
// console.log(currentProject);
  return !!login ? (<>
    <Grid container spacing="8">
      {!canPoll && <Grid item xs={12}>
        <Box sx={{padding: 1, border: '1px solid transparent', borderColor: theme.palette.secondary.main, marginBottom: 2, borderRadius: 2}}>
          <Typography sx={{color: theme.palette.secondary.main}} html>{t("pub.poll.closed_circle")}</Typography>
        </Box>
      </Grid>}

      {!!canPoll && <>
        {currentProject?.extra_data?.show_poll_progress !== '0' && <SharedPollProgress progress={progress} />}
        
        {step === 0 && !!currentIntroPage && !!currentIntroPage.body && <Grid item xs={12}>
          {!!pollAnswers && pollAnswers.filter(ans => !!ans.answer_option_id || !!ans.answer_content).length > 0 && <Box sx={{padding: 1, border: '1px solid transparent', borderColor: theme.palette.secondary.main, marginBottom: 2, borderRadius: 2}}>
            <Typography sx={{color: theme.palette.secondary.main}} html>{t("pub.poll.already_answered_poll")}</Typography>
          </Box>}
          <Typography html>{currentIntroPage.body[lang] || currentIntroPage.body[fb_lang]}</Typography>

          {/* {!!currentProject && parseInt(currentProject?.extra_data?.show_divstabar || '0', 10) === 1 && !!divStaBarValues && !!pollAnswers && pollAnswers.filter(ans => !!ans.answer_option_id || !!ans.answer_content).length > 0 && <Box>
            <DivStaBarBetter showPct={true} disableVoters={true} data={divStaBarValues} />
          </Box>} */}
        </Grid>}

        {step >= ((currentProject?.questions_count || 0) + 1) && !!currentOutroPage && !!currentOutroPage.body && <Grid item xs={12}>
          <Typography html>{currentOutroPage.body[lang] || currentOutroPage.body[fb_lang]}</Typography>

          <Box sx={{marginBottom: 2}}>
            {currentProject?.questions_info?.map((qi, i) => <>
              {!!(pollAnswers[i + 1].answer_option_id || pollAnswers[i + 1].answer_content) && <>
                {!(qi.is_extended_profile && !!pollAnswers[i + 1].existing) && <>
                  {/* {JSON.stringify(qi.answer_options)} */}
                  <Typography sx={{paddingLeft: 1, fontStyle: 'italic', fontSize: '0.8rem', marginTop: 1}}>{qi.body[lang] || qi.body[fb_lang]}</Typography>
                  {!!pollAnswers[i + 1].answer_option_id && <Typography sx={{paddingLeft: 2}}>{((qi.answer_options.filter(ao => pollAnswers[i + 1].answer_option_id === ao.id)[0] || {}).body || {})[lang]}</Typography>}
                  {!pollAnswers[i + 1].answer_option_id && <Typography sx={{paddingLeft: 2}}>{pollAnswers[i + 1].answer_content}</Typography>}
                </>}
              </>}
            </>)}
          </Box>

          {/* {!!currentProject && parseInt(currentProject?.extra_data?.show_divstabar || '0', 10) === 1 && !!divStaBarValues && !!pollAnswers && pollAnswers.filter(ans => !!ans.answer_option_id || !!ans.answer_content).length > 0 && <Box>
            <DivStaBarBetter showPct={true} disableVoters={true} data={divStaBarValues} />
          </Box>} */}
        </Grid>}

        {step > 0 && step < ((currentProject?.questions_count || 0) + 1) && !!currentProject?.questions_info && currentProject?.questions_info[step - 1].question_type !== 'yesno' && <SharedPollSlide
          currentQuestion={currentProject?.questions_info[step - 1]}
          pollAnswers={pollAnswers}
          setPollAnswers={(new_answers) => {setPollAnswers(new_answers);}}
          step={step}
          userProfile={userProfile}
        />}

        {step > 0 && step < ((currentProject?.questions_count || 0) + 1) && !!currentProject?.questions_info && currentProject?.questions_info[step - 1].question_type === 'yesno' && <SharedPollSlideYesNo
          currentQuestion={currentProject?.questions_info[step - 1]}
          pollAnswers={pollAnswers}
          setPollAnswers={(new_answers) => {setPollAnswers(new_answers);}}
          step={step}
          onStep={(mmm) => {
            if (!!currentProject) goToStep(mmm, step, currentProject, pollAnswers, (mod) => {setStep(mod);}, userProfile)
          }}
        />}

        {!!canPoll && !(!!currentProject?.questions_info && !!currentProject?.questions_info[step - 1] && currentProject?.questions_info[step - 1].question_type === 'yesno') && <SharedPollButtons
          layout={((currentProject?.questions_info || [])[step - 1] || {}).extra_data?.layout || 'normal'}
          onClose={() => {
            endPoll();
          }}
          onFinish={() => {
            saveAnswers();
          }}
          onStep={(mmm) => {
            // console.log(pollAnswers);
            if (!!currentProject) goToStep(mmm, step, currentProject, pollAnswers, (mod) => {setStep(mod);}, userProfile)
          }}
          step={step}
          pollAnswers={pollAnswers}
        />}
      </>}
    </Grid>
  </>) : (<>{notLoggedIn}</>);
}
