import React, { FunctionComponent } from 'react';

import {
  Grid
} from '@mui/material';

import {
  Typography
} from '../../interface';

import { BlockPublicBaseImage } from './BaseImage';

import { EmailBlock } from '../../../models/EmailBlock';

type BlockPublicImageLeftProps = {
  block: EmailBlock;
  marked: boolean;
  activeLocale: 'nl' | 'fr';
};

// function isBenefitBlock(block: EmailBlock | BenefitBlock): block is BenefitBlock {
//   return true;
// }

export const BlockPublicImageLeft: FunctionComponent<BlockPublicImageLeftProps> = ({block, marked, activeLocale}) => {
  let string_value = block.string_value;
  let title_value = block.title_value;

  return ( <Grid container item xs={12} sx={{
    marginTop: block.settings?.mtop || 0,
    marginBottom: block.settings?.mbot || 0,
    border: `1px solid ${block.settings?.border || 'transparent'}`,
    padding: '12px',
    ...(!!marked ? {backgroundColor: 'rgba(255, 255, 0, 0.3)'} : {})
  }}>
    <Grid item xs={5}>
      <BlockPublicBaseImage block={block} activeLocale={activeLocale} />
    </Grid>
    <Grid item xs={7} sx={{paddingLeft: 1}}>
      {!!title_value && <Typography variant="h3" html>{title_value}</Typography>}
      {!title_value && <Typography variant="h3" sx={{fontStyle: 'italic', color: 'lightgrey !important'}}>Title goes here...</Typography>}

      {!!string_value && <Typography html>{string_value}</Typography>}
      {!string_value && <Typography sx={{fontStyle: 'italic', color: 'lightgrey !important'}}>Content goes here...</Typography>}
    </Grid>
  </Grid>);
};
