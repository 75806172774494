import { atom } from "jotai";
import { User, LOGIN_URL, RENEW_URL, LOGOUT_URL } from "../models/User";
import { fetch_one } from "./server_helper";
import { getCookie, setCookie } from 'react-use-cookie';
import { Project } from "../models/Project";

export const loginAtom = atom<User|undefined>(undefined);

export default function do_login(username: string, password: string, cb:(z:User|undefined) => void) {
  fetch_one<User>(`/nl${LOGIN_URL}`, {
    fields: ['id', 'first_name', 'last_name', 'auth_token', 'email', 'is_admin', 'extra_data', 'permission_map', 'is_special_permissions'],
    username: username,
    password: password,
    priv: 1
  }, (x, y) => {
    setCookie('panelmetrics_token', x?.auth_token);
    setCookie('panelmetrics_email', x?.email);
    cb(x);
    return x;
  }, (x) => {
    cb(undefined);
  });
}

export function do_login_slug(slug: string, cb:(z:User|undefined) => void, project?: Project) {
  fetch_one<User>(`/nl${LOGIN_URL}`, {
    fields: ['id', 'first_name', 'last_name', 'auth_token', 'email', 'is_admin', 'extra_data', 'permission_map', 'is_special_permissions'],
    slug: slug,
    priv: 1
  }, (x, y) => {
    setCookie('panelmetrics_token', x?.auth_token);
    setCookie('panelmetrics_email', x?.email);
    cb(x);
    return x;
  }, (x) => {
    cb(undefined);
  }, undefined, project);
}

export function do_login_magic_link(magic_link: string, cb:(z:User|undefined) => void, project?: Project) {
  fetch_one<User>(`/nl${LOGIN_URL}`, {
    fields: ['id', 'first_name', 'last_name', 'auth_token', 'email', 'is_admin', 'extra_data', 'permission_map', 'is_special_permissions'],
    magic_link: magic_link,
    priv: 1
  }, (x, y) => {
    setCookie('panelmetrics_token', x?.auth_token);
    setCookie('panelmetrics_email', x?.email);
    cb(x);
    return x;
  }, (x) => {
    cb(undefined);
  }, undefined, project);
}

export function do_login_sms_code(sms_code: string, cb:(z:User|undefined) => void, project?: Project) {
  fetch_one<User>(`/nl${LOGIN_URL}`, {
    fields: ['id', 'first_name', 'last_name', 'auth_token', 'email', 'is_admin', 'extra_data', 'permission_map', 'is_special_permissions'],
    sms_code: sms_code,
    priv: 1
  }, (x, y) => {
    setCookie('panelmetrics_token', x?.auth_token);
    setCookie('panelmetrics_email', x?.email);
    cb(x);
    return x;
  }, (x) => {
    cb(undefined);
  }, undefined, project);
}

export function do_logout(cb:(z:User|undefined) => void) {
  fetch_one<User>(`/nl${LOGOUT_URL}`, {
    fields: [],
    priv: 1
  }, (x, y) => {
    setCookie('panelmetrics_token', '');
    setCookie('panelmetrics_email', '');
    cb(undefined);
  }, (x) => {
    setCookie('panelmetrics_token', '');
    setCookie('panelmetrics_email', '');
    cb(undefined);
  }, {auth_token: getCookie('panelmetrics_token'), email: getCookie('panelmetrics_email')});
}

export function renew_sign_in(cb:(z:User|undefined) => void) {
  fetch_one<User>(`/nl${RENEW_URL}`, {
    fields: ['id', 'first_name', 'last_name', 'auth_token', 'email', 'is_admin', 'extra_data', 'permission_map', 'is_special_permissions'],
    priv: 1
  }, (x, y) => {
    cb(x);
    return x;
  }, (x) => {
    cb(undefined);
  }, {auth_token: getCookie('panelmetrics_token'), email: getCookie('panelmetrics_email')});
}