import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';

import {
  Box,
  Divider,
  Grid
} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';

import { StyledButton } from './StyledButton';
import { StyledTextField } from './StyledTextField';

import { SearchFields } from './Search/Fields';
import { SearchFilterStatus } from './Search/Filter/Status';
import { SearchFilterStatusStrings } from './Search/Filter/StatusStrings';
import { SearchFilterTrueFalse } from './Search/Filter/TrueFalse';
import { SearchGeneral } from './Search/General';

import { loginAtom } from '../../lib/auth';
import { searchAtom } from '../../lib/search';

type SearchBoxProps = {
  sx?: object;
  children?: React.ReactNode;
  simple?: boolean;
  collapsable?: boolean;
  filter_enabled?: boolean;
  filter_bookyear?: boolean;
  filter_meeting_status?: boolean;
  hide_reset?: boolean;
  defaultFilterEnabled?: string[];
  initialValues?: any;
  transactionSearch?: (z:{name:string, vennoot_number:string, catch_all:string}) => void;
  userSearch?: (z:{name:string, email:string, birth_date:string, vennoot_number:string, street:string, number:string, zip:string, catch_all:string, enabled:string[]}) => void;
  transferSearch?: (z:{name:string, email:string, birth_date:string, vennoot_number:string, street:string, number:string, zip:string, catch_all:string, status:string[]}) => void;
  onSearch?: (z:string, filters?:{enabled:string[], book_year_id:number, meeting_status?:number[]}) => void;
  exitSearch?: (z:string, filters?:{book_year_id:number, checked?: string[], identity_front?: string[], item?: string[], verified?: string[], cancelled?: string[]}) => void;
  deathSearch?: (z:string, filters?:{checked?: string[]}) => void;
  customUserSearch?: (z:{birth_date:string, street:string, number:string, zip:string}) => void;
  customUserLayout?: ("padding" | "name" | "email" | "birthdate" | "vennr" | "address")[];
  memo?: 'email_templates' | 'user_changes' | 'users' | 'transactions' | 'faqs' | 'security_questions' | 'statics' | 'custom201s' | 'deaths' | 'exits' | 'shares' | 'dividend_calculations' | 'dividend_pains' | 'banners' | 'benefits' | 'news' | 'stock_transfers';
};

export const SearchBox: FunctionComponent<SearchBoxProps> = ({memo, collapsable, hide_reset, children, sx, initialValues, simple, onSearch, userSearch, transferSearch, customUserSearch, customUserLayout, transactionSearch, exitSearch, deathSearch, filter_enabled, defaultFilterEnabled, filter_bookyear, filter_meeting_status}) => {
  const theme = useTheme();

  let default_book_year = 8;
  let timer_timeout = 500;

  const [login, setLogin] = useAtom(loginAtom);
  const [memoSearch, setMemoSearch] = useAtom(searchAtom);

  const [search, setSearch] = useState(!!memo ? memoSearch[memo].catch_all : (!!initialValues?.catch_all ? initialValues?.catch_all : ''));

  const [collapsed, setCollapsed] = useState(false);

  const [searchTimer, setSearchTimer] = useState(setTimeout(() => {}, timer_timeout));
  const [enterPressed, setEnterPressed] = useState(false);
  const [doSearch, setDoSearch] = useState(false);

  const [searchBirthdate, setSearchBirthdate] = useState(!!memo ? memoSearch[memo].birth_date : (!!initialValues?.birth_date ? initialValues.birth_date : ''));
  const [searchCatchAll, setSearchCatchAll] = useState(!!memo ? memoSearch[memo].catch_all : (!!initialValues?.catch_all ? initialValues?.catch_all : ''));
  const [searchEmail, setSearchEmail] = useState((!!memo ? memoSearch[memo].email : ''));
  const [searchName, setSearchName] = useState((!!memo ? memoSearch[memo].name : ''));
  const [searchNumber, setSearchNumber] = useState(!!memo ? memoSearch[memo].number : (!!initialValues?.number ? initialValues.number : ''));
  const [searchStreet, setSearchStreet] = useState(!!memo ? memoSearch[memo].street : (!!initialValues?.street ? initialValues.street : ''));
  const [searchVennootNumber, setSearchVennootNumber] = useState((!!memo ? memoSearch[memo].vennoot_number : ''));
  const [searchZipCity, setSearchZipCity] = useState(!!memo ? memoSearch[memo].zip : (!!initialValues?.zip ? initialValues.zip : ''));

  const [filterEnabled, setFilterEnabled] = useState(!!memo ? memoSearch[memo].enabled : (defaultFilterEnabled || ['1']));
  const [filterTransferStatus, setFilterTransferStatus] = useState(!!memo ? memoSearch[memo].transfer_status : ['', 'open', 'wait', 'pending']);
  const [filterChecked, setFilterChecked] = useState(!!memo ? memoSearch[memo].checked : ['0']);
  const [filterVerified, setFilterVerified] = useState(!!memo ? memoSearch[memo].verified : ['0']);
  const [filterCancelled, setFilterCancelled] = useState(!!memo ? memoSearch[memo].cancelled : ['0']);
  const [filterIdentity, setFilterIdentity] = useState(!!memo ? memoSearch[memo].identity : ['0', '1', '2', '3']);
  const [filterItem, setFilterItem] = useState(!!memo ? memoSearch[memo].item : ['0', '1', '2', '3']);
  const [filterBookYear, setFilterBookYear] = useState(!!memo ? memoSearch[memo].book_year : 0);
  const [filterMeetingStatus, setFilterMeetingStatus] = useState(!!memo ? memoSearch[memo].meeting_status : [0, 1, 2, 3]);

  let search_use_enter = false;
  let search_use_autosearch = false;
  // if (!!login && login.extra_data?.settings?.search_use_enter === '1') search_use_enter = true;
  // if (!!login && login.extra_data?.settings?.search_use_autosearch === '1') search_use_autosearch = true;
  if (!search_use_enter) search_use_autosearch = true;

  useEffect(() => {
    if (!!doSearch) {
      clearTimeout(searchTimer);
      setDoSearch(false);

      if (!!memo && !!memoSearch) {
        setMemoSearch({
          ...memoSearch,
          [memo]: {
            catch_all: searchCatchAll || search,
            birth_date: searchBirthdate,
            email: searchEmail,
            name: searchName,
            street: searchStreet,
            number: searchNumber,
            zip: searchZipCity,
            vennoot_number: searchVennootNumber,
            enabled: filterEnabled,
            checked: filterChecked,
            verified: filterVerified,
            cancelled: filterCancelled,
            identity: filterIdentity,
            item: filterItem,
            book_year: filterBookYear,
            meeting_status: filterMeetingStatus,
            transfer_status: filterTransferStatus
          }
        });
      }

      if (!!userSearch) {
        userSearch({
          name: searchName,
          email: searchEmail,
          birth_date: searchBirthdate,
          vennoot_number: searchVennootNumber,
          street: searchStreet,
          number: searchNumber,
          zip: searchZipCity,
          catch_all: searchCatchAll,
          enabled: filterEnabled
        });
      }
      if (!!transferSearch) {
        transferSearch({
          name: searchName,
          email: searchEmail,
          birth_date: searchBirthdate,
          vennoot_number: searchVennootNumber,
          street: searchStreet,
          number: searchNumber,
          zip: searchZipCity,
          catch_all: searchCatchAll,
          status: filterTransferStatus
        });
      }
      if (!!customUserSearch) {
        customUserSearch({
          birth_date: searchBirthdate,
          street: searchStreet,
          number: searchNumber,
          zip: searchZipCity
        });
      }
      if (!!transactionSearch) {
        transactionSearch({
          name: searchName,
          vennoot_number: searchVennootNumber,
          catch_all: searchCatchAll
        });
      }
      if (!!simple && !!onSearch) {
        onSearch(search, {
          enabled: filterEnabled,
          book_year_id: filterBookYear,
          meeting_status: filterMeetingStatus
        });
      }
      if (!!exitSearch) {
        exitSearch(searchCatchAll, {
          book_year_id: filterBookYear,
          checked: filterChecked,
          verified: filterVerified,
          cancelled: filterCancelled,
          identity_front: filterIdentity,
          item: filterItem
        });
      }
      if (!!deathSearch) {
        deathSearch(searchCatchAll, {
          checked: filterChecked
        });
      }
    }
  }, [doSearch]);

  useEffect(() => {
    if (!!search_use_enter && !!enterPressed) {
      clearTimeout(searchTimer);
      setEnterPressed(false);
      setDoSearch(true);
    }
  }, [enterPressed]);

  useEffect(() => {
    if (!!search_use_autosearch && !!userSearch) {
      clearTimeout(searchTimer);
      setSearchTimer(setTimeout(() => {setDoSearch(true);}, timer_timeout));
    }
  }, [searchName, searchEmail, searchBirthdate, searchVennootNumber, searchStreet, searchNumber, searchZipCity, searchCatchAll, filterEnabled]);

  useEffect(() => {
    if (!!search_use_autosearch && !!transferSearch) {
      clearTimeout(searchTimer);
      setSearchTimer(setTimeout(() => {setDoSearch(true);}, timer_timeout));
    }
  }, [searchName, searchEmail, searchBirthdate, searchVennootNumber, searchStreet, searchNumber, searchZipCity, searchCatchAll, filterTransferStatus]);

  useEffect(() => {
    if (!!search_use_autosearch && !!customUserSearch) {
      clearTimeout(searchTimer);
      setSearchTimer(setTimeout(() => {setDoSearch(true);}, timer_timeout));
    }
  }, [searchBirthdate, searchStreet, searchNumber, searchZipCity]);

  useEffect(() => {
    if (!!search_use_autosearch && !!transactionSearch) {
      clearTimeout(searchTimer);
      setSearchTimer(setTimeout(() => {setDoSearch(true);}, timer_timeout));
    }
  }, [searchName, searchVennootNumber, searchCatchAll]);

  useEffect(() => {
    if (!!search_use_autosearch && !!simple && !!onSearch) {
      clearTimeout(searchTimer);
      setSearchTimer(setTimeout(() => {setDoSearch(true);}, timer_timeout));
    }
  }, [search, filterEnabled, filterBookYear, filterMeetingStatus]);

  useEffect(() => {
    if (!!search_use_autosearch && !!exitSearch) {
      clearTimeout(searchTimer);
      setSearchTimer(setTimeout(() => {setDoSearch(true);}, timer_timeout));
    }
  }, [searchCatchAll, filterBookYear, filterChecked, filterVerified, filterCancelled, filterIdentity, filterItem]);

  useEffect(() => {
    if (!!search_use_autosearch && !!deathSearch) {
      clearTimeout(searchTimer);
      setSearchTimer(setTimeout(() => {setDoSearch(true);}, timer_timeout));
    }
  }, [searchCatchAll, filterChecked]);

  useEffect(() => {
    if (!!initialValues) {
      if (!!initialValues.resettable) {
        setSearchBirthdate('');
        setSearchStreet('');
        setSearchNumber('');
        setSearchZipCity('');
        setSearchCatchAll('');
        setSearch('');
        setSearchName('');
        setSearchEmail('');
        setSearchVennootNumber('');
        setFilterEnabled(defaultFilterEnabled || ['1']);
        setFilterVerified(['0']);
        setFilterChecked(['0']);
        setFilterCancelled(['0']);
        setFilterIdentity(['0', '1', '2', '3']);
        setFilterItem(['0', '1', '2', '3']);
        setFilterBookYear(default_book_year);
        setFilterMeetingStatus([0, 1, 2, 3]);
        setFilterTransferStatus(['', 'open', 'wait', 'pending']);
      }
      if (!!initialValues.birth_date) setSearchBirthdate(initialValues.birth_date);
      if (!!initialValues.street) setSearchStreet(initialValues.street);
      if (!!initialValues.number) setSearchNumber(initialValues.number);
      if (!!initialValues.zip) setSearchZipCity(initialValues.zip);
      if (!!initialValues.vennoot_number) setSearchVennootNumber(initialValues.vennoot_number);
    }
  }, [initialValues]);

  useEffect(() => {
    if (!!collapsable) {
      setCollapsed(true);
    }
  }, [collapsable]);

  const resetSearch = () => {
    if (initialValues?.resettable) {
      setSearchBirthdate('');
      setSearchStreet('');
      setSearchNumber('');
      setSearchZipCity('');
      setSearchCatchAll('');
      setSearch('');
      setSearchVennootNumber('');
    } else {
      setSearchBirthdate(!!initialValues?.birth_date ? initialValues?.birth_date : '');
      setSearchStreet(!!initialValues?.street ? initialValues?.street : '');
      setSearchNumber(!!initialValues?.number ? initialValues?.number : '');
      setSearchZipCity(!!initialValues?.zip ? initialValues?.zip : '');
      setSearchCatchAll(!!initialValues?.catch_all ? initialValues?.catch_all : '');
      setSearch(!!initialValues?.catch_all ? initialValues?.catch_all : '');
      setSearchVennootNumber(!!initialValues?.vennoot_number ? initialValues?.vennoot_number : '');
    }
    setSearchName('');
    setSearchEmail('');
    setFilterEnabled(defaultFilterEnabled || ['1']);
    setFilterVerified(['0']);
    setFilterChecked(['0']);
    setFilterCancelled(['0']);
    setFilterIdentity(['0', '1', '2', '3']);
    setFilterItem(['0', '1', '2', '3']);
    setFilterBookYear(default_book_year);
    setFilterMeetingStatus([0, 1, 2, 3]);
    setFilterTransferStatus(['', 'open', 'wait', 'pending']);

    if (!!memo) {
      setMemoSearch({
        ...memoSearch,
        [memo]: {
          catch_all: !!initialValues?.catch_all ? initialValues?.catch_all : '',
          birth_date: !!initialValues?.birth_date ? initialValues?.birth_date : '',
          email: '',
          name: '',
          street: !!initialValues?.street ? initialValues?.street : '',
          number: !!initialValues?.number ? initialValues?.number : '',
          zip: !!initialValues?.zip ? initialValues?.zip : '',
          vennoot_number: '',
          enabled: defaultFilterEnabled || ['1'],
          checked: ['0'],
          verified: ['0'],
          cancelled: ['0'],
          identity: ['0', '1', '2', '3'],
          item: ['0', '1', '2', '3'],
          book_year: default_book_year,
          meeting_status: [0, 1, 2, 3],
          transfer_status: ['', 'open', 'wait', 'pending']
        }
      });
    }

    setDoSearch(true);
  }

  return (<Box
    sx={{
      backgroundColor: theme.palette.tertiary?.main,
      padding: 1,
      position: 'relative',
      overflow: 'hidden',
      marginBottom: 2,
      ...sx
    }}>
      <SearchIcon sx={{position: 'absolute', top: -8, left: -8, height: 100, width: 100, opacity: 0.2}} />
      {!simple && <Box>
        {(!!userSearch || !!transactionSearch || !!exitSearch || !!deathSearch || !!customUserSearch || !!transferSearch) && <Grid container spacing={8}>
          <Grid container item xs={12}>
            
            <SearchGeneral
              general_visible={(!!userSearch || !!transactionSearch || !!exitSearch || !!deathSearch || !!transferSearch)}
              resetSearch={resetSearch}
              searchCatchAll={searchCatchAll}
              setSearchCatchAll={setSearchCatchAll}
              setEnterPressed={setEnterPressed}
              collapsable={collapsable}
              collapsed={collapsed}
              setCollapsed={setCollapsed}
            />

            {(!!userSearch || !!transferSearch) && <SearchFields
              visible={!!userSearch || !!transferSearch}
              generator_fields={["padding", "name", "email", "birthdate", "padding", "vennr", "address"]}
              setEnterPressed={setEnterPressed}
              memo={memo}
              doSearch={(pars) => {
                setSearchName(pars.searchName);
                setSearchEmail(pars.searchEmail);
                setSearchBirthdate(pars.searchBirthdate);
                setSearchVennootNumber(pars.searchVennootNumber);
                setSearchStreet(pars.searchStreet);
                setSearchNumber(pars.searchNumber);
                setSearchZipCity(pars.searchZipCity);
              }}
            />}

            {!!customUserSearch && <SearchFields
              visible={!!customUserSearch}
              generator_fields={!!customUserLayout ? customUserLayout : []}
              setEnterPressed={setEnterPressed}
              values={{
                birth_date: searchBirthdate,
                street: searchStreet,
                number: searchNumber,
                zip: searchZipCity
              }}
              memo={memo}
              doSearch={(pars) => {
                setSearchBirthdate(pars.searchBirthdate);
                setSearchStreet(pars.searchStreet);
                setSearchNumber(pars.searchNumber);
                setSearchZipCity(pars.searchZipCity);
              }}
            />}

            {!!transactionSearch && <SearchFields
              visible={!!transactionSearch}
              generator_fields={["padding", "padding", "name", "vennr"]}
              setEnterPressed={setEnterPressed}
              memo={memo}
              doSearch={(pars) => {
                setSearchName(pars.searchName);
                setSearchVennootNumber(pars.searchVennootNumber);
              }}
            />}

          </Grid>
        </Grid>}

        {!!children && children}
      </Box>}
      {!!simple && <Grid container item xs={12}>
        <Grid item xs={3}>&nbsp;</Grid>
        <Grid item xs={3}>
          {!hide_reset && <StyledButton
            label="Reset search"
            id='reset'
            // startIcon={}
            onClick={(v) => {
              resetSearch();
            }}
            sx={{marginBottom: -4.5, marginLeft: 1}}
          />}
        </Grid>
        <Grid item xs={6} sx={{paddingRight: 1}}>
          <StyledTextField
            label="Search..."
            sx={{width: '100%', "input": {backgroundColor: '#a9c2c0', padding: 1}}}
            value={search}
            id='search'
            onChange={(v) => {
              setSearch(v);
            }}
            setEnterPressed={setEnterPressed}
          />
        </Grid>
      </Grid>}

      {((!!filter_enabled || !!filter_bookyear || !!exitSearch || !!deathSearch || !!filter_meeting_status || !!transferSearch) && (!collapsable || (!!collapsable && !collapsed))) && <Grid container spacing="8">
        <Grid item xs={12}>
          <Divider />
        </Grid>

        {!!filter_enabled && <SearchFilterTrueFalse
          name="Active"
          filterValue={filterEnabled}
          setEnterPressed={setEnterPressed}
          setFilterValue={setFilterEnabled}
        />}
        
        {!!filter_meeting_status && <SearchFilterStatus
          values={[
            [0, "Blank"],
            [1, "Mailed"],
            [2, "To Post"],
            [3, "Posted"]
          ]}
          filterValue={filterMeetingStatus}
          setEnterPressed={setEnterPressed}
          setFilterValue={setFilterMeetingStatus}
        />}

        {!!transferSearch && <SearchFilterStatusStrings
          values={[
            ['', "Blank"],
            ['open', "Open"],
            ['wait', "Docs"],
            ['pending', "Pending"],
            ['approved', "Approved"],
            ['disapproved', "Disapproved"],
            ['cancelled', "Cancelled"]
          ]}
          filterValue={filterTransferStatus}
          setEnterPressed={setEnterPressed}
          setFilterValue={setFilterTransferStatus}
        />}

        {(!!exitSearch || !!deathSearch) && <>
          {(!!exitSearch || !!deathSearch) && <SearchFilterTrueFalse
            name="Checked"
            activeName='Checked'
            inactiveName='Unchecked'
            filterValue={filterChecked}
            setEnterPressed={setEnterPressed}
            setFilterValue={setFilterChecked}
          />}
          {(!!exitSearch) && <SearchFilterTrueFalse
            name="Verified"
            activeName='Verified'
            inactiveName='Unverified'
            filterValue={filterVerified}
            setEnterPressed={setEnterPressed}
            setFilterValue={setFilterVerified}
          />}
          {(!!exitSearch) && <SearchFilterTrueFalse
            name="Cancelled"
            activeName='Cancelled'
            inactiveName='Active'
            filterValue={filterCancelled}
            setEnterPressed={setEnterPressed}
            setFilterValue={setFilterCancelled}
          />}
        </>}
      </Grid>}
    </Box>);
}