import React, { FunctionComponent, useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAtomValue, useAtom } from 'jotai';

import { fetch_one, check_subdomain } from '../lib/server_helper';
import { loginAtom } from '../lib/auth';
import { currentProjectAtom } from '../lib/project';

import { createTheme, Theme, ThemeProvider } from '@mui/material/styles';

import '../i18n.ts';

import {Layout} from './Layout';

import {ContentCities} from './content/Cities';
import {ContentStatics} from './content/Statics';

import {AdminCompanies} from './admin/Companies';
import {AdminDomains} from './admin/Domains';
import {AdminEmbeddables} from './admin/Embeddables';
import {AdminEmbeddableDetail} from './admin/EmbeddableDetail';
import {AdminMailer} from './admin/Mailer';
import {AdminMailerDetail} from './admin/MailerDetail';
import {AdminPages} from './admin/Pages';
import {AdminPermissionDetail} from './admin/PermissionDetail';
import {AdminProjects} from './admin/Projects';
import {AdminPwaDetail} from './admin/PwaDetail';
import {AdminReportDetail} from './admin/ReportDetail';
import {AdminReports} from './admin/Reports';
import {AdminQuestionDetail} from './admin/QuestionDetail';
import {AdminQuestionRulesDetail} from './admin/QuestionRulesDetail';
import {AdminQuestions} from './admin/Questions';
import {AdminReportVersionVisualizer} from './admin/ReportVersionVisualizer';
import {AdminSegments} from './admin/Segments';
import {AdminSegmentsDetail} from './admin/SegmentsDetail';
import {AdminStatsDetail} from './admin/StatsDetail';
import {AdminThemeDetail} from './admin/ThemeDetail';
import {AdminUsers} from './admin/Users';
import {AdminUserDetail} from './admin/UserDetail';
import {AdminUserInvites} from './admin/UserInvites';

import {EmbedContent} from './embed/Content';

import {PubAfterPoll} from './pub/AfterPoll';
import {PubContent} from './pub/Content';
import {PubPoll} from './pub/Poll';
import {PubPrivacy} from './pub/Privacy';
import {PubProfile} from './pub/Profile';

import {ContentEditPage} from './content/EditPage';
import {Dashboard} from './Dashboard';
import {GenericError} from './GenericError';
import {GenericSuccess} from './GenericSuccess';
import {Help} from './Help';
import {Landing} from './Landing';
import {Login} from './Login';
import {Profile} from './Profile';

import { Project } from '../models/Project';

type MainProps = {}

export const Main: FunctionComponent<MainProps> = () => {
  const login = useAtomValue(loginAtom);
  let is_admin:boolean = login?.is_admin || false;
  let subdomain = check_subdomain();
  let admin_on_admin = !!is_admin && subdomain === 'admin';

  const defaultTheme = createTheme({
    typography: {
      fontFamily: ['Golos Text', 'sans-serif'].join(','),
      fontSize: 14,
      fontWeightLight: 100,
      fontWeightRegular: 400,
      fontWeightMedium: 700,
  
      h1: {
        fontSize: "2rem",
        fontFamily: 'Golos Text',
        color: 'rgb(16, 46, 86)'
      },
      h2: {
        fontSize: "1.5rem",
        fontFamily: 'Golos Text',
        color: 'rgb(16, 46, 86)'
      },
      h3: {
        fontSize: "1.25rem",
        fontFamily: 'Golos Text',
        color: 'rgb(16, 46, 86)'
      },
      h4: {
        fontSize: "1rem",
        fontFamily: 'Golos Text',
        color: 'rgb(16, 46, 86)'
      },
      h5: {
        fontSize: "0.8rem"
      },
      h6: {
        color: 'rgb(16, 46, 86)'
      },
      body1: {
        color: 'rgb(16, 46, 86)'
      },
      body2: {
        color: 'rgb(16, 46, 86)'
      }
    },
    palette: {
      primary: {
        light: 'rgb(93, 146, 217)',
        main: 'rgb(16, 46, 86)', // dark blue
        dark: 'rgb(7, 39, 82)'
      },
      primary_grey: {
        main: '#5D6071', // old grey
        light: '#aeb0b8'
      },
      secondary: {
        main: 'rgb(255, 16, 84)', // red
        light: '#ff5f80',
        dark: '#c4002c'
      },
      tertiary: {
        main: 'rgb(97, 92, 191)' // light green
      },
      quaternary: {
        main: 'rgb(152, 182, 180)' // apple blue sea green
      },
      warning: {
        main: '#ff9800' // orange
      },
      danger: {
        main: '#b80018' // red
      },
      opacitated: {
        main_01: 'rgba(16, 46, 86, 0.1)',
        main_02: 'rgba(16, 46, 86, 0.2)'
      },
      profile_blox: {
        identity: 'lightgoldenrodyellow',
        register: 'lightblue',
        account: 'rgb(232, 220, 186)',
        internal: 'rgb(159, 181, 161)',
        contact: 'rgb(230, 165, 173)',
        guardian: 'rgb(237, 189, 126)',
        email_history: 'rgb(171, 193, 245)',
        security: 'rgb(255, 191, 248)',
        exit: 'rgb(157, 158, 157)',
        itsme: 'rgba(255, 70, 18, 0.7)'
      },
      mode: 'light',
      background: {
        default: '#ffffff'
      }
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: ({ownerState, theme}) => ({
            textTransform: 'none',
            ...(!ownerState.no_lowercase && {
              // textTransform: 'lowercase'
              textTransform: 'none'
            }), ...(!!ownerState.capitalise && {
              // textTransform: 'capitalize'
              textTransform: 'none'
            }), ...(!!ownerState.transform_none && {
              textTransform: 'none'
            })
          })
        }
      }
    }
  });

  const [theme, setTheme] = useState<Theme>(defaultTheme);
  const [currentProject, setCurrentProject] = useAtom(currentProjectAtom);
  const [landingEnabled, setLandingEnabled] = useState<boolean>((!!currentProject && !!currentProject.enabled && !!currentProject.show_landing));
  
  useEffect(() => {
    if (subdomain.length > 0) {
      fetch_one<Project>('/nl/v3/pub/define_project', {
        object: 'project',
        fields: ['id', 'subdomain', 'name', 'get_logo_item', 'get_logo192_item', 'get_logo512_item', 'get_favicon', 'get_menu_logo', 'project_token', 'theme_info', 'fallback_language', 'questions|count', 'questions_info', 'iteration', 'is_itsme', 'is_email', 'is_sms', 'is_eid', 'languages', 'extra_data', 'enabled', 'show_landing', 'privacy_url', 'privacy_check', 'closed_circle'],
        subdomain: subdomain,
        full_url: window.location.host
      }, (ob, complete_data) => {
        setCurrentProject(ob);
        setLandingEnabled(!!ob.enabled && !!ob.show_landing && subdomain.indexOf("pnlmtrcs") < 0);
      }, (z) => {
        
      }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
    }
  }, [subdomain]);
  
  useEffect(() => {
    // console.log(`p- ${!!currentProject} nota- ${!is_admin} --- switching theme`);
    if (!!currentProject && subdomain !== 'admin') {
      setTheme({
        ...theme,
        typography: {
          ...theme.typography,
          h1: {
            ...theme.typography.h1,
            color: currentProject.theme_info?.text_colour || theme.typography.h1.color
          },
          h2: {
            ...theme.typography.h2,
            color: currentProject.theme_info?.text_colour || theme.typography.h2.color
          },
          h3: {
            ...theme.typography.h3,
            color: currentProject.theme_info?.text_colour || theme.typography.h3.color
          },
          h4: {
            ...theme.typography.h4,
            color: currentProject.theme_info?.text_colour || theme.typography.h4.color
          },
          h6: {
            ...theme.typography.h6,
            color: currentProject.theme_info?.text_colour || theme.typography.h6.color
          },
          body1: {
            ...theme.typography.body1,
            color: currentProject.theme_info?.text_colour || theme.typography.body1.color
          },
          body2: {
            ...theme.typography.body2,
            color: currentProject.theme_info?.text_colour || theme.typography.body2.color
          }
        },
        palette: {
          ...theme.palette,
          primary: {
            ...theme.palette.primary,
            main: currentProject.theme_info?.primary || theme.palette.primary.main,
            light: currentProject.theme_info?.primary_light || theme.palette.primary.light,
            dark: currentProject.theme_info?.primary_dark || theme.palette.primary.dark,
            contrastText: currentProject.theme_info?.contrast_text || theme.palette.primary.contrastText
          },
          tertiary: {
            ...theme.palette.tertiary,
            main: currentProject.theme_info?.secondary || theme.palette.tertiary?.main,
          }
        }
      });

      if (!!currentProject.get_logo192_item && !!currentProject.get_logo512_item) {
        var myDynamicManifest = {
          "name": currentProject.name,
          "short_name": currentProject.name,
          "description": `${currentProject.name} - a Panelmetrics project`,
          "start_url": document.location.origin,
          "background_color": "#000000",
          "theme_color": currentProject.theme_info?.primary || theme.palette.primary.main,
          "icons": [
            {
              "src": currentProject.get_favicon,
              "sizes": "64x64 32x32 24x24 16x16",
              "type": "image/png"
            },
            {
              "src": currentProject.get_logo192_item,
              "type": "image/png",
              "sizes": "192x192"
            },
            {
              "src": currentProject.get_logo512_item,
              "type": "image/png",
              "sizes": "512x512"
            }
          ]
        }
        const stringManifest = JSON.stringify(myDynamicManifest);
        const blob = new Blob([stringManifest], {type: 'application/json'});
        const manifestURL = URL.createObjectURL(blob);
        // @ts-ignore
        document.querySelector('#manifest-placeholder').setAttribute('href', manifestURL);
      }
      // @ts-ignore
      if (!!currentProject.get_favicon) document.querySelector('#pm_fav').setAttribute('href', currentProject.get_favicon);
      // @ts-ignore
      if (!!currentProject.get_favicon) document.querySelector('#pm_fav2').setAttribute('href', currentProject.get_favicon);
    } else {
      setTheme(defaultTheme);
    }
  }, [currentProject, login]);


  // let mah_theme = this.props.theme;
  // if (!!practice && !!practice.custom_theme && !!practice.custom_theme.primary_main && !!practice.custom_theme.contrast_main && !!practice.custom_theme.tertiary_main) {
  //   let pm = practice.custom_theme.primary_main;
  //   if (pm === 'undefined') pm = '#97BE6B';
  //   let cm = practice.custom_theme.contrast_main;
  //   if (cm === 'undefined') cm = '#FFFFFF';
  //   let tm = practice.custom_theme.tertiary_main;
  //   if (tm === 'undefined') tm = '#FFFFFF';

  //   base.palette.primary = {
  //     main: pm,
  //     contrastText: cm
  //   };
  //   base.palette.tertiary = {
  //     main: tm
  //   };
  //   if (!!practice.custom_theme.onboarding_bg && practice.custom_theme.onboarding_bg.indexOf('missing') < 0) base.fancy_bg_img = `url(${practice.custom_theme.onboarding_bg})`;
  //   // console.log(base, practice.custom_theme);
  //   mah_theme = createMuiTheme(base);
  // }

  return (<ThemeProvider theme={theme}>
    <Layout version="0.0.1" loginId={login?.id} project={currentProject}>
      {subdomain !== 'admin' && !!currentProject && !!landingEnabled && <Routes>
        <Route path='/landing' element={<PubContent/>} />

        <Route path="*" element={<Navigate to="/landing" replace />} />
      </Routes>}
      {subdomain !== 'admin' && !!currentProject && !currentProject.enabled && <Routes>
        <Route path='/' element={<GenericError detail="404" />} />

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>}

      {!(subdomain !== 'admin' && !!currentProject && !currentProject.enabled) && <Routes>
        <Route path='/' element={<Landing/>} />

        <Route path='/dashboard' element={<Dashboard/>} />

        <Route path='/cities' element={!!admin_on_admin ? <ContentCities/> : <GenericError detail="invalid_rights" />} />

        <Route path='/statics' element={!!admin_on_admin ? <ContentStatics/> : <GenericError detail="invalid_rights" />} />
        <Route path='/static/new' element={!!admin_on_admin ? <ContentEditPage object_type="static" return_path="/statics" /> : <GenericError detail="invalid_rights" />} />
        <Route path='/static/:id' element={!!admin_on_admin ? <ContentEditPage object_type="static" return_path="/statics" /> : <GenericError detail="invalid_rights" />} />

        <Route path='/companies' element={!!admin_on_admin ? <AdminCompanies/> : <GenericError detail="invalid_rights" />} />
        <Route path='/company/new' element={!!admin_on_admin ? <ContentEditPage object_type="company" return_path="/companies" /> : <GenericError detail="invalid_rights" />} />
        <Route path='/company/:id' element={!!admin_on_admin ? <ContentEditPage object_type="company" return_path="/companies" /> : <GenericError detail="invalid_rights" />} />

        <Route path='/projects' element={!!admin_on_admin ? <AdminProjects/> : <GenericError detail="invalid_rights" />} />
        <Route path='/project/new' element={!!admin_on_admin ? <ContentEditPage object_type="project" return_path="/projects" /> : <GenericError detail="invalid_rights" />} />
        <Route path='/project/:id' element={!!admin_on_admin ? <ContentEditPage object_type="project" return_path="/projects" /> : <GenericError detail="invalid_rights" />} />

        <Route path='/theme/:id' element={!!admin_on_admin ? <AdminThemeDetail /> : <GenericError detail="invalid_rights" />} />

        <Route path='/pwa/:id' element={!!admin_on_admin ? <AdminPwaDetail /> : <GenericError detail="invalid_rights" />} />

        <Route path='/stats/:id' element={!!admin_on_admin ? <AdminStatsDetail /> : <GenericError detail="invalid_rights" />} />

        <Route path='/mailer' element={!!admin_on_admin ? <AdminMailer /> : <GenericError detail="invalid_rights" />} />
        <Route path='/email_template/new' element={is_admin ? <AdminMailerDetail/> : <GenericError detail="invalid_rights" />} />
        <Route path='/email_template/:id' element={is_admin ? <AdminMailerDetail/> : <GenericError detail="invalid_rights" />} />
        {/* <Route path='/emails/:id' element={is_admin ? <EmailEmails/> : <GenericError detail="invalid_rights" />} /> */}

        <Route path='/domains/:project_id' element={!!admin_on_admin ? <AdminDomains/> : <GenericError detail="invalid_rights" />} />
        <Route path='/domain/:project_id/new' element={!!admin_on_admin ? <ContentEditPage object_type="domain" return_path="/domains/:project_id" /> : <GenericError detail="invalid_rights" />} />
        <Route path='/domain/:project_id/:id' element={!!admin_on_admin ? <ContentEditPage object_type="domain" return_path="/domains/:project_id" /> : <GenericError detail="invalid_rights" />} />

        <Route path='/embeddables/:project_id' element={!!admin_on_admin ? <AdminEmbeddables/> : <GenericError detail="invalid_rights" />} />
        <Route path='/embeddable/:project_id/new' element={!!admin_on_admin ? <AdminEmbeddableDetail /> : <GenericError detail="invalid_rights" />} />
        <Route path='/embeddable/:project_id/:id' element={!!admin_on_admin ? <AdminEmbeddableDetail /> : <GenericError detail="invalid_rights" />} />

        <Route path='/pages/:project_id' element={!!admin_on_admin ? <AdminPages/> : <GenericError detail="invalid_rights" />} />
        <Route path='/page/:project_id/new' element={!!admin_on_admin ? <ContentEditPage object_type="page" return_path="/pages/:project_id" /> : <GenericError detail="invalid_rights" />} />
        <Route path='/page/:project_id/:id' element={!!admin_on_admin ? <ContentEditPage object_type="page" return_path="/pages/:project_id" /> : <GenericError detail="invalid_rights" />} />

        <Route path='/questions/:project_id' element={!!admin_on_admin ? <AdminQuestions/> : <GenericError detail="invalid_rights" />} />
        <Route path='/question/:project_id/new' element={!!admin_on_admin ? <AdminQuestionDetail /> : <GenericError detail="invalid_rights" />} />
        <Route path='/question/:project_id/:id' element={!!admin_on_admin ? <AdminQuestionDetail /> : <GenericError detail="invalid_rights" />} />
        <Route path='/questionrules/:project_id/:id' element={!!admin_on_admin ? <AdminQuestionRulesDetail /> : <GenericError detail="invalid_rights" />} />

        <Route path='/reports/:project_id' element={!!admin_on_admin ? <AdminReports/> : <GenericError detail="invalid_rights" />} />
        <Route path='/report/:project_id/new' element={!!admin_on_admin ? <AdminReportDetail /> : <GenericError detail="invalid_rights" />} />
        <Route path='/report/:project_id/:id' element={!!admin_on_admin ? <AdminReportDetail /> : <GenericError detail="invalid_rights" />} />
        <Route path='/report_version/:id' element={!!admin_on_admin ? <AdminReportVersionVisualizer /> : <GenericError detail="invalid_rights" />} />

        <Route path='/segments' element={!!admin_on_admin ? <AdminSegments/> : <GenericError detail="invalid_rights" />} />
        <Route path='/segment/new' element={!!admin_on_admin ? <AdminSegmentsDetail /> : <GenericError detail="invalid_rights" />} />
        <Route path='/segment/:id' element={!!admin_on_admin ? <AdminSegmentsDetail /> : <GenericError detail="invalid_rights" />} />

        <Route path='/users' element={!!admin_on_admin ? <AdminUsers/> : <GenericError detail="invalid_rights" />} />
        <Route path='/user_invites/:project_id' element={!!admin_on_admin ? <AdminUserInvites/> : <GenericError detail="invalid_rights" />} />
        <Route path='/admin/new' element={!!admin_on_admin ? <AdminUserDetail/> : <GenericError detail="invalid_rights" />} />
        <Route path='/permissions/:id' element={!!admin_on_admin ? <AdminPermissionDetail/> : <GenericError detail="invalid_rights" />} />

        <Route path='/help/:slug' element={!!login ? <Help/> : <GenericError detail="invalid_rights" />} />

        <Route path='/login' element={<Login/>} />
        <Route path='/profile/:slug' element={!login ? <Profile/> : <GenericError detail="invalid_rights" />} />
        <Route path='/m/:magic_link' element={!login ? <Profile/> : <GenericError detail="invalid_rights" />} />
        <Route path='/profile' element={!!login ? <PubProfile/> : <GenericError detail="invalid_rights" />} />

        <Route path='/poll' element={!!login ? <PubPoll/> : <Login/>} />
        <Route path='/poll_outro' element={!!login ? <PubAfterPoll/> : <Login/>} />
        {/* <Route path='/poll' element={<PubPoll/>} /> */}

        <Route path='/privacy' element={<PubPrivacy/>} />

        <Route path='/embed/:token' element={<EmbedContent/>} />

        <Route path='/success/:key' element={<GenericSuccess />} />

        <Route path='/:page_key' element={<PubContent/>} />

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>}
    </Layout>
  </ThemeProvider>);
}

