import { FunctionComponent, useState, useEffect } from 'react';
// import { useTheme } from '@mui/material/styles';
// import { useAtom, useAtomValue } from 'jotai';
// import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next'

// import { loginAtom } from '../../lib/auth';
// import { fetchPage } from '../../lib/page';
// import { currentProjectAtom } from '../../lib/project';

import {
  Box
} from '@mui/material';

import {
  Typography
} from '../../components/interface';

// import { Page } from '../../models/Page';

type Props = {};

export const PubPrivacy: FunctionComponent<Props> = () => {
  // const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const [login, setLogin] = useAtom(loginAtom);
  // const currentProject = useAtomValue(currentProjectAtom);
  // let location = useLocation();

  return (<>
    <Box sx={{
      width: '100%',
      margin: 'auto',
      display: 'flex',
      flexDirection: 'column'
    }}>
      <Typography html>{t("panelmetrics.privacy", "nl")}</Typography>
    </Box>
  </>);
}
