import React, { FunctionComponent } from 'react';

import {
  Grid
} from '@mui/material';

import {
  Typography
} from '../../interface';

import { EmailBlock } from '../../../models/EmailBlock';

type BlockPublicTitleText2ColProps = {
  block: EmailBlock;
  marked: boolean;
  activeLocale: 'nl' | 'fr';
};

export const BlockPublicTitleText2Col: FunctionComponent<BlockPublicTitleText2ColProps> = ({block, marked, activeLocale}) => {
  let string_value = block.string_value;
  let title_value = block.title_value;

  let string_value_right = block.string_value_right;
  let title_value_right = block.title_value_right;

  return ( <Grid container item xs={12} sx={{
    marginTop: block.settings?.mtop || 0,
    marginBottom: block.settings?.mbot || 0,
    border: `1px solid ${block.settings?.border || 'transparent'}`,
    padding: '12px',
    ...(!!marked ? {backgroundColor: 'rgba(255, 255, 0, 0.3)'} : {})
  }}>
    <Grid item xs={6} sx={{paddingRight: 0.5}}>
      {!!title_value && <Typography variant="h3" html>{title_value}</Typography>}
      {!title_value && <Typography variant="h3" sx={{fontStyle: 'italic', color: 'lightgrey !important'}}>Title goes here...</Typography>}

      {!!string_value && <Typography html>{string_value}</Typography>}
      {!string_value && <Typography sx={{fontStyle: 'italic', color: 'lightgrey !important'}}>Content goes here...</Typography>}
    </Grid>
    <Grid item xs={6} sx={{paddingLeft: 0.5}}>
      {!!title_value_right && <Typography variant="h3" html>{title_value_right}</Typography>}
      {!title_value_right && <Typography variant="h3" sx={{fontStyle: 'italic', color: 'lightgrey !important'}}>Title goes here...</Typography>}

      {!!string_value_right && <Typography html>{string_value_right}</Typography>}
      {!string_value_right && <Typography sx={{fontStyle: 'italic', color: 'lightgrey !important'}}>Content goes here...</Typography>}
    </Grid>
  </Grid>);
};
