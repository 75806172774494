import { atom } from "jotai";
import { FoQueue } from "../models/FoQueue";
import { User } from "../models/User";
import { fetch_one } from "./server_helper";

export const queueAtom = atom<FoQueue[]|undefined>(undefined);

export default function refresh_queues(login: User, cb:(z:FoQueue[]|undefined) => void) {
  fetch_one<FoQueue>('/nl/v3/objects/custom_action', {
    // object: 'foqueue',
    object: 'user',
    class_action: 'custom_api_my_queue',
    fields: ['id', 'type', 'user_id', 'extra_data', 'queueable_id', 'queueable_type'],
    handler_id: login?.id
  }, (x, y) => {
    if (x) {
      if (!!x.custom_result && typeof(x.custom_result) === 'object') {
        cb(x.custom_result);
        return x.custom_result;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }, (z) => {

  }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
}