import { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useAtom } from 'jotai';
// import { useNavigate } from 'react-router-dom';
// import { useTranslation } from 'react-i18next'

// import { fetch_all } from '../../lib/server_helper';

import {
  Avatar,
  Box
} from '@mui/material';
// import { e } from '@tanstack/query-core/build/legacy/queryClient-Ho-z40Sw';

import {
  Typography
} from '../../components/interface';

// import { loginAtom } from '../../lib/auth';

type AvailableLang = 'nl' | 'fr' | 'en';
type PickersLanguagePickerProps = {
  value: AvailableLang[];
  onChange: (z: AvailableLang[]) => void;
};

export const PickersLanguagePicker: FunctionComponent<PickersLanguagePickerProps> = ({value, onChange}) => {
  const theme = useTheme();
  // const navigate = useNavigate();
  // const {t, i18n} = useTranslation(['translations']);

  // const [login, setLogin] = useAtom(loginAtom);

  return (<>
    <Typography sx={{fontSize: '0.75rem', marginBottom: 1}}>Available languages</Typography>
    <Box sx={{display: 'flex', flexDirection: 'row'}}>
      {['nl', 'fr', 'en'].map((lang) => (
          <Avatar
            key={lang}
            onClick={() => {
              let new_value: AvailableLang[] = [];
              if (value.indexOf(lang as AvailableLang) > -1) {
                new_value = [...value.filter((kk): kk is AvailableLang => kk !== lang)]
              } else {
                new_value = [...value, lang as AvailableLang];
              }
              onChange(new_value);
            }}
            sx={{
              width: 24,
              height: 24,
              fontSize: '0.8rem',
              textAlign:'center',
              cursor: 'pointer',
              marginRight: 1,
              backgroundColor: (value.indexOf(lang as AvailableLang) > -1 ? theme.palette.primary.main : 'red')
            }}
          >{lang}</Avatar>
        ))}
    </Box>
  </>);
}




