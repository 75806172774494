import React, { FunctionComponent, useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

import { fetch_one } from '../../lib/server_helper';
import { checkAllowed } from '../../lib/permission';

import {
  CircularProgress,
  Divider,
  Grid
} from '@mui/material';

import { loginAtom } from '../../lib/auth';

import { AlertCheckInputs } from '../../components/alerts/CheckInputs';
import { AlertSaved } from '../../components/alerts/Saved';
import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import {
  ImagePicker,
  Page,
  StyledButton,
  Typography
} from '../../components/interface';

import { MenuItem } from '../../models/MenuItem';
import { Project } from '../../models/Project';

// const { DateTime } = require("luxon");

type AvailableLang = 'nl' | 'fr' | 'en';
type Props = {};

export const AdminPwaDetail: FunctionComponent<Props> = () => {
  const navigate = useNavigate();
  // const theme = useTheme();
  const {t, i18n} = useTranslation(['translations']);

  const params = useParams();

  const [login, setLogin] = useAtom(loginAtom);
  const [project, setProject] = useState<Project>();
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const [loader, setLoader] = useState<boolean>(true);
  const [saveLoader, setSaveLoader] = useState<boolean>(false);
  const [projectId, setProjectId] = useState<string | undefined>(params.id);

  const [logo192Item, setLogo192Item] = useState<File>();
  const [logo192ItemUrl, setLogo192ItemUrl] = useState<string>();
  const [logo512Item, setLogo512Item] = useState<File>();
  const [logo512ItemUrl, setLogo512ItemUrl] = useState<string>();

  const [errorSnackOpen, setErrorSnackOpen] = useState<boolean>(false);
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);
  const [exitSavedSnackOpen, setDividendSavedSnackOpen] = useState<boolean>(false);

  const fields = [
    'id', 'get_logo192_item', 'get_logo512_item'
  ];

  const saveSetting = () => {
    if (checkAllowed(login, null, projectId?.toString() || '0', "ProjectTheme", 'u', true)) {
      setSaveLoader(true);

      let formData = new FormData();
      formData.append('object', 'project');
      formData.append('handler_id', (login?.id || "").toString());
      fields.map(field => {
        formData.append('fields[]', field);
      });
      if (!!projectId) formData.append('ob[id]', projectId.toString());
      if (!!projectId) formData.append('id', (projectId || '').toString());
      if (!!logo192Item) formData.append(`ob[logo192_item]`, logo192Item);
      if (!!logo192ItemUrl) formData.append(`ob[set_logo192_item_url]`, logo192ItemUrl);
      if (!!logo512Item) formData.append(`ob[logo512_item]`, logo512Item);
      if (!!logo512ItemUrl) formData.append(`ob[set_logo512_item_url]`, logo512ItemUrl);

      fetch_one<Project>('/nl/v3/objects/save', formData, (ob, complete_data) => {
        openObject(ob);
        navigate(`/projects`);
        setSaveLoader(false);
      }, (z) => {
        setServerErrorSnackOpen(true);
      }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
    }
  };

  const fetchCalculation = () => {
    if (checkAllowed(login, null, projectId?.toString() || '0', "Projects", 'r', true)) {
      setLoader(true);
      
      fetch_one<Project>('/nl/v3/objects/fetch_all', {
        object: 'project',
        fields: fields,
        id: projectId
      }, (ob, complete_data) => {
        openObject(ob);
        setLoader(false);
      }, (z) => {
        setServerErrorSnackOpen(true);
      }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchCalculation();
  }, [projectId]);

  const openObject = (ob: Project) => {
    setProject(ob);
    setMenuItems(ob.theme_info?.menu_items as MenuItem[]);
  }

  return (
    <Page sx={{}} title={t("objects.project.pwa.title")}>
      <Grid container spacing="8">

        <Grid item xs={6}>
          <Typography sx={{fontSize: '0.8rem'}}>Small PWA Logo (192x192, PNG)</Typography>
          <ImagePicker existing={project?.get_logo192_item} item={logo192Item} itemUrl={logo192ItemUrl} onSelect={(_item:File|undefined, _itemUrl:string|undefined) => {
            setLogo192Item(_item);
            setLogo192ItemUrl(_itemUrl);
          }} />
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{fontSize: '0.8rem'}}>Large PWA Logo (512x512, PNG)</Typography>
          <ImagePicker existing={project?.get_logo512_item} item={logo512Item} itemUrl={logo512ItemUrl} onSelect={(_item:File|undefined, _itemUrl:string|undefined) => {
            setLogo512Item(_item);
            setLogo512ItemUrl(_itemUrl);
          }} />
        </Grid>

        <Grid item xs={12} sx={{alignContent: 'baseline', textAlign: 'center', position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 1201}}>
          <Divider sx={{marginTop: 1, marginBottom: 2}} />

          {!!loader && <CircularProgress />}
          {!loader && <StyledButton
            label={t("public.buttons.cancel")}
            id='cancel'
            // contained
            onClick={(v) => {
              navigate(`/projects`);
            }}
            sx={{marginLeft: 1}}
          />}
          {checkAllowed(login, null, projectId?.toString() || '0', "ProjectTheme", 'u', true) && !loader && <StyledButton
            label={t("public.buttons.save")}
            id='save'
            contained
            onClick={(v) => {
              saveSetting();
            }}
            sx={{marginLeft: 1}}
          />}
        </Grid>
      </Grid>

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
      {!!errorSnackOpen && <AlertCheckInputs open={errorSnackOpen} setOpen={(b) => {setErrorSnackOpen(b);}} />}
      {!!exitSavedSnackOpen && <AlertSaved open={exitSavedSnackOpen} setOpen={(b) => {setDividendSavedSnackOpen(b);}} />}
    </Page>
  );
}
