import { FunctionComponent, useEffect, useState } from 'react';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom, useAtomValue } from 'jotai';
// import { useNavigate } from 'react-router-dom';

// import { fetch_one } from '../lib/server_helper';

import {
  CircularProgress
} from '@mui/material';

import { FoQueue } from '../models/FoQueue';
import { loginAtom } from '../lib/auth';
import refresh_queues, { queueAtom } from '../lib/queues';

type SidebarQueuesProps = {};

export const SidebarQueues: FunctionComponent<SidebarQueuesProps> = () => {
  const theme = useTheme();
  // const navigate = useNavigate();
  const login = useAtomValue(loginAtom);

  const [myQueues, setMyQueues] = useAtom(queueAtom);

  const [queues, setQueues] = useState<FoQueue[]>([]);
  const [loader, setLoader] = useState<boolean>(!!login);
  const [searchInterval, setSearchInterval] = useState(setInterval(() => {}, 30 * 1000));

  useEffect(() => {
    clearTimeout(searchInterval);
    if (!!login) {
      onSearch();
      setSearchInterval(setInterval(onSearch, 30 * 1000));
    }
  }, [login]);

  const onSearch = () => {
    setLoader(true);
    if (!!login) {
      // refresh_queues(login, (res) => {
      //   if (!!res) {
      //     setMyQueues(res);
      //     setQueues(res);
      //   }
      //   setLoader(false);
      // });
      setLoader(false);
    }
  };

  return (<>
    {!!loader && <CircularProgress sx={{color: theme.palette.tertiary?.main}} />}

    {/* {!loader && divcalcQueues.length > 0 && divcalcQueues.map(q => (<>
      <Badge badgeContent={(q.extra_data?.users || 0) - (q.extra_data?.user_ids?.length || 0)} color="secondary" sx={{
        marginLeft: '8px',
        '& .MuiBadge-badge': {
          right: 8,
          top: 8
        }
      }}>
        <Avatar sx={{
          backgroundColor: 'white',
          height: 40,
          width: 40,
          "& img": {
            width: 32,
            height: 32
          },
          border: '2px solid transparent',
          borderColor: theme.palette.tertiary?.main
        }}><EuroIcon sx={{
          color: theme.palette.tertiary?.main
        }} /></Avatar>
      </Badge>
    </>))} */}
  </>);
}
