import { FunctionComponent, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom, useAtomValue } from 'jotai';
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next'

import { fetch_one, check_subdomain } from '../../lib/server_helper';
import do_login, { loginAtom } from '../../lib/auth';
import { currentProjectAtom } from '../../lib/project';

import {
  Box,
  CircularProgress
} from '@mui/material';

import MainLogo from '../images/MainLogo.png';

import {
  Link,
  Typography
} from '../../components/interface';

import { EmbedAlternateLayout } from './AlternateLayout';

import { Embeddable } from '../../models/Embeddable';
import { Project } from '../../models/Project';

type EmbedContentProps = {};

export const EmbedContent: FunctionComponent<EmbedContentProps> = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const [login, setLogin] = useAtom(loginAtom);
  const currentProject = useAtomValue(currentProjectAtom);

  const params = useParams();

  // const [currentProject, setCurrentProject] = useState<Project|null>(null);
  const [currentEmbed, setCurrentEmbed] = useState<Embeddable|null>(null);
  const [embedToken, setEmbedToken] = useState<string | undefined>(params.token);

  let subdomain = check_subdomain();
  
  useEffect(() => {
    if (subdomain.length > 0) {
      fetch_one<Embeddable>('/nl/v3/pub/define_embed', {
        fields: ['id', 'extra_data', 'token_key'],
        subdomain: subdomain,
        full_url: window.location.host,
        embed_token: embedToken
      }, (ob, complete_data) => {
        setCurrentEmbed(ob);
      }, (z) => {
        
      }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
    }
  }, [currentProject]);

  const fb_lang = currentProject?.fallback_language|| 'nl';
  const lang = i18n.resolvedLanguage || fb_lang;

  let renderLayout = false;
  let renderAlternateLayout = false;

  if (!!currentEmbed && !!currentEmbed.extra_data) {
    if (currentEmbed.extra_data.is_website === '1') {
      renderLayout = true;
    };
    if (currentEmbed.extra_data.poll_only === '1') {
      renderLayout = false;
      renderAlternateLayout = true;
    }
  }

  return (<>
    {!currentEmbed && <Box sx={{width: '100%', paddingTop: 5, textAlign: 'center'}}><CircularProgress /></Box>}
    {!!currentEmbed && <>
      {!renderAlternateLayout && renderLayout && <>Content for stats and full website</>}
      {!renderLayout && renderAlternateLayout && <EmbedAlternateLayout currentEmbed={currentEmbed} />}
    </>}
  </>);
}
