import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';

import { currentProjectAtom } from '../../lib/project';

import {
  Box
} from '@mui/material';
import {
  Typography
} from '../interface';

export type DstValue = {
  label: {[z:string]: string};
  voters?: number;
  values: {
    color: string;
    tooltip: string;
    value: number;
    textColor: string;
  }[]
};
type Props = {
  sx?: object;
  data: DstValue[];
  disableVoters: boolean;
  showPct: boolean;
  overrideVoters?: number;
};

export const DivStaBarBetter: FunctionComponent<Props> = ({data, disableVoters, showPct, overrideVoters}) => {
  const theme = useTheme();

  const currentProject = useAtomValue(currentProjectAtom);
  const {t, i18n} = useTranslation(['translations']);
  const fb_lang = currentProject?.fallback_language|| 'nl';
  const lang = i18n.resolvedLanguage || fb_lang;

  const mainHeight = "60px";
  const doubleMainHeight = "100px";
  const height = "40px";

  // const [actualValue, setActualValue] = useState<string>(value);

  useEffect(() => {
    
  }, [data]);

  return (<>
    {data.map(yesno => (<Box sx={{
      width: '100%',
      height: (!disableVoters && ((!!overrideVoters && overrideVoters > 0) || (!!yesno.voters && yesno.voters > 0)) ? doubleMainHeight : mainHeight),
      padding: 0,
      margin: 1,
      marginBottom: 4
    }}>
      <Typography sx={{
        padding: 0,
        margin: 0,
        fontSize: '0.8rem'
      }}>{yesno.label[lang] || yesno.label[fb_lang]}{!showPct && ` (${yesno.values[0].value + yesno.values[1].value})`}</Typography>

      <DivStaBarBetterSingle
        yes={yesno.values[0].value}
        no={yesno.values[1].value}
        voters={0}
        mainHeight={mainHeight}
        height={height}
        showPct={showPct}
      />
      {!disableVoters && ((!!overrideVoters && overrideVoters > 0) || (!!yesno.voters && yesno.voters > 0)) && <DivStaBarBetterSingle
        yes={yesno.values[0].value}
        no={yesno.values[1].value}
        voters={!!overrideVoters && overrideVoters > 0 ? overrideVoters : (yesno.voters || 0)}
        mainHeight={mainHeight}
        height={height}
        showPct={showPct}
      />}
    </Box>))}
  </>);
}

type SingleProps = {
  yes: number;
  no: number;
  voters: number;
  mainHeight: string;
  height: string;
  showPct: boolean;
};
const DivStaBarBetterSingle: FunctionComponent<SingleProps> = ({yes, no, voters, mainHeight, height, showPct}) => {
  const theme = useTheme();
  const {t, i18n} = useTranslation(['translations']);

  const [total, setTotal] = useState<number>(0);
  const [yesPct, setYesPct] = useState<number>(0);
  const [noPct, setNoPct] = useState<number>(0);
  const [voterPct, setVoterPct] = useState<number>(0);

  useEffect(() => {
    if (voters > 0) {
      setTotal(voters - (yes + no));
    } else {
      setTotal(yes + no);
    }
  }, [yes, no, voters]);

  useEffect(() => {
    setYesPct(yes > 0 ? (yes/total*100) : 0);
    setNoPct(no > 0 ? (no/total*100) : 0);
    setVoterPct(voters > 0 ? (100.0 - ((yes/total*100) + (no/total*100))) : 0);
  }, [total]);

  return (<Box sx={{
    width: '100%',
    height: height,
    padding: 0,
    position: 'relative',
    border: '1px solid transparent',
    borderColor: theme.palette.primary.main
  }}>
    {yes > 0 && <Box sx={{
      position: 'absolute',
      left: 0,
      top: 0,
      height: height,
      width: `${yesPct}%`,
      minWidth: '1px',
      backgroundColor: 'lightgreen',
      textAlign: 'left'
    }}></Box>}

    {voters > 0 && <Box sx={{
      position: 'absolute',
      left: yesPct,
      top: 0,
      height: height,
      minWidth: '1px',
      width: `${voterPct}%`,
      backgroundColor: 'rgba(0,0,0,0.1)',
    }}></Box>}

    {no > 0 && <Box sx={{
      position: 'absolute',
      right: 0,
      top: 0,
      height: height,
      width: `${noPct}%`,
      minWidth: '1px',
      backgroundColor: 'red',
      textAlign: 'right'
    }}></Box>}

    {yes > 0 && <Typography sx={{
      position: 'absolute',
      top: 10,
      left: 4,
      fontSize: '0.7rem'
    }}>{`${t("buttons.yesno.yes").replace(/<[^>]*>?/gm, '')} (${showPct ? `${yesPct.toFixed(2)}%` : yes})`}</Typography>}
    {no > 0 && <Typography sx={{
      position: 'absolute',
      top: 10,
      right: 4,
      fontSize: '0.7rem'
    }}>{`${t("buttons.yesno.no").replace(/<[^>]*>?/gm, '')} (${showPct ? `${noPct.toFixed(2)}%` : no})`}</Typography>}
    {voters > 0 && <Typography sx={{
      position: 'absolute',
      top: 10,
      left: 0,
      width: '100%',
      fontSize: '0.7rem',
      textAlign: 'center'
    }}>{`${t("yesno.stats.kiesgerechtigden").replace(/<[^>]*>?/gm, '')} (${showPct ? `${voterPct.toFixed(2)}%` : (voters - yes - no)})`}</Typography>}

    <Box style={{
      position: 'absolute',
      top: -10,
      left: '50%',
      borderStyle: 'dashed',
      borderRight: 'none',
      borderTop: 'none',
      borderBottom: 'none',
      borderLeft: '1px dashed black',
      width: '1px',
      height: mainHeight,
      marginLeft: '-1px'
    }}></Box>
  </Box>);
}