import { FunctionComponent } from 'react';
import { useAtom } from 'jotai';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next'

import {
  Avatar,
  Box
} from '@mui/material';

import { currentProjectAtom } from '../lib/project';

// import TreeLogo from '../images/favicon.png';
import MainLogo from '../images/MainLogo.png';

import { Link } from '../components/interface/Link';
import { Typography } from '../components/interface/Typography';

type GenericErrorProps = {
  detail?: string;
  hide_detail?: boolean;
  cta_label?: string;
  cta_link?: string;
};

export const GenericError: FunctionComponent<GenericErrorProps> = ({detail, hide_detail, cta_label, cta_link}) => {
  const [currentProject, setCurrentProject] = useAtom(currentProjectAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  let detailed_explanation = '';
  if (detail === 'invalid_rights') detailed_explanation = t("general_errorpage.errors.invalid_rights", 'You are either not logged in, or you have invalid rights to access this page.');
  if (detail === 'not_signed_in') detailed_explanation = t("general_errorpage.errors.not_signed_in", 'You are not signed in.');
  if (detail === '404') detailed_explanation = t("general_errorpage.errors.404", 'The project you are trying to access is no longer available.');

  return (
    <Box sx={{
      width: 'auto',
      maxWidth: '600px',
      margin: 'auto',
      display: 'flex',
      flexDirection: 'column'
    }}>
      <Avatar sx={{alignSelf: 'center', width: '200px', height: '200px', marginBottom: 4, marginTop: 4}} src={!!currentProject && !!currentProject.theme_info?.logo ? currentProject.theme_info?.logo : "https://via.placeholder.com/200x200"} />

      {!hide_detail && <Typography variant="h3" sx={{color: theme.palette.secondary.main, textAlign: 'center', marginBottom: 2}}>{t("general_errorpage.title", 'An error occurred. You have arrived at this errorpage.')}</Typography>}
      {!hide_detail && <Typography sx={{textAlign: 'center'}}>{t("general_errorpage.subtitle_detailed_description", 'Following details were found while throwing this error:')}<br />{detailed_explanation}</Typography>}

      {!!hide_detail && <Typography sx={{color: theme.palette.secondary.main, textAlign: 'center'}}>{detailed_explanation}</Typography>}

      <Link sx={{textAlign: 'center', marginTop: 4}} label={(!!cta_label ? cta_label : 'Take me back').replace(/<[^>]*>?/gm, '')} onClick={() => {navigate(!!cta_link ? cta_link : "/");}} />
    </Box>
  );
}
