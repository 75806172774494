import React, { FunctionComponent, useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';

import {
  Grid
} from '@mui/material';

import { loginAtom } from '../../lib/auth';

import { Typography } from './Typography';

import SunEditor from 'suneditor-react';
import SunEditorCore from "suneditor/src/lib/core";

type StyledEditorNoTranslProps = {
  label: string;
  value: string;
  getSunEditorInstance?: (sunEditor: SunEditorCore) => void;
  onChange: (z:string) => void;
};

export const StyledEditorNoTransl: FunctionComponent<StyledEditorNoTranslProps> = ({label, onChange, value, getSunEditorInstance}) => {
  // const theme = useTheme();

  const [login, setLogin] = useAtom(loginAtom);

  const [editorContent, setEditorContent] = useState<string>(value || '');
  const [inited, setInited] = useState<boolean>(false);

  let setting_editor_height = '300px';
  if (!!login && !!login.extra_data?.settings?.editor_height) setting_editor_height = login.extra_data.settings.editor_height;
  let setting_languages_split_raw = '0';

  useEffect(() => {
    if (!inited && value !== editorContent) {
      setEditorContent(value);
      setInited(true);
    }
  }, [value]);

  useEffect(() => {
    if (!!onChange) onChange(editorContent);
  }, [editorContent]);

  return (<>
    <Grid item xs={12}>
      <Typography sx={{fontSize: '0.75rem'}}>{label}</Typography>
      <SunEditor
        height={setting_editor_height}
        getSunEditorInstance={getSunEditorInstance}
        setOptions={{
          defaultStyle: "font-size: 16px; font-family: 'Golos Text', sans-serif",
          buttonList: [
            ['undo', 'redo'],
            ['font', 'fontSize', 'formatBlock'],
            ['paragraphStyle', 'blockquote'],
            ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
            ['fontColor', 'hiliteColor', 'textStyle'],
            ['removeFormat'],
            '/',
            ['outdent', 'indent'],
            ['align', 'horizontalRule', 'list', 'lineHeight'],
            ['table', 'link', 'image', 'video'],
            // ['table', 'link', 'image', 'video', 'math'], // You must add the 'katex' library at options to use the 'math' plugin.
            // ['imageGallery'], // You must add the "imageGalleryUrl".
            ['fullScreen', 'showBlocks', 'codeView'],
            ['preview', 'print'],
            // ['save', 'template'],
            // ['dir', 'dir_ltr', 'dir_rtl'],
            // '/', Line break
          ]
        }}
        setContents={editorContent}
        onChange={(v) => {setEditorContent(v);}}
      />
    </Grid>
  </>);
}