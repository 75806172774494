import { FunctionComponent } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";

import { do_logout, loginAtom } from '../../lib/auth';
import { useAtom } from 'jotai';

import {
  Avatar,
  Box,
  Divider,
  Typography
} from '@mui/material';

// import TreeLogo from '../../images/favicon.png';

import {Link} from '../interface/Link';

type ProfileProps = {};

export const Profile: FunctionComponent<ProfileProps> = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [login, setLogin] = useAtom(loginAtom);

  return (
    <Box sx={{ flexGrow: 1, alignItems: 'center', display: 'flex', margin: -1, padding: 1, backgroundColor: theme.palette.primary?.main, borderBottom: '1px solid white', borderBottomColor: theme.palette.tertiary?.main}}>
      {/* <Avatar sx={{marginRight: 1}} src={TreeLogo} /> */}
      <Box sx={{flexGrow: 1}}>

        <Typography sx={{fontSize: '1.1rem', color: 'white'}}>{!!login ? `${login?.first_name} ${login?.last_name}` : 'Not logged in'}</Typography>
        <span style={{display: 'none'}}>{login?.email}</span>
        <Divider color="white" />

        {!!login && <Box sx={{textAlign: 'right'}}>
          <Link sx={{display: 'inline-block'}} smaller onClick={(e) => {
            navigate("/settings");
          }} label='Settings' />
          <Box sx={{width: '2px', height: '10px', marginLeft: 0.5, marginRight: 0.5, backgroundColor: 'white', display: 'inline-block'}} />
          <Link sx={{display: 'inline-block'}} smaller onClick={(e) => {
            do_logout((result) => {
              setLogin(result);
              window.location.reload();
            });
          }} label='Sign out' />
        </Box>}

        {!login && <Link sx={{textAlign: 'right'}} smaller onClick={(e) => {
          navigate("/login");
        }} label='Sign in' />}
        
      </Box>
    </Box>
  );
}
