import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

import { fetch_one, fetch_all } from '../../lib/server_helper';

import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  Grid
} from '@mui/material';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { loginAtom } from '../../lib/auth';

import { AlertCheckInputs } from '../../components/alerts/CheckInputs';
import { AlertSaved } from '../../components/alerts/Saved';
import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import {
  Page,
  LanguageFab,
  StyledButton,
  StyledColourPicker,
  StyledEditor,
  StyledIconButton,
  StyledSelect,
  StyledSwitch,
  StyledTextField,
  StyledTextFieldTransl,
  Typography
} from '../../components/interface';

import { AnswerOption } from '../../models/AnswerOption';
import { Embeddable } from '../../models/Embeddable';

// const { DateTime } = require("luxon");

// type AvailableLang = 'nl' | 'fr' | 'en';
type AdminEmbeddableDetailProps = {};

export const AdminEmbeddableDetail: FunctionComponent<AdminEmbeddableDetailProps> = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const {t, i18n} = useTranslation(['translations']);

  const params = useParams();

  const [login, setLogin] = useAtom(loginAtom);
  const [embeddableDetail, setEmbeddableDetail] = useState<Embeddable|null>(null);
  const [loader, setLoader] = useState<boolean>(true);
  const [saveLoader, setSaveLoader] = useState<boolean>(false);
  const [projectId, setProjectId] = useState<string | undefined>(params.project_id);
  const [embeddableId, setEmbeddableId] = useState<string | undefined>(params.id);

  const [errorSnackOpen, setErrorSnackOpen] = useState<boolean>(false);
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);
  const [exitSavedSnackOpen, setDividendSavedSnackOpen] = useState<boolean>(false);

  const fields = [
    'id', 'created_at', 'updated_at', 'token_key', 'extra_data', 'description'
  ];

  const saveSetting = () => {
    setSaveLoader(true);

    let formData = new FormData();
    formData.append('object', 'embeddable');
    formData.append('handler_id', (login?.id || "").toString());
    fields.map(field => {
      formData.append('fields[]', field);
    });
    if (!!projectId) formData.append('ob[project_id]', projectId.toString());
    if (!!embeddableId) formData.append('ob[id]', (embeddableId || '').toString());
    if (!!embeddableId) formData.append('id', (embeddableId || '').toString());
    // embeddableDetail
    formData.append('ob[description]', embeddableDetail?.description || '');

    formData.append('ob[extra_data][is_website]', embeddableDetail?.extra_data?.is_website || '0');
    formData.append('ob[extra_data][poll_only]', embeddableDetail?.extra_data?.poll_only || '0');
    formData.append('ob[extra_data][background_colour]', embeddableDetail?.extra_data?.background_colour || '');

    fetch_one<Embeddable>('/nl/v3/objects/save', formData, (ob, complete_data) => {
      setEmbeddableId(ob.id?.toString());
      setProjectId(ob.project_id?.toString());
      openObject(ob);
      setSaveLoader(false);
      setDividendSavedSnackOpen(true);
      navigate(`/embeddables/${projectId}`);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
  };

  const fetchCalculation = () => {
    setLoader(true);
    
    fetch_one<Embeddable>('/nl/v3/objects/fetch_all', {
      object: 'embeddable',
      fields: fields,
      id: embeddableId
    }, (ob, complete_data) => {
      openObject(ob);
      setLoader(false);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
  };

  useEffect(() => {
    fetchCalculation();
  }, []);

  const openObject = (ob: Embeddable) => {
    setEmbeddableDetail(ob);
  }

  return (
    <Page sx={{}} title={`Embeddable Detail`}>
      <Grid container spacing="8">

        <Grid item container xs={12} spacing="4" sx={{alignContent: 'baseline'}}>
          <Grid item xs={12}>
            <StyledTextField
              label="Internal description"
              value={embeddableDetail?.description || ''}
              id="pos"
              onChange={(v) => {if (!!embeddableDetail) setEmbeddableDetail({
                ...embeddableDetail,
                description: v});
              }}
              fullWidth
            />
          </Grid>
          {!!embeddableDetail?.id && <Grid item xs={12}>
            <StyledTextField
              label="iframe url token"
              value={embeddableDetail?.token_key || ''}
              id="token_key"
              disabled
              onChange={(v) => {}}
              fullWidth
            />
          </Grid>}
          
          <Grid item container xs={6} spacing="4" sx={{alignContent: 'baseline'}}>
            <Grid item xs={12}>
              <StyledSwitch
                label="Type of iframe"
                offLabel="Statistics"
                onLabel="Website"
                value={embeddableDetail?.extra_data?.is_website === '1'}
                onChange={(v) => {if (!!embeddableDetail) setEmbeddableDetail({
                  ...embeddableDetail,
                  extra_data: {
                    ...(embeddableDetail.extra_data || {}),
                    is_website: (!!v ? '1' : '0')
                  }
                });}}
              />
            </Grid> 
          </Grid>
          
          <Grid item container xs={6} spacing="4" sx={{alignContent: 'baseline'}}>
            {embeddableDetail?.extra_data?.is_website === '1' && <Grid item xs={12}>
              <StyledSwitch
                label="Website content"
                offLabel="Full website"
                onLabel="Poll only"
                value={embeddableDetail?.extra_data?.poll_only === '1'}
                onChange={(v) => {if (!!embeddableDetail) setEmbeddableDetail({
                  ...embeddableDetail,
                  extra_data: {
                    ...(embeddableDetail.extra_data || {}),
                    poll_only: (!!v ? '1' : '0')
                  }
                });}}
              />
            </Grid>}
            {embeddableDetail?.extra_data?.is_website === '1' && <Grid item xs={12}>
              <StyledColourPicker
                label="Background colour"
                value={embeddableDetail?.extra_data?.background_colour || ''}
                onChange={(v) => {if (!!embeddableDetail) setEmbeddableDetail({
                  ...embeddableDetail,
                  extra_data: {
                    ...(embeddableDetail.extra_data || {}),
                    background_colour: v
                  }
                });}}
              />
            </Grid>}
          </Grid>

          <Grid item xs={12} sx={{marginTop: 3}}>
            <Typography>Current implementation:<br/>- Statistics: <b>No</b><br/>- Website / Full website: <b>No</b><br/>- Website / Poll only: <b>Yes</b></Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{alignContent: 'baseline', textAlign: 'center', position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 1201}}>
          <Divider sx={{marginTop: 1, marginBottom: 2}} />

          {!loader && <StyledButton
            label={t("public.buttons.cancel")}
            id='cancel'
            // contained
            onClick={(v) => {
              navigate(`/embeddables/${projectId}`);
            }}
            sx={{marginLeft: 1}}
          />}
          {!loader && <StyledButton
            label={t("public.buttons.save")}
            id='save'
            contained
            onClick={(v) => {
              saveSetting();
            }}
            sx={{marginLeft: 1}}
          />}
        </Grid>
      </Grid>

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
      {!!errorSnackOpen && <AlertCheckInputs open={errorSnackOpen} setOpen={(b) => {setErrorSnackOpen(b);}} />}
      {!!exitSavedSnackOpen && <AlertSaved open={exitSavedSnackOpen} setOpen={(b) => {setDividendSavedSnackOpen(b);}} />}
    </Page>
  );
}
