import { FunctionComponent } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useAtomValue } from 'jotai';
// import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next'

// import { checkConditionsForAnswerOption } from '../../lib/poll';
import { currentProjectAtom } from '../../lib/project';

import {
  Box,
  Grid
} from '@mui/material';

// import MainLogo from '../images/MainLogo.png';

import {
  Typography,
  StyledButton,
} from '../../components/interface';

import { Answer } from '../../models/Answer';
import { AnswerOption } from '../../models/AnswerOption';

type SharedPollSlideYesNoProps = {
  currentQuestion: {
    body: {[x:string]: string};
    question_type: string;
    id: number;
    is_optional: boolean;
    is_extended_profile: boolean;
    show_outro_type: string;
    answer_options: AnswerOption[];
    extra_data: {
      alignment: string;
    };
  };
  pollAnswers: Answer[];
  setPollAnswers: (a:Answer[]) => void;
  step: number;
  onStep: (s:number) => void;
};

export const SharedPollSlideYesNo: FunctionComponent<SharedPollSlideYesNoProps> = ({currentQuestion, pollAnswers, setPollAnswers, step, onStep}) => {
  // const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const currentProject = useAtomValue(currentProjectAtom);


  const fb_lang:string = currentProject?.fallback_language|| 'nl';
  const lang:string = i18n.resolvedLanguage || fb_lang;

  const current_answer = (!!pollAnswers[step] ? (pollAnswers[step].answer_option_id || '') : '');
  let nay_id = currentQuestion.answer_options[0].id;
  let yay_id = currentQuestion.answer_options[1].id;
  const is_optional = !!currentQuestion?.is_optional;

  return (<Grid item xs={12}>
    <Typography html sx={{
      textAlign: (currentQuestion?.extra_data?.alignment || 'left')
    }}>{currentQuestion.body[lang] || currentQuestion.body[fb_lang]}</Typography>
    <Box sx={{textAlign: 'center'}}>
      <StyledButton
        label={t("buttons.yesno.no")}
        id='yesno_no'
        contained
        onClick={(v) => {
          let new_poll_answers = [...pollAnswers];

          new_poll_answers[step] = {
            ...pollAnswers[step],
            question_id: !!currentQuestion ? currentQuestion.id : undefined,
            iteration: currentProject?.iteration || 1,
            answer_option_id: nay_id
          };

          setPollAnswers(new_poll_answers);
          // save answer
          onStep(1);
        }}
        sx={{
          marginLeft: 1,
          marginRight: 1,
          opacity: (current_answer === "" ? 1 : (current_answer === nay_id ? 1 : 0.6))
        }}
      />

      <StyledButton
        label={t("buttons.yesno.yes")}
        id='yesno_yes'
        contained
        onClick={(v) => {
          let new_poll_answers = [...pollAnswers];

          new_poll_answers[step] = {
            ...pollAnswers[step],
            question_id: !!currentQuestion ? currentQuestion.id : undefined,
            iteration: currentProject?.iteration || 1,
            answer_option_id: yay_id
          };

          setPollAnswers(new_poll_answers);
          // save answer
          onStep(1);
        }}
        sx={{
          marginLeft: 1,
          marginRight: 1,
          opacity: (current_answer === "" ? 1 : (current_answer === yay_id ? 1 : 0.6))
        }}
      />
    </Box>
    {!!is_optional && <Box sx={{textAlign: 'center', marginTop: 1}}>
      <StyledButton
        label={t("buttons.yesno.skip")}
        id='skip'
        text
        onClick={(v) => {
          let new_poll_answers = [...pollAnswers];

          new_poll_answers[step] = {
            ...pollAnswers[step],
            question_id: !!currentQuestion ? currentQuestion.id : undefined,
            iteration: currentProject?.iteration || 1,
            answer_option_id: undefined,
            answer_content: ''
          };

          setPollAnswers(new_poll_answers);
          // save answer
          onStep(1);
        }}
        sx={{
          marginLeft: 1,
          marginRight: 1
        }}
      />
    </Box>}
    <Box sx={{textAlign: 'center', marginTop: 4}}>
      <StyledButton
        label={t("buttons.back")}
        id='back'
        text
        onClick={(v) => {
          onStep(-1);
        }}
        sx={{
          marginLeft: 1,
          marginRight: 1
        }}
      />
    </Box>
  </Grid>);
}
