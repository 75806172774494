import React, { FunctionComponent } from 'react';

import {
  Box,
  Grid
} from '@mui/material';

import { EmailBlock } from '../../../models/EmailBlock';

type BlockPublicSeparatorProps = {
  block: EmailBlock;
  marked: boolean;
  activeLocale: 'nl' | 'fr';
};

export const BlockPublicSeparator: FunctionComponent<BlockPublicSeparatorProps> = ({block, marked}) => {
  return ( <Grid xs={12} sx={{
    marginTop: block.settings?.mtop || 0,
    marginBottom: block.settings?.mbot || 0,
    // border: `1px solid ${block.settings?.border || 'transparent'}`,
    padding: '12px',
    ...(!!marked ? {backgroundColor: 'rgba(255, 255, 0, 0.3)'} : {})
  }}>
    <Box sx={{width: '100%', height: '1px', backgroundColor: block.sep_color || 'rgb(16, 81, 86)'}}></Box>
  </Grid>);
};
