import { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
// import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

import { fetch_all, fetch_one } from '../../lib/server_helper';

import {
  Avatar
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import VisibilityIcon from '@mui/icons-material/Visibility';

import {
  DataGrid,
  Page,
  SearchBox,
  StyledButton,
  StyledIconButton,
  Typography
} from '../../components/interface';

import { Static } from '../../models/Static';
import { loginAtom } from '../../lib/auth';

type ContentStaticsProps = {};

export const ContentStatics: FunctionComponent<ContentStaticsProps> = () => {
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  const [login, setLogin] = useAtom(loginAtom);
  const [statics, setStatics] = useState<Static[]>([]);
  const [staticsCount, setStaticsCount] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(true);
  const [removeLoader, setRemoveLoader] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("id DESC");

  useEffect(() => {
    onSearch('');
  }, [page, order]);

  const onRemove = (id: number) => {
    if (login && login.is_special_permissions) {
      setRemoveLoader(true);
      fetch_one<Static>('/nl/v3/objects/remove', {
        object: 'static',
        ob: {
          id: id
        }
      }, (obs, complete_data) => {
        onSearch('');
        setRemoveLoader(false);
      }, (z) => {

      }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
    }
  };

  const onSearch = (s: string) => {
    if (login && login.is_special_permissions) {
      setLoader(true);
      fetch_all<Static>('/nl/v3/objects/fetch_all', {
        object: 'static',
        fields: ['id', 'identifier', 'alternate_name', 'body', 'company|id/name', 'project|id/name'],
        per_page: 20,
        page: page,
        order: order,
        filter: {
          search: s
        }
      }, (obs, complete_data) => {
        setStatics(obs);

        let count:number = 0;
        if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
        setStaticsCount(count);

        setLoader(false);
      }, (z) => {

      }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
    } else {
      setLoader(false);
    }
  };

  const columns: GridColDef[] = [
    { field: 'identifier', headerName: 'Identifier', width: 300 },
    { field: 'alternate_name', headerName: 'Alternate names', width: 300 },
    { field: 'company_id', headerName: 'Override', width: 150, sortable: false, renderCell: (params) => <>
      <Typography>{!!params.row.company_id ? params.row.company_name : ''}{!!params.row.project_id && <br />}{!!params.row.project_id ? params.row.project_name : ''}</Typography>
    </> },
    { field: 'lang_nl', headerName: 'Languages', width: 200, sortable: false, renderCell: (params) => <>
      {Object.keys(params.row.body).map(kkk => <>
        <Avatar sx={{width: 24, height: 24, marginLeft: 1, backgroundColor: (!!params.row.body[kkk] ? theme.palette.tertiary?.main : 'red'), fontSize: '0.8rem'}}>{kkk}</Avatar>
      </>)}
    </> },
  ];

  return (
    <Page sx={{}} title={t("objects.statics.title")} actions={<>
      {login && login.is_special_permissions && <StyledButton
        label="Create new"
        id='create_new'
        contained
        path={'/static/new'}
        sx={{marginRight: 3}}
        startIcon={<AddCircleOutlineIcon />}
      />}
    </>}>
      <SearchBox
        simple={true}
        onSearch={(s) => {
          setPage(0);
          onSearch(s);
        }}
        memo="statics"
      />
      <DataGrid
        action_count={2}
        actions={(params) => {
          return (<>
            {login && login.is_special_permissions && <StyledIconButton color="primary" path={`/static/${params.row.id}`}>
              <EditIcon />
            </StyledIconButton>}
            {login && login.is_special_permissions && !removeLoader && <StyledIconButton destroyBtn color="primary" onClick={(e) => {
              onRemove(params.row.id);
            }}>
              <DeleteForeverIcon />
            </StyledIconButton>}
          </>);
        }}
        getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
        objectCount={staticsCount}
        objects={(statics || []).map(u => ({
          ...u
        }))}
        columns={columns}
        loader={loader}
        onSortModelChange={(a, b) => {
          let a_0_field:string = a[0].field;
          setOrder([a_0_field, a[0].sort].join(' '));
        }}
        page={page}
        setPage={(p) => {setPage(p);}}
      />
    </Page>
  );
}
