import { FunctionComponent, useState, useEffect } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useAtom, useAtomValue } from 'jotai';
// import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next'

import { checkConditionsForAnswerOption } from '../../../lib/poll';
import { currentProjectAtom } from '../../../lib/project';

import {
  Box,
  Grid,
} from '@mui/material';

import CircleIcon from '@mui/icons-material/Circle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

import {
  Typography,
} from '../../../components/interface';

import { Answer } from '../../../models/Answer';
import { AnswerOption } from '../../../models/AnswerOption';
import { User } from '../../../models/User';

type Props = {
  currentQuestion: {
    body: {[x:string]: string};
    question_type: string;
    id: number;
    is_optional: boolean;
    is_extended_profile: boolean;
    show_outro_type: string;
    answer_options: AnswerOption[];
    extra_data: {
      alignment: string;
      layout?: string;
    };
  };
  pollAnswers: Answer[];
  setPollAnswers: (a:Answer[]) => void;
  step: number;
  userProfile?: User;
  // setStep: (n:number) => void;
};

export const SlideLayoutMultiElectionForm: FunctionComponent<Props> = ({currentQuestion, pollAnswers, setPollAnswers, step, userProfile}) => {
  // const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const currentProject = useAtomValue(currentProjectAtom);

  const [answers, setAnswers] = useState<number[]>((!!pollAnswers[step] ? (pollAnswers[step].answer_content || '') : '').split("|").map(id => parseInt(id, 10)));

  const fb_lang:string = currentProject?.fallback_language|| 'nl';
  const lang:string = i18n.resolvedLanguage || fb_lang;

  useEffect(() => {
    let new_poll_answers = [...pollAnswers];

    new_poll_answers[step] = {
      ...pollAnswers[step],
      question_id: !!currentQuestion ? currentQuestion.id : undefined,
      iteration: currentProject?.iteration || 1,
      answer_content: answers.join("|")
    };

    setPollAnswers(new_poll_answers);
  }, [answers]);

  return (<>
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <Box sx={{
          backgroundColor: 'black',
          color: 'white',
          fontSize: '2rem',
          padding: 3,
          textAlign: 'center',
          borderRadius: 40,
          "& p": {
            color: 'white',
            margin: 0,
            fontSize: {
              xs: '1.2rem',
              md: '2rem'
            },
            textAlign: 'center',
          }
        }}>
          <Typography sx={{
            textAlign: (currentQuestion?.extra_data?.alignment || 'left')
          }} html>{currentQuestion.body[lang] || currentQuestion.body[fb_lang]}</Typography>
        </Box>
      </Grid>
      {currentQuestion.answer_options.map((ao, i) => (!!ao.id && !!currentProject && checkConditionsForAnswerOption(ao.id, step, currentProject, pollAnswers, userProfile) ? <Grid item xs={12} sm={6} md={4}>
        <Box sx={{
          padding: 2,
          paddingLeft: 4,
          border: '5px solid black',
          cursor: 'pointer',
          position: 'relative',
          "& p": {
            color: 'black',
            margin: 0,
            fontSize: '1.2rem',
            textAlign: 'center',
          }
        }} onClick={() => {
          if (!!ao.id && answers.indexOf(ao.id) > -1) {
            setAnswers(answers.filter(a => a !== ao.id));
          } else {
            setAnswers([...answers, ao.id || 0]);
          }
        }}>
          {answers.indexOf(ao.id) < 0 && <RadioButtonUncheckedIcon sx={{
            position: 'absolute',
            left: 8,
            top: '50%',
            marginTop: '-20px',
            width: '40px',
            height: '40px'
          }} />}
          {answers.indexOf(ao.id) > -1 && <CircleIcon sx={{
            position: 'absolute',
            left: 8,
            top: '50%',
            marginTop: '-20px',
            width: '40px',
            height: '40px',
            zIndex: 999,
            opacity: 0.5
          }} />}
          {answers.indexOf(ao.id) > -1 && <TaskAltIcon sx={{
            position: 'absolute',
            left: 8,
            top: '50%',
            marginTop: '-20px',
            width: '40px',
            height: '40px',
            zIndex: 1000
          }} />}
          <Typography sx={{
            textAlign: 'center'
          }}>{!!ao.body ? ao.body[lang] || ao.body[fb_lang] : ''}</Typography>
        </Box>
      </Grid> : <></>))}
    </Grid>

    {/* <FormControl>
      <RadioGroup
        aria-labelledby="answergroup"
        name="answergroup"
        value={!!pollAnswers[step] ? (pollAnswers[step].answer_option_id || '') : ''}
        onChange={(e) => {
          // setSelectedAnswers([parseInt(e.target.value, 10)]);
          let new_poll_answers = [...pollAnswers];

          new_poll_answers[step] = {
            ...pollAnswers[step],
            question_id: !!currentQuestion ? currentQuestion.id : undefined,
            iteration: currentProject?.iteration || 1,
            answer_option_id: parseInt(e.target.value, 10)
          };

          setPollAnswers(new_poll_answers);
        }}
      >
        {currentQuestion.answer_options.map((ao, i) => <>
        KAKAKAK
          {!!ao.id && !!currentProject && checkConditionsForAnswerOption(ao.id, step, currentProject, pollAnswers, userProfile) && <FormControlLabel value={ao.id} control={<Radio />} label={!!ao.body ? ao.body[lang] || ao.body[fb_lang] : ''} />}
        </>)}
      </RadioGroup>
    </FormControl> */}
    </>);
}
