import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

import { fetch_one, fetch_all } from '../../lib/server_helper';

import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  Grid
} from '@mui/material';

import { loginAtom } from '../../lib/auth';

import { AlertCheckInputs } from '../../components/alerts/CheckInputs';
import { AlertSaved } from '../../components/alerts/Saved';
import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import {
  Page,
  LanguageFab,
  StyledButton,
  StyledEditor,
  StyledIconButton,
  StyledSelect,
  StyledSwitch,
  StyledTextField,
  StyledTextFieldTransl,
  Typography
} from '../../components/interface';

import { Project } from '../../models/Project';
import { Question } from '../../models/Question';
import { Report } from '../../models/Report';

// const { DateTime } = require("luxon");

type AvailableLang = 'nl' | 'fr' | 'en';
type Props = {};

export const AdminReportDetail: FunctionComponent<Props> = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const {t, i18n} = useTranslation(['translations']);

  const params = useParams();

  const [login, setLogin] = useAtom(loginAtom);
  const [reportDetail, setReportDetail] = useState<Report|null>(null);
  const [loader, setLoader] = useState<boolean>(true);
  const [saveLoader, setSaveLoader] = useState<boolean>(false);
  const [projectId, setProjectId] = useState<string | undefined>(params.project_id);
  const [reportId, setReportId] = useState<string | undefined>(params.id);

  const [item, setItem] = useState<File>();
  const [itemUrl, setItemUrl] = useState<string>();

  const [activeLocale, setActiveLocale] = useState<AvailableLang>('en');

  const [projects, setProjects] = useState<Project[]>([]);
  const [internalQuestions, setInternalQuestions] = useState<Question[]>([]);
  const [externalQuestions, setExternalQuestions] = useState<Question[]>([]);

  const [errorSnackOpen, setErrorSnackOpen] = useState<boolean>(false);
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);
  const [exitSavedSnackOpen, setDividendSavedSnackOpen] = useState<boolean>(false);

  const fields = [
    'id', 'created_at', 'updated_at', 'name', 'extra_data'
  ];

  const saveSetting = () => {
    setSaveLoader(true);

    fetch_one<Report>('/nl/v3/objects/save', {
      object: 'report',
      fields: fields,
      ob: {
        ...reportDetail,
        project_id: projectId
      }
    }, (ob, complete_data) => {
      navigate(`/reports/${projectId}`);
      setSaveLoader(false);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
  };

  const fetchCalculation = () => {
    setLoader(true);
    
    fetch_one<Report>('/nl/v3/objects/fetch_all', {
      object: 'report',
      fields: fields,
      id: reportId
    }, (ob, complete_data) => {
      setReportDetail(ob);
      setLoader(false);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
  };

  const fetchProjects = () => {
    fetch_all<Project>('/nl/v3/objects/fetch_all', {
      object: 'project',
      fields: ['id', 'name'],
      per_page: 999,
      order: 'name ASC'
    }, (obs, complete_data) => {
      setProjects(obs);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
  };

  const fetchQuestions = (id: number|string, setQuestions: (q:Question[]) => void) => {
    fetch_all<Question>('/nl/v3/objects/fetch_all', {
      object: 'question',
      fields: ['id', 'body'],
      per_page: 999,
      filter: {
        advanced: {
          project_id: id,
        }
      }
    }, (obs, complete_data) => {
      setQuestions(obs);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
  };

  useEffect(() => {
    fetchCalculation();
    fetchProjects();
  }, []);

  useEffect(() => {
    if (!!reportDetail?.extra_data?.internal_project_id) {
      fetchQuestions(reportDetail?.extra_data?.internal_project_id, setInternalQuestions);
    } else {

    }
  }, [reportDetail?.extra_data?.internal_project_id]);

  useEffect(() => {
    if (!!reportDetail?.extra_data?.external_project_id) {
      fetchQuestions(reportDetail?.extra_data?.external_project_id, setExternalQuestions);
    } else {

    }
  }, [reportDetail?.extra_data?.external_project_id]);

  return (
    <Page sx={{}} title={`${reportDetail?.name || 'Report Detail'} (${activeLocale})`}>
      <LanguageFab
        activeLocale={activeLocale}
        setActiveLocale={(lc) => {setActiveLocale(lc);}}
      />
      <Grid container spacing="8">

        <Grid item container xs={12} spacing="4" sx={{alignContent: 'baseline'}}>
          <Grid item xs={12}>
            <StyledTextField
              label="Name"
              value={reportDetail?.name?.toString() || ''}
              id="name"
              onChange={(v) => {if (!!reportDetail) setReportDetail({
                ...reportDetail,
                name: v});
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <StyledSelect
              label="Report type"
              value={reportDetail?.extra_data?.type || ''}
              id='q_type'
              onChange={(v) => {
                if (!!reportDetail) setReportDetail({
                  ...reportDetail,
                  extra_data: {
                    ...(reportDetail.extra_data || {}),
                    type: v
                  }
                });
              }}
              list={[
                {id: '', name: t("report_types.none")},
                {id: 'custom_bc', name: t("report_types.custom.bavetcharet")}
              ]}
            />
          </Grid>

          {reportDetail?.extra_data?.type === 'custom_bc' && <>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{fontWeight: 'bold'}}>Select projects</Typography>
            </Grid>
            <Grid item xs={6}>
              <StyledSelect
                label="Internal project"
                value={reportDetail?.extra_data?.internal_project_id?.toString() || ''}
                id='internal_project_id'
                onChange={(v) => {
                  if (!!reportDetail) setReportDetail({
                    ...reportDetail,
                    extra_data: {
                      ...(reportDetail.extra_data || {}),
                      internal_project_id: !!v ? parseInt(v, 10) : undefined
                    }
                  });
                }}
                list={[
                  {id: '', name: t("projects.none")},
                  ...(projects.map(project => {return {id: project.id?.toString() || '', name: project.name || ''};}))
                ]}
              />
            </Grid>
            <Grid item xs={6}>
              <StyledSelect
                label="External project"
                value={reportDetail?.extra_data?.external_project_id?.toString() || ''}
                id='external_project_id'
                onChange={(v) => {
                  if (!!reportDetail) setReportDetail({
                    ...reportDetail,
                    extra_data: {
                      ...(reportDetail.extra_data || {}),
                      external_project_id: !!v ? parseInt(v, 10) : undefined
                    }
                  });
                }}
                list={[
                  {id: '', name: t("projects.none")},
                  ...(projects.map(project => {return {id: project.id?.toString() || '', name: project.name || ''};}))
                ]}
              />
            </Grid>

            {!!reportDetail?.extra_data?.internal_project_id && !!reportDetail?.extra_data?.external_project_id && <>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{fontWeight: 'bold'}}>Question mapping</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{fontWeight: 'bold'}}>Weight</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{fontWeight: 'bold'}}>Internal</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{fontWeight: 'bold'}}>External</Typography>
              </Grid>

              <Grid item xs={3}>
                <Typography><span style={{textDecoration: 'underline'}}>Location</span><br /><span style={{fontSize: '0.7rem', fontStyle: 'italic', textDecoration: 'none !important'}}>Make sure the positions of the answeroptions of<br/>both questions are the same!</span></Typography>
              </Grid>
              <Grid item xs={3}>
                
              </Grid>
              <Grid item xs={3}>
                <StyledSelect
                  label="Internal location question"
                  value={reportDetail?.extra_data?.internal_location_question_id?.toString() || ''}
                  id='internal_location_question_id'
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        internal_location_question_id: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  list={[
                    {id: '', name: t("questions.none")},
                    ...(internalQuestions.map(question => {return {id: question.id?.toString() || '', name: question.body?.en || ''};}))
                  ]}
                />
              </Grid>
              <Grid item xs={3}>
                <StyledSelect
                  label="External location question"
                  value={reportDetail?.extra_data?.external_location_question_id?.toString() || ''}
                  id='external_location_question_id'
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        external_location_question_id: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  list={[
                    {id: '', name: t("questions.none")},
                    ...(externalQuestions.map(question => {return {id: question.id?.toString() || '', name: question.body?.en || ''};}))
                  ]}
                />
              </Grid>




              <Grid item xs={3}>
                <Typography sx={{textDecoration: 'underline', fontWeight: 'bold'}}>Dimension 1 - Welfare</Typography>
              </Grid>
              <Grid item xs={9}>
                <StyledTextFieldTransl
                  label="Dimension 1 name (Welfare)"
                  value={reportDetail?.extra_data?.dimension1 || {nl: '', en: '', fr: ''}}
                  id="name"
                  activeLocale={activeLocale}
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        dimension1:v
                      }
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{textDecoration: 'underline'}}>Welfare Q1</Typography>
              </Grid>
              <Grid item xs={3}>
                <StyledTextField
                  label="Weight (0-100)"
                  value={reportDetail?.extra_data?.q_id_1_1_wt?.toString() || ''}
                  id="weight"
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_1_1_wt: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <StyledSelect
                  label="Internal welfare question 1"
                  value={reportDetail?.extra_data?.q_id_1_1_in?.toString() || ''}
                  id='q_id_1_1_in'
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_1_1_in: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  list={[
                    {id: '', name: t("questions.none")},
                    ...(internalQuestions.map(question => {return {id: question.id?.toString() || '', name: question.body?.en || ''};}))
                  ]}
                />
              </Grid>
              <Grid item xs={3}>
                <StyledSelect
                  label="External welfare question 1"
                  value={reportDetail?.extra_data?.q_id_1_1_ex?.toString() || ''}
                  id='q_id_1_1_ex'
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_1_1_ex: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  list={[
                    {id: '', name: t("questions.none")},
                    ...(externalQuestions.map(question => {return {id: question.id?.toString() || '', name: question.body?.en || ''};}))
                  ]}
                />
              </Grid>


              <Grid item xs={3}>
                <Typography sx={{textDecoration: 'underline'}}>Welfare Q2</Typography>
              </Grid>
              <Grid item xs={3}>
                <StyledTextField
                  label="Weight (0-100)"
                  value={reportDetail?.extra_data?.q_id_1_2_wt?.toString() || ''}
                  id="weight"
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_1_2_wt: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <StyledSelect
                  label="Internal welfare question 2"
                  value={reportDetail?.extra_data?.q_id_1_2_in?.toString() || ''}
                  id='q_id_1_2_in'
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_1_2_in: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  list={[
                    {id: '', name: t("questions.none")},
                    ...(internalQuestions.map(question => {return {id: question.id?.toString() || '', name: question.body?.en || ''};}))
                  ]}
                />
              </Grid>
              <Grid item xs={3}>
                <StyledSelect
                  label="External welfare question 2"
                  value={reportDetail?.extra_data?.q_id_1_2_ex?.toString() || ''}
                  id='q_id_1_2_ex'
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_1_2_ex: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  list={[
                    {id: '', name: t("questions.none")},
                    ...(externalQuestions.map(question => {return {id: question.id?.toString() || '', name: question.body?.en || ''};}))
                  ]}
                />
              </Grid>

              <Grid item xs={3}>
                <Typography sx={{textDecoration: 'underline'}}>Welfare Q3</Typography>
              </Grid>
              <Grid item xs={3}>
                <StyledTextField
                  label="Weight (0-100)"
                  value={reportDetail?.extra_data?.q_id_1_3_wt?.toString() || ''}
                  id="weight"
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_1_3_wt: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <StyledSelect
                  label="Internal welfare question 3"
                  value={reportDetail?.extra_data?.q_id_1_3_in?.toString() || ''}
                  id='q_id_1_3_in'
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_1_3_in: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  list={[
                    {id: '', name: t("questions.none")},
                    ...(internalQuestions.map(question => {return {id: question.id?.toString() || '', name: question.body?.en || ''};}))
                  ]}
                />
              </Grid>
              <Grid item xs={3}>
                <StyledSelect
                  label="External welfare question 3"
                  value={reportDetail?.extra_data?.q_id_1_3_ex?.toString() || ''}
                  id='q_id_1_3_ex'
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_1_3_ex: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  list={[
                    {id: '', name: t("questions.none")},
                    ...(externalQuestions.map(question => {return {id: question.id?.toString() || '', name: question.body?.en || ''};}))
                  ]}
                />
              </Grid>




              <Grid item xs={3}>
                <Typography sx={{textDecoration: 'underline', fontWeight: 'bold'}}>Dimension 2 - Participation</Typography>
              </Grid>
              <Grid item xs={9}>
                <StyledTextFieldTransl
                  label="Dimension 2 name (Participation)"
                  value={reportDetail?.extra_data?.dimension2 || {nl: '', en: '', fr: ''}}
                  id="name"
                  activeLocale={activeLocale}
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        dimension2: v
                      }
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{textDecoration: 'underline'}}>Participation Q1</Typography>
              </Grid>
              <Grid item xs={3}>
                <StyledTextField
                  label="Weight (0-100)"
                  value={reportDetail?.extra_data?.q_id_2_1_wt?.toString() || ''}
                  id="weight"
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_2_1_wt: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <StyledSelect
                  label="Internal participation question 1"
                  value={reportDetail?.extra_data?.q_id_2_1_in?.toString() || ''}
                  id='q_id_2_1_in'
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_2_1_in: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  list={[
                    {id: '', name: t("questions.none")},
                    ...(internalQuestions.map(question => {return {id: question.id?.toString() || '', name: question.body?.en || ''};}))
                  ]}
                />
              </Grid>
              <Grid item xs={3}>
                <StyledSelect
                  label="External participation question 1"
                  value={reportDetail?.extra_data?.q_id_2_1_ex?.toString() || ''}
                  id='q_id_2_1_ex'
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_2_1_ex: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  list={[
                    {id: '', name: t("questions.none")},
                    ...(externalQuestions.map(question => {return {id: question.id?.toString() || '', name: question.body?.en || ''};}))
                  ]}
                />
              </Grid>

              <Grid item xs={3}>
                <Typography sx={{textDecoration: 'underline'}}>Participation Q2</Typography>
              </Grid>
              <Grid item xs={3}>
                <StyledTextField
                  label="Weight (0-100)"
                  value={reportDetail?.extra_data?.q_id_2_2_wt?.toString() || ''}
                  id="weight"
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_2_2_wt: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <StyledSelect
                  label="Internal participation question 2"
                  value={reportDetail?.extra_data?.q_id_2_2_in?.toString() || ''}
                  id='q_id_2_2_in'
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_2_2_in: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  list={[
                    {id: '', name: t("questions.none")},
                    ...(internalQuestions.map(question => {return {id: question.id?.toString() || '', name: question.body?.en || ''};}))
                  ]}
                />
              </Grid>
              <Grid item xs={3}>
                <StyledSelect
                  label="External participation question 2"
                  value={reportDetail?.extra_data?.q_id_2_2_ex?.toString() || ''}
                  id='q_id_2_2_ex'
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_2_2_ex: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  list={[
                    {id: '', name: t("questions.none")},
                    ...(externalQuestions.map(question => {return {id: question.id?.toString() || '', name: question.body?.en || ''};}))
                  ]}
                />
              </Grid>

              <Grid item xs={3}>
                <Typography sx={{textDecoration: 'underline'}}>Participation Q3</Typography>
              </Grid>
              <Grid item xs={3}>
                <StyledTextField
                  label="Weight (0-100)"
                  value={reportDetail?.extra_data?.q_id_2_3_wt?.toString() || ''}
                  id="weight"
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_2_3_wt: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <StyledSelect
                  label="Internal participation question 3"
                  value={reportDetail?.extra_data?.q_id_2_3_in?.toString() || ''}
                  id='q_id_2_3_in'
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_2_3_in: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  list={[
                    {id: '', name: t("questions.none")},
                    ...(internalQuestions.map(question => {return {id: question.id?.toString() || '', name: question.body?.en || ''};}))
                  ]}
                />
              </Grid>
              <Grid item xs={3}>
                <StyledSelect
                  label="External participation question 3"
                  value={reportDetail?.extra_data?.q_id_2_3_ex?.toString() || ''}
                  id='q_id_2_3_ex'
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_2_3_ex: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  list={[
                    {id: '', name: t("questions.none")},
                    ...(externalQuestions.map(question => {return {id: question.id?.toString() || '', name: question.body?.en || ''};}))
                  ]}
                />
              </Grid>




              <Grid item xs={3}>
                <Typography sx={{textDecoration: 'underline', fontWeight: 'bold'}}>Dimension 3 - Emotional Support</Typography>
              </Grid>
              <Grid item xs={9}>
                <StyledTextFieldTransl
                  label="Dimension 3 name (Emotional Support)"
                  value={reportDetail?.extra_data?.dimension3 || {nl: '', en: '', fr: ''}}
                  id="name"
                  activeLocale={activeLocale}
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        dimension3: v
                      }
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{textDecoration: 'underline'}}>Emotional Support Q1</Typography>
              </Grid>
              <Grid item xs={3}>
                <StyledTextField
                  label="Weight (0-100)"
                  value={reportDetail?.extra_data?.q_id_3_1_wt?.toString() || ''}
                  id="weight"
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_3_1_wt: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <StyledSelect
                  label="Internal emotional support question 1"
                  value={reportDetail?.extra_data?.q_id_3_1_in?.toString() || ''}
                  id='q_id_3_1_in'
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_3_1_in: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  list={[
                    {id: '', name: t("questions.none")},
                    ...(internalQuestions.map(question => {return {id: question.id?.toString() || '', name: question.body?.en || ''};}))
                  ]}
                />
              </Grid>
              <Grid item xs={3}>
                <StyledSelect
                  label="External emotional support question 1"
                  value={reportDetail?.extra_data?.q_id_3_1_ex?.toString() || ''}
                  id='q_id_3_1_ex'
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_3_1_ex: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  list={[
                    {id: '', name: t("questions.none")},
                    ...(externalQuestions.map(question => {return {id: question.id?.toString() || '', name: question.body?.en || ''};}))
                  ]}
                />
              </Grid>

              <Grid item xs={3}>
                <Typography sx={{textDecoration: 'underline'}}>Emotional Support Q2</Typography>
              </Grid>
              <Grid item xs={3}>
                <StyledTextField
                  label="Weight (0-100)"
                  value={reportDetail?.extra_data?.q_id_3_2_wt?.toString() || ''}
                  id="weight"
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_3_2_wt: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <StyledSelect
                  label="Internal emotional support question 2"
                  value={reportDetail?.extra_data?.q_id_3_2_in?.toString() || ''}
                  id='q_id_3_2_in'
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_3_2_in: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  list={[
                    {id: '', name: t("questions.none")},
                    ...(internalQuestions.map(question => {return {id: question.id?.toString() || '', name: question.body?.en || ''};}))
                  ]}
                />
              </Grid>
              <Grid item xs={3}>
                <StyledSelect
                  label="External emotional support question 2"
                  value={reportDetail?.extra_data?.q_id_3_2_ex?.toString() || ''}
                  id='q_id_3_2_ex'
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_3_2_ex: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  list={[
                    {id: '', name: t("questions.none")},
                    ...(externalQuestions.map(question => {return {id: question.id?.toString() || '', name: question.body?.en || ''};}))
                  ]}
                />
              </Grid>

              <Grid item xs={3}>
                <Typography sx={{textDecoration: 'underline'}}>Emotional Support Q3</Typography>
              </Grid>
              <Grid item xs={3}>
                <StyledTextField
                  label="Weight (0-100)"
                  value={reportDetail?.extra_data?.q_id_3_3_wt?.toString() || ''}
                  id="weight"
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_3_3_wt: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <StyledSelect
                  label="Internal emotional support question 3"
                  value={reportDetail?.extra_data?.q_id_3_3_in?.toString() || ''}
                  id='q_id_3_3_in'
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_3_3_in: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  list={[
                    {id: '', name: t("questions.none")},
                    ...(internalQuestions.map(question => {return {id: question.id?.toString() || '', name: question.body?.en || ''};}))
                  ]}
                />
              </Grid>
              <Grid item xs={3}>
                <StyledSelect
                  label="External emotional support question 3"
                  value={reportDetail?.extra_data?.q_id_3_3_ex?.toString() || ''}
                  id='q_id_3_3_ex'
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_3_3_ex: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  list={[
                    {id: '', name: t("questions.none")},
                    ...(externalQuestions.map(question => {return {id: question.id?.toString() || '', name: question.body?.en || ''};}))
                  ]}
                />
              </Grid>

              <Grid item xs={3}>
                <Typography sx={{textDecoration: 'underline'}}>Emotional Support Q4</Typography>
              </Grid>
              <Grid item xs={3}>
                <StyledTextField
                  label="Weight (0-100)"
                  value={reportDetail?.extra_data?.q_id_3_4_wt?.toString() || ''}
                  id="weight"
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_3_4_wt: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <StyledSelect
                  label="Internal emotional support question 4"
                  value={reportDetail?.extra_data?.q_id_3_4_in?.toString() || ''}
                  id='q_id_3_4_in'
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_3_4_in: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  list={[
                    {id: '', name: t("questions.none")},
                    ...(internalQuestions.map(question => {return {id: question.id?.toString() || '', name: question.body?.en || ''};}))
                  ]}
                />
              </Grid>
              <Grid item xs={3}>
                <StyledSelect
                  label="External emotional support question 4"
                  value={reportDetail?.extra_data?.q_id_3_4_ex?.toString() || ''}
                  id='q_id_3_4_ex'
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_3_4_ex: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  list={[
                    {id: '', name: t("questions.none")},
                    ...(externalQuestions.map(question => {return {id: question.id?.toString() || '', name: question.body?.en || ''};}))
                  ]}
                />
              </Grid>




              <Grid item xs={3}>
                <Typography sx={{textDecoration: 'underline', fontWeight: 'bold'}}>Dimension 4 - Educational Support</Typography>
              </Grid>
              <Grid item xs={9}>
                <StyledTextFieldTransl
                  label="Dimension 4 name (Educational Support)"
                  value={reportDetail?.extra_data?.dimension4 || {nl: '', en: '', fr: ''}}
                  id="name"
                  activeLocale={activeLocale}
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        dimension4: v
                      }
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{textDecoration: 'underline'}}>Educational Support Q1</Typography>
              </Grid>
              <Grid item xs={3}>
                <StyledTextField
                  label="Weight (0-100)"
                  value={reportDetail?.extra_data?.q_id_4_1_wt?.toString() || ''}
                  id="weight"
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_4_1_wt: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <StyledSelect
                  label="Internal educational support question 1"
                  value={reportDetail?.extra_data?.q_id_4_1_in?.toString() || ''}
                  id='q_id_4_1_in'
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_4_1_in: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  list={[
                    {id: '', name: t("questions.none")},
                    ...(internalQuestions.map(question => {return {id: question.id?.toString() || '', name: question.body?.en || ''};}))
                  ]}
                />
              </Grid>
              <Grid item xs={3}>
                <StyledSelect
                  label="External educational support question 1"
                  value={reportDetail?.extra_data?.q_id_4_1_ex?.toString() || ''}
                  id='q_id_4_1_ex'
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_4_1_ex: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  list={[
                    {id: '', name: t("questions.none")},
                    ...(externalQuestions.map(question => {return {id: question.id?.toString() || '', name: question.body?.en || ''};}))
                  ]}
                />
              </Grid>

              <Grid item xs={3}>
                <Typography sx={{textDecoration: 'underline'}}>Educational Support Q2</Typography>
              </Grid>
              <Grid item xs={3}>
                <StyledTextField
                  label="Weight (0-100)"
                  value={reportDetail?.extra_data?.q_id_4_2_wt?.toString() || ''}
                  id="weight"
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_4_2_wt: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <StyledSelect
                  label="Internal educational support question 2"
                  value={reportDetail?.extra_data?.q_id_4_2_in?.toString() || ''}
                  id='q_id_4_2_in'
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_4_2_in: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  list={[
                    {id: '', name: t("questions.none")},
                    ...(internalQuestions.map(question => {return {id: question.id?.toString() || '', name: question.body?.en || ''};}))
                  ]}
                />
              </Grid>
              <Grid item xs={3}>
                <StyledSelect
                  label="External educational support question 2"
                  value={reportDetail?.extra_data?.q_id_4_2_ex?.toString() || ''}
                  id='q_id_4_2_ex'
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_4_2_ex: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  list={[
                    {id: '', name: t("questions.none")},
                    ...(externalQuestions.map(question => {return {id: question.id?.toString() || '', name: question.body?.en || ''};}))
                  ]}
                />
              </Grid>




              <Grid item xs={3}>
                <Typography sx={{textDecoration: 'underline', fontWeight: 'bold'}}>Dimension 5 - Environment</Typography>
              </Grid>
              <Grid item xs={9}>
                <StyledTextFieldTransl
                  label="Dimension 5 name (Environment)"
                  value={reportDetail?.extra_data?.dimension5 || {nl: '', en: '', fr: ''}}
                  id="name"
                  activeLocale={activeLocale}
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        dimension5: v
                      }
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{textDecoration: 'underline'}}>Environment Q1</Typography>
              </Grid>
              <Grid item xs={3}>
                <StyledTextField
                  label="Weight (0-100)"
                  value={reportDetail?.extra_data?.q_id_5_1_wt?.toString() || ''}
                  id="weight"
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_5_1_wt: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <StyledSelect
                  label="Internal environment question 1"
                  value={reportDetail?.extra_data?.q_id_5_1_in?.toString() || ''}
                  id='q_id_5_1_in'
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_5_1_in: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  list={[
                    {id: '', name: t("questions.none")},
                    ...(internalQuestions.map(question => {return {id: question.id?.toString() || '', name: question.body?.en || ''};}))
                  ]}
                />
              </Grid>
              <Grid item xs={3}>
                <StyledSelect
                  label="External environment question 1"
                  value={reportDetail?.extra_data?.q_id_5_1_ex?.toString() || ''}
                  id='q_id_5_1_ex'
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_5_1_ex: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  list={[
                    {id: '', name: t("questions.none")},
                    ...(externalQuestions.map(question => {return {id: question.id?.toString() || '', name: question.body?.en || ''};}))
                  ]}
                />
              </Grid>

              <Grid item xs={3}>
                <Typography sx={{textDecoration: 'underline'}}>Environment Q2</Typography>
              </Grid>
              <Grid item xs={3}>
                <StyledTextField
                  label="Weight (0-100)"
                  value={reportDetail?.extra_data?.q_id_5_2_wt?.toString() || ''}
                  id="weight"
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_5_2_wt: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <StyledSelect
                  label="Internal environment question 2"
                  value={reportDetail?.extra_data?.q_id_5_2_in?.toString() || ''}
                  id='q_id_5_2_in'
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_5_2_in: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  list={[
                    {id: '', name: t("questions.none")},
                    ...(internalQuestions.map(question => {return {id: question.id?.toString() || '', name: question.body?.en || ''};}))
                  ]}
                />
              </Grid>
              <Grid item xs={3}>
                <StyledSelect
                  label="External environment question 2"
                  value={reportDetail?.extra_data?.q_id_5_2_ex?.toString() || ''}
                  id='q_id_5_2_ex'
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_5_2_ex: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  list={[
                    {id: '', name: t("questions.none")},
                    ...(externalQuestions.map(question => {return {id: question.id?.toString() || '', name: question.body?.en || ''};}))
                  ]}
                />
              </Grid>

              <Grid item xs={3}>
                <Typography sx={{textDecoration: 'underline'}}>Environment Q3</Typography>
              </Grid>
              <Grid item xs={3}>
                <StyledTextField
                  label="Weight (0-100)"
                  value={reportDetail?.extra_data?.q_id_5_3_wt?.toString() || ''}
                  id="weight"
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_5_3_wt: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <StyledSelect
                  label="Internal environment question 3"
                  value={reportDetail?.extra_data?.q_id_5_3_in?.toString() || ''}
                  id='q_id_5_3_in'
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_5_3_in: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  list={[
                    {id: '', name: t("questions.none")},
                    ...(internalQuestions.map(question => {return {id: question.id?.toString() || '', name: question.body?.en || ''};}))
                  ]}
                />
              </Grid>
              <Grid item xs={3}>
                <StyledSelect
                  label="External environment question 3"
                  value={reportDetail?.extra_data?.q_id_5_3_ex?.toString() || ''}
                  id='q_id_5_3_ex'
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_5_3_ex: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  list={[
                    {id: '', name: t("questions.none")},
                    ...(externalQuestions.map(question => {return {id: question.id?.toString() || '', name: question.body?.en || ''};}))
                  ]}
                />
              </Grid>




              <Grid item xs={3}>
                <Typography sx={{textDecoration: 'underline', fontWeight: 'bold'}}>Dimension 6 - Family & Diversity</Typography>
              </Grid>
              <Grid item xs={9}>
                <StyledTextFieldTransl
                  label="Dimension 6 name (Family & Diversity)"
                  value={reportDetail?.extra_data?.dimension6 || {nl: '', en: '', fr: ''}}
                  id="name"
                  activeLocale={activeLocale}
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        dimension6: v
                      }
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{textDecoration: 'underline'}}>Family & Diversity Q1</Typography>
              </Grid>
              <Grid item xs={3}>
                <StyledTextField
                  label="Weight (0-100)"
                  value={reportDetail?.extra_data?.q_id_6_1_wt?.toString() || ''}
                  id="weight"
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_6_1_wt: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <StyledSelect
                  label="Internal family/diversity question 1"
                  value={reportDetail?.extra_data?.q_id_6_1_in?.toString() || ''}
                  id='q_id_6_1_in'
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_6_1_in: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  list={[
                    {id: '', name: t("questions.none")},
                    ...(internalQuestions.map(question => {return {id: question.id?.toString() || '', name: question.body?.en || ''};}))
                  ]}
                />
              </Grid>
              <Grid item xs={3}>
                <StyledSelect
                  label="External family/diversity question 1"
                  value={reportDetail?.extra_data?.q_id_6_1_ex?.toString() || ''}
                  id='q_id_6_1_ex'
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_6_1_ex: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  list={[
                    {id: '', name: t("questions.none")},
                    ...(externalQuestions.map(question => {return {id: question.id?.toString() || '', name: question.body?.en || ''};}))
                  ]}
                />
              </Grid>

              <Grid item xs={3}>
                <Typography sx={{textDecoration: 'underline'}}>Family & Diversity Q2</Typography>
              </Grid>
              <Grid item xs={3}>
                <StyledTextField
                  label="Weight (0-100)"
                  value={reportDetail?.extra_data?.q_id_6_2_wt?.toString() || ''}
                  id="weight"
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_6_2_wt: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <StyledSelect
                  label="Internal family/diversity question 2"
                  value={reportDetail?.extra_data?.q_id_6_2_in?.toString() || ''}
                  id='q_id_6_2_in'
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_6_2_in: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  list={[
                    {id: '', name: t("questions.none")},
                    ...(internalQuestions.map(question => {return {id: question.id?.toString() || '', name: question.body?.en || ''};}))
                  ]}
                />
              </Grid>
              <Grid item xs={3}>
                <StyledSelect
                  label="External family/diversity question 2"
                  value={reportDetail?.extra_data?.q_id_6_2_ex?.toString() || ''}
                  id='q_id_6_2_ex'
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_6_2_ex: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  list={[
                    {id: '', name: t("questions.none")},
                    ...(externalQuestions.map(question => {return {id: question.id?.toString() || '', name: question.body?.en || ''};}))
                  ]}
                />
              </Grid>

              <Grid item xs={3}>
                <Typography sx={{textDecoration: 'underline'}}>Family & Diversity Q3</Typography>
              </Grid>
              <Grid item xs={3}>
                <StyledTextField
                  label="Weight (0-100)"
                  value={reportDetail?.extra_data?.q_id_6_3_wt?.toString() || ''}
                  id="weight"
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_6_3_wt: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <StyledSelect
                  label="Internal family/diversity question 3"
                  value={reportDetail?.extra_data?.q_id_6_3_in?.toString() || ''}
                  id='q_id_6_3_in'
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_6_3_in: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  list={[
                    {id: '', name: t("questions.none")},
                    ...(internalQuestions.map(question => {return {id: question.id?.toString() || '', name: question.body?.en || ''};}))
                  ]}
                />
              </Grid>
              <Grid item xs={3}>
                <StyledSelect
                  label="External family/diversity question 3"
                  value={reportDetail?.extra_data?.q_id_6_3_ex?.toString() || ''}
                  id='q_id_6_3_ex'
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        q_id_6_3_ex: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  list={[
                    {id: '', name: t("questions.none")},
                    ...(externalQuestions.map(question => {return {id: question.id?.toString() || '', name: question.body?.en || ''};}))
                  ]}
                />
              </Grid>



              <Grid item xs={3}>
                <Typography sx={{textDecoration: 'underline'}}>NPS question</Typography>
              </Grid>
              <Grid item xs={3}></Grid>
              <Grid item xs={3}>
                <StyledSelect
                  label="Internal NPS question"
                  value={reportDetail?.extra_data?.internal_open_question_id?.toString() || ''}
                  id='internal_open_question_id'
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        internal_open_question_id: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  list={[
                    {id: '', name: t("questions.none")},
                    ...(internalQuestions.map(question => {return {id: question.id?.toString() || '', name: question.body?.en || ''};}))
                  ]}
                />
              </Grid>
              <Grid item xs={3}>
                <StyledSelect
                  label="External NPS question"
                  value={reportDetail?.extra_data?.external_open_question_id?.toString() || ''}
                  id='external_open_question_id'
                  onChange={(v) => {
                    if (!!reportDetail) setReportDetail({
                      ...reportDetail,
                      extra_data: {
                        ...(reportDetail.extra_data || {}),
                        external_open_question_id: !!v ? parseInt(v, 10) : undefined
                      }
                    });
                  }}
                  list={[
                    {id: '', name: t("questions.none")},
                    ...(externalQuestions.map(question => {return {id: question.id?.toString() || '', name: question.body?.en || ''};}))
                  ]}
                />
              </Grid>
            </>}
          </>}
        </Grid>

        <Grid item xs={12} sx={{alignContent: 'baseline', textAlign: 'center', position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 1201}}>
          <Divider sx={{marginTop: 1, marginBottom: 2}} />

          {!!loader && <CircularProgress />}
          {/* {!loader && <StyledButton
            label="Start manage mode"
            id='start_mm'
            // contained
            onClick={(v) => {
              setMovingMode(true);
            }}
            sx={{marginLeft: 1}}
          />} */}
          {!loader && <StyledButton
            label={t("public.buttons.cancel")}
            id='cancel'
            // contained
            onClick={(v) => {
              navigate(`/reports/${projectId}`);
            }}
            sx={{marginLeft: 1}}
          />}
          {!loader && <StyledButton
            label={t("public.buttons.save")}
            id='save'
            contained
            onClick={(v) => {
              saveSetting();
            }}
            sx={{marginLeft: 1}}
          />}
        </Grid>
      </Grid>

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
      {!!errorSnackOpen && <AlertCheckInputs open={errorSnackOpen} setOpen={(b) => {setErrorSnackOpen(b);}} />}
      {!!exitSavedSnackOpen && <AlertSaved open={exitSavedSnackOpen} setOpen={(b) => {setDividendSavedSnackOpen(b);}} />}
    </Page>
  );
}
