import React, { FunctionComponent, useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

import { fetch_one, fetch_all } from '../../lib/server_helper';

import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  CircularProgress,
  Divider,
  Grid
} from '@mui/material';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { loginAtom } from '../../lib/auth';

import { AlertCheckInputs } from '../../components/alerts/CheckInputs';
import { AlertSaved } from '../../components/alerts/Saved';
import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import {
  Page,
  StyledButton,
  StyledSelect,
  Typography
} from '../../components/interface';

import { Company } from '../../models/Company';
import { Permission } from '../../models/Permission';
import { Project } from '../../models/Project';
import { User } from '../../models/User';

// const { DateTime } = require("luxon");

// type AvailableLang = 'nl' | 'fr' | 'en';
type AdminPermissionDetailProps = {};

export const AdminPermissionDetail: FunctionComponent<AdminPermissionDetailProps> = () => {
  const navigate = useNavigate();
  // const theme = useTheme();
  const {t, i18n} = useTranslation(['translations']);

  const params = useParams();

  const [login, setLogin] = useAtom(loginAtom);
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [loader, setLoader] = useState<boolean>(true);
  const [saveLoader, setSaveLoader] = useState<boolean>(false);
  const [userId, setUserId] = useState<string | undefined>(params.id);
  const [user, setUser] = useState<User | undefined>();

  const [myCompanies, setMyCompanies] = useState<Company[]>([]);
  const [myProjects, setMyProjects] = useState<Project[]>([]);

  const [editPermission, setEditPermission] = useState<boolean>(false);
  const [activePermission, setActivePermission] = useState<Permission>();

  const [errorSnackOpen, setErrorSnackOpen] = useState<boolean>(false);
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);
  const [exitSavedSnackOpen, setDividendSavedSnackOpen] = useState<boolean>(false);

  const fields = [
    'id', 'user_id', 'company_id', 'project_id', 'permission_level', 'extra_data', 'company|name', 'project|name', 'project|company|name'
  ];

  const saveSetting = () => {
    setSaveLoader(true);

    fetch_one<Permission>('/nl/v3/objects/save', {
      object: 'permission',
      fields: fields,
      ob: activePermission
    }, (ob, complete_data) => {
      fetchCalculation();
      setEditPermission(false);
      setActivePermission(undefined);
      setSaveLoader(false);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
  };

  const deleteUser = () => {
    fetch_one<User>('/nl/v3/objects/custom_action', {
      object: 'user',
      id: user?.id,
      class_action: 'custom_api_remove_permissions'
    }, (ob, complete_data) => {
      navigate('/users');
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
  };

  const switchUser = () => {
    fetch_one<User>('/nl/v3/objects/save', {
      object: 'user',
      fields: [],
      ob: {
        id: user?.id,
        is_special_permissions: !user?.is_special_permissions
      }
    }, (ob, complete_data) => {
      fetchUser();
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
  };

  const fetchCalculation = () => {
    setLoader(true);
    
    fetch_all<Permission>('/nl/v3/objects/fetch_all', {
      object: 'permission',
      fields: fields,
      filter: {
        search: '',
        advanced: {
          user_id: userId
        }
      }
    }, (obs, complete_data) => {
      openObject(obs);
      setLoader(false);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
  };

  const fetchMyCompanies = () => {
    fetch_all<Company>('/nl/v3/objects/fetch_all', {
      object: 'company',
      fields: ['id', 'name'],
      order: 'name ASC',
      handler_id: login?.id
    }, (obs, complete_data) => {
      setMyCompanies(obs);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
  };

  const fetchMyProjects = () => {
    fetch_all<Project>('/nl/v3/objects/fetch_all', {
      object: 'project',
      fields: ['id', 'name', 'company|name'],
      handler_id: login?.id
    }, (obs, complete_data) => {
      setMyProjects(obs);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
  };

  const fetchUser = () => {
    setLoader(true);
    
    fetch_one<User>('/nl/v3/objects/fetch_all', {
      object: 'user',
      fields: ['id', 'first_name', 'last_name', 'permission_map', 'is_special_permissions'],
      id: userId
    }, (ob, complete_data) => {
      setUser(ob);
      setLoader(false);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
  };

  useEffect(() => {
    fetchMyCompanies();
    fetchMyProjects();
  }, []);

  useEffect(() => {
    fetchUser();
    fetchCalculation();
  }, [userId]);

  // useEffect(() => {
  //   console.log(activePermission);
  // }, [activePermission]);

  const openObject = (obs: Permission[]) => {
    setPermissions(obs);
  }

  return (
    <Page sx={{}} title={[t("objects.permissions.title"), '-', user?.first_name, user?.last_name].join(" ")}>
      <Grid container spacing="8">

        {!!user && user?.id === login?.id && <Grid item container xs={12} spacing="4" sx={{alignContent: 'baseline'}}>
          <Grid item xs={12}>
            Nice try...
          </Grid>
        </Grid>}

        {!!user && !!editPermission && <Grid item container xs={12} spacing="4" sx={{alignContent: 'baseline'}}>
          <Grid item xs={6}>
            <StyledSelect
              label="Applies to"
              value={activePermission?.permission_apply || 'all'}
              id='q_type'
              onChange={(v) => {
                setActivePermission({...activePermission, project_id: undefined, company_id: undefined, permission_apply: v});
              }}
              list={[
                {id: 'all', name: t("permission_applies_to.all")},
                {id: 'company', name: t("permission_applies_to.company")},
                {id: 'project', name: t("permission_applies_to.project")}
              ]}
            />
          </Grid>
          {activePermission?.permission_apply === 'company' && <Grid item xs={6}>
            <StyledSelect
              label="Select company"
              value={!!activePermission?.company_id ? activePermission?.company_id.toString() : ''}
              id='c_id'
              onChange={(v) => {
                setActivePermission({...activePermission, company_id: parseInt(v, 10)});
              }}
              list={myCompanies.map(c => {return {id: c.id.toString(), name: c.name};})}
            />
          </Grid>}
          {activePermission?.permission_apply === 'project' && <Grid item xs={6}>
            <StyledSelect
              label="Select project"
              value={!!activePermission?.project_id ? activePermission?.project_id.toString() : ''}
              id='p_id'
              onChange={(v) => {
                setActivePermission({...activePermission, project_id: parseInt(v, 10)});
              }}
              list={myProjects.sort((a, b) => (a.company_name || '').localeCompare(b.company_name || '') || (a.name || '').localeCompare(b.name || '')).map(c => {return {id: (c.id || '').toString(), name: [c.company_name, c.name || ''].join(" - ")};})}
            />
          </Grid>}

          <Grid container item xs={12} sx={{marginTop: 2}}>
            <Grid item xs={6}>Module</Grid>
            <Grid item xs={1}>Read</Grid>
            <Grid item xs={1}>Create</Grid>
            <Grid item xs={1}>Update</Grid>
            <Grid item xs={1}>Delete</Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={12}><Divider/></Grid>

            {user?.permission_map?.default.map((line, i) => line[6] ? <></> : <Grid container item xs={12} sx={{"&:hover": {backgroundColor: 'rgba(0, 0, 0, 0.03)'}}}>
              {!line[5] && <Grid item xs={6}>
                <StyledButton id="switch_btn" text disabled={(!!line[7] && (activePermission?.permission_level?.split('')[line[7]*4 + 1] || '') !== "T")} onClick={() => {
                  let new_perm_level = (activePermission?.permission_level?.split('') || []);
                  new_perm_level.splice(i*4 + 0, 1, "T");
                  new_perm_level.splice(i*4 + 1, 1, "T");
                  new_perm_level.splice(i*4 + 2, 1, "T");
                  new_perm_level.splice(i*4 + 3, 1, "T");
                  setActivePermission({...activePermission, permission_level: new_perm_level.join("")});
                }} label={line[0]}
                />
              </Grid>}
              {!!line[5] && <Grid item xs={6}>
                <StyledButton id="switch_btn" text onClick={() => {}} label={line[0]}
                />
              </Grid>}
              <Grid item xs={1}>
                {line[2] && <Checkbox defaultChecked={!!line[5]} disabled={!!line[5] || (!!line[7] && (activePermission?.permission_level?.split('')[line[7]*4 + 1] || '') !== "T")} size="small" sx={{padding: '4px'}} checked={!!line[5] || (activePermission?.permission_level?.split('')[i*4 + 1] || '') === "T"} onChange={(v) => {
                  let new_perm_level = (activePermission?.permission_level?.split('') || []);
                  new_perm_level.splice(i*4 + 1, 1, v.target.checked ? "T" : "F");
                  if (!v.target.checked) {
                    new_perm_level.splice(i*4 + 0, 1, "F");
                    new_perm_level.splice(i*4 + 2, 1, "F");
                    new_perm_level.splice(i*4 + 3, 1, "F");

                    let _x = user?.permission_map?.default.map((l, ii) => {
                      if (l[7] === i) {
                        new_perm_level.splice(ii*4 + 0, 1, "F");
                        new_perm_level.splice(ii*4 + 1, 1, "F");
                        new_perm_level.splice(ii*4 + 2, 1, "F");
                        new_perm_level.splice(ii*4 + 3, 1, "F");
                      }

                      return undefined;
                    });
                  }
                  setActivePermission({...activePermission, permission_level: new_perm_level.join("")});
                }} name={`option${i}_0`} />}
              </Grid>
              <Grid item xs={1}>
                {line[1] && <Checkbox defaultChecked={!!line[5]} disabled={!!line[5] || (!!line[7] && (activePermission?.permission_level?.split('')[line[7]*4 + 1] || '') !== "T") || (activePermission?.permission_level?.split('')[i*4 + 1] || '') !== "T"} size="small" sx={{padding: '4px'}} checked={!!line[5] || (activePermission?.permission_level?.split('')[i*4 + 0] || '') === "T"} onChange={(v) => {
                  let new_perm_level = (activePermission?.permission_level?.split('') || []);
                  new_perm_level.splice(i*4 + 0, 1, v.target.checked ? "T" : "F");
                  setActivePermission({...activePermission, permission_level: new_perm_level.join("")});
                }} name={`option${i}_0`} />}
              </Grid>
              <Grid item xs={1}>
                {line[3] && <Checkbox defaultChecked={!!line[5]} disabled={!!line[5] || (!!line[7] && (activePermission?.permission_level?.split('')[line[7]*4 + 1] || '') !== "T") || (activePermission?.permission_level?.split('')[i*4 + 1] || '') !== "T"} size="small" sx={{padding: '4px'}} checked={!!line[5] || (activePermission?.permission_level?.split('')[i*4 + 2] || '') === "T"} onChange={(v) => {
                  let new_perm_level = (activePermission?.permission_level?.split('') || []);
                  new_perm_level.splice(i*4 + 2, 1, v.target.checked ? "T" : "F");
                  setActivePermission({...activePermission, permission_level: new_perm_level.join("")});
                }} name={`option${i}_0`} />}
              </Grid>
              <Grid item xs={1}>
                {line[4] && <Checkbox defaultChecked={!!line[5]} disabled={!!line[5] || (!!line[7] && (activePermission?.permission_level?.split('')[line[7]*4 + 1] || '') !== "T") || (activePermission?.permission_level?.split('')[i*4 + 1] || '') !== "T"} size="small" sx={{padding: '4px'}} checked={!!line[5] || (activePermission?.permission_level?.split('')[i*4 + 3] || '') === "T"} onChange={(v) => {
                  let new_perm_level = (activePermission?.permission_level?.split('') || []);
                  new_perm_level.splice(i*4 + 3, 1, v.target.checked ? "T" : "F");
                  setActivePermission({...activePermission, permission_level: new_perm_level.join("")});
                }} name={`option${i}_0`} />}
              </Grid>
              <Grid item xs={2}>
                
              </Grid>
            </Grid>)}
          </Grid>
        </Grid>}

        {!!user && !editPermission && user?.id !== login?.id && <Grid item container xs={12} spacing="4" sx={{alignContent: 'baseline'}}>
          <Grid item xs={12}>
            <Typography>Admin level: <b>{!!user?.is_special_permissions ? 'PanelMetrics Admin' : 'Company/Project Admin'}</b> (<StyledButton id="switch_btn" text onClick={() => {
              switchUser();
            }} label="switch"/>)</Typography>
          </Grid>

          {!!user?.is_special_permissions && <Grid item xs={12}>
          <Typography>PanelMetrics admins have access to practically everything</Typography>
          </Grid>}

          {!user?.is_special_permissions && <>
            <Grid item xs={12}>
              <Divider sx={{marginTop: 1, marginBottom: 2}} />
            </Grid>

            <Grid item xs={12} md={2}>
              <Card>
                <CardContent>
                  <Typography sx={{textAlign: 'center'}}>
                    <AddCircleOutlineIcon />
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" onClick={() => {
                    setActivePermission({user_id: user.id, permission_level: '-'.repeat((user.permission_map?.default.length || 0) * 4)});
                    setEditPermission(true);
                  }}>Create new permission set</Button>
                </CardActions>
              </Card>
            </Grid>
            {permissions.map(permission => <Grid item xs={12} md={2}>
              <Card>
                <CardContent>
                  {!!permission.company_name && <Typography sx={{textAlign: 'center'}}>{permission.company_name}</Typography>}
                  {!!permission.project_name && <Typography sx={{textAlign: 'center'}}>{permission.project_company_name} - {permission.project_name}</Typography>}
                  {!permission.company_name && !permission.project_name && <Typography sx={{textAlign: 'center'}}>All projects</Typography>}

                  <Typography sx={{textAlign: 'center', fontStyle: 'italic', fontSize: '0.7rem', marginTop: 1}}>
                    {permission.permission_level?.split("").filter(s => s === "T").length} permissions granted
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" onClick={() => {
                    setActivePermission({...permission, permission_apply: (!!permission.project_id ? 'project' : (!!permission.company_id ? 'company' : 'all'))});
                    setEditPermission(true);
                  }}>Edit</Button>
                </CardActions>
              </Card>
            </Grid>)}

          </>}
        </Grid>}

        {!editPermission && <Grid item xs={12} sx={{alignContent: 'baseline', textAlign: 'center', position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 1201}}>
          <Divider sx={{marginTop: 1, marginBottom: 2}} />

          {!!loader && <CircularProgress />}
          {!loader && <StyledButton
            label="Back"
            id='cancel'
            // contained
            onClick={(v) => {
              navigate(`/users`);
            }}
            sx={{marginLeft: 1}}
          />}
          {!loader && <StyledButton
            id="destroy"
            label="Destroy"
            destroyBtn
            secondary
            onClick={(e) => {
              deleteUser();
            }}
            sx={{marginLeft: 1}}
          />}
        </Grid>}
        {!!editPermission && <Grid item xs={12} sx={{alignContent: 'baseline', textAlign: 'center', position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 1201}}>
          <Divider sx={{marginTop: 1, marginBottom: 2}} />

          {!!loader && <CircularProgress />}
          {!loader && <StyledButton
            label={t("public.buttons.cancel")}
            id='cancel'
            // contained
            onClick={(v) => {
              setEditPermission(false);
            }}
            sx={{marginLeft: 1}}
          />}
          {!loader && <StyledButton
            label={t("public.buttons.save")}
            id='save'
            contained
            onClick={(v) => {
              saveSetting();
            }}
            sx={{marginLeft: 1}}
          />}
        </Grid>}
      </Grid>

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
      {!!errorSnackOpen && <AlertCheckInputs open={errorSnackOpen} setOpen={(b) => {setErrorSnackOpen(b);}} />}
      {!!exitSavedSnackOpen && <AlertSaved open={exitSavedSnackOpen} setOpen={(b) => {setDividendSavedSnackOpen(b);}} />}
    </Page>
  );
}
