import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

import { fetch_one, fetch_all } from '../../lib/server_helper';
import { checkAllowed } from '../../lib/permission';

import {
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';

import { loginAtom } from '../../lib/auth';

import { AlertCheckInputs } from '../../components/alerts/CheckInputs';
import { AlertSaved } from '../../components/alerts/Saved';
import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import {
  Page,
  StyledButton,
  StyledSelect,
  StyledTextField,
  Typography
} from '../../components/interface';

import { AnswerOption } from '../../models/AnswerOption';
import { Question } from '../../models/Question';

var he = require('he');

// const { DateTime } = require("luxon");

// type AvailableLang = 'nl' | 'fr' | 'en';
type AdminQuestionRulesDetailProps = {};

export const AdminQuestionRulesDetail: FunctionComponent<AdminQuestionRulesDetailProps> = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const {t, i18n} = useTranslation(['translations']);

  const params = useParams();

  const [login, setLogin] = useAtom(loginAtom);
  const [questionDetail, setQuestionDetail] = useState<Question|null>(null);
  const [answerOptions, setAnswerOptions] = useState<AnswerOption[]>([]);
  const [previousQuestions, setPreviousQuestions] = useState<Question[]>([]);
  const [loader, setLoader] = useState<boolean>(true);
  const [saveLoader, setSaveLoader] = useState<boolean>(false);
  const [projectId, setProjectId] = useState<string | undefined>(params.project_id);
  const [questionId, setQuestionId] = useState<string | undefined>(params.id);

  // const [activeLocale, setActiveLocale] = useState<AvailableLang>('en');

  const [errorSnackOpen, setErrorSnackOpen] = useState<boolean>(false);
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);
  const [exitSavedSnackOpen, setDividendSavedSnackOpen] = useState<boolean>(false);

  const fields = [
    'id', 'extra_data', 'question_type', 'pos', 'body', 'project|id', 'simple_answer_options'
  ];

  const saveSetting = () => {
    if (checkAllowed(login, null, projectId?.toString() || '0', "ProjectQuestionRules", 'u', true)) {
      setSaveLoader(true);

      fetch_one<Question>('/nl/v3/objects/save', {
        object: 'question',
        id: questionId || '',
        handler_id: login?.id,
        fields: fields,
        ob: {
          id: questionId || '',
          project_id: projectId,
          extra_data: questionDetail?.extra_data
        }
      }, (ob, complete_data) => {
        setQuestionId(ob.id?.toString());
        setProjectId(ob.project_id?.toString());
        openObject(ob);
        setSaveLoader(false);
        navigate(`/questions/${projectId}`);
      }, (z) => {
        setServerErrorSnackOpen(true);
      }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
    }
  };

  const fetchAnswerOptions = () => {
    if (checkAllowed(login, null, projectId?.toString() || '0', "ProjectQuestionRules", 'r', true)) {
      if (!!questionId) {
        fetch_all<AnswerOption>('/nl/v3/objects/fetch_all', {
          object: 'answer_option',
          fields: ['id', 'created_at', 'updated_at', 'body', 'extra_data', 'helper', 'pos', 'question|id'],
          per_page: 1000,
          page: 0,
          order: 'pos ASC',
          filter: {
            advanced: {
              question_id: questionId
            }
          }
        }, (obs, complete_data) => {
          setAnswerOptions(obs);
        }, (z) => {
          setServerErrorSnackOpen(true);
        }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
      }
    }
  };

  const fetchPreviousQuestions = () => {
    if (!!questionDetail?.project_id) {
      fetch_all<Question>('/nl/v3/objects/fetch_all', {
        object: 'question',
        fields: fields,
        filter: {
          advanced: {
            project_id: questionDetail?.project_id
          }
        }
      }, (ob, complete_data) => {
        // console.log(ob, questionDetail?.pos);
        setPreviousQuestions(ob.filter(qqq => (qqq.pos || 1) < (questionDetail?.pos || 1)));
        // console.log(ob.filter(qqq => (qqq.pos || 1) < (questionDetail?.pos || 1)));
      }, (z) => {
        setServerErrorSnackOpen(true);
      }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
    }
  };

  const fetchCalculation = () => {
    if (checkAllowed(login, null, projectId?.toString() || '0', "ProjectQuestionRules", 'r', true)) {
      setLoader(true);
      
      fetch_one<Question>('/nl/v3/objects/fetch_all', {
        object: 'question',
        fields: fields,
        id: questionId
      }, (ob, complete_data) => {
        openObject(ob);
        setLoader(false);
      }, (z) => {
        setServerErrorSnackOpen(true);
      }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchCalculation();
    if (!!questionId) fetchAnswerOptions();
  }, []);

  useEffect(() => {
    fetchPreviousQuestions();
  }, [questionDetail]);

  const openObject = (ob: Question) => {
    setQuestionDetail(ob);
  }

  return (
    <Page sx={{}} title={`Question Rules`}>
      {!!questionDetail && <Grid container spacing="8">

        <Grid item xs={12}>
          <Typography sx={{textDecoration: 'underline', fontStyle: 'italic', fontSize: '0.9rem'}}>Rules on the question</Typography>
        </Grid>
        <Grid item xs={12} sx={{marginBottom: 1, marginTop: 1}}>
          <Divider sx={{marginBottom: 1}} />
          <Typography sx={{textDecoration: 'underline'}}>{(questionDetail?.body || {en: ''}).en}</Typography>
        </Grid>
        <Grid item xs={6}>
          {/* <Typography>Skip this question if</Typography> */}
          <StyledSelect
            label="Question selection criteria"
            value={questionDetail?.extra_data?.rules?.question_criteria || 'hideif'}
            id='q_criteria'
            onChange={(v) => {
              setQuestionDetail({
                ...questionDetail,
                extra_data: {
                  ...(questionDetail.extra_data || {}),
                  rules: {
                    ...(questionDetail.extra_data?.rules || {}),
                    question_criteria: (v || 'hideif') as 'hideif' | 'showif'
                  }
                }
              });
            }}
            list={[
              {id: 'hideif', name: "Hide this question if"},
              {id: 'showif', name: "Show this question if"}
            ]}
          />
        </Grid>
        <Grid item xs={6}>
          <StyledSelect
            label="Operand"
            value={questionDetail?.extra_data?.rules?.question_operand || 'and'}
            id='operand'
            onChange={(v) => {
              setQuestionDetail({
                ...questionDetail,
                extra_data: {
                  ...(questionDetail.extra_data || {}),
                  rules: {
                    ...(questionDetail.extra_data?.rules || {}),
                    question_operand: (v || 'and') as 'and' | 'or'
                  }
                }
              });
            }}
            list={[
              {id: 'and', name: "AND"},
              {id: 'or', name: "OR"}
            ]}
          />
        </Grid>
        <Grid container item xs={12} spacing="4">
          {((questionDetail.extra_data?.rules || {}).question || []).map((rule, i) => <>
            <Grid item xs={(rule.answer_option_id?.toString() || '') === 'zip' ? 6 : 12}>
              <FormControl fullWidth sx={{marginTop: 1, marginBottom: 1}}>
                <InputLabel id={`selected_ao-label`}>{"Selected answer option".replace(/<[^>]*>?/gm, '')}</InputLabel>
                <Select
                  value={rule.answer_option_id?.toString() || ''}
                  labelId={`selected_ao-label`}
                  id='selected_ao'
                  label={he.decode("Selected answer option")}
                  onChange={(e) => {
                    let new_rules = [...((questionDetail.extra_data?.rules || {}).question || [])];
  
                    if (e.target.value.toString().indexOf('prov_') > -1) {
                      new_rules[i] = {
                        ...((questionDetail.extra_data?.rules || {}).question || [])[i],
                        answer_option_id: e.target.value,
                        question_id: 'profile_province'
                      };
                    } else {
                      if (e.target.value.toString().indexOf('zip') > -1) {
                        new_rules[i] = {
                          ...((questionDetail.extra_data?.rules || {}).question || [])[i],
                          answer_option_id: e.target.value,
                          question_id: 'profile_zip'
                        };
                      } else {
                        new_rules[i] = {
                          ...((questionDetail.extra_data?.rules || {}).question || [])[i],
                          answer_option_id: parseInt(e.target.value, 10)
                        };
                      }
                    }
  
                    setQuestionDetail({
                      ...questionDetail,
                      extra_data: {
                        ...(questionDetail.extra_data || {}),
                        rules: {
                          ...(questionDetail.extra_data?.rules || {}),
                          question: new_rules
                        }
                      }
                    });
                  }}
                  sx={{
                    ...{
                      width: '100%',
                    },
                    '& .MuiInputBase-input': {
                      paddingBottom: 1,
                      paddingTop: 1
                    }
                  }}
                >
                  <MenuItem key={0} value='0'>Remove rule</MenuItem>
                  {previousQuestions.map(qqq => (qqq.simple_answer_options || []).map(item => <MenuItem key={item.id} value={item.id}>{(qqq.body?.en || '').replace(/<[^>]*>?/gm, '')} / {item.body.en.replace(/<[^>]*>?/gm, '')}</MenuItem>))}
                  <MenuItem key="prov_flanders" value='prov_flanders'>Profile / Province / Flanders</MenuItem>
                  <MenuItem key="prov_wallonia" value='prov_wallonia'>Profile / Province / Wallonia</MenuItem>
                  <MenuItem key="prov_antwerp" value='prov_antwerp'>Profile / Province / Antwerp</MenuItem>
                  <MenuItem key="prov_brussels" value='prov_brussels'>Profile / Province / Brussels Capital Region</MenuItem>
                  <MenuItem key="prov_eastflanders" value='prov_eastflanders'>Profile / Province / East Flanders</MenuItem>
                  <MenuItem key="prov_flemishbrabant" value='prov_flemishbrabant'>Profile / Province / Flemish Brabant</MenuItem>
                  <MenuItem key="prov_hainaut" value='prov_hainaut'>Profile / Province / Hainaut</MenuItem>
                  <MenuItem key="prov_liege" value='prov_liege'>Profile / Province / Liège</MenuItem>
                  <MenuItem key="prov_limburg" value='prov_limburg'>Profile / Province / Limburg</MenuItem>
                  <MenuItem key="prov_luxembourg" value='prov_luxembourg'>Profile / Province / Luxembourg</MenuItem>
                  <MenuItem key="prov_namur" value='prov_namur'>Profile / Province / Namur</MenuItem>
                  <MenuItem key="prov_walloonbrabant" value='prov_walloonbrabant'>Profile / Province / Walloon Brabant</MenuItem>
                  <MenuItem key="prov_westflanders" value='prov_westflanders'>Profile / Province / West Flanders</MenuItem>
                  <MenuItem key="zip" value='zip'>Profile / Zip</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {(rule.answer_option_id?.toString() || '') === 'zip' && <Grid item xs={6}>
              <StyledTextField
                label="ZipCodes, separated with comma"
                value={rule.pars || ''}
                id={`zips_${i}`}
                onChange={(v) => {
                  let new_rules = [...((questionDetail.extra_data?.rules || {}).question || [])];

                  new_rules[i] = {
                    ...((questionDetail.extra_data?.rules || {}).question || [])[i],
                    pars: v
                  };

                  setQuestionDetail({
                    ...questionDetail,
                    extra_data: {
                      ...(questionDetail.extra_data || {}),
                      rules: {
                        ...(questionDetail.extra_data?.rules || {}),
                        question: new_rules
                      }
                    }
                  });
                }}
                fullWidth
              />
            </Grid>}
          </>)}
        </Grid>

        <Grid item xs={12} spacing="4">
          <StyledButton
            label={t("questions.rules.add_more_cta")}
            id='add_rule'
            contained
            onClick={(v) => {
              setQuestionDetail({
                ...questionDetail,
                extra_data: {
                  ...(questionDetail.extra_data || {}),
                  rules: {
                    ...(questionDetail.extra_data?.rules || {}),
                    question: [
                      ...((questionDetail.extra_data?.rules || {}).question || []),
                      {
                        answer_option_id: undefined
                      }
                    ]
                  }
                }
              });
            }}
            sx={{marginLeft: 1}}
          />
        </Grid>

        <Grid item xs={12} sx={{marginTop: 4}}>
          <Typography sx={{textDecoration: 'underline', fontStyle: 'italic', fontSize: '0.9rem'}}>Rules on answer options</Typography>
        </Grid>

        <Grid item container xs={12} spacing="4" sx={{alignContent: 'baseline'}}>
          {((questionDetail?.question_type || 'open') === 'multi' || (questionDetail?.question_type || 'open') === 'single') && answerOptions.map((answer_option, i) => <>
            <Grid item xs={12} sx={{marginBottom: 1, marginTop: 1}}>
              <Divider sx={{marginBottom: 1}} />
              <Typography sx={{textDecoration: 'underline'}}>{(answer_option?.body || {en: ''}).en}</Typography>
            </Grid>
            <Grid item xs={6}>
              {/* <Typography>Hide this answer option if</Typography> */}
              <StyledSelect
                label="AnswerOption selection criteria"
                value={!!questionDetail?.extra_data?.rules?.answers && !!questionDetail?.extra_data?.rules?.answers[answer_option.id || 0] ? questionDetail?.extra_data?.rules?.answers[answer_option.id || 0].criteria || 'hideif' : 'hideif'}
                id='criteria'
                onChange={(v) => {
                  setQuestionDetail({
                    ...questionDetail,
                    extra_data: {
                      ...(questionDetail.extra_data || {}),
                      rules: {
                        ...(questionDetail.extra_data?.rules || {}),
                        answers: {
                          ...(questionDetail.extra_data?.rules?.answers || {}),
                          [answer_option.id || 0]: {
                            ...((!!questionDetail?.extra_data?.rules?.answers ? questionDetail.extra_data?.rules?.answers : {})[answer_option.id || 0] || {}),
                            criteria: (v || 'hideif') as 'hideif' | 'showif'
                          }
                        }
                      }
                    }
                  });
                }}
                list={[
                  {id: 'hideif', name: "Hide this answer option if"},
                  {id: 'showif', name: "Show this answer option if"}
                ]}
              />
            </Grid>
            <Grid item xs={6}>
              <StyledSelect
                label="Operand"
                value={!!questionDetail?.extra_data?.rules?.answers && !!questionDetail?.extra_data?.rules?.answers[answer_option.id || 0] ? questionDetail?.extra_data?.rules?.answers[answer_option.id || 0].operand || 'and' : 'and'}
                id='operand'
                onChange={(v) => {
                  setQuestionDetail({
                    ...questionDetail,
                    extra_data: {
                      ...(questionDetail.extra_data || {}),
                      rules: {
                        ...(questionDetail.extra_data?.rules || {}),
                        answers: {
                          ...(questionDetail.extra_data?.rules?.answers || {}),
                          [answer_option.id || 0]: {
                            ...((!!questionDetail?.extra_data?.rules?.answers ? questionDetail.extra_data?.rules?.answers : {})[answer_option.id || 0] || {}),
                            operand: (v || 'and') as 'and' | 'or'
                          }
                        }
                      }
                    }
                  });
                }}
                list={[
                  {id: 'and', name: "AND"},
                  {id: 'or', name: "OR"}
                ]}
              />
            </Grid>
            <Grid container item xs={12} spacing="4">
              {(((!!questionDetail?.extra_data?.rules?.answers ? questionDetail.extra_data?.rules?.answers : {})[answer_option.id || 0] || {}).question || []).map((rule, i) => <>
                <Grid item xs={(rule.answer_option_id?.toString() || '') === 'zip' ? 6 : 12}>
                  <FormControl fullWidth sx={{marginTop: 1, marginBottom: 1}}>
                    <InputLabel id={`selected_ao-label`}>{"Selected answer option".replace(/<[^>]*>?/gm, '')}</InputLabel>
                    <Select
                      value={rule.answer_option_id?.toString() || ''}
                      labelId={`selected_ao-label`}
                      id='selected_ao'
                      label={he.decode("Selected answer option")}
                      onChange={(e) => {
                        let new_rules = [...(((!!questionDetail?.extra_data?.rules?.answers ? questionDetail.extra_data?.rules?.answers : {})[answer_option.id || 0] || {}).question || [])];
      
                        if (e.target.value.toString().indexOf('prov_') > -1) {
                          new_rules[i] = {
                            ...(((!!questionDetail?.extra_data?.rules?.answers ? questionDetail.extra_data?.rules?.answers : {})[answer_option.id || 0] || {}).question || [])[i],
                            answer_option_id: e.target.value,
                            question_id: 'profile_province'
                          };
                        } else {
                          if (e.target.value.toString().indexOf('zip') > -1) {
                            new_rules[i] = {
                              ...(((!!questionDetail?.extra_data?.rules?.answers ? questionDetail.extra_data?.rules?.answers : {})[answer_option.id || 0] || {}).question || [])[i],
                              answer_option_id: e.target.value,
                              question_id: 'profile_zip'
                            };
                          } else {
                            new_rules[i] = {
                              ...(((!!questionDetail?.extra_data?.rules?.answers ? questionDetail.extra_data?.rules?.answers : {})[answer_option.id || 0] || {}).question || [])[i],
                              answer_option_id: parseInt(e.target.value, 10)
                            };
                          }
                        }
      
                        setQuestionDetail({
                          ...questionDetail,
                          extra_data: {
                            ...(questionDetail.extra_data || {}),
                            rules: {
                              ...(questionDetail.extra_data?.rules || {}),
                              answers: {
                                ...(questionDetail.extra_data?.rules?.answers || {}),
                                [answer_option.id || 0]: {
                                  ...((!!questionDetail?.extra_data?.rules?.answers ? questionDetail.extra_data?.rules?.answers : {})[answer_option.id || 0] || {}),
                                  question: new_rules
                                }
                              }
                            }
                          }
                        });
                      }}
                      sx={{
                        ...{
                          width: '100%',
                        },
                        '& .MuiInputBase-input': {
                          paddingBottom: 1,
                          paddingTop: 1
                        }
                      }}
                    >
                      <MenuItem key={0} value='0'>Remove rule</MenuItem>
                      {previousQuestions.map(qqq => (qqq.simple_answer_options || []).map(item => <MenuItem key={item.id} value={item.id}>{(qqq.body?.en || '').replace(/<[^>]*>?/gm, '')} / {item.body.en.replace(/<[^>]*>?/gm, '')}</MenuItem>))}
                      <MenuItem key="prov_flanders" value='prov_flanders'>Profile / Province / Flanders</MenuItem>
                      <MenuItem key="prov_wallonia" value='prov_wallonia'>Profile / Province / Wallonia</MenuItem>
                      <MenuItem key="prov_antwerp" value='prov_antwerp'>Profile / Province / Antwerp</MenuItem>
                      <MenuItem key="prov_brussels" value='prov_brussels'>Profile / Province / Brussels Capital Region</MenuItem>
                      <MenuItem key="prov_eastflanders" value='prov_eastflanders'>Profile / Province / East Flanders</MenuItem>
                      <MenuItem key="prov_flemishbrabant" value='prov_flemishbrabant'>Profile / Province / Flemish Brabant</MenuItem>
                      <MenuItem key="prov_hainaut" value='prov_hainaut'>Profile / Province / Hainaut</MenuItem>
                      <MenuItem key="prov_liege" value='prov_liege'>Profile / Province / Liège</MenuItem>
                      <MenuItem key="prov_limburg" value='prov_limburg'>Profile / Province / Limburg</MenuItem>
                      <MenuItem key="prov_luxembourg" value='prov_luxembourg'>Profile / Province / Luxembourg</MenuItem>
                      <MenuItem key="prov_namur" value='prov_namur'>Profile / Province / Namur</MenuItem>
                      <MenuItem key="prov_walloonbrabant" value='prov_walloonbrabant'>Profile / Province / Walloon Brabant</MenuItem>
                      <MenuItem key="prov_westflanders" value='prov_westflanders'>Profile / Province / West Flanders</MenuItem>
                      <MenuItem key="zip" value='zip'>Profile / Zip</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {(rule.answer_option_id?.toString() || '') === 'zip' && <Grid item xs={6}>
                  <StyledTextField
                    label="ZipCodes, separated with comma"
                    value={rule.pars || ''}
                    id={`zips_${i}`}
                    onChange={(v) => {
                      let new_rules = [...(((!!questionDetail?.extra_data?.rules?.answers ? questionDetail.extra_data?.rules?.answers : {})[answer_option.id || 0] || {}).question || [])];
      
                      new_rules[i] = {
                        ...(((!!questionDetail?.extra_data?.rules?.answers ? questionDetail.extra_data?.rules?.answers : {})[answer_option.id || 0] || {}).question || [])[i],
                        pars: v
                      };
    
                      setQuestionDetail({
                        ...questionDetail,
                        extra_data: {
                          ...(questionDetail.extra_data || {}),
                          rules: {
                            ...(questionDetail.extra_data?.rules || {}),
                            answers: {
                              ...(questionDetail.extra_data?.rules?.answers || {}),
                              [answer_option.id || 0]: {
                                ...((!!questionDetail?.extra_data?.rules?.answers ? questionDetail.extra_data?.rules?.answers : {})[answer_option.id || 0] || {}),
                                question: new_rules
                              }
                            }
                          }
                        }
                      });
                    }}
                    fullWidth
                  />
                </Grid>}
              </>)}
            </Grid>

            <Grid item xs={12} spacing="4">
              <StyledButton
                label={t("questions.rules.add_more_cta")}
                id='add_rule'
                contained
                onClick={(v) => {
                  setQuestionDetail({
                    ...questionDetail,
                    extra_data: {
                      ...(questionDetail.extra_data || {}),
                      rules: {
                        ...(questionDetail.extra_data?.rules || {}),
                        answers: {
                          ...(questionDetail.extra_data?.rules?.answers || {}),
                          [answer_option.id || 0]: {
                            ...((!!questionDetail?.extra_data?.rules?.answers ? questionDetail.extra_data?.rules?.answers : {})[answer_option.id || 0] || {}),
                            question: [
                              ...(((!!questionDetail?.extra_data?.rules?.answers ? questionDetail.extra_data?.rules?.answers : {})[answer_option.id || 0] || {}).question || []),
                              {
                                answer_option_id: undefined
                              }
                            ]
                          }
                        }
                      }
                    }
                  });
                }}
                sx={{marginLeft: 1}}
              />
            </Grid>
          </>)}
        </Grid>



        {/* <Grid item container xs={12} spacing="4" sx={{alignContent: 'baseline'}}>
          {((questionDetail?.question_type || 'open') === 'multi' || (questionDetail?.question_type || 'open') === 'single') && <>
            <Grid item xs={12}>
              <Typography sx={{textDecoration: 'underline', fontStyle: 'italic', fontSize: '0.9rem'}}>{t("questions.answer_options.title")}</Typography>
            </Grid>
            <Grid item container xs={12}>
              {answerOptions.map((answer_option, i) => <>
                <Grid item xs={1}>
                  <Typography>{answer_option.id}</Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography>{(answer_option?.body || {en: ''}).en}</Typography>
                </Grid>
                <Grid item xs={1}>
                  
                </Grid>
              </>)}
            </Grid>
          </>}
        </Grid> */}

        <Grid item xs={12} sx={{alignContent: 'baseline', textAlign: 'center', position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 1201}}>
          <Divider sx={{marginTop: 1, marginBottom: 2}} />

          {!loader && <StyledButton
            label={t("public.buttons.cancel")}
            id='cancel'
            // contained
            onClick={(v) => {
              navigate(`/questions/${projectId}`);
            }}
            sx={{marginLeft: 1}}
          />}
          {checkAllowed(login, null, projectId?.toString() || '0', "ProjectQuestionRules", 'u', true) && !loader && <StyledButton
            label={t("public.buttons.save")}
            id='save'
            contained
            onClick={(v) => {
              saveSetting();
            }}
            sx={{marginLeft: 1}}
          />}
        </Grid>
      </Grid>}

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
      {!!errorSnackOpen && <AlertCheckInputs open={errorSnackOpen} setOpen={(b) => {setErrorSnackOpen(b);}} />}
      {!!exitSavedSnackOpen && <AlertSaved open={exitSavedSnackOpen} setOpen={(b) => {setDividendSavedSnackOpen(b);}} />}
    </Page>
  );
}
