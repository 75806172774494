import { FunctionComponent, useState, useEffect } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useAtom, useAtomValue } from 'jotai';
// import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next'

import { currentProjectAtom } from '../../lib/project';

import {
  Divider,
  Grid
} from '@mui/material';

import {
  StyledButton
} from '../../components/interface';

import { Answer } from '../../models/Answer';

type SharedPollButtonsProps = {
  onClose:() => void;
  onFinish:() => void;
  onStep:(s:number) => void;
  step: number;
  pollAnswers: Answer[];
  layout?: string;
};

export const SharedPollButtons: FunctionComponent<SharedPollButtonsProps> = ({onClose, onFinish, onStep, step, pollAnswers, layout}) => {
  // const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const currentProject = useAtomValue(currentProjectAtom);

  let theLayout = 'normal'
  if (!!layout) theLayout = layout;

  let btnStop = "buttons.stop";
  let btnBack = "buttons.back";
  let btnNext = "buttons.next";
  let btnFinish = "buttons.finish";
  let extraSx = {};

  if (theLayout === 'election_form') {
    btnStop = "layout.election_form.buttons.stop";
    btnBack = "layout.election_form.buttons.back";
    btnNext = "layout.election_form.buttons.next";
    btnFinish = "layout.election_form.buttons.finish";
    extraSx = {
      backgroundColor: 'black',
      padding: 2,
      paddingLeft: 4,
      paddingRight: 4,
      fontSize: '1.25rem',
      borderRadius: 40,
      marginTop: 1,
      marginBottom: 1
    };
  }

  return (<>
    <Grid item xs={12}>
      <Divider sx={{marginTop: 1, marginBottom: 2}} />
    </Grid>
    <Grid item xs={12} sx={{textAlign: 'center'}}>
      {step === 0 && <StyledButton
        label={t(btnStop)}
        id='stop'
        contained
        onClick={(v) => {
          onClose();
          window.scrollTo(0, 0)
        }}
        sx={{marginLeft: 1, marginRight: 1, ...extraSx}}
      />}
      {step > 0 && <StyledButton
        label={t(btnBack)}
        id='back'
        contained
        onClick={(v) => {
          onStep(-1);
          window.scrollTo(0, 0)
        }}
        sx={{marginLeft: 1, marginRight: 1, ...extraSx}}
      />}
      {step < ((currentProject?.questions_count || 0) + 1) && <StyledButton
        label={t(btnNext)}
        id='next'
        contained
        disabled={
          currentProject?.questions_info && 
          currentProject?.questions_info[step - 1] && 
          (
            currentProject?.questions_info[step - 1].question_type === 'open' || 
            currentProject?.questions_info[step - 1].question_type === 'single' || 
            currentProject?.questions_info[step - 1].question_type === 'multi'
          ) && 
          !currentProject?.questions_info[step - 1].is_optional &&
          (
            !pollAnswers[step] || 
            (
              (!!pollAnswers[step] ? (pollAnswers[step].answer_content || '') : '').length < 1 && 
              (!!pollAnswers[step] ? (pollAnswers[step].answer_option_id || '') : '').toString().length < 1
            )
          )
        }
        onClick={(v) => {
          onStep(1);
          window.scrollTo(0, 0)
        }}
        sx={{marginLeft: 1, marginRight: 1, ...extraSx}}
      />}
      {step >= ((currentProject?.questions_count || 0) + 1) && <StyledButton
        label={t(btnFinish)}
        id='finish'
        contained
        onClick={(v) => {
          onFinish();
          window.scrollTo(0, 0)
        }}
        sx={{marginLeft: 1, marginRight: 1, ...extraSx}}
      />}
    </Grid>
  </>);
}
