import { Answer } from "../models/Answer";
import { Project } from "../models/Project";
import { User } from "../models/User";

export function checkConditionsForAnswerOption(ao_id:number, step:number, currentProject:Project, pollAnswers:Answer[], login: User|undefined) {
  const checkStep = step - 1;

  if (
    !!currentProject?.questions_info && 
    !!currentProject?.questions_info[checkStep] && 
    !!currentProject?.questions_info[checkStep].extra_data?.rules && 
    !!currentProject?.questions_info[checkStep].extra_data?.rules?.answers && 
    !!(currentProject?.questions_info[checkStep].extra_data?.rules?.answers || {})[ao_id]
  ) {
    // console.log(currentProject?.questions_info[checkStep].extra_data?.rules?.answers);
    // console.log((currentProject?.questions_info[checkStep].extra_data?.rules?.answers || {})[ao_id]);

    let conditions_met:boolean[] = [];
    ((currentProject?.questions_info[checkStep].extra_data?.rules?.answers || {})[ao_id].question || []).forEach(rule => {
      let rule_matched = false;
      if (rule.question_id?.toString() === 'profile_province') {
        if (rule.answer_option_id?.toString() === "prov_antwerp" && login?.province === "Antwerp") rule_matched = true;
        if (rule.answer_option_id?.toString() === "prov_brussels" && login?.province === "Brussels Capital Region") rule_matched = true;
        if (rule.answer_option_id?.toString() === "prov_eastflanders" && login?.province === "East Flanders") rule_matched = true;
        if (rule.answer_option_id?.toString() === "prov_flemishbrabant" && login?.province === "Flemish Brabant") rule_matched = true;
        if (rule.answer_option_id?.toString() === "prov_hainaut" && login?.province === "Hainaut") rule_matched = true;
        if (rule.answer_option_id?.toString() === "prov_liege" && login?.province === "Liège") rule_matched = true;
        if (rule.answer_option_id?.toString() === "prov_limburg" && login?.province === "Limburg") rule_matched = true;
        if (rule.answer_option_id?.toString() === "prov_luxembourg" && login?.province === "Luxembourg") rule_matched = true;
        if (rule.answer_option_id?.toString() === "prov_namur" && login?.province === "Namur") rule_matched = true;
        if (rule.answer_option_id?.toString() === "prov_walloonbrabant" && login?.province === "Walloon Brabant") rule_matched = true;
        if (rule.answer_option_id?.toString() === "prov_westflanders" && login?.province === "West Flanders") rule_matched = true;

        if (rule.answer_option_id?.toString() === "prov_flanders" && (login?.province === "Antwerp" || login?.province === "East Flanders" || login?.province === "Flemish Brabant" || login?.province === "Limburg" || login?.province === "West Flanders")) rule_matched = true;
        if (rule.answer_option_id?.toString() === "prov_wallonia" && (login?.province === "Hainaut" || login?.province === "Liège" || login?.province === "Luxembourg" || login?.province === "Namur" || login?.province === "Walloon Brabant")) rule_matched = true;
      } else {
        if (rule.question_id?.toString() === 'profile_zip') {
          if (!!login?.zip && !!rule.pars && rule.pars.length > 0 && rule.pars.indexOf(login?.zip.toString()) > -1) rule_matched = true;
        } else {
          pollAnswers.map(answer => {
            if (answer.question_id === rule.question_id) {
              if (!!answer.answer_option_id && answer.answer_option_id === rule.answer_option_id) {
                rule_matched = true;
              }
              if (!!answer.answer_content && answer.answer_content.split("|").filter(iii => parseInt(iii, 10) === rule.answer_option_id).length > 0) {
                rule_matched = true;
              }
            }
          });
        }
      }

      // console.log(ao_id, rule, conditions_met, login);
      conditions_met.push(rule_matched);
    });

    if (conditions_met.length > 0) {
      let operand = ((currentProject?.questions_info[checkStep].extra_data?.rules?.answers || {})[ao_id].operand || 'and');
      let criteria = ((currentProject?.questions_info[checkStep].extra_data?.rules?.answers || {})[ao_id].criteria || 'hideif');

      if (operand === 'and') {
        if (conditions_met.filter(b => !b).length < 1) {
          return criteria != 'hideif';
        } else {
          return criteria == 'hideif';
        }
      } else {
        if (conditions_met.filter(b => !!b).length > 0) {
          return criteria != 'hideif';
        } else {
          return criteria == 'hideif';
        }
      }
    } else {
      return true;
    }
  } else {
    return true;
  }
}

export function goToStep(mod:number, step:number, currentProject:Project, pollAnswers:Answer[], cb:(m:number) => void, login: User|undefined) {
  const checkStep = step - 1 + mod;

  if (!!currentProject?.questions_info && !!currentProject?.questions_info[checkStep] && !!currentProject?.questions_info[checkStep].is_extended_profile) {
    let answer_given = pollAnswers.filter(a => a.question_id === (currentProject?.questions_info || [])[checkStep].id)[0];
    if (!!answer_given && !!answer_given.existing) {
      goToStep(mod + mod, step, currentProject, pollAnswers, cb, login);
    } else {
      actualGoToStep(mod, step, currentProject, pollAnswers, cb, login);
    }
  } else {
    actualGoToStep(mod, step, currentProject, pollAnswers, cb, login);
  }
}

function actualGoToStep(mod:number, step:number, currentProject:Project, pollAnswers:Answer[], cb:(m:number) => void, login: User|undefined) {
  const checkStep = step - 1 + mod;
// console.log('check ', checkStep);
// console.log((currentProject?.questions_info || []));
// console.log((currentProject?.questions_info || [])[checkStep]);
// console.log((currentProject?.questions_info || [])[checkStep].extra_data);
  if (!!currentProject?.questions_info && !!currentProject?.questions_info[checkStep] && !!currentProject?.questions_info[checkStep].extra_data?.rules) {
    // console.log(currentProject?.questions_info[checkStep].extra_data?.rules);
// console.log(pollAnswers);
    let conditions_met:boolean[] = [];
    (currentProject?.questions_info[checkStep].extra_data?.rules?.question || []).map(rule => {
      let rule_matched = false;

      if (rule.question_id?.toString() === 'profile_province') {
        if (rule.answer_option_id?.toString() === "prov_antwerp" && login?.province === "Antwerp") rule_matched = true;
        if (rule.answer_option_id?.toString() === "prov_brussels" && login?.province === "Brussels Capital Region") rule_matched = true;
        if (rule.answer_option_id?.toString() === "prov_eastflanders" && login?.province === "East Flanders") rule_matched = true;
        if (rule.answer_option_id?.toString() === "prov_flemishbrabant" && login?.province === "Flemish Brabant") rule_matched = true;
        if (rule.answer_option_id?.toString() === "prov_hainaut" && login?.province === "Hainaut") rule_matched = true;
        if (rule.answer_option_id?.toString() === "prov_liege" && login?.province === "Liège") rule_matched = true;
        if (rule.answer_option_id?.toString() === "prov_limburg" && login?.province === "Limburg") rule_matched = true;
        if (rule.answer_option_id?.toString() === "prov_luxembourg" && login?.province === "Luxembourg") rule_matched = true;
        if (rule.answer_option_id?.toString() === "prov_namur" && login?.province === "Namur") rule_matched = true;
        if (rule.answer_option_id?.toString() === "prov_walloonbrabant" && login?.province === "Walloon Brabant") rule_matched = true;
        if (rule.answer_option_id?.toString() === "prov_westflanders" && login?.province === "West Flanders") rule_matched = true;

        if (rule.answer_option_id?.toString() === "prov_flanders" && (login?.province === "Antwerp" || login?.province === "East Flanders" || login?.province === "Flemish Brabant" || login?.province === "Limburg" || login?.province === "West Flanders")) rule_matched = true;
        if (rule.answer_option_id?.toString() === "prov_wallonia" && (login?.province === "Hainaut" || login?.province === "Liège" || login?.province === "Luxembourg" || login?.province === "Namur" || login?.province === "Walloon Brabant")) rule_matched = true;
      } else {
        if (rule.question_id?.toString() === 'profile_zip') {
          if (!!login?.zip && !!rule.pars && rule.pars.length > 0 && rule.pars.indexOf(login?.zip.toString()) > -1) rule_matched = true;
        } else {
          pollAnswers.map(answer => {
            if (answer.question_id === rule.question_id) {
              if (!!answer.answer_option_id && answer.answer_option_id === rule.answer_option_id) {
                rule_matched = true;
              }
              if (!!answer.answer_content && answer.answer_content.split("|").filter(iii => parseInt(iii, 10) === rule.answer_option_id).length > 0) {
                rule_matched = true;
              }
            }
            return true;
          });
        }
      }

      conditions_met.push(rule_matched);
    });

    if (conditions_met.length > 0) {
      let operand = (currentProject?.questions_info[checkStep].extra_data?.rules?.question_operand || 'and');
      let criteria = (currentProject?.questions_info[checkStep].extra_data?.rules?.question_criteria || 'hideif');

      if (operand === 'and') {
        if (conditions_met.filter(b => !b).length < 1) {
          if (criteria === 'hideif') goToStep(mod + 1, step, currentProject, pollAnswers, cb, login);
          if (criteria === 'showif') cb(step + mod);
        } else {
          if (criteria === 'showif') goToStep(mod + 1, step, currentProject, pollAnswers, cb, login);
          if (criteria === 'hideif') cb(step + mod);
        }
      } else {
        if (conditions_met.filter(b => !!b).length > 0) {
          if (criteria === 'hideif') goToStep(mod + 1, step, currentProject, pollAnswers, cb, login);
          if (criteria === 'showif') cb(step + mod);
        } else {
          if (criteria === 'showif') goToStep(mod + 1, step, currentProject, pollAnswers, cb, login);
          if (criteria === 'hideif') cb(step + mod);
        }
      }
    } else {
      cb(step + mod);
    }
  } else {
    cb(step + mod);
  }
}