import React, { FunctionComponent, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useLocation, useNavigate } from "react-router-dom";

import {
  Badge,
  Box
} from '@mui/material';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {Link} from '../interface/Link';

type MenuItemProps = {
  icon?: React.ReactNode;
  path?: string;
  label: string;
  hidden?: boolean;
  child?: boolean;
  children?: React.ReactNode;
  count?: number;
};

export const MenuItem: FunctionComponent<MenuItemProps> = ({count, icon, path, label, hidden, children, child}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [contentOpen, setContentOpen] = useState<boolean>(false);
  const [ctrlActive, setCtrlActive] = useState(false);

  let is_active:boolean = pathname === path;

  if (hidden) {
    return (<></>);
  } else {
    if (!!children) {
      return (<>
        <Box sx={{ flexGrow: 1, alignItems: 'center', display: 'flex', margin: -1, padding: 1.5, marginTop: 1, backgroundColor: ((is_active || contentOpen) ? theme.palette.primary.light : theme.palette.primary?.main), cursor: 'pointer'}} onClick={(e) => {
          setContentOpen(!contentOpen);
        }}>
          <Box sx={{
            width: '24px',
            height: '24px',
            color: 'white'
          }}>
            {!!icon && icon}
          </Box>
          <Link onClick={(e) => {}} label={label} sx={{
            marginLeft: 2,
            color: 'white'
          }} />
          <Badge badgeContent={contentOpen ? 0 : (count || 0)} color="secondary" sx={{
            width: '24px',
            height: '24px',
            marginLeft: 'auto',
            // marginRight: -1,
            '& span': {
              top: 12,
              right: 40
            }
          }}>
            <Box sx={{
              width: '24px',
              height: '24px',
              color: 'white',
              // marginLeft: 'auto',
              marginRight: -1
            }}>
              {!contentOpen && <ExpandMoreIcon />}
              {!!contentOpen && <ExpandLessIcon />}
            </Box>
          </Badge>
        </Box>
        {!!contentOpen && <Box sx={{margin: -1, marginTop: 1, paddingTop: 1, backgroundColor: 'rgb(12, 31, 66)'}}>
          {children}
        </Box>}
      </>);
    } else {
      return (<Box sx={{
        flexGrow: 1,
        alignItems: 'center',
        display: 'flex',
        padding: 1.5,
        cursor: 'pointer',
        ...(!!child ? {
          margin: 0,
          backgroundColor: (is_active ? theme.palette.primary.light : 'rgb(12, 31, 66)'),
          "& p": {
            fontSize: '0.9rem !important'
          },
          paddingTop: 0.5,
          paddingBottom: 0.5
        } : {
          margin: -1,
          backgroundColor: (is_active ? theme.palette.primary.light : theme.palette.primary.main),
          marginTop: 1
        })
      }} onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        
        if (ctrlActive || e.ctrlKey || e.button === 1) {
          if (!!path) window.open(path, "_BLANK");
        } else {
          if (!!path) navigate(path);
        }
        setCtrlActive(false);
      }} 
      onAuxClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        
        if (!!path) window.open(path, "_BLANK");
      }}
      onMouseDown={(e) => {
        e.preventDefault();
        e.stopPropagation();
        
        if (e.button == 1) {
          if (!!path) window.open(path, "_BLANK");
        }
      }}
      // onKeyUp={(e) => {
      //   if (e.key === "Meta") {
      //     setCtrlActive(false);
      //   }
      // }}
      // onKeyDown={(e) => {
      //   if (e.key === "Meta") {
      //     setCtrlActive(true);
      //   }
      // }}
      >
        <Box sx={{
          width: '24px',
          height: '24px',
          color: 'white'
        }}>
          {!!icon && icon}
        </Box>
        <Link onClick={(e) => {}} label={label} sx={{
          marginLeft: 2,
          color: (path === "/nonexisting" ? 'grey' : 'white')
        }} />
      </Box>);
    }
  }
}
