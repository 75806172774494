import { FunctionComponent, useEffect } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useAtom, useAtomValue } from 'jotai';
import { useNavigate, useParams } from "react-router-dom";
// import { useTranslation } from 'react-i18next'
import { useSearchParams } from "react-router-dom";

import { loginAtom, do_login_slug, do_login_magic_link } from '../lib/auth';
import { currentProjectAtom } from '../lib/project';

import {
  Box,
  CircularProgress
} from '@mui/material';

// import ItsmeLoginButton from '../images/itsme.svg';
// import MainLogo from '../images/MainLogo.png';

// import { Link } from '../components/interface/Link';
// import { Typography } from '../components/interface/Typography';

type ProfileProps = {};

export const Profile: FunctionComponent<ProfileProps> = () => {
  // const theme = useTheme();
  const navigate = useNavigate();
  const currentProject = useAtomValue(currentProjectAtom);
  // const {t, i18n} = useTranslation(['translations']);
  const [login, setLogin] = useAtom(loginAtom);
  let [searchParams, setSearchParams] = useSearchParams();

  const params = useParams();
  const magic_link: string | undefined = params.magic_link;
  const slug: string | undefined = params.slug;

  // const slug: string | undefined = params.embed;

  useEffect(() => {
    if (!!slug) {
      do_login_slug(slug, (result) => {
        setLogin(result);

        if (!!searchParams.get('embed')) {
          navigate(`/embed/${searchParams.get('embed')}`);
        } else {
          navigate("/poll");
        }
      }, currentProject);
    }
  }, [slug]);

  useEffect(() => {
    if (!!magic_link) {
      do_login_magic_link(magic_link, (result) => {
        setLogin(result);
        navigate("/poll");
      }, currentProject);
    }
  }, [magic_link]);

  return (<>
    <Box sx={{
      width: '100%',
      margin: 'auto',
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center'
    }}>
      <CircularProgress />
    </Box>
  </>);
}
