import React, { FunctionComponent } from 'react';
import { useTheme } from '@mui/material/styles';

import {
  Avatar,
  Box
} from '@mui/material';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { BlockSettingsImage } from './Image';
import { BlockSettingsImageLeft } from './ImageLeft';
import { BlockSettingsImageRight } from './ImageRight';
import { BlockSettingsImageTitleText } from './ImageTitleText';
import { BlockSettingsSeparator } from './Separator';
import { BlockSettingsText } from './Text';
import { BlockSettingsTitleText } from './TitleText';
import { BlockSettingsTitleText2Col } from './TitleText2Col';

import { EmailBlock } from '../../../models/EmailBlock';

type BlockSettingsBaseProps = {
  forceClose: boolean;
  isFirst: boolean;
  isLast: boolean;
  setActive: (e:boolean) => void;
  setBlock: (eb:EmailBlock) => void;
  deleteFromList: () => void;
  setNewPosition: (change:number) => void;
  block: EmailBlock;
};

export const BlockSettingsBase: FunctionComponent<BlockSettingsBaseProps> = ({isFirst, isLast, forceClose, block, setBlock, setActive, setNewPosition, deleteFromList}) => {
  const theme = useTheme();
  
  return (<>
    {!!forceClose && <Box sx={{position: 'relative'}}>
      <DeleteForeverIcon sx={{cursor: 'pointer', position: 'absolute', right: 72, top: 8, zIndex: 1001, height: 24, width: 24, color: theme.palette.secondary.main}} onClick={() => {
        deleteFromList();
      }} />
      {!isLast && <Avatar sx={{cursor: 'pointer', position: 'absolute', right: 40, top: 8, zIndex: 1001, height: 24, width: 24}} onClick={() => {
        setNewPosition(1);
      }}><ExpandMoreIcon /></Avatar>}
      {!isFirst && <Avatar sx={{cursor: 'pointer', position: 'absolute', right: 8, top: 8, zIndex: 1001, height: 24, width: 24}} onClick={() => {
        setNewPosition(-1);
      }}><ExpandLessIcon /></Avatar>}
    </Box>}
    {block.type === "image" && <BlockSettingsImage
      forceClose={forceClose}
      setActive={setActive}
      block={block}
      setBlock={setBlock}
    />}
    {block.type === "text" && <BlockSettingsText
      forceClose={forceClose}
      setActive={setActive}
      block={block}
      setBlock={setBlock}
    />}
    {block.type === "title_text" && <BlockSettingsTitleText
      forceClose={forceClose}
      setActive={setActive}
      block={block}
      setBlock={setBlock}
    />}
    {block.type === "image_title_text" && <BlockSettingsImageTitleText
      forceClose={forceClose}
      setActive={setActive}
      block={block}
      setBlock={setBlock}
    />}
    {block.type === "image_left" && <BlockSettingsImageLeft
      forceClose={forceClose}
      setActive={setActive}
      block={block}
      setBlock={setBlock}
    />}
    {block.type === "image_right" && <BlockSettingsImageRight
      forceClose={forceClose}
      setActive={setActive}
      block={block}
      setBlock={setBlock}
    />}
    {block.type === "title_text_2col" && <BlockSettingsTitleText2Col
      forceClose={forceClose}
      setActive={setActive}
      block={block}
      setBlock={setBlock}
    />}
    {block.type === "separator" && <BlockSettingsSeparator
      forceClose={forceClose}
      setActive={setActive}
      block={block}
      setBlock={setBlock}
    />}
  </>);
};