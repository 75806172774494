export const LOGIN_URL = '/v3/users/login';
export const LOGOUT_URL = '/v3/users/logout';
export const RENEW_URL = '/v3/users/renew_sign_in';

export type User = {
  id?: number;
  auth_token: string;
  email: string;
  first_name?: string;
  last_name?: string;
  phone?: string;
  gender?: string;
  birth_date?: string;
  created_at?: string;
  enabled?: boolean;
  locale?: string;
  itsme_sub?: string;
  street?: string;
  number?: string;
  zip?: string;
  province?: string;
  city?: string;
  is_itsme?: boolean;
  is_anon?: boolean;
  is_special_permissions?: boolean;
  is_mail_invitation?: boolean;
  is_mail_keep_posted?: boolean;

  extra_data?: {
    settings:{
      editor_height?:string,
      languages_split?:string,
      destroy_confirm_ignore?:string
    }
  }

  permission_map?: {
    default: [string, boolean, boolean, boolean, boolean, boolean, boolean, number][]
    company: {[z:string]: {[z:string]: {c: boolean, r: boolean, u: boolean, d: boolean}}}
    project: {[z:string]: {[z:string]: {c: boolean, r: boolean, u: boolean, d: boolean}}}
    all: {[z:string]: {c: boolean, r: boolean, u: boolean, d: boolean}}
  }

  readonly custom_result?: {
    notice?: string;
  }

  readonly is_admin?: boolean;

  readonly readable_address?: string;
}