import { User } from "../models/User";

export function checkAllowed(user: User|undefined, company_id: string|null, project_id: string|null, module: string, action: 'c'|'r'|'u'|'d', underAnyCompany?: boolean, underAnyProject?: boolean) {
  let allowed = false;

  if (!!user) {
    if (user.is_special_permissions) return true;
    
    if (!!user.permission_map) {
      if (!!project_id && !!user.permission_map.project[project_id] && !!user.permission_map.project[project_id][module]) {
        allowed = user.permission_map.project[project_id][module][action];
      } else {
        if (!!company_id && !!user.permission_map.company[company_id] && !!user.permission_map.company[company_id][module]) {
          allowed = user.permission_map.company[company_id][module][action];
        } else {
          if (!!user.permission_map.all && user.permission_map.all[module]) {
            allowed = user.permission_map.all[module][action];
          } else {
            // allowed status false
          }
        }
      }

      if (!allowed && !!underAnyCompany) {
        let _x = Object.values(user.permission_map.company).map(c_map => {
          if (!allowed) allowed = c_map[module][action];

          return undefined;
        });
      }

      if (!allowed && !!underAnyProject) {
        let _x = Object.values(user.permission_map.project).map(p_map => {
          if (!allowed) allowed = p_map[module][action];

          return undefined;
        });
      }
    }
  }

  return allowed;
}