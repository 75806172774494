import { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
// import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

import { fetch_all, fetch_one } from '../../lib/server_helper';

import {
  Grid
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import VisibilityIcon from '@mui/icons-material/Visibility';

import {
  DataGrid,
  Page,
  SearchBox,
  StyledButton,
  StyledIconButton,
  StyledTextField,
  Typography
} from '../../components/interface';

import { City } from '../../models/City';
import { loginAtom } from '../../lib/auth';

type ContentCitiesProps = {};

export const ContentCities: FunctionComponent<ContentCitiesProps> = () => {
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  const [login, setLogin] = useAtom(loginAtom);
  const [cities, setCities] = useState<City[]>([]);
  const [citiesCount, setCitiesCount] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(true);
  const [removeLoader, setRemoveLoader] = useState<boolean>(false);
  const [showImport, setShowImport] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("id DESC");
  const [codes, setCodes] = useState<string>('');

  useEffect(() => {
    onSearch('');
  }, [page, order]);

  const onImport = () => {
    if (login && login.is_special_permissions) {
      setRemoveLoader(true);
      fetch_one<City>('/nl/v3/objects/custom_action', {
        object: 'city',
        class_action: 'custom_api_import_list',
        code_list: codes
      }, (obs, complete_data) => {
        setRemoveLoader(false);
        setCodes('');
        setShowImport(false);
        onSearch('');
      }, (z) => {

      }, login);
    }
  };

  const onSearch = (s: string) => {
    if (login && login.is_special_permissions) {
      setLoader(true);
      fetch_all<City>('/nl/v3/objects/fetch_all', {
        object: 'city',
        fields: ['id', 'name', 'province|id/name', 'votes2019'],
        per_page: 20,
        page: page,
        order: order,
        // filter: {
        //   search: s
        // }
      }, (obs, complete_data) => {
        setCities(obs);

        let count:number = 0;
        if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
        setCitiesCount(count);

        setLoader(false);
      }, (z) => {

      }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
    } else {
      setLoader(false);
    }
  };

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', width: 300 },
    { field: 'province_name', headerName: 'Province', width: 300, sortable: false },
    { field: 'votes2019', headerName: '2019 Votes', width: 150 }
  ];

  return (
    <Page sx={{}} title={t("objects.cities.title")} actions={<>
      {login && login.is_special_permissions && <StyledButton
        label="Import"
        id='create_new'
        contained
        onClick={() => {
          setShowImport(!showImport);
        }}
        sx={{marginRight: 3}}
        startIcon={<AddCircleOutlineIcon />}
      />}
    </>}>
      {/* <SearchBox
        simple={true}
        onSearch={(s) => {
          setPage(0);
          onSearch(s);
        }}
        memo="cities"
      /> */}
      {!!showImport && <>
        <Grid container spacing="4">
          <Grid item xs={12}>
            <StyledTextField
              label="Excel contents (no header)"
              value={codes}
              id='codes'
              onChange={(v) => {
                setCodes(v);
              }}
              fullWidth
              multiline
              sx={{marginTop: 1}}
            />
          </Grid>
          <Grid item xs={12}>
            {login && login.is_special_permissions && !removeLoader && <StyledButton
              label="Import list"
              id='import_list'
              contained
              onClick={() => {
                onImport();
              }}
            />}
          </Grid>
        </Grid>
      </>}
      <DataGrid
        action_count={1}
        actions={(params) => {
          return (<>
            {/* <StyledIconButton color="primary" path={`/city/${params.row.id}`}>
              <EditIcon />
            </StyledIconButton> */}
          </>);
        }}
        getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
        objectCount={citiesCount}
        objects={(cities || []).map(u => ({
          ...u
        }))}
        columns={columns}
        loader={loader}
        onSortModelChange={(a, b) => {
          let a_0_field:string = a[0].field;
          setOrder([a_0_field, a[0].sort].join(' '));
        }}
        page={page}
        setPage={(p) => {setPage(p);}}
      />
    </Page>
  );
}
