import React, { FunctionComponent, useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

import { fetch_one, fetch_all } from '../../lib/server_helper';
import { checkAllowed } from '../../lib/permission';

import { usePDF } from '@react-pdf/renderer';
import { Bar, BarChart, CartesianGrid, Dot, ReferenceArea, ReferenceLine, Scatter, ScatterChart, XAxis, YAxis } from 'recharts';

import {
  Grid,
  Paper
} from '@mui/material';

import { Cell, Tooltip, ResponsiveContainer } from 'recharts';
import { TooltipProps } from 'recharts';
import {
  ValueType,
  NameType,
} from 'recharts/types/component/DefaultTooltipContent';

import { loginAtom } from '../../lib/auth';

// import { AlertCheckInputs } from '../../components/alerts/CheckInputs';
// import { AlertSaved } from '../../components/alerts/Saved';
// import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import {
  Page,
  StyledSelect,
  Typography
} from '../../components/interface';

import MyDocument from '../pdf/CustomReportBc';

import { ReportVersion } from '../../models/ReportVersion';

// const { DateTime } = require("luxon");

// type AvailableLang = 'nl' | 'fr' | 'en';
type Props = {};

export const AdminReportVersionVisualizer: FunctionComponent<Props> = () => {
  // const navigate = useNavigate();
  // const theme = useTheme();
  const {t} = useTranslation(['translations']);

  const params = useParams();

  const colours = ["rgb(56, 51, 107, 1)", "rgb(66, 135, 245, 1)", "rgb(203, 43, 224, 1)", "rgb(222, 205, 55, 1)", "rgb(135, 68, 73, 1)", "rgb(46, 87, 42, 1)"];

  const [login, setLogin] = useAtom(loginAtom);
  const [reportVersion, setReportVersion] = useState<ReportVersion>();
  const [loader, setLoader] = useState<boolean>(true);
  const [projectId, setProjectId] = useState<string | undefined>(params.id);
  const [activeLocation, setActiveLocation] = useState<string>('');
  const [locData, setLocData] = useState<{
    id: number|undefined;
    name: {[k: string]: string};
    user_in_ids: number[];
    user_ex_ids: number[];
    user_in_count: number;
    user_ex_count: number;
    questions: {
      [s:string]: {
        internal: {
          detractors: number;
          promotors: number;
          score: number;
          locations: number;
        },
        external: {
          detractors: number;
          promotors: number;
          score: number;
          locations: number;
        },
        weight: number;
      }
    },
    totals: {
      [s:string]: {
        internal: number;
        external: number;
        locations_in: number;
        locations_ex: number;
      }
    },
    nps_in: {
      detractors: number;
      promotors: number;
      score: number;
    },
    nps_ex: {
      detractors: number;
      promotors: number;
      score: number;
    }
  }>();
  const [instance, updateInstance] = usePDF({
    document: (<MyDocument
      locData={locData}
    />),
  });

  const fetchCalculation = () => {
    if (checkAllowed(login, null, projectId?.toString() || '0', "ProjectExport", 'r', true)) {
      setLoader(true);
      
      fetch_one<ReportVersion>('/nl/v3/objects/fetch_all', {
        object: 'report_version',
        fields: ['id', 'extra_data', 'name'],
        id: projectId
      }, (ob) => {
        setReportVersion(ob);
        setLoader(false);
      }, () => {
        // setServerErrorSnackOpen(true);
      }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
    } else {
      setLoader(false);
    }
  };

  

  useEffect(() => {
    fetchCalculation();
  }, []);
  useEffect(() => {
    let dimension = Object.values((reportVersion?.extra_data?.results?.dimensions || {})).filter(fff => (fff.id?.toString() || '0') === activeLocation)[0];

    if (!!dimension) {
      // console.log(dimension);
      setLocData(dimension);
    }
  }, [activeLocation]);
  useEffect( () => {
    // console.log(locData);
    updateInstance(<MyDocument
      locData={locData}
      allData={reportVersion}
    />)
  }, [locData]);

  const CustomTooltip = ({
    active,
    payload,
    label,
}: TooltipProps<ValueType, NameType>) => {
    if (active) {
      return (
        <Paper sx={{padding: 2}}>
          <Typography sx={{fontWeight: 'bold'}}>{(((payload || [])[0] || {})['payload'] || {})['name']}</Typography>
          {(payload || []).map(mm => <Typography>{mm.name}: {mm.value}</Typography>)}
        </Paper>
      );
    }

    return null;
};

  return (
    <Page sx={{}} title={t("objects.report_version.visualizer.title")}>
      <Grid container spacing="8">
        {/* <Grid item xs={12}>
          {!!reportVersion?.extra_data?.results && JSON.stringify(reportVersion?.extra_data?.results)}
        </Grid> */}

        <Grid item xs={6}>
          <StyledSelect
            label="Location"
            value={activeLocation}
            id='location'
            onChange={(v) => {
              setActiveLocation(v);
            }}
            list={[
              {id: '', name: "--- ---"},
              ...(Object.values((reportVersion?.extra_data?.results?.dimensions || {})).map(dimension => {return {id: dimension.id?.toString() || '0', name: dimension.name.en || ''}}))
            ]}
          />
        </Grid>
        {!!locData && <Grid item xs={6}>
          {/* <StyledButton
            label="Download report"
            id="download"
            onClick={() => {
              ReactDOM.render(<PDFViewer><MyDocument /></PDFViewer>, document.getElementById('root'));
            }}
          /> */}
          {/* <PDFDownloadLink document={<MyDocument
            locData={locData}
          />} fileName="report.pdf">
            {/* @ts-ignore *}
            {({ loading }) => loading ? 'Loading document...' : 'Download now!'}
          </PDFDownloadLink> */}
          {!!instance.url && <a href={instance.url} download="report.pdf">Download</a>}
        </Grid>}

        {!!reportVersion?.extra_data?.results?.dimensions && Object.values(reportVersion?.extra_data?.results.dimensions).map(dimension => <>
          {!!dimension.id && dimension.id.toString() === activeLocation && <>
            <Grid item xs={4} sx={{backgroundColor: 'rgba(0, 0, 0, 0.1)'}}>
              <Typography>{dimension.name.en}</Typography>
            </Grid>
            <Grid item xs={3} sx={{backgroundColor: 'rgba(0, 0, 0, 0.1)'}}>
              <Typography>Ouders</Typography>
            </Grid>
            <Grid item xs={3} sx={{backgroundColor: 'rgba(0, 0, 0, 0.1)'}}>
              <Typography>Personeel</Typography>
            </Grid>
            <Grid item xs={2} sx={{backgroundColor: 'rgba(0, 0, 0, 0.1)'}}>
              <Typography>Gewicht</Typography>
            </Grid>

            <Grid item xs={4} sx={{backgroundColor: 'rgba(0, 0, 0, 0.1)'}}>
              <Typography>Participants</Typography>
            </Grid>
            <Grid item xs={3} sx={{backgroundColor: 'rgba(0, 0, 0, 0.1)'}}>
              <Typography>{dimension.user_ex_count}</Typography>
            </Grid>
            <Grid item xs={3} sx={{backgroundColor: 'rgba(0, 0, 0, 0.1)'}}>
              <Typography>{dimension.user_in_count}</Typography>
            </Grid>
            <Grid item xs={2} sx={{backgroundColor: 'rgba(0, 0, 0, 0.1)'}}>
              
            </Grid>

            {[
              ['q11', 'q12', 'q13'],
              ['q21', 'q22', 'q23'],
              ['q31', 'q32', 'q33', 'q34'],
              ['q41', 'q42'],
              ['q51', 'q52', 'q53'],
              ['q61', 'q62', 'q63']
            ].map((dim_questions, i) => {
              return (<>
                {dim_questions.map(q => <>
                  <Grid item xs={4}>
                    <Typography>{q.toUpperCase()}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>{dimension.questions[q].external.score}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>{dimension.questions[q].internal.score}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography>{dimension.questions[q].weight}%</Typography>
                  </Grid>
                </>)}
                <Grid item xs={4} sx={{backgroundColor: 'rgba(0, 0, 0, 0.08)'}}>
                  {/* @ts-ignore */}
                  <Typography>{((reportVersion.extra_data || {})[`dimension${i + 1}`] || {})['nl']}</Typography>
                </Grid>
                <Grid item xs={3} sx={{backgroundColor: 'rgba(0, 0, 0, 0.08)'}}>
                  <Typography>{dimension.totals[`dim${i + 1}`].external}</Typography>
                </Grid>
                <Grid item xs={3} sx={{backgroundColor: 'rgba(0, 0, 0, 0.08)'}}>
                  <Typography>{dimension.totals[`dim${i + 1}`].internal}</Typography>
                </Grid>
                <Grid item xs={2} sx={{backgroundColor: 'rgba(0, 0, 0, 0.08)'}}></Grid>
              </>);
            })}

            <Grid item xs={4} sx={{backgroundColor: 'rgba(0, 0, 0, 0.1)'}}>
              <Typography>NPS</Typography>
            </Grid>
            <Grid item xs={3} sx={{backgroundColor: 'rgba(0, 0, 0, 0.1)'}}>
              <Typography>{dimension.nps_ex.score * 100}</Typography>
            </Grid>
            <Grid item xs={3} sx={{backgroundColor: 'rgba(0, 0, 0, 0.1)'}}>
              <Typography>{dimension.nps_in.score * 100}</Typography>
            </Grid>
            <Grid item xs={2} sx={{backgroundColor: 'rgba(0, 0, 0, 0.1)'}}>
              
            </Grid>

            <Grid item xs={12}>&nbsp;</Grid>



            
            {!!locData && ['dim1', 'dim2', 'dim3', 'dim4', 'dim5', 'dim6'].map((dimKey, i) => <Grid item xs={4}>
              {/* @ts-ignore */}
              <Typography sx={{textAlign: 'center'}}>{((reportVersion.extra_data || {})[`dimension${i + 1}`] || {})['nl']}</Typography>
              {!!locData.totals[dimKey] && <ResponsiveContainer width="100%" height={200}>
                <BarChart data={[
                  {
                    name: "Pers.",
                    score: [0, locData.totals[dimKey].internal],
                  },
                  {
                    name: "Ouders",
                    score: [0, locData.totals[dimKey].external]
                  }
                ]} layout="vertical" margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20
                }}>
                  <XAxis type="number" domain={[0, 5]} ticks={[0, 1, 2, 3, 4, 5]} />
                  <YAxis dataKey="name" type="category" />
                  <Bar dataKey="score">
                    {[
                      {
                        name: "Pers.",
                        score: [0, locData.totals[dimKey].internal],
                      },
                      {
                        name: "Ouders",
                        score: [0, locData.totals[dimKey].external]
                      }
                    ].map(bar => <Cell fill={(bar.score[1] > 2.5 ? '#089c19' : '#eb4034')} />)}
                  </Bar>
                  <CartesianGrid strokeDasharray="3 3" />
                </BarChart>
              </ResponsiveContainer>}
            </Grid>)}

            <Grid item xs={12}>&nbsp;</Grid>



            
            {!!locData && <Grid item xs={12}>
              <ResponsiveContainer width="100%" height={600}>
                <ScatterChart
                  margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20
                  }}
                >
                  <CartesianGrid />
                  <ReferenceArea x1={0} x2={2.5} y1={2.5} y2={0} fill="red" fillOpacity={0.3} />
                  <ReferenceArea x1={2.5} x2={5} y1={2.5} y2={5} fill="green" fillOpacity={0.3} />
                  <ReferenceArea x1={0} x2={2.5} y1={5} y2={2.5} fill="orange" fillOpacity={0.2} />
                  <ReferenceArea x1={2.5} x2={5} y1={2.5} y2={0} fill="orange" fillOpacity={0.2} />

                  <XAxis type="number" dataKey="external" label="&nbsp;     &nbsp;      &nbsp;     &nbsp;       &nbsp;   &nbsp;       &nbsp;      &nbsp;      &nbsp;      &nbsp;      Ouders" domain={[0, 5]} ticks={[0, 2.5, 5]} />
                  <YAxis type="number" dataKey="internal" label="Pers.  &nbsp; &nbsp;   &nbsp; &nbsp;   &nbsp;" domain={[0, 5]} ticks={[0, 2.5, 5]} />
                  <Tooltip content={<CustomTooltip />} />

                  <Scatter data={['dim1', 'dim2', 'dim3', 'dim4', 'dim5', 'dim6'].map((dimKey, i) => {return !!locData.totals[dimKey] ? {
                    // @ts-ignore
                    name: ((reportVersion.extra_data || {})[`dimension${i + 1}`] || {})['nl'],
                    internal: locData.totals[dimKey].internal,
                    external: locData.totals[dimKey].external
                  } : {name: '', internal: '', external: ''};})} fill="#8884d8" shape={(props:any) => {
                    const {cx, cy, fill, name} = props;
                    return (
                      <g>
                        <Dot cx={cx} cy={cy} r={5} fill={fill} />
                        <g transform={`translate(${cx},${cy})`}>
                          <text x={10} y={0} dy={4} textAnchor="left">{name}</text>
                        </g>
                      </g>
                     );
                  }} />

                  <ReferenceLine y={0} stroke="#000000" />
                  <ReferenceLine x={0} stroke="#000000" />
                </ScatterChart>
              </ResponsiveContainer>
            </Grid>}
          </>}






          {!dimension.id && '0' === activeLocation && <>
            <Grid item xs={4} sx={{backgroundColor: 'rgba(0, 0, 0, 0.1)'}}>
              <Typography>{dimension.name.en}</Typography>
            </Grid>
            <Grid item xs={3} sx={{backgroundColor: 'rgba(0, 0, 0, 0.1)'}}>
              <Typography>Ouders</Typography>
            </Grid>
            <Grid item xs={3} sx={{backgroundColor: 'rgba(0, 0, 0, 0.1)'}}>
              <Typography>Personeel</Typography>
            </Grid>
            <Grid item xs={2} sx={{backgroundColor: 'rgba(0, 0, 0, 0.1)'}}>
              <Typography>Gewicht</Typography>
            </Grid>

            <Grid item xs={4} sx={{backgroundColor: 'rgba(0, 0, 0, 0.1)'}}>
              <Typography>Participants</Typography>
            </Grid>
            <Grid item xs={3} sx={{backgroundColor: 'rgba(0, 0, 0, 0.1)'}}>
              <Typography>{dimension.user_ex_count}</Typography>
            </Grid>
            <Grid item xs={3} sx={{backgroundColor: 'rgba(0, 0, 0, 0.1)'}}>
              <Typography>{dimension.user_in_count}</Typography>
            </Grid>
            <Grid item xs={2} sx={{backgroundColor: 'rgba(0, 0, 0, 0.1)'}}>
              
            </Grid>

            {[
              ['q11', 'q12', 'q13'],
              ['q21', 'q22', 'q23'],
              ['q31', 'q32', 'q33', 'q34'],
              ['q41', 'q42'],
              ['q51', 'q52', 'q53'],
              ['q61', 'q62', 'q63']
            ].map((dim_questions, i) => {
              return (<>
                {dim_questions.map(q => <>
                  <Grid item xs={4}>
                    <Typography>{q.toUpperCase()}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>{dimension.questions[q].external.score / dimension.questions[q].external.locations}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>{dimension.questions[q].internal.score / dimension.questions[q].internal.locations}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography>{dimension.questions[q].weight}%</Typography>
                  </Grid>
                </>)}
                <Grid item xs={4} sx={{backgroundColor: 'rgba(0, 0, 0, 0.08)'}}>
                  {/* @ts-ignore */}
                  <Typography>{((reportVersion.extra_data || {})[`dimension${i + 1}`] || {})['nl']}</Typography>
                </Grid>
                <Grid item xs={3} sx={{backgroundColor: 'rgba(0, 0, 0, 0.08)'}}>
                  <Typography>{(dimension.totals[`dim${i + 1}`].external / dimension.totals[`dim${i + 1}`].locations_ex).toFixed(2)} (loc: {dimension.totals[`dim${i + 1}`].locations_ex})</Typography>
                </Grid>
                <Grid item xs={3} sx={{backgroundColor: 'rgba(0, 0, 0, 0.08)'}}>
                  <Typography>{(dimension.totals[`dim${i + 1}`].internal / dimension.totals[`dim${i + 1}`].locations_in).toFixed(2)} (loc: {dimension.totals[`dim${i + 1}`].locations_in})</Typography>
                </Grid>
                <Grid item xs={2} sx={{backgroundColor: 'rgba(0, 0, 0, 0.08)'}}></Grid>
              </>);
            })}

            <Grid item xs={4} sx={{backgroundColor: 'rgba(0, 0, 0, 0.1)'}}>
              <Typography>NPS (total)</Typography>
            </Grid>
            <Grid item xs={3} sx={{backgroundColor: 'rgba(0, 0, 0, 0.1)'}}>
              <Typography>{dimension.nps_ex.score * 100}%</Typography>
            </Grid>
            <Grid item xs={3} sx={{backgroundColor: 'rgba(0, 0, 0, 0.1)'}}>
              <Typography>{dimension.nps_in.score * 100}%</Typography>
            </Grid>
            <Grid item xs={2} sx={{backgroundColor: 'rgba(0, 0, 0, 0.1)'}}>
              
            </Grid>

            {(Object.values((reportVersion?.extra_data?.results?.dimensions || {})).map((dimension, i) => <>{(dimension.name.en || '') !== "Total" && <>
              <Grid item xs={4} sx={{backgroundColor: 'rgba(0, 0, 0, 0.1)'}}>
                <Typography>NPS ({dimension.name.en})</Typography>
              </Grid>
              <Grid item xs={3} sx={{backgroundColor: 'rgba(0, 0, 0, 0.1)'}}>
                <Typography>{dimension.nps_ex.score * 100}%</Typography>
              </Grid>
              <Grid item xs={3} sx={{backgroundColor: 'rgba(0, 0, 0, 0.1)'}}>
                <Typography>{dimension.nps_in.score * 100}%</Typography>
              </Grid>
              <Grid item xs={2} sx={{backgroundColor: 'rgba(0, 0, 0, 0.1)'}}>
                
              </Grid>
            </>}</>))}

            <Grid item xs={12}>&nbsp;</Grid>



                        
            {!!locData && ['dim1', 'dim2', 'dim3', 'dim4', 'dim5', 'dim6'].map((dimKey, i) => <Grid item xs={4}>
              {/* @ts-ignore */}
              <Typography sx={{textAlign: 'center'}}>{((reportVersion.extra_data || {})[`dimension${i + 1}`] || {})['nl']}</Typography>
              {!!locData.totals[dimKey] && <ResponsiveContainer width="100%" height={300}>
                <BarChart data={[
                  {
                    name: "Pers.",
                    score: [0, parseFloat((locData.totals[dimKey].internal / locData.totals[dimKey].locations_in).toFixed(2))],
                    ...Object.values((reportVersion?.extra_data?.results?.dimensions || {})).map(dimension => {return {[dimension.name.en || '']: [0, dimension.totals[dimKey].internal]};}).filter(fff => Object.keys(fff)[0] !== "Total").reduce((prev, curr) => {
                      return {
                          ...prev,
                          ...curr
                      };
                    })
                  },
                  {
                    name: "Ouders",
                    score: [0, parseFloat((locData.totals[dimKey].external / locData.totals[dimKey].locations_ex).toFixed(2))],
                    ...Object.values((reportVersion?.extra_data?.results?.dimensions || {})).map(dimension => {return {[dimension.name.en || '']: [0, dimension.totals[dimKey].external]};}).filter(fff => Object.keys(fff)[0] !== "Total").reduce((prev, curr) => {
                      return {
                          ...prev,
                          ...curr
                      };
                    })
                  }
                ]} layout="vertical" margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20
                }}>
                  <XAxis type="number" domain={[0, 5]} ticks={[0, 1, 2, 3, 4, 5]} />
                  <YAxis dataKey="name" type="category" />
                  <Tooltip />
                  <Bar dataKey="score">
                    {[
                      {
                        name: "Pers.",
                        score: [0, parseFloat((locData.totals[dimKey].internal / locData.totals[dimKey].locations_in).toFixed(2))],
                      },
                      {
                        name: "Ouders",
                        score: [0, parseFloat((locData.totals[dimKey].external / locData.totals[dimKey].locations_ex).toFixed(2))]
                      }
                    ].map(bar => <Cell fill={(bar.score[1] > 2.5 ? '#089c19' : '#eb4034')} />)}
                  </Bar>
                  {(Object.values((reportVersion?.extra_data?.results?.dimensions || {})).filter(fff => (fff.name.en || '') !== "Total").map((dimension, i) => <Bar dataKey={dimension.name.en || ''} fill={colours[i]} />))}

                  <CartesianGrid strokeDasharray="3 3" />
                </BarChart>
              </ResponsiveContainer>}
            </Grid>)}

            <Grid item xs={12}>&nbsp;</Grid>




            {!!locData && <Grid item xs={12}>
              <ResponsiveContainer width="100%" height={600}>
                <ScatterChart
                  margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20
                  }}
                >
                  <CartesianGrid />
                  <ReferenceArea x1={0} x2={2.5} y1={2.5} y2={0} fill="red" fillOpacity={0.3} />
                  <ReferenceArea x1={2.5} x2={5} y1={2.5} y2={5} fill="green" fillOpacity={0.3} />
                  <ReferenceArea x1={0} x2={2.5} y1={5} y2={2.5} fill="orange" fillOpacity={0.2} />
                  <ReferenceArea x1={2.5} x2={5} y1={2.5} y2={0} fill="orange" fillOpacity={0.2} />

                  <XAxis type="number" dataKey="external" label="&nbsp;     &nbsp;      &nbsp;     &nbsp;       &nbsp;   &nbsp;       &nbsp;      &nbsp;      &nbsp;      &nbsp;      Ouders" domain={[0, 5]} ticks={[0, 2.5, 5]} />
                  <YAxis type="number" dataKey="internal" label="Pers.  &nbsp; &nbsp;   &nbsp; &nbsp;   &nbsp;" domain={[0, 5]} ticks={[0, 2.5, 5]} />
                  <Tooltip content={<CustomTooltip />} />

                  <Scatter data={[
                    ...['dim1', 'dim2', 'dim3', 'dim4', 'dim5', 'dim6'].map((dimKey, i) => {return !!locData.totals[dimKey] ? {
                      // @ts-ignore
                      name: `${((reportVersion.extra_data || {})[`dimension${i + 1}`] || {})['nl'][0]}${i+1}`,
                      colour: "#8884d8",
                      internal: parseFloat((locData.totals[dimKey].internal / locData.totals[dimKey].locations_in).toFixed(2)),
                      external: parseFloat((locData.totals[dimKey].external / locData.totals[dimKey].locations_ex).toFixed(2))
                    } : {name: '', colour: '', internal: '', external: ''};}),
                    ...(Object.values((reportVersion?.extra_data?.results?.dimensions || {})).filter(fff => (fff.name.en || '') !== "Total").map((dimension, iii) => {
                      let res:{name: string, colour: string, internal: number, external: number}[] = [];
                      ['dim1', 'dim2', 'dim3', 'dim4', 'dim5', 'dim6'].forEach((dimKey, i) => {
                        if (!!dimension.totals[dimKey] && (dimension.totals[dimKey].internal + dimension.totals[dimKey].external) > 0) {
                          res.push({
                            // @ts-ignore
                            name: `${((reportVersion.extra_data || {})[`dimension${i + 1}`] || {})['nl'][0]}${i+1}`,
                            colour: colours[iii],
                            internal: dimension.totals[dimKey].internal,
                            external: dimension.totals[dimKey].external
                          });
                        }
                      });
                      return res;
                    }).reduce((prev, curr) => {
                      return [
                          ...prev,
                          ...curr
                      ];
                    }))
                  ]} fill="#8884d8" shape={(props:any) => {
                    const {cx, cy, fill, name} = props;
                    return (
                      <g>
                        <Dot cx={cx} cy={cy} r={5} fill={props.payload.colour} />
                        <g transform={`translate(${cx},${cy})`}>
                          <text x={10} y={0} dy={4} textAnchor="left">{name}</text>
                        </g>
                      </g>
                    );
                  }} />

                  <ReferenceLine y={0} stroke="#000000" />
                  <ReferenceLine x={0} stroke="#000000" />
                </ScatterChart>
              </ResponsiveContainer>
            </Grid>}
          </>}
        </>)}
      </Grid>
    </Page>
  );
}
