import React, { FunctionComponent } from 'react';

import { EmailBlock } from '../../../models/EmailBlock';

type BlockPublicBaseImageProps = {
  block: EmailBlock;
  activeLocale: 'nl' | 'fr';
};

// function isBenefitBlock(block: EmailBlock | BenefitBlock): block is BenefitBlock {
//   return true
// }

export const BlockPublicBaseImage: FunctionComponent<BlockPublicBaseImageProps> = ({activeLocale, block}) => {
  let item = block.item;
  let item_url = block.set_item_url;
  let get_item = block.get_item;

  return (<>
    {!!item && <img style={{width: '100%', height: 'auto', marginTop: '10px', marginBottom: '10px'}} width="600" src={URL.createObjectURL(item)}></img>}
    {!item && !!item_url && <img style={{width: '100%', height: 'auto', marginTop: '10px', marginBottom: '10px'}} width="600" src={item_url}></img>}
    {!item  && !item_url && !!get_item && <img style={{width: '100%', height: 'auto', marginTop: '10px', marginBottom: '10px'}} width="600" src={get_item}></img>}
  </>);
};
