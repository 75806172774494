import React, { ChangeEvent, FunctionComponent } from 'react';
import InputMask from 'react-input-mask';
// import { useTheme } from '@mui/material/styles';

import {
  InputAdornment,
  TextField
} from '@mui/material';

import ErrorIcon from '@mui/icons-material/Error';

var he = require('he');

type VennNrProps = {
  sx?: object;
  label: string;
  value: string;
  error?: boolean;
  id: string;
  type?: string;
  onChange: (z:string|ChangeEvent<HTMLInputElement>) => void;
  setEnterPressed?: (v:boolean) => void;
};

export const VennNr: FunctionComponent<VennNrProps> = ({label, onChange, sx, error, value, id, type, setEnterPressed}) => {
  // const theme = useTheme();

  return (
    <InputMask
      mask={[/[AB]/i, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, "-", /[0-9]/, /[0-9]/]}
      placeholder=""
      value={value}
      onChange={(v) => {onChange(v.target.value);}}
      onKeyDown={(e) => {
        if (e.key === 'Enter' && !!setEnterPressed) {
          setEnterPressed(true);
        }
      }}
    >
      <TextField
        // value={value}
        id={id}
        type={type}
        variant="outlined"
        label={he.decode(label)}
        // onChange={(e) => {onChange(e.target.value)}}
        sx={{
          width: 'auto',
          padding: 1,
          'input': {
            padding: 1
          },
          'label[data-shrink=true]': {
            left: 8,
            top: 8
          },
          'label[data-shrink=false]': {
            opacity: 0.4
          },
          ...sx
        }}
        InputProps={{
          endAdornment: !!error ?(<InputAdornment position="end">
            <ErrorIcon style={{color: '#d32f2f'}} />
          </InputAdornment>) : null
        }}
      />
    </InputMask>
  );
}