import { FunctionComponent, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom, useAtomValue } from 'jotai';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { fetch_one } from '../../lib/server_helper';
import { fetchPage } from '../../lib/page';
import { loginAtom, renew_sign_in } from '../../lib/auth';
import { currentProjectAtom } from '../../lib/project';

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  RedditIcon,
  TelegramIcon,
  WhatsappIcon,
  XIcon,
} from "react-share";

import {
  Box,
  Divider,
  Grid
} from '@mui/material';

import {
  Typography,
  StyledButton,
  StyledSwitch
} from '../../components/interface';

import { AlertSaved } from '../../components/alerts/Saved';

import { Page } from '../../models/Page';
import { User } from '../../models/User';

type Props = {};

export const PubAfterPoll: FunctionComponent<Props> = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const [login, setLogin] = useAtom(loginAtom);
  const currentProject = useAtomValue(currentProjectAtom);
  const [currentPage, setCurrentPage] = useState<Page|null>(null);

  const fields = ['id', 'is_mail_invitation', 'is_mail_keep_posted'];

  const [currentUser, setCurrentUser] = useState<User|null>(null);
  const [userSavedSnackOpen, setUserSavedSnackOpen] = useState<boolean>(false);

  const reloadUser = () => {
    if (!!login) {
      fetch_one<User>('/nl/v3/objects/fetch_all', {
        object: 'user',
        fields: fields,
        id: login.id
      }, (ob, complete_data) => {
        setCurrentUser(ob);
        // renew_sign_in((x) => {
        //   setLogin(x);
        //   console.log('renew_sign_in from profile');
        // });
      }, (z) => {
        
      }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
    }
  };

  const saveUser = () => {
    let formData = new FormData();
    formData.append('object', 'user');
    formData.append('handler_id', (login?.id || "").toString());
    fields.map(field => {
      formData.append('fields[]', field);
    });
    formData.append('ob[id]', (login?.id || "").toString());
    formData.append('id', (login?.id || "").toString());
    
    formData.append(`ob[is_mail_invitation]`, (currentUser?.is_mail_invitation ? '1' : '0'));
    formData.append(`ob[is_mail_keep_posted]`, (currentUser?.is_mail_keep_posted ? '1' : '0'));

    fetch_one<User>('/nl/v3/objects/save', formData, (ob, complete_data) => {
      reloadUser();
      setUserSavedSnackOpen(true);
      navigate("/success/saved");
    }, (z) => {
      reloadUser();
      // setUserSavedSnackOpen(true);
    }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
  };

  useEffect(() => {
    reloadUser();
  }, [login]);
  
  useEffect(() => {
    fetchPage('poll_outro', (ob) => {setCurrentPage(ob);}, login, 'poll_outro');
  }, [currentProject]);

  const fb_lang = currentProject?.fallback_language|| 'nl';
  const lang = i18n.resolvedLanguage || fb_lang;

  return (<>
    <Grid container spacing="8">
      {!!currentPage && !!currentPage.body && <Grid item xs={12} sx={{textAlign: 'center', marginBottom: 2}}>
        <Typography html>{currentPage.body[lang] || currentPage.body[fb_lang]}</Typography>
      </Grid>}

      {currentProject?.extra_data?.hide_afterpoll_share !== '1' && <Grid item xs={12} md={6}>
        <Box sx={{
          border: '1px solid transparent',
          borderColor: theme.palette.primary.main,
          borderRadius: 4,
          padding: 1
        }}>
          <Grid container item xs={12} spacing="8">
            <Grid item xs={12} sx={{textAlign: 'center', marginBottom: 1}}>
              <Typography variant="h3" sx={{textDecoration: 'underline'}}>{t("public.poll_outro.share_title")}</Typography>
            </Grid>
            <Grid item xs={12} sx={{textAlign: 'center', "& button:hover": {opacity: 0.75}, "& button": {margin: 0.5}}}>
              <EmailShareButton
                url={document.location.origin}
                subject="s"
                body="b"
              ><EmailIcon size={32} round={true} /></EmailShareButton>

              <FacebookShareButton
                url={document.location.origin}
              ><FacebookIcon size={32} round={true} /></FacebookShareButton>

              <LinkedinShareButton
                url={document.location.origin}
                title={currentProject?.name}
              ><LinkedinIcon size={32} round={true} /></LinkedinShareButton>

              <RedditShareButton
                url={document.location.origin}
                title={currentProject?.name}
              ><RedditIcon size={32} round={true} /></RedditShareButton>

              <TelegramShareButton
                url={document.location.origin}
                title={currentProject?.name}
              ><TelegramIcon size={32} round={true} /></TelegramShareButton>

              <TwitterShareButton
                url={document.location.origin}
                title={currentProject?.name}
              ><XIcon size={32} round={true} /></TwitterShareButton>

              <WhatsappShareButton
                url={document.location.origin}
                title={currentProject?.name}
              ><WhatsappIcon size={32} round={true} /></WhatsappShareButton>
            </Grid>
            {/* <Grid item xs={12} sx={{textAlign: 'center', marginBottom: 1}}>
              <Divider />
            </Grid>
            <Grid item xs={12} sx={{textAlign: 'center', marginBottom: 1}}>
              <Typography variant="h3" sx={{textDecoration: 'underline'}}>{t("public.poll_outro.refer_title")}</Typography>
            </Grid>
            <Grid item xs={12} sx={{textAlign: 'center', marginBottom: 1}}>
              <Typography sx={{fontStyle: 'italic', fontSize: '0.9rem'}}>Laat vrienden en familie registreren met je referral-code,<br/>en maak kans op a en b en c...</Typography>
            </Grid> */}
          </Grid>
        </Box>
      </Grid>}

      <Grid item xs={12} md={6}>
        <Box sx={{
          border: '1px solid transparent',
          borderColor: theme.palette.primary.main,
          borderRadius: 4,
          padding: 1
        }}>
          <Grid container item xs={12} spacing="8">
            <Grid item xs={12} sx={{textAlign: 'center', marginBottom: 1}}>
              <Typography variant="h3" sx={{textDecoration: 'underline'}}>{t("public.poll_outro.title")}</Typography>
            </Grid>
            <Grid item xs={12} sx={{textAlign: 'center', marginBottom: 2}}>
              <Typography sx={{fontStyle: 'italic', fontSize: '0.9rem'}}>{t("public.poll_outro.explanation")}</Typography>
            </Grid>
            <Grid item xs={12} sx={{textAlign: 'left'}}>
              <StyledSwitch
                label={t("public.profile.fields.mail_preference.invite_for_panel")}
                offLabel={t("public.sendable.do_not_send")}
                onLabel={t("public.sendable.send")}
                value={!!currentUser?.is_mail_invitation}
                onChange={(v) => {if (!!currentUser) setCurrentUser({
                  ...currentUser,
                  is_mail_invitation: !!v});
                }}
              />
            </Grid>
            <Grid item xs={12} sx={{textAlign: 'left'}}>
              <StyledSwitch
                label={t("public.profile.fields.mail_preference.keep_posted")}
                offLabel={t("public.sendable.do_not_send")}
                onLabel={t("public.sendable.send")}
                value={!!currentUser?.is_mail_keep_posted}
                onChange={(v) => {if (!!currentUser) setCurrentUser({
                  ...currentUser,
                  is_mail_keep_posted: !!v});
                }}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{alignContent: 'baseline', textAlign: 'center'}}>
            <Divider sx={{marginTop: 1, marginBottom: 2}} />

            <StyledButton
              label={t("public.buttons.cancel")}
              id='cancel'
              // contained
              onClick={(v) => {
                navigate(`/`);
              }}
              sx={{marginLeft: 1}}
            />
            <StyledButton
              label={t("public.buttons.save")}
              id='save'
              contained
              onClick={(v) => {
                saveUser();
              }}
              sx={{marginLeft: 1}}
            />
          </Grid>
        </Box>
      </Grid>

    </Grid>

    {!!userSavedSnackOpen && <AlertSaved open={userSavedSnackOpen} setOpen={(b) => {setUserSavedSnackOpen(b);}} />}
  </>);
}
