import { FunctionComponent, useState, useEffect } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useAtom, useAtomValue } from 'jotai';
import { useNavigate } from "react-router-dom";
// import { useTranslation } from 'react-i18next'

import do_login, { loginAtom } from '../../lib/auth';
// import { currentProjectAtom } from '../../lib/project';

import {
  Box
} from '@mui/material';

// import {
//   Typography
// } from '../../components/interface';

import { Login } from '../Login';
import { SharedPoll } from '../shared/Poll';

import { Embeddable } from '../../models/Embeddable';

type EmbedAlternateLayoutProps = {
  currentEmbed: Embeddable;
};

export const EmbedAlternateLayout: FunctionComponent<EmbedAlternateLayoutProps> = ({currentEmbed}) => {
  // const theme = useTheme();
  const navigate = useNavigate();
  // const {t, i18n} = useTranslation(['translations']);
  const [login, setLogin] = useAtom(loginAtom);
  // const currentProject = useAtomValue(currentProjectAtom);

  // const fb_lang = currentProject?.fallback_language|| 'nl';
  // const lang = i18n.resolvedLanguage || fb_lang;

  return (<Box sx={{
    display: 'flex',
    flexWrap: 'wrap',
    height: '100%',
    alignContent: 'baseline',
    ...(!!currentEmbed?.extra_data?.background_colour ? {backgroundColor: currentEmbed?.extra_data?.background_colour} : {})
  }}>
    <Box sx={{
      width: '100%',
      padding: 4
    }}>
      {!login && <Login embed_token={currentEmbed.token_key} />}
      {!!login && <SharedPoll notLoggedIn={<></>} endPoll={() => {navigate(`/embed/${currentEmbed.token_key}`);}} />}
    </Box>
  </Box>);
}
