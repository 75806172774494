import React, { FunctionComponent, useEffect, useState } from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

import ReactPDFChart from "react-pdf-charts";
import { Bar, BarChart, CartesianGrid, Cell, Dot, Line, LineChart, ReferenceArea, ReferenceLine, ResponsiveContainer, Scatter, ScatterChart, XAxis, YAxis } from 'recharts';

import { ReportVersion } from '../../models/ReportVersion';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    // backgroundColor: '#E4E4E4',
    padding: 10,
    fontSize: '12px'
  },
  table: {
    width: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 4,
    paddingBottom: 4,
  },
  bordered: {
    borderTop: '1px solid #EEE',
  },
  header: {
    borderTop: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.08)'
  },
  bold: {
    fontWeight: 'bold',
  },
  center: {
    textAlign: 'center',
  },
  title: {
    fontSize: '16px'
  },
  col4: {
    width: '33.333%',
  },
  col3: {
    width: '25%',
  },
  col6: {
    width: '50%',
  },
  col12: {
    width: '100%',
  },
});

// Create Document Component
type Props = {
  allData?: ReportVersion;
  locData?: {
    id: number|undefined;
    name: {[k: string]: string};
    user_in_ids: number[];
    user_ex_ids: number[];
    user_in_count: number;
    user_ex_count: number;
    questions: {
      [s:string]: {
        internal: {
          detractors: number;
          promotors: number;
          score: number;
          locations: number;
        },
        external: {
          detractors: number;
          promotors: number;
          score: number;
          locations: number;
        },
        weight: number;
      }
    },
    totals: {
      [s:string]: {
        internal: number;
        external: number;
        locations_in: number;
        locations_ex: number;
      }
    },
    nps_in: {
      detractors: number;
      promotors: number;
      score: number;
    },
    nps_ex: {
      detractors: number;
      promotors: number;
      score: number;
    }
  };
};

export const MyDocument: FunctionComponent<Props> = ({locData, allData}) => (
  <Document>
    <Page size="A4" style={styles.page} dpi={300}>
      <View style={styles.table}>
        <View style={[styles.row, styles.bold, styles.center, styles.title]}>
          <Text style={styles.col12}>
            {locData && locData.name.nl}
          </Text>
        </View>
        <View style={[styles.row]}>
          <Text style={styles.col12}>
            Automatisch rapport. Als er geen volgende pagina's zijn, dan is er een data-fout. Contacteer IT indien dit voorkomt.
          </Text>
        </View>
      </View>
    </Page>

    {!!locData?.id && <Page size="A4" style={styles.page} dpi={300}>
      <View style={styles.table}>
        <View style={[styles.row, styles.bold, styles.header, styles.bordered]}>
          <Text style={styles.col3}>
            {locData.name.nl}
          </Text>
          <Text style={styles.col3}>
            Ouders
          </Text>
          <Text style={styles.col3}>
            Personeel
          </Text>
          <Text style={styles.col3}>
            Gewicht
          </Text>
        </View>
        <View style={[styles.row, styles.bold, styles.header, styles.bordered]}>
          <Text style={styles.col3}>
            Participants
          </Text>
          <Text style={styles.col3}>
            {locData.user_ex_count}
          </Text>
          <Text style={styles.col3}>
            {locData.user_in_count}
          </Text>
          <Text style={styles.col3}></Text>
        </View>

        {
          [
            {dimName: allData?.extra_data?.dimension1?.nl, dimKey: 'dim1', questions: ['q11', 'q12', 'q13']},
            {dimName: allData?.extra_data?.dimension2?.nl, dimKey: 'dim2', questions: ['q21', 'q22', 'q23']},
            {dimName: allData?.extra_data?.dimension3?.nl, dimKey: 'dim3', questions: ['q31', 'q32', 'q33', 'q34']},
            {dimName: allData?.extra_data?.dimension4?.nl, dimKey: 'dim4', questions: ['q41', 'q42']},
            {dimName: allData?.extra_data?.dimension5?.nl, dimKey: 'dim5', questions: ['q51', 'q52', 'q53']},
            {dimName: allData?.extra_data?.dimension6?.nl, dimKey: 'dim6', questions: ['q61', 'q62', 'q63']}
          ].map(row => {
            let dimName = row.dimName;
            let dimKey = row.dimKey;
            let questions = row.questions;

            return <>
              {questions.map(q => <>
                {!!locData.questions[q] && <View style={[styles.row, styles.bordered]}>
                  <Text style={styles.col3}>
                    {q.toUpperCase()}
                  </Text>
                  <Text style={styles.col3}>
                    {locData.questions[q].external.score}
                  </Text>
                  <Text style={styles.col3}>
                    {locData.questions[q].internal.score}
                  </Text>
                  <Text style={styles.col3}>
                    {locData.questions[q].weight}
                  </Text>
                </View>}
              </>)}
              {!!locData.totals[dimKey] && <View style={[styles.row, styles.bordered, styles.header]}>
                <Text style={styles.col3}>
                  {dimName}
                </Text>
                <Text style={styles.col3}>
                  {locData.totals[dimKey].external}
                </Text>
                <Text style={styles.col3}>
                  {locData.totals[dimKey].internal}
                </Text>
                <Text style={styles.col3}></Text>
              </View>}
            </>;
          })
        }
        <View style={[styles.row, styles.bordered, styles.header]}>
          <Text style={styles.col3}>
            NPS
          </Text>
          <Text style={styles.col3}>
            {locData.nps_ex.score * 100}%
          </Text>
          <Text style={styles.col3}>
            {locData.nps_in.score * 100}%
          </Text>
          <Text style={styles.col3}></Text>
        </View>
      </View>
    </Page>}
    {!!locData && !locData?.id && <Page size="A4" style={styles.page} dpi={300}>
      <View style={styles.table}>
        <View style={[styles.row, styles.bold, styles.header, styles.bordered]}>
          <Text style={styles.col3}>
            {locData.name.nl}
          </Text>
          <Text style={styles.col3}>
            Ouders
          </Text>
          <Text style={styles.col3}>
            Personeel
          </Text>
          <Text style={styles.col3}>
            Gewicht
          </Text>
        </View>
        <View style={[styles.row, styles.bold, styles.header, styles.bordered]}>
          <Text style={styles.col3}>
            Participants
          </Text>
          <Text style={styles.col3}>
            {locData.user_ex_count}
          </Text>
          <Text style={styles.col3}>
            {locData.user_in_count}
          </Text>
          <Text style={styles.col3}></Text>
        </View>

        {
          [
            {dimName: allData?.extra_data?.dimension1?.nl, dimKey: 'dim1', questions: ['q11', 'q12', 'q13']},
            {dimName: allData?.extra_data?.dimension2?.nl, dimKey: 'dim2', questions: ['q21', 'q22', 'q23']},
            {dimName: allData?.extra_data?.dimension3?.nl, dimKey: 'dim3', questions: ['q31', 'q32', 'q33', 'q34']},
            {dimName: allData?.extra_data?.dimension4?.nl, dimKey: 'dim4', questions: ['q41', 'q42']},
            {dimName: allData?.extra_data?.dimension5?.nl, dimKey: 'dim5', questions: ['q51', 'q52', 'q53']},
            {dimName: allData?.extra_data?.dimension6?.nl, dimKey: 'dim6', questions: ['q61', 'q62', 'q63']}
          ].map(row => {
            let dimName = row.dimName;
            let dimKey = row.dimKey;
            let questions = row.questions;

            return <>
              {questions.map(q => <>
                {!!locData.questions[q] && <View style={[styles.row, styles.bordered]}>
                  <Text style={styles.col3}>
                    {q.toUpperCase()}
                  </Text>
                  <Text style={styles.col3}>
                    {(locData.questions[q].external.score / locData.questions[q].external.locations)}
                  </Text>
                  <Text style={styles.col3}>
                    {(locData.questions[q].internal.score / locData.questions[q].internal.locations)}
                  </Text>
                  <Text style={styles.col3}>
                    {locData.questions[q].weight}
                  </Text>
                </View>}
              </>)}
              {!!locData.totals[dimKey] && <View style={[styles.row, styles.bordered, styles.header]}>
                <Text style={styles.col3}>
                  {dimName}
                </Text>
                <Text style={styles.col3}>
                  {(locData.totals[dimKey].external / locData.totals[dimKey].locations_ex).toFixed(2)} (loc: {locData.totals[dimKey].locations_ex})
                </Text>
                <Text style={styles.col3}>
                  {(locData.totals[dimKey].internal / locData.totals[dimKey].locations_in).toFixed(2)} (loc: {locData.totals[dimKey].locations_in})
                </Text>
                <Text style={styles.col3}></Text>
              </View>}
            </>;
          })
        }
        <View style={[styles.row, styles.bordered, styles.header]}>
          <Text style={styles.col3}>
            NPS (Total)
          </Text>
          <Text style={styles.col3}>
            {locData.nps_ex.score * 100}%
          </Text>
          <Text style={styles.col3}>
            {locData.nps_in.score * 100}%
          </Text>
          <Text style={styles.col3}></Text>
        </View>
        {(Object.values((allData?.extra_data?.results?.dimensions || {})).map((dimension, i) => <>{(dimension.name.en || '') !== "Total" && <View style={[styles.row, styles.bordered, styles.header]}>
          <Text style={styles.col3}>
            NPS ({dimension.name.en})
          </Text>
          <Text style={styles.col3}>
            {dimension.nps_ex.score * 100}%
          </Text>
          <Text style={styles.col3}>
            {dimension.nps_in.score * 100}%
          </Text>
          <Text style={styles.col3}></Text>
        </View>}</>))}
      </View>
    </Page>}



    {!!locData?.id && <Page size="A4" style={styles.page} dpi={300}>
      <View style={styles.table}>
        {[
          {[allData?.extra_data?.dimension1?.nl || 'Dimensie 1']: 'dim1', [allData?.extra_data?.dimension2?.nl || "Dimensie 2"]: 'dim2'},
          {[allData?.extra_data?.dimension3?.nl || "Dimensie 3"]: 'dim3', [allData?.extra_data?.dimension4?.nl || "Dimensie 4"]: 'dim4'},
          {[allData?.extra_data?.dimension5?.nl || "Dimensie 5"]: 'dim5', [allData?.extra_data?.dimension6?.nl || "Dimensie 6"]: 'dim6'}
        ].map(dimData => <View style={[styles.row]}>
          {Object.keys(dimData).map(dimName => <View style={[styles.col6]}>
            <Text style={[styles.bold, styles.center]}>{dimName}</Text>
            {/* @ts-ignore */}
            {locData.totals[dimData[dimName]] && <ReactPDFChart>
              <BarChart data={[
                {
                  name: "Personeel",
                  // @ts-ignore
                  score: [0, locData.totals[dimData[dimName]].internal, 0],
                },
                {
                  name: "Ouders",
                  // @ts-ignore
                  score: [0, locData.totals[dimData[dimName]].external, 0],
                }
              ]} height={150} width={240} layout="vertical">
                <XAxis type="number" domain={[0, 5]} ticks={[0, 1, 2, 3, 4, 5]} />
                <YAxis dataKey="name" type="category" />
                <Bar dataKey="score" isAnimationActive={false}>
                  {[
                    {
                      name: "Personeel",
                      // @ts-ignore
                      score: [0, locData.totals[dimData[dimName]].internal, 0],
                    },
                    {
                      name: "Ouders",
                      // @ts-ignore
                      score: [0, locData.totals[dimData[dimName]].external, 0],
                    }
                  ].map(bar => <Cell fill={(bar.score[1] > 2.5 ? '#089c19' : '#eb4034')} />)}
                </Bar>
                <CartesianGrid strokeDasharray="3 3" />
              </BarChart>
            </ReactPDFChart>}
          </View>)}
        </View>)}
      </View>
    </Page>}
    {!!locData && !locData?.id && <Page size="A4" style={styles.page} dpi={300}>
      <View style={styles.table}>
        {[
          {[allData?.extra_data?.dimension1?.nl || 'Dimensie 1']: 'dim1', [allData?.extra_data?.dimension2?.nl || "Dimensie 2"]: 'dim2'},
          {[allData?.extra_data?.dimension3?.nl || "Dimensie 3"]: 'dim3', [allData?.extra_data?.dimension4?.nl || "Dimensie 4"]: 'dim4'},
          {[allData?.extra_data?.dimension5?.nl || "Dimensie 5"]: 'dim5', [allData?.extra_data?.dimension6?.nl || "Dimensie 6"]: 'dim6'}
        ].map(dimData => <View style={[styles.row]}>
          {Object.keys(dimData).map(dimName => <View style={[styles.col6]}>
            <Text style={[styles.bold, styles.center]}>{dimName}</Text>
            {/* @ts-ignore */}
            {locData.totals[dimData[dimName]] && <ReactPDFChart>
              <BarChart data={[
                {
                  name: "Personeel",
                  // @ts-ignore
                  score: [0, parseFloat((locData.totals[dimData[dimName]].internal / locData.totals[dimData[dimName]].locations_in).toFixed(2))],
                  // @ts-ignore
                  ...Object.values((allData?.extra_data?.results?.dimensions || {})).map(dimension => {return {[dimension.name.en || '']: [0, dimension.totals[dimData[dimName]].internal]};}).filter(fff => Object.keys(fff)[0] !== "Total").reduce((prev, curr) => {
                    return {
                        ...prev,
                        ...curr
                    };
                  })
                },
                {
                  name: "Ouders",
                  // @ts-ignore
                  score: [0, parseFloat((locData.totals[dimData[dimName]].external / locData.totals[dimData[dimName]].locations_ex).toFixed(2))],
                  // @ts-ignore
                  ...Object.values((allData?.extra_data?.results?.dimensions || {})).map(dimension => {return {[dimension.name.en || '']: [0, dimension.totals[dimData[dimName]].external]};}).filter(fff => Object.keys(fff)[0] !== "Total").reduce((prev, curr) => {
                    return {
                        ...prev,
                        ...curr
                    };
                  })
                }
              ]} height={150} width={240} layout="vertical">
                <XAxis type="number" domain={[0, 5]} ticks={[0, 1, 2, 3, 4, 5]} />
                <YAxis dataKey="name" type="category" />
                <Bar dataKey="score" isAnimationActive={false}>
                  {[
                    {
                      name: "Personeel",
                      // @ts-ignore
                      score: [0, parseFloat((locData.totals[dimData[dimName]].internal / locData.totals[dimData[dimName]].locations_in).toFixed(2))],
                    },
                    {
                      name: "Ouders",
                      // @ts-ignore
                      score: [0, parseFloat((locData.totals[dimData[dimName]].external / locData.totals[dimData[dimName]].locations_ex).toFixed(2))],
                    }
                  ].map(bar => <Cell fill={(bar.score[1] > 2.5 ? '#089c19' : '#eb4034')} />)}
                </Bar>
                {(Object.values((allData?.extra_data?.results?.dimensions || {})).filter(fff => (fff.name.en || '') !== "Total").map((dimension, i) => <Bar isAnimationActive={false} dataKey={dimension.name.en || ''} fill={["rgb(56, 51, 107, 1)", "rgb(66, 135, 245, 1)", "rgb(203, 43, 224, 1)", "rgb(222, 205, 55, 1)", "rgb(135, 68, 73, 1)", "rgb(46, 87, 42, 1)"][i]} />))}

                <CartesianGrid strokeDasharray="3 3" />
              </BarChart>
            </ReactPDFChart>}
          </View>)}
        </View>)}

        <View style={[styles.row]}>
          {(Object.values((allData?.extra_data?.results?.dimensions || {})).filter(fff => (fff.name.en || '') !== "Total").map((dimension, i) => <View style={[styles.col6]}><View style={{
            width: '10px',
            height: '10px',
            marginRight: '8px',
            backgroundColor: ["rgb(56, 51, 107, 1)", "rgb(66, 135, 245, 1)", "rgb(203, 43, 224, 1)", "rgb(222, 205, 55, 1)", "rgb(135, 68, 73, 1)", "rgb(46, 87, 42, 1)"][i],
            borderRadius: '2px'
          }} /> <Text style={[styles.bold]}>{(dimension.name.en || '')}</Text></View>))}
        </View>
      </View>
    </Page>}




    {!!locData?.id && <Page size="A4" style={styles.page} dpi={300}>
      <View style={styles.table}>
        <View style={styles.row}>
          <View style={styles.col12}>
            <ReactPDFChart>
              <ScatterChart height={300} width={550}>
                <CartesianGrid />
                <ReferenceArea x1={0} x2={2.5} y1={2.5} y2={0} fill="red" fillOpacity={0.3} />
                <ReferenceArea x1={2.5} x2={5} y1={2.5} y2={5} fill="green" fillOpacity={0.3} />
                <ReferenceArea x1={0} x2={2.5} y1={5} y2={2.5} fill="orange" fillOpacity={0.2} />
                <ReferenceArea x1={2.5} x2={5} y1={2.5} y2={0} fill="orange" fillOpacity={0.2} />

                <XAxis type="number" dataKey="external" label="&nbsp;     &nbsp;      &nbsp;     &nbsp;       &nbsp;   &nbsp;       &nbsp;      &nbsp;      &nbsp;      &nbsp;      Ouders" domain={[0, 5]} ticks={[0, 2.5, 5]} />
                <YAxis type="number" dataKey="internal" label="Pers.  &nbsp; &nbsp;   &nbsp; &nbsp;   &nbsp;" domain={[0, 5]} ticks={[0, 2.5, 5]} />

                <Scatter isAnimationActive={false} data={['dim1', 'dim2', 'dim3', 'dim4', 'dim5', 'dim6'].map((dimKey, i) => {return !!locData.totals[dimKey] ? {
                  // @ts-ignore
                  name: ((allData?.extra_data || {})[`dimension${i + 1}`] || {})['nl'],
                  internal: locData.totals[dimKey].internal,
                  external: locData.totals[dimKey].external
                } : {name: '', internal: '', external: ''};})} fill="#8884d8" shape={(props:any) => {
                  const {cx, cy, fill, name} = props;
                  return (
                    <g>
                      <Dot cx={cx} cy={cy} r={5} fill={fill} />
                      <g transform={`translate(${cx},${cy})`}>
                        <text x={10} y={0} dy={4} textAnchor="left" fontSize={10}>{name}</text>
                      </g>
                    </g>
                   );
                }} />

                <ReferenceLine y={0} stroke="#000000" />
                <ReferenceLine x={0} stroke="#000000" />
              </ScatterChart>
            </ReactPDFChart>
          </View>
        </View>
      </View>
    </Page>}
    {!!locData && !locData?.id && <Page size="A4" style={styles.page} dpi={300}>
      <View style={styles.table}>
        <View style={styles.row}>
          <View style={styles.col12}>
            <ReactPDFChart>
              <ScatterChart height={300} width={550}>
                <CartesianGrid />
                <ReferenceArea x1={0} x2={2.5} y1={2.5} y2={0} fill="red" fillOpacity={0.3} />
                <ReferenceArea x1={2.5} x2={5} y1={2.5} y2={5} fill="green" fillOpacity={0.3} />
                <ReferenceArea x1={0} x2={2.5} y1={5} y2={2.5} fill="orange" fillOpacity={0.2} />
                <ReferenceArea x1={2.5} x2={5} y1={2.5} y2={0} fill="orange" fillOpacity={0.2} />

                <XAxis type="number" dataKey="external" label="&nbsp;     &nbsp;      &nbsp;     &nbsp;       &nbsp;   &nbsp;       &nbsp;      &nbsp;      &nbsp;      &nbsp;      Ouders" domain={[0, 5]} ticks={[0, 2.5, 5]} />
                  <YAxis type="number" dataKey="internal" label="Pers.  &nbsp; &nbsp;   &nbsp; &nbsp;   &nbsp;" domain={[0, 5]} ticks={[0, 2.5, 5]} />

                <Scatter isAnimationActive={false} data={[
                    ...['dim1', 'dim2', 'dim3', 'dim4', 'dim5', 'dim6'].map((dimKey, i) => {return !!locData.totals[dimKey] ? {
                      // @ts-ignore
                      name: `${((allData?.extra_data || {})[`dimension${i + 1}`] || {})['nl'][0]}${i+1}`,
                      colour: "#8884d8",
                      internal: parseFloat((locData.totals[dimKey].internal / locData.totals[dimKey].locations_in).toFixed(2)),
                      external: parseFloat((locData.totals[dimKey].external / locData.totals[dimKey].locations_ex).toFixed(2))
                    } : {name: '', colour: '', internal: '', external: ''};}),
                    ...(Object.values((allData?.extra_data?.results?.dimensions || {})).filter(fff => (fff.name.en || '') !== "Total").map((dimension, iii) => {
                      let res:{name: string, colour: string, internal: number, external: number}[] = [];
                      ['dim1', 'dim2', 'dim3', 'dim4', 'dim5', 'dim6'].forEach((dimKey, i) => {
                        if (!!dimension.totals[dimKey] && (dimension.totals[dimKey].internal + dimension.totals[dimKey].external) > 0) {
                          res.push({
                            // @ts-ignore
                            name: `${((allData?.extra_data || {})[`dimension${i + 1}`] || {})['nl'][0]}${i+1}`,
                            colour: ["rgb(56, 51, 107, 1)", "rgb(66, 135, 245, 1)", "rgb(203, 43, 224, 1)", "rgb(222, 205, 55, 1)", "rgb(135, 68, 73, 1)", "rgb(46, 87, 42, 1)"][iii],
                            internal: dimension.totals[dimKey].internal,
                            external: dimension.totals[dimKey].external
                          });
                        }
                      });
                      return res;
                    }).reduce((prev, curr) => {
                      return [
                          ...prev,
                          ...curr
                      ];
                    }))
                  ]} fill="#8884d8" shape={(props:any) => {
                  const {cx, cy, fill, name} = props;
                  return (
                    <g>
                      <Dot cx={cx} cy={cy} r={5} fill={props.payload.colour} />
                      <g transform={`translate(${cx},${cy})`}>
                        <text x={10} y={0} dy={0} textAnchor="left" fontSize={10}>{name}</text>
                      </g>
                    </g>
                   );
                }} />

                <ReferenceLine y={0} stroke="#000000" />
                <ReferenceLine x={0} stroke="#000000" />
              </ScatterChart>
            </ReactPDFChart>
          </View>
        </View>
        <View style={[styles.row]}>
          <View style={[styles.col6]}>
            {/* @ts-ignore */}
            <Text style={[styles.bold]}>{`${((allData?.extra_data || {})[`dimension${1}`] || {})['nl'][0]}${1}`}: {((allData?.extra_data || {})[`dimension${1}`] || {})['nl']}</Text>
          </View>
          <View style={[styles.col6]}>
            {/* @ts-ignore */}
            <Text style={[styles.bold]}>{`${((allData?.extra_data || {})[`dimension${2}`] || {})['nl'][0]}${2}`}: {((allData?.extra_data || {})[`dimension${2}`] || {})['nl']}</Text>
          </View>
          <View style={[styles.col6]}>
            {/* @ts-ignore */}
            <Text style={[styles.bold]}>{`${((allData?.extra_data || {})[`dimension${3}`] || {})['nl'][0]}${3}`}: {((allData?.extra_data || {})[`dimension${3}`] || {})['nl']}</Text>
          </View>
        </View>
        <View style={[styles.row]}>
          <View style={[styles.col6]}>
            {/* @ts-ignore */}
            <Text style={[styles.bold]}>{`${((allData?.extra_data || {})[`dimension${4}`] || {})['nl'][0]}${4}`}: {((allData?.extra_data || {})[`dimension${4}`] || {})['nl']}</Text>
          </View>
          <View style={[styles.col6]}>
            {/* @ts-ignore */}
            <Text style={[styles.bold]}>{`${((allData?.extra_data || {})[`dimension${5}`] || {})['nl'][0]}${5}`}: {((allData?.extra_data || {})[`dimension${5}`] || {})['nl']}</Text>
          </View>
          <View style={[styles.col6]}>
            {/* @ts-ignore */}
            <Text style={[styles.bold]}>{`${((allData?.extra_data || {})[`dimension${6}`] || {})['nl'][0]}${6}`}: {((allData?.extra_data || {})[`dimension${6}`] || {})['nl']}</Text>
          </View>
        </View>
        <View style={[styles.row]}>
          {(Object.values((allData?.extra_data?.results?.dimensions || {})).filter(fff => (fff.name.en || '') !== "Total").slice(0, 3).map((dimension, i) => <View style={[styles.col6]}><View style={{
            width: '10px',
            height: '10px',
            marginRight: '8px',
            backgroundColor: ["rgb(56, 51, 107, 1)", "rgb(66, 135, 245, 1)", "rgb(203, 43, 224, 1)", "rgb(222, 205, 55, 1)", "rgb(135, 68, 73, 1)", "rgb(46, 87, 42, 1)"][i],
            borderRadius: '2px'
          }} /> <Text style={[styles.bold]}>{(dimension.name.en || '')}</Text></View>))}
        </View>
        <View style={[styles.row]}>
          {(Object.values((allData?.extra_data?.results?.dimensions || {})).filter(fff => (fff.name.en || '') !== "Total").slice(3, 6).map((dimension, i) => <View style={[styles.col6]}><View style={{
            width: '10px',
            height: '10px',
            marginRight: '8px',
            backgroundColor: ["rgb(222, 205, 55, 1)", "rgb(135, 68, 73, 1)", "rgb(46, 87, 42, 1)"][i],
            borderRadius: '2px'
          }} /> <Text style={[styles.bold]}>{(dimension.name.en || '')}</Text></View>))}
          <View style={[styles.col6]}> </View>
        </View>
      </View>
    </Page>}

  </Document>
);
export default MyDocument;