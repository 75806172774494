import { FunctionComponent, useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

import { fetch_all, fetch_one } from '../../lib/server_helper';
import { checkAllowed } from '../../lib/permission';

import {
  CircularProgress
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import {
  DataGrid,
  Page,
  SearchBox,
  StyledButton,
  StyledIconButton,
  Typography
} from '../../components/interface';

import { Question } from '../../models/Question';
import { loginAtom } from '../../lib/auth';

const { DateTime } = require("luxon");

type AdminQuestionsProps = {};

export const AdminQuestions: FunctionComponent<AdminQuestionsProps> = () => {
  // const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  const [login, setLogin] = useAtom(loginAtom);

  const params = useParams();
  const projectId: string | undefined = params.project_id;

  const [templates, setTemplates] = useState<Question[]>([]);
  const [templatesCount, setTemplatesCount] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(true);
  const [removeLoader, setRemoveLoader] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("pos ASC");
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);

  // Settings


  useEffect(() => {
    onSearch('');
  }, [page, order, projectId]);

  const onRemove = (id: number) => {
    if (checkAllowed(login, null, projectId?.toString() || '0', "ProjectQuestions", 'd', true)) {
      setRemoveLoader(true);
      fetch_one<Question>('/nl/v3/objects/remove', {
        object: 'question',
        ob: {
          id: id
        }
      }, (obs, complete_data) => {
        onSearch('');
        setRemoveLoader(false);
      }, (z) => {

      }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
    }
  };

  const onSearch = (s: string) => {
    if (checkAllowed(login, null, projectId?.toString() || '0', "ProjectQuestions", 'r', true)) {
      // console.log(filters);
      setLoader(true);
      fetch_all<Question>('/nl/v3/objects/fetch_all', {
        object: 'question',
        fields: ['id', 'created_at', 'updated_at', 'body', 'pos', 'extra_data', 'project|name', 'question_type'],
        per_page: 20,
        page: page,
        order: order,
        filter: {
          search: s,
          advanced: {
            project_id: projectId,
            fallback: s
          }
        }
      }, (obs, complete_data) => {
        setTemplates(obs);

        let count:number = 0;
        if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
        setTemplatesCount(count);

        setLoader(false);
      }, (z) => {
        setServerErrorSnackOpen(true);
      }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
    } else {
      setLoader(false);
    }
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 100, sortable: true },
    { field: 'body', headerName: 'Body', width: 250, sortable: false, renderCell: params => <>
      <Typography sx={{
        fontSize: '0.9rem'
      }}>{params.row.body.en || JSON.stringify(params.row.body)}</Typography>
    </> },
    { field: 'question_type', headerName: 'Q. Type', width: 100, sortable: true },
    // { field: 'created_at', headerName: 'Created', width: 120, valueFormatter: params => DateTime.fromISO(params?.value).toFormat("D") },
    // { field: 'updated_at', headerName: 'Updated', width: 120, valueFormatter: params => DateTime.fromISO(params?.value).toFormat("D") },
    { field: 'pos', headerName: '#', width: 100, sortable: true },
    { field: 'extra_data', headerName: 'Rules', width: 200, sortable: false, renderCell: params => <>
      <Typography sx={{
        fontSize: '0.9rem'
      }}>{(params.row.extra_data?.rules?.question || []).length > 0 || Object.values(params.row.extra_data?.rules?.answers || {}).filter((aa:any) => (aa.question || []).length > 0).length > 0 ? 'Yes' : 'No'}</Typography>
    </> },
  ];

  return (
    <Page sx={{}} title={t("objects.questions.title")} actions={<>
      {checkAllowed(login, null, projectId?.toString() || '0', "ProjectQuestions", 'c', true) && <StyledButton
        label="Create new"
        id='create_new'
        contained
        path={`/question/${projectId}/new`}
        sx={{marginRight: 3}}
        startIcon={<AddCircleOutlineIcon />}
      />}
    </>}>
      <SearchBox
        simple={true}
        onSearch={(s) => {
          setPage(0);
          onSearch(s);
        }}
      />
      <DataGrid
        action_count={3}
        actions={(params) => {
          return (<>
            {checkAllowed(login, null, projectId?.toString() || '0', "ProjectQuestions", 'u', true) && <StyledIconButton title="Edit" color="primary" path={`/question/${projectId}/${params.row.id}`}>
              <EditIcon />
            </StyledIconButton>}
            {checkAllowed(login, null, projectId?.toString() || '0', "ProjectQuestionRules", 'r', true) && <StyledIconButton title="Rules" color="primary" path={`/questionrules/${projectId}/${params.row.id}`}>
              <DesignServicesIcon />
            </StyledIconButton>}
            {!!removeLoader && <CircularProgress />}
            {checkAllowed(login, null, projectId?.toString() || '0', "ProjectQuestions", 'd', true) && !removeLoader && <StyledIconButton title="Destroy" destroyBtn color="primary" onClick={(e) => {
              onRemove(params.row.id);
            }}>
              <DeleteForeverIcon />
            </StyledIconButton>}
          </>);
        }}
        getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
        objectCount={templatesCount}
        objects={(templates || []).map(u => ({
          ...u
        }))}
        columns={columns}
        loader={loader}
        onSortModelChange={(a, b) => {
          let a_0_field:string = a[0].field;
          setOrder([a_0_field, a[0].sort].join(' '));
        }}
        page={page}
        setPage={(p) => {setPage(p);}}
      />

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
    </Page>
  );
}
