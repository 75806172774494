import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useNavigate, useParams } from 'react-router-dom';

import { fetch_one, fetch_all } from '../../lib/server_helper';

import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  Grid
} from '@mui/material';

import { loginAtom } from '../../lib/auth';

import { AlertCheckInputs } from '../../components/alerts/CheckInputs';
import { AlertSaved } from '../../components/alerts/Saved';
import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import {
  Page,
  BlockPicker,
  BlockTags,
  StyledButton,
  StyledDateField,
  StyledSelect,
  StyledSwitch,
  StyledTextField,
  Typography
} from '../../components/interface';

import { BlockPublicBase } from '../../components/blocks/Public/Base';
import { BlockSettingsBase } from '../../components/blocks/Settings/Base';

import { EmailBlock } from '../../models/EmailBlock';
import { EmailTemplate } from '../../models/EmailTemplate';
import { Segment } from '../../models/Segment';

// const { DateTime } = require("luxon");

type Props = {};

export const AdminMailerDetail: FunctionComponent<Props> = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  // const { isLoading, isError, data, error } = useQuery({
  //   queryKey: ['login'],
  //   queryFn: async () => {return },
  // });

  const params = useParams();

  const [login, setLogin] = useAtom(loginAtom);
  const [templateDetail, setTemplateDetail] = useState<EmailTemplate|null>(null);
  const [mySegments, setMySegments] = useState<Segment[]>([]);
  const [loader, setLoader] = useState<boolean>(true);
  const [saveLoader, setSaveLoader] = useState<boolean>(false);
  const [exitId, setDividendId] = useState<string | undefined>(params.id);

  const [blocks, setBlocks] = useState<EmailBlock[]>([]);

  const [activeHover, setActiveHover] = useState<number>();

  const [movingMode, setMovingMode] = useState<boolean>(false);

  const [previewLoading, setPreviewLoading] = useState<boolean>(true);

  const [errorSnackOpen, setErrorSnackOpen] = useState<boolean>(false);
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);
  const [exitSavedSnackOpen, setDividendSavedSnackOpen] = useState<boolean>(false);

  const fields = [
    'id', 'created_at', 'updated_at', 'name', 'extra_data', 'is_commercial', 'is_system', 'subject', 'internal_key', 'block_info', 'locale'
  ];

  const saveSetting = (keep_open: boolean) => {
    setSaveLoader(true);

    let formData = new FormData();
    formData.append('object', 'v2_email_template');
    formData.append('handler_id', (login?.id || "").toString());
    fields.map(field => {
      formData.append('fields[]', field);
    });
    if (!!exitId) formData.append('ob[id]', exitId.toString());
    if (!!exitId) formData.append('id', exitId.toString());
    // templateDetail
    formData.append('ob[is_commercial]', !!templateDetail?.is_commercial ? '1' : '0');
    formData.append('ob[is_system]', !!templateDetail?.is_system ? '1' : '0');
    formData.append('ob[locale]', templateDetail?.locale || 'nl');
    formData.append('ob[internal_key]', templateDetail?.internal_key || 'k');
    formData.append('ob[subject]', templateDetail?.subject || 'TestSubject');
    formData.append('ob[extra_data][user_list]', templateDetail?.extra_data?.user_list || '');
    formData.append('ob[extra_data][segment_id]', templateDetail?.extra_data?.segment_id || '');
    // blocks
    blocks.map((block, i) => {
      formData.append(`blocks[${i}][block_type]`, block.type || '');
      formData.append(`blocks[${i}][id]`, block.id?.toString() || '');
      formData.append(`blocks[${i}][rank]`, i.toString() || '');
      formData.append(`blocks[${i}][extra_data][sep_color]`, block.sep_color || '');
      formData.append(`blocks[${i}][extra_data][string_value]`, block.string_value || '');
      formData.append(`blocks[${i}][extra_data][title_value]`, block.title_value || '');
      formData.append(`blocks[${i}][extra_data][string_value_right]`, block.string_value_right || '');
      formData.append(`blocks[${i}][extra_data][title_value_right]`, block.title_value_right || '');
      formData.append(`blocks[${i}][extra_data][settings][mtop]`, block.settings?.mtop || '');
      formData.append(`blocks[${i}][extra_data][settings][mbot]`, block.settings?.mbot || '');
      formData.append(`blocks[${i}][extra_data][settings][image_url]`, block.settings?.image_url || '');
      formData.append(`blocks[${i}][extra_data][settings][border]`, block.settings?.border || '');
      if (!!block.item) formData.append(`blocks[${i}][item]`, block.item);
      if (!!block.set_item_url) formData.append(`blocks[${i}][set_item_url]`, block.set_item_url);
    });

    fetch_one<EmailTemplate>('/nl/v3/objects/save', formData, (ob, complete_data) => {
      setDividendId(ob.id?.toString());
      openCalculation(ob);
      setSaveLoader(false);
      setDividendSavedSnackOpen(true);
      if (!keep_open) navigate("/mailer");
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const fetchCalculation = () => {
    setLoader(true);
    
    fetch_one<EmailTemplate>('/nl/v3/objects/fetch_all', {
      object: 'v2_email_template',
      fields: fields,
      id: exitId
    }, (ob, complete_data) => {
      openCalculation(ob);
      setLoader(false);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, login);
  };

  const fetchMySegments = () => {
    fetch_all<Segment>('/nl/v3/objects/fetch_all', {
      object: 'segment',
      fields: ['id', 'name'],
      handler_id: login?.id
    }, (obs, complete_data) => {
      setMySegments(obs);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
  };

  useEffect(() => {
    fetchCalculation();
    fetchMySegments();
  }, []);

  const openCalculation = (ob: EmailTemplate) => {
    setTemplateDetail(ob);

    let blocks:EmailBlock[] = [];
    if (!!ob.block_info) ob.block_info.map(b_info => {
      let new_block:EmailBlock = b_info;
      blocks.push(new_block);
    });
    setBlocks(blocks);

    setPreviewLoading(false);
  }

  return (
    <Page sx={{}} title={`Template Detail`}>
      <Grid container spacing="8">

        <Grid item xs={4}>
          <StyledSwitch
            label="Is custom EmailTemplate"
            value={!!templateDetail?.is_commercial}
            onChange={(v) => {if (!!templateDetail) setTemplateDetail({
              ...templateDetail,
              is_commercial: !!v,
              ...(!!v ? {is_system: false} : {})
            });}}
          />
        </Grid>
        <Grid item xs={4}>
          <StyledSelect
            label="Language"
            value={templateDetail?.locale || 'nl'}
            id='locale'
            onChange={(v) => {if (!!templateDetail) setTemplateDetail({
              ...templateDetail,
              locale: v
            });}}
            list={[
              {id: 'nl', name: "Dutch"},
              {id: 'fr', name: "French"},
              {id: 'en', name: "English"}
            ]}
          />
        </Grid>
        <Grid item xs={4}>
          <StyledTextField
            label={!!templateDetail?.is_commercial ? "Name" : "Internal Key"}
            value={templateDetail?.internal_key || ''}
            id="internal_key"
            onChange={(v) => {if (!!templateDetail) setTemplateDetail({
              ...templateDetail,
              internal_key: v});
            }}
            fullWidth
          />
        </Grid>

        {!!templateDetail?.is_commercial && <>
          <Grid item xs={3} sx={{textAlign: 'right', alignSelf: 'center'}}>
            <Typography>Email list</Typography>
          </Grid>
          <Grid item xs={6}>
            <StyledTextField
              label="List of emails"
              value={templateDetail?.extra_data?.user_list || ''}
              id="email_list"
              onChange={(v) => {if (!!templateDetail) setTemplateDetail({
                ...templateDetail,
                extra_data: {
                  ...(templateDetail?.extra_data || {}),
                  user_list: v
                }
              });}}
              fullWidth
              multiline
            />
          </Grid>
          <Grid item xs={3}></Grid>

          <Grid item xs={3} sx={{textAlign: 'right', alignSelf: 'center'}}>
            <Typography>Segment</Typography>
          </Grid>
          <Grid item xs={6}>
            <StyledSelect
              label="Segment"
              value={templateDetail?.extra_data?.segment_id || ''}
              id='buy_date_mode'
              onChange={(v) => {if (!!templateDetail) setTemplateDetail({
                ...templateDetail,
                extra_data: {
                  ...(templateDetail?.extra_data || {}),
                  segment_id: v
                }
              });}}
              list={[
                {id: '', name: "None selected"},
                ...mySegments.sort((a, b) => (a.name || '').localeCompare(b.name || '')).map(c => {return {id: (c.id || '').toString(), name: c.name || ''};})
              ]}
            />
          </Grid>
          <Grid item xs={3}></Grid>
        </>}

        <Grid item xs={12}>
          <Divider sx={{marginTop: 1, marginBottom: 2}} />

          <StyledTextField
            label="Subject"
            value={templateDetail?.subject || ''}
            id="subject"
            onChange={(v) => {if (!!templateDetail) setTemplateDetail({
              ...templateDetail,
              subject: v});
            }}
            fullWidth
          />
        </Grid>

        {!movingMode && <Grid item container xs={12} sx={{display: 'contents'}}>
          <BlockTags />
          <BlockPicker
            onClick={(block_type:string) => {
              let new_block:EmailBlock = {
                type: block_type
              };
              setBlocks([
                ...blocks,
                new_block
              ]);
              setPreviewLoading(false);
            }}
          />
        </Grid>}

        <Grid item xs={12}>
          <Box sx={{
            width: "calc(100% - 640px)",
            display: 'inline-block'
          }}>
            {blocks.map((block, i) => <>
              <BlockSettingsBase
                isFirst={i === 0}
                isLast={i === (blocks.length - 1)}
                forceClose={movingMode}
                setNewPosition={(change) => {
                  let old_blocks = [...blocks];
                  old_blocks.splice(i+change, 0, old_blocks.splice(i, 1)[0]);
                  setBlocks(old_blocks);
                }}
                deleteFromList={() => {
                  let old_blocks = [...blocks];
                  old_blocks.splice(i, 1);
                  setBlocks(old_blocks);
                }}
                setActive={(ac) => {setActiveHover(ac ? i : undefined);}}
                block={block}
                setBlock={(eb:EmailBlock) => {
                  let new_blocks:EmailBlock[] = [];
                  blocks.map((bbb, iii) => {
                    if (iii !== i) new_blocks.push(bbb);
                    if (iii === i) new_blocks.push(eb);
                  });
                  setBlocks(new_blocks);
                }}
              />
            </>)}
          </Box>
          <Box sx={{
            width: "600px",
            display: 'inline-block',
            border: '1px solid transparent',
            borderColor: theme.palette.primary.main,
            padding: 1, 
            float: 'right',
            borderRadius: 0.5
          }}>
            {!!previewLoading && <CircularProgress sx={{marginLeft: 'auto', marginRight: 'auto', display: 'block'}} />}
            {!previewLoading && <Grid container sx={{
              width: '600px',
              "& div": {
                "& h3": {
                  marginTop: '10px',
                  marginBottom: '10px',
                  color: '#105156',
                  fontSize: '16px'
                },
                "& p": {
                  marginTop: '10px',
                  marginBottom: '10px',
                  color: '#212933',
                  fontSize: '14px'
                },
                "& ul, & ol": {
                  color: '#212933',
                  fontSize: '14px'
                },
                "& a": {
                  color: '#5cbf8d !important',
                  textDecoration: 'none !important'
                }
              }
            }}>
              {/* {!templateDetail?.is_commercial && <>
                <Grid xs={12}>
                  <img style={{width: '100%', height: 'auto'}} width="660" src="header_wide.jpg"></img>
                </Grid>
              </>} */}
              {blocks.map((block, i) => <>
                <BlockPublicBase block={block} marked={activeHover === i} activeLocale={(templateDetail?.locale || 'nl') as 'nl' | 'fr'} />
              </>)}
              {/* {!templateDetail?.is_commercial && <>
                <Grid xs={12}>
                  <img style={{width: '100%', height: 'auto'}} width="660" src="footer_wide.jpg"></img>
                </Grid>
              </>} */}
            </Grid>}
          </Box>
        </Grid>
        
        <Grid item xs={12} sx={{alignContent: 'baseline', textAlign: 'center', position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 1201}}>
          <Divider sx={{marginTop: 1, marginBottom: 2}} />

          {!movingMode && <>
            {!!loader && <CircularProgress />}
            {!loader && <StyledButton
              label="Start manage mode"
              id='start_mm'
              // contained
              onClick={(v) => {
                setMovingMode(true);
              }}
              sx={{marginLeft: 1}}
            />}
            {!loader && <StyledButton
              label="Cancel"
              id='cancel'
              // contained
              onClick={(v) => {
                navigate("/mailer");
              }}
              sx={{marginLeft: 1}}
            />}
            {!loader && <StyledButton
              label="Save"
              id='save'
              contained
              onClick={(v) => {
                saveSetting(false);
              }}
              sx={{marginLeft: 1}}
            />}
            {!loader && <StyledButton
              label="Save and keep open"
              id='save_opened'
              contained
              onClick={(v) => {
                saveSetting(true);
              }}
              sx={{marginLeft: 1}}
            />}
          </>}
          {!!movingMode && <>
            <StyledButton
              label="Stop manage mode"
              id='cancel_mm'
              // contained
              onClick={(v) => {
                setMovingMode(false);
              }}
              sx={{marginLeft: 1}}
            />
          </>}
        </Grid>
      </Grid>

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
      {!!errorSnackOpen && <AlertCheckInputs open={errorSnackOpen} setOpen={(b) => {setErrorSnackOpen(b);}} />}
      {!!exitSavedSnackOpen && <AlertSaved open={exitSavedSnackOpen} setOpen={(b) => {setDividendSavedSnackOpen(b);}} />}
    </Page>
  );
}
