import React, { FunctionComponent, useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

import { fetch_one } from '../../lib/server_helper';

import {
  Box,
  Divider,
  Grid
} from '@mui/material';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { loginAtom } from '../../lib/auth';

import { AlertCheckInputs } from '../../components/alerts/CheckInputs';
import { AlertSaved } from '../../components/alerts/Saved';
import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import {
  Page,
  StyledButton,
  StyledTextField,
  Typography
} from '../../components/interface';

import { User } from '../../models/User';

// const { DateTime } = require("luxon");

// type AvailableLang = 'nl' | 'fr' | 'en';
type AdminUserDetailProps = {};

export const AdminUserDetail: FunctionComponent<AdminUserDetailProps> = () => {
  const navigate = useNavigate();
  // const theme = useTheme();
  const {t, i18n} = useTranslation(['translations']);

  const params = useParams();

  const [login, setLogin] = useAtom(loginAtom);
  const [embeddableDetail, setEmbeddableDetail] = useState<User|null>(null);
  const [loader, setLoader] = useState<boolean>(true);
  const [saveLoader, setSaveLoader] = useState<boolean>(false);

  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastname] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  const [errorSnackOpen, setErrorSnackOpen] = useState<boolean>(false);
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);
  const [exitSavedSnackOpen, setDividendSavedSnackOpen] = useState<boolean>(false);

  const fields = [
    'id', 'created_at', 'updated_at', 'first_name', 'last_name', 'email'
  ];

  const saveSetting = () => {
    setSaveLoader(true);

    fetch_one<User>('/nl/v3/objects/custom_action', {
      object: 'user',
      class_action: 'custom_api_invite_admin',
      ob: {
        first_name: firstName,
        last_name: lastName,
        email: email
      }
    }, (ob, complete_data) => {
      navigate(`/users`);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
  };

  return (
    <Page sx={{}} title={t("invite.admin")}>
      <Grid container spacing="8">

        <Grid item container xs={12} spacing="4" sx={{alignContent: 'baseline'}}>
          <Grid item xs={12} sx={{marginBottom: 2}}>
            <Box sx={{padding: 2, backgroundColor: 'rgba(0, 255, 0, 0.1)'}}>
              <Typography>If the given email exists already, the user will become an admin. If the user does not exist, a blank user will be created.</Typography>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <StyledTextField
              label="First name"
              value={firstName || ''}
              id="fn"
              onChange={(v) => {setFirstName(v);}}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <StyledTextField
              label="Last name"
              value={lastName || ''}
              id="ln"
              onChange={(v) => {setLastname(v);}}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <StyledTextField
              label="Email"
              value={email || ''}
              id="email"
              type="email"
              onChange={(v) => {setEmail(v);}}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{alignContent: 'baseline', textAlign: 'center', position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 1201}}>
          <Divider sx={{marginTop: 1, marginBottom: 2}} />

          {<StyledButton
            label={t("public.buttons.cancel")}
            id='cancel'
            // contained
            onClick={(v) => {
              navigate(`/users`);
            }}
            sx={{marginLeft: 1}}
          />}
          {<StyledButton
            label={t("public.buttons.save")}
            id='save'
            disabled={!firstName || !lastName || !email}
            contained
            onClick={(v) => {
              saveSetting();
            }}
            sx={{marginLeft: 1}}
          />}
        </Grid>
      </Grid>

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
      {!!errorSnackOpen && <AlertCheckInputs open={errorSnackOpen} setOpen={(b) => {setErrorSnackOpen(b);}} />}
      {!!exitSavedSnackOpen && <AlertSaved open={exitSavedSnackOpen} setOpen={(b) => {setDividendSavedSnackOpen(b);}} />}
    </Page>
  );
}
