import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';

import {
  Avatar,
  Grid,
  Tab,
  Tabs
} from '@mui/material';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  StyledTextField,
  Typography
} from '../../interface';

import { EmailBlock } from '../../../models/EmailBlock';

type BlockSettingsSeparatorProps = {
  forceClose: boolean;
  setActive: (e:boolean) => void;
  setBlock: (eb:EmailBlock) => void;
  block: EmailBlock;
};

export const BlockSettingsSeparator: FunctionComponent<BlockSettingsSeparatorProps> = ({forceClose, block, setBlock, setActive}) => {
  const theme = useTheme();

  const [sepColor, setSepColor] = useState<string>(block.sep_color || '');

  const [mtop, setMtop] = useState<string>(block.settings?.mtop || '0px');
  const [mbot, setMbot] = useState<string>(block.settings?.mbot || '0px');

  const [activeTab, setActiveTab] = useState<number>(0);
  const [isHidden, setIsHidden] = useState<boolean>(true);

  useEffect(() => {
    let new_block = block;

    new_block.sep_color = sepColor;
    
    if (!new_block.settings) new_block.settings = {};
    new_block.settings.mtop = mtop;
    new_block.settings.mbot = mbot;

    setBlock(new_block);
  }, [sepColor, mtop, mbot]);

  return (<Grid item container xs={12} sx={{border: '1px solid lightgrey', marginBottom: 1}} onMouseEnter={() => {setActive(true);}} onMouseLeave={() => {setActive(false);}}>
    <Grid item xs={12} sx={{marginBottom: 0.5, position: 'relative', minHeight: '32px'}} onClick={() => {
      if (!!isHidden) setIsHidden(!isHidden);
    }}>
      {!forceClose && isHidden && <Avatar sx={{cursor: 'pointer', position: 'absolute', right: 8, top: 8, zIndex: 1000, height: 24, width: 24}}><ExpandLessIcon /></Avatar>}
      {!forceClose && !isHidden && <Avatar sx={{cursor: 'pointer', position: 'absolute', right: 8, top: 8, zIndex: 1000, height: 24, width: 24}} onClick={() => {
        setIsHidden(!isHidden);
      }}><ExpandMoreIcon /></Avatar>}

      {(!!forceClose || isHidden) && <Typography sx={{paddingLeft: 1, paddingTop: 1}}>Separator</Typography>}

      {!forceClose && !isHidden && <Tabs value={activeTab} sx={{backgroundColor: theme.palette.opacitated?.main_01}} onChange={(event: React.SyntheticEvent, newValue: number) => {setActiveTab(newValue);}}>
        <Tab label="Content" />
        <Tab label="Settings" />
      </Tabs>}
    </Grid>
    {!forceClose && !isHidden && <>
      {activeTab === 0 && <Grid container item xs={12} hidden={activeTab !== 0} sx={{paddingLeft: 1, paddingRight: 1}}>
        <Grid item xs={12}>
          <StyledTextField
            label="Separator colour"
            value={sepColor}
            id="title_value"
            onChange={(v:string) => {setSepColor(v);}}
            fullWidth
          />
        </Grid>
      </Grid>}
      {activeTab === 1 && <Grid container item xs={12} sx={{paddingLeft: 1, paddingRight: 1}}>
        <Grid item xs={12}>
          <StyledTextField
            label="Margin Top"
            value={mtop || '0px'}
            id="mtop"
            onChange={(v:string) => {setMtop(v);}}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <StyledTextField
            label="Margin Bottom"
            value={mbot || '0px'}
            id="mbot"
            onChange={(v:string) => {setMbot(v);}}
            fullWidth
          />
        </Grid>
      </Grid>}
    </>}
  </Grid>);
};