import React, { FunctionComponent } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useAtom } from 'jotai';

import {
  SpeedDial,
  SpeedDialAction
} from '@mui/material';

import TranslateIcon from '@mui/icons-material/Translate';

// import { Typography } from './Typography';

type AvailableLang = 'nl' | 'fr' | 'en';
type LanguageFabProps = {
  setActiveLocale: (a: AvailableLang) => void;
  activeLocale: AvailableLang;
};

// export const LanguageFab: FunctionComponent<LanguageFabProps> = ({setActiveLocale, activeLocale}) => {
//   const theme = useTheme();

//   return (<React.Fragment>
//     <Fab aria-label="language" sx={{ zIndex: 1202, position: 'fixed', bottom: 16, right: 16, backgroundColor: theme.palette.tertiary?.main, color: theme.palette.primary.main }} onClick={() => {
//       setActiveLocale(activeLocale === 'nl' ? 'fr' : 'nl');
//     }}>
//       {activeLocale === 'nl' ? <>NL</> : <>FR</>}
//     </Fab>
//   </React.Fragment>);
// }

export const LanguageFab: FunctionComponent<LanguageFabProps> = ({setActiveLocale, activeLocale}) => {
  const theme = useTheme();

  return (<React.Fragment>
    <SpeedDial
      ariaLabel="Languages"
      sx={{zIndex: 1202, position: 'fixed', bottom: 16, right: 16, color: theme.palette.primary.main}}
      icon={<TranslateIcon />}
    >
      {['nl', 'fr', 'en'].map((action) => (
        <SpeedDialAction
          key={action}
          icon={action}
          onClick={() => {
            setActiveLocale(action as AvailableLang);
          }}
        />
      ))}
    </SpeedDial>
  </React.Fragment>);
}
