import { fetch_one, check_subdomain } from '../lib/server_helper';

import { Page } from "../models/Page";
import { User } from "../models/User";

export function fetchPage(page_type:string, cb: (ob:any) => void, login?: User, pageKey?: string, err_cb?: () => void) {
  let subdomain = check_subdomain();

  if (subdomain.length > 0) {
    fetch_one<Page>('/nl/v3/pub/fetch_page', {
      object: 'page',
      fields: ['id', 'title', 'body'],
      subdomain: subdomain,
      full_url: window.location.host,
      page_type: page_type,
      page_key: pageKey
    }, (ob, complete_data) => {
      cb(ob);
    }, (z) => {
      if (!!err_cb) err_cb();
    }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
  }
}