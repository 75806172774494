import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter } from "react-router-dom";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';

import i18next from './i18n';

import {Main} from './pages/Main';
import {Loader} from './components/Loader';

import { LicenseInfo } from "@mui/x-license-pro";
LicenseInfo.setLicenseKey(
  "993f1d8406233c77c2eaf9db599f07f7Tz05NzgwOCxFPTE3NTc1NDM1MDcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPVEzLTIwMjQsS1Y9Mg=="
);

const { Settings } = require("luxon");

const queryClient = new QueryClient();
Settings.defaultLocale = "en-UK";

declare module '@mui/material/styles' {
  interface Palette {
    opacitated?: {main_01?: string, main_02?: string};
    primary_grey?: {main?: string, light?: string};
    tertiary?: {main?: string};
    quaternary?: {main?: string};
    danger?: {main?: string};
    profile_blox?: {identity?: string, register?: string, account?: string, internal?: string, contact?: string, guardian?: string, email_history?: string, security?: string, exit?: string, itsme?: string};
  }
  interface PaletteOptions {
    opacitated?: {main_01?: string, main_02?: string};
    primary_grey: {main: string, light: string};
    tertiary: {main: string};
    quaternary: {main: string};
    danger: {main: string};
    profile_blox?: {identity?: string, register?: string, account?: string, internal?: string, contact?: string, guardian?: string, email_history?: string, security?: string, exit?: string, itsme?: string};
  }
};

const theme = createTheme({
  typography: {
    fontFamily: ['Golos Text', 'sans-serif'].join(','),
    fontSize: 14,
    fontWeightLight: 100,
    fontWeightRegular: 400,
    fontWeightMedium: 700,

    h1: {
      fontSize: "2rem",
      fontFamily: 'Golos Text',
      color: 'rgb(16, 46, 86)'
    },
    h2: {
      fontSize: "1.5rem",
      fontFamily: 'Golos Text',
      color: 'rgb(16, 46, 86)'
    },
    h3: {
      fontSize: "1.25rem",
      fontFamily: 'Golos Text',
      color: 'rgb(16, 46, 86)'
    },
    h4: {
      fontSize: "1rem",
      fontFamily: 'Golos Text',
      color: 'rgb(16, 46, 86)'
    },
    h5: {
      fontSize: "0.8rem"
    },
    h6: {
      color: 'rgb(16, 46, 86)'
    },
    body1: {
      color: 'rgb(16, 46, 86)'
    },
    body2: {
      color: 'rgb(16, 46, 86)'
    }
  },
  palette: {
    primary: {
      light: 'rgb(93, 146, 217)',
      main: 'rgb(16, 46, 86)', // dark blue
      dark: 'rgb(7, 39, 82)'
    },
    primary_grey: {
      main: '#5D6071', // old grey
      light: '#aeb0b8'
    },
    secondary: {
      main: 'rgb(255, 16, 84)', // red
      light: '#ff5f80',
      dark: '#c4002c'
    },
    tertiary: {
      main: 'rgb(97, 92, 191)' // light green
    },
    quaternary: {
      main: 'rgb(152, 182, 180)' // apple blue sea green
    },
    warning: {
      main: '#ff9800' // orange
    },
    danger: {
      main: '#b80018' // red
    },
    opacitated: {
      main_01: 'rgba(16, 46, 86, 0.1)',
      main_02: 'rgba(16, 46, 86, 0.2)'
    },
    profile_blox: {
      identity: 'lightgoldenrodyellow',
      register: 'lightblue',
      account: 'rgb(232, 220, 186)',
      internal: 'rgb(159, 181, 161)',
      contact: 'rgb(230, 165, 173)',
      guardian: 'rgb(237, 189, 126)',
      email_history: 'rgb(171, 193, 245)',
      security: 'rgb(255, 191, 248)',
      exit: 'rgb(157, 158, 157)',
      itsme: 'rgba(255, 70, 18, 0.7)'
    },
    mode: 'light',
    background: {
      default: '#ffffff'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ownerState, theme}) => ({
          textTransform: 'none',
          ...(!ownerState.no_lowercase && {
            // textTransform: 'lowercase'
            textTransform: 'none'
          }), ...(!!ownerState.capitalise && {
            // textTransform: 'capitalize'
            textTransform: 'none'
          }), ...(!!ownerState.transform_none && {
            textTransform: 'none'
          })
        })
      }
    }
  }
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <React.Suspense fallback={(<span>Please wait...</span>)}>
      <Loader />
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <Main />
          </ThemeProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </React.Suspense>
  </React.StrictMode>
);
// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
