import React, { FunctionComponent } from 'react';
// import { useTheme } from '@mui/material/styles';

import {
  Box,
  Divider,
  Grid,
  Skeleton
} from '@mui/material';

import {
  Typography
} from '../../components/interface';

type BlockPickerProps = {
  onClick: (e:string) => void;
  hide_title?: boolean
};

export const BlockPicker: FunctionComponent<BlockPickerProps> = ({hide_title, onClick}) => {
  // const theme = useTheme();

  return (<Grid container spacing="4" sx={{position: 'sticky', top: 10, backgroundColor: 'white', zIndex: 1200, paddingLeft: 1}}>
    {!hide_title && <Grid item xs={12}>
      <Typography sx={{textDecoration: 'underline'}}>Types</Typography>
    </Grid>}
    <Grid item xs={1}>
      <Box sx={{border: '1px solid lightgrey', padding: 0.5, cursor: 'pointer'}} onClick={() => {onClick('image');}}>
        <Skeleton animation={false} variant="rectangular" height={80} />
        <Divider/>
        <Typography sx={{fontSize: '0.8rem', textAlign: 'center'}}>Image</Typography>
      </Box>
    </Grid>
    <Grid item xs={1}>
      <Box sx={{border: '1px solid lightgrey', padding: 0.5, cursor: 'pointer'}} onClick={() => {onClick('text');}}>
        <Skeleton animation={false} variant="text" height={10} />
        <Skeleton animation={false} variant="text" height={10} />
        <Skeleton animation={false} variant="text" height={10} />
        <Skeleton animation={false} variant="text" height={10} />
        <Skeleton animation={false} variant="text" height={10} />
        <Skeleton animation={false} variant="text" height={10} />
        <Skeleton animation={false} variant="text" height={10} />
        <Skeleton animation={false} variant="text" height={10} />
        <Divider/>
        <Typography sx={{fontSize: '0.8rem', textAlign: 'center'}}>Text</Typography>
      </Box>
    </Grid>
    <Grid item xs={1}>
      <Box sx={{border: '1px solid lightgrey', padding: 0.5, cursor: 'pointer'}} onClick={() => {onClick('title_text');}}>
        <Skeleton animation={false} variant="text" height={30} width="80%" />
        <Skeleton animation={false} variant="text" height={10} />
        <Skeleton animation={false} variant="text" height={10} />
        <Skeleton animation={false} variant="text" height={10} />
        <Skeleton animation={false} variant="text" height={10} />
        <Skeleton animation={false} variant="text" height={10} />
        <Divider/>
        <Typography sx={{fontSize: '0.8rem', textAlign: 'center'}}>Title/Text</Typography>
      </Box>
    </Grid>
    <Grid item xs={1}>
      <Box sx={{border: '1px solid lightgrey', padding: 0.5, cursor: 'pointer'}} onClick={() => {onClick('image_title_text');}}>
        <Skeleton animation={false} variant="rectangular" height={40} />
        <Skeleton animation={false} variant="text" height={20} width="80%" />
        <Skeleton animation={false} variant="text" height={10} />
        <Skeleton animation={false} variant="text" height={10} />
        <Divider/>
        <Typography sx={{fontSize: '0.8rem', textAlign: 'center'}}>Im/Ti/Te</Typography>
      </Box>
    </Grid>
    <Grid item xs={1}>
      <Box sx={{border: '1px solid lightgrey', padding: 0.5, cursor: 'pointer'}} onClick={() => {onClick('image_left');}}>
        <Box sx={{width: '50%', display: 'inline-block'}}>
          <Skeleton animation={false} variant="rectangular" height={75} />
        </Box>
        <Box sx={{width: '45%', marginLeft: '4%', display: 'inline-block'}}>
          <Skeleton animation={false} variant="text" height={20} width="80%" />
          <Skeleton animation={false} variant="text" height={10} />
          <Skeleton animation={false} variant="text" height={10} />
          <Skeleton animation={false} variant="text" height={10} />
          <Skeleton animation={false} variant="text" height={10} />
          <Skeleton animation={false} variant="text" height={10} />
        </Box>
        <Divider/>
        <Typography sx={{fontSize: '0.8rem', textAlign: 'center'}}>Im - Ti/Te</Typography>
      </Box>
    </Grid>
    <Grid item xs={1}>
      <Box sx={{border: '1px solid lightgrey', padding: 0.5, cursor: 'pointer'}} onClick={() => {onClick('image_right');}}>
        <Box sx={{width: '45%', marginRight: '4%', display: 'inline-block'}}>
          <Skeleton animation={false} variant="text" height={20} width="80%" />
          <Skeleton animation={false} variant="text" height={10} />
          <Skeleton animation={false} variant="text" height={10} />
          <Skeleton animation={false} variant="text" height={10} />
          <Skeleton animation={false} variant="text" height={10} />
          <Skeleton animation={false} variant="text" height={10} />
        </Box>
        <Box sx={{width: '50%', display: 'inline-block'}}>
          <Skeleton animation={false} variant="rectangular" height={75} />
        </Box>
        <Divider/>
        <Typography sx={{fontSize: '0.8rem', textAlign: 'center'}}>Ti/Te - Im</Typography>
      </Box>
    </Grid>
    <Grid item xs={1}>
      <Box sx={{border: '1px solid lightgrey', padding: 0.5, cursor: 'pointer'}} onClick={() => {onClick('title_text_2col');}}>
        <Box sx={{width: '48%', marginRight: '2%', display: 'inline-block'}}>
          <Skeleton animation={false} variant="text" height={20} width="80%" />
          <Skeleton animation={false} variant="text" height={10} />
          <Skeleton animation={false} variant="text" height={10} />
          <Skeleton animation={false} variant="text" height={10} />
          <Skeleton animation={false} variant="text" height={10} />
          <Skeleton animation={false} variant="text" height={10} />
        </Box>
        <Box sx={{width: '48%', marginLeft: '2%', display: 'inline-block'}}>
          <Skeleton animation={false} variant="text" height={20} width="80%" />
          <Skeleton animation={false} variant="text" height={10} />
          <Skeleton animation={false} variant="text" height={10} />
          <Skeleton animation={false} variant="text" height={10} />
          <Skeleton animation={false} variant="text" height={10} />
          <Skeleton animation={false} variant="text" height={10} />
        </Box>
        <Divider/>
        <Typography sx={{fontSize: '0.8rem', textAlign: 'center'}}>Ti/Te - Ti/Te</Typography>
      </Box>
    </Grid>
    <Grid item xs={1}>
      <Box sx={{border: '1px solid lightgrey', padding: 0.5, cursor: 'pointer'}} onClick={() => {onClick('separator');}}>
        <Skeleton animation={false} variant="rectangular" height={33} sx={{ bgcolor: 'rgba(0, 0, 0, 0.03)', marginBottom: '3px' }} />
        <Skeleton animation={false} variant="rectangular" height={4} />
        <Skeleton animation={false} variant="rectangular" height={33} sx={{ bgcolor: 'rgba(0, 0, 0, 0.03)', marginTop: '3px', marginBottom: '3px' }} />
        <Divider/>
        <Typography sx={{fontSize: '0.8rem', textAlign: 'center'}}>Separator</Typography>
      </Box>
    </Grid>
    <Grid item xs={1}></Grid>
    <Grid item xs={1}></Grid>
    <Grid item xs={1}></Grid>
    <Grid item xs={1}></Grid>
  </Grid>);
}