import { FunctionComponent } from 'react';
import { useTheme } from '@mui/material/styles';

import {
  Grid,
  LinearProgress
} from '@mui/material';

import {
  Typography
} from '../../components/interface';

type SharedPollProgressProps = {
  progress: number;
};

export const SharedPollProgress: FunctionComponent<SharedPollProgressProps> = ({progress}) => {
  const theme = useTheme();

  return (<>
    <Grid item xs={12} sx={{textAlign: 'center'}}>
      <LinearProgress variant="determinate" value={progress} sx={{width: '100%'}} />
    </Grid>
    <Grid item xs={12}>
      <Typography variant="body2" sx={{color: theme.palette.primary.main}}>{`${Math.round(progress)}%`}</Typography>
    </Grid>
  </>);
}
