import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

import { fetch_one, fetch_all } from '../../lib/server_helper';
import { checkAllowed } from '../../lib/permission';

import {
  Divider,
  Grid
} from '@mui/material';

import { loginAtom } from '../../lib/auth';

import { AlertCheckInputs } from '../../components/alerts/CheckInputs';
import { AlertSaved } from '../../components/alerts/Saved';
import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import {
  Page,
  StyledButton,
  StyledSelect,
  StyledTextField,
  Typography
} from '../../components/interface';

import { Project } from '../../models/Project';
import { Question } from '../../models/Question';
import { Segment } from '../../models/Segment';

// var he = require('he');

// const { DateTime } = require("luxon");

// type AvailableLang = 'nl' | 'fr' | 'en';
type Props = {};
type Zip = {
  id: number;
  zip: number;
  city_name: string;
  city_votes2019: number;
};

export const AdminSegmentsDetail: FunctionComponent<Props> = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const {t, i18n} = useTranslation(['translations']);

  const params = useParams();

  const [login, setLogin] = useAtom(loginAtom);
  const [segmentDetail, setSegmentDetail] = useState<Segment|null>(null);
  const [myProjects, setMyProjects] = useState<Project[]>([]);
  const [myQuestions, setMyQuestions] = useState<Question[]>([]);
  const [mySegments, setMySegments] = useState<Segment[]>([]);
  const [loader, setLoader] = useState<boolean>(true);
  const [saveLoader, setSaveLoader] = useState<boolean>(false);
  const [segmentId, setSegmentId] = useState<string | undefined>(params.id);

  const [cities, setCities] = useState<{id: string; name: string;}[]>([]);

  // const [activeLocale, setActiveLocale] = useState<AvailableLang>('en');

  const [errorSnackOpen, setErrorSnackOpen] = useState<boolean>(false);
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);
  const [exitSavedSnackOpen, setDividendSavedSnackOpen] = useState<boolean>(false);

  const fields = [
    'id', 'extra_data', 'name', 'project_id'
  ];

  const saveSetting = () => {
    // if (checkAllowed(login, null, projectId?.toString() || '0', "ProjectSegments", 'u', true)) {
      setSaveLoader(true);

      fetch_one<Segment>('/nl/v3/objects/save', {
        object: 'segment',
        id: segmentDetail?.id || undefined,
        handler_id: login?.id,
        fields: fields,
        ob: {
          id: segmentDetail?.id || undefined,
          project_id: segmentDetail?.project_id,
          name: segmentDetail?.name || '',
          extra_data: {
            ...segmentDetail?.extra_data,
            rules: (segmentDetail?.extra_data?.rules || []).filter(rule => rule.selection !== '')
          }
        }
      }, (ob, complete_data) => {
        setSegmentDetail(ob);
        setSaveLoader(false);
        navigate(`/segments`);
      }, (z) => {
        setServerErrorSnackOpen(true);
      }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
    // }
  };

  const fetchMyProjects = () => {
    fetch_all<Project>('/nl/v3/objects/fetch_all', {
      object: 'project',
      fields: ['id', 'name', 'company|name'],
      handler_id: login?.id
    }, (obs, complete_data) => {
      setMyProjects(obs);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
  };

  const fetchMyQuestions = () => {
    if (!!segmentDetail?.project_id) {
      fetch_all<Question>('/nl/v3/objects/fetch_all', {
        object: 'question',
        fields: ['id', 'body', 'pos', 'extra_data', 'project|name', 'simple_answer_options'],
        per_page: 1000,
        page: 0,
        order: 'pos ASC',
        filter: {
          advanced: {
            project_id: segmentDetail?.project_id
          }
        }
      }, (obs, complete_data) => {
        setMyQuestions(obs);
      }, (z) => {
        setServerErrorSnackOpen(true);
      }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
    }
  };

  const fetchMySegments = () => {
    fetch_all<Segment>('/nl/v3/objects/fetch_all', {
      object: 'segment',
      fields: ['id', 'name'],
      handler_id: login?.id
    }, (obs, complete_data) => {
      setMySegments(obs);
    }, (z) => {
      setServerErrorSnackOpen(true);
    }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
  };

  const fetchCalculation = () => {
    if (!!segmentId) {
      fetch_one<Segment>('/nl/v3/objects/fetch_all', {
        object: 'segment',
        fields: fields,
        id: segmentId
      }, (ob, complete_data) => {
        setSegmentDetail(ob);
        setLoader(false);
      }, (z) => {
        setServerErrorSnackOpen(true);
      }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
    } else {
      setLoader(false);
    }
  };

  const fetchZips = () => {
    // if (checkAllowed(login, null, projectId?.toString() || '0', "ProjectExport", 'r', true)) {
      fetch_all<Zip>('/nl/v3/objects/fetch_all', {
        object: 'zip',
        fields: ['id', 'city|name', 'zip'],
        per_page: 10000
      }, (obs, complete_data) => {
        let list: {[z:string]: number[]} = {};
        let list_a: {id: string; name: string;}[] = [];
        obs.forEach(ob => {
          if (!!list[ob.city_name]) {
            if (list[ob.city_name].indexOf(ob.zip) > -1) {

            } else {
              list[ob.city_name].push(ob.zip);
            }
          } else {
            list[ob.city_name] = [];
            list[ob.city_name].push(ob.zip);
          }
        });
        Object.keys(list).sort((a, b) => a.localeCompare(b)).forEach(ob_key => {
          list_a.push({
            id: ob_key,
            name: `${ob_key} (${list[ob_key].join(", ")})`
          });
        });

        setCities(list_a);
      }, (z) => {
        setServerErrorSnackOpen(true);
      }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
    // } else {
    //   setLoader(false);
    // }
  };

  useEffect(() => {
    fetchCalculation();
    fetchMyProjects();
    fetchMySegments();
    fetchZips();
  }, []);

  useEffect(() => {
    if (!!segmentDetail?.project_id) {
      fetchMyQuestions();
    }
  }, [segmentDetail?.project_id]);

  return (
    <Page sx={{}} title={`Question Rules`}>
      <Grid container spacing="8">

        <Grid item xs={12}>
          <StyledTextField
            label={t("objects.segment.name")}
            value={segmentDetail?.name || ''}
            id="name"
            onChange={(v) => {
              setSegmentDetail({
                ...(segmentDetail || {}),
                name: v
              });
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <StyledSelect
            label="Select project or leave blank"
            value={!!segmentDetail?.project_id ? segmentDetail?.project_id.toString() : ''}
            id='p_id'
            onChange={(v) => {
              setSegmentDetail({
                ...(segmentDetail || {}),
                project_id: !!v ? parseInt(v, 10) : undefined
              });
            }}
            list={[
              {id: '', name: 'None selected'},
              ...myProjects.sort((a, b) => (a.company_name || '').localeCompare(b.company_name || '') || (a.name || '').localeCompare(b.name || '')).map(c => {return {id: (c.id || '').toString(), name: [c.company_name, c.name || ''].join(" - ")};})
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{textDecoration: 'underline', fontStyle: 'italic', fontSize: '0.9rem'}}>Filters</Typography>
          <Typography sx={{fontStyle: 'italic', fontSize: '0.7rem'}}>All rules will be chained with 'AND'.</Typography>
        </Grid>

        {((segmentDetail || {}).extra_data?.rules || []).map((rule, i) => <Grid container item xs={12} spacing="8">
          <Grid item xs={6}>
            <StyledSelect
              label="Filter"
              value={rule.selection || ''}
              id='selection'
              onChange={(v) => {
                let new_rules:{
                  selection?: string;
                  pars?: string;
                }[] = [];
                ((segmentDetail || {}).extra_data?.rules || []).forEach((rulee, ii) => {
                  if (i === ii) {
                    let temp_rule = {...rulee, selection: v};
                    new_rules.push(temp_rule);
                  } else {
                    new_rules.push(rulee);
                  }
                });

                setSegmentDetail({
                  ...(segmentDetail || {}),
                  extra_data: {
                    ...((segmentDetail || {}).extra_data || {}),
                    rules: new_rules
                  }
                });
              }}
              list={[
                {id: '', name: 'None selected (deleted when saving)'},
                {id: 'age', name: 'Age'},
                {id: 'age_ft', name: 'Age from-to'},
                {id: 'city', name: 'City'},
                {id: 'gender', name: 'Gender'},
                {id: 'zip', name: 'PostalCode'},
                {id: 'province', name: 'Province'},
                {id: '---', name: '---'},
                // {id: 'answered_project', name: 'Took part in project'},
                ...mySegments.filter(ss => ss.id !== segmentDetail?.id).sort((a, b) => (a.name || '').localeCompare(b.name || '')).map(c => {return {id: (c.id || '').toString(), name: c.name || ''};}),
                {id: '---', name: '---'},
                ...myQuestions.map(c => {return {id: `question_${(c.id || '').toString()}`, name: c.body?.nl || c.body?.en || ''};}),
              ]}
            />
          </Grid>
          <Grid item xs={6}>
            {rule.selection !== '' && <>
              {rule.selection === 'age' && <StyledTextField
                label={t("objects.segment_rule.age")}
                value={rule.pars || ''}
                id="age"
                onChange={(v) => {
                  let new_rules:{
                    selection?: string;
                    pars?: string;
                  }[] = [];
                  ((segmentDetail || {}).extra_data?.rules || []).forEach((rulee, ii) => {
                    if (i === ii) {
                      let temp_rule = {...rulee, pars: v};
                      new_rules.push(temp_rule);
                    } else {
                      new_rules.push(rulee);
                    }
                  });
  
                  setSegmentDetail({
                    ...(segmentDetail || {}),
                    extra_data: {
                      ...((segmentDetail || {}).extra_data || {}),
                      rules: new_rules
                    }
                  });
                }}
                fullWidth
              />}

              {rule.selection === 'age_ft' && <>
                <Grid container item xs={12} spacing="8">
                  <Grid item xs={6}>
                    <StyledTextField
                      label={t("objects.segment_rule.age_from")}
                      value={(rule.pars || '').split("-")[0] || ''}
                      id="age_from"
                      onChange={(v) => {
                        let new_rules:{
                          selection?: string;
                          pars?: string;
                        }[] = [];
                        ((segmentDetail || {}).extra_data?.rules || []).forEach((rulee, ii) => {
                          if (i === ii) {
                            let old_age = (rule.pars || '').split("-");
                            let temp_rule = {
                              ...rulee,
                              pars: [v, old_age[1] || ''].join("-")
                            };
                            new_rules.push(temp_rule);
                          } else {
                            new_rules.push(rulee);
                          }
                        });
        
                        setSegmentDetail({
                          ...(segmentDetail || {}),
                          extra_data: {
                            ...((segmentDetail || {}).extra_data || {}),
                            rules: new_rules
                          }
                        });
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <StyledTextField
                      label={t("objects.segment_rule.age_to")}
                      value={(rule.pars || '').split("-")[1] || ''}
                      id="age_to"
                      onChange={(v) => {
                        let new_rules:{
                          selection?: string;
                          pars?: string;
                        }[] = [];
                        ((segmentDetail || {}).extra_data?.rules || []).forEach((rulee, ii) => {
                          if (i === ii) {
                            let old_age = (rule.pars || '').split("-");
                            let temp_rule = {
                              ...rulee,
                              pars: [old_age[0] || '', v].join("-")
                            };
                            new_rules.push(temp_rule);
                          } else {
                            new_rules.push(rulee);
                          }
                        });
        
                        setSegmentDetail({
                          ...(segmentDetail || {}),
                          extra_data: {
                            ...((segmentDetail || {}).extra_data || {}),
                            rules: new_rules
                          }
                        });
                      }}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </>}

              {rule.selection === 'city' && <>
                <StyledSelect
                  label={t("objects.segment_rule.city")}
                  value={rule.pars || ''}
                  id='city'
                  onChange={(v) => {
                    let new_rules:{
                      selection?: string;
                      pars?: string;
                    }[] = [];
                    ((segmentDetail || {}).extra_data?.rules || []).forEach((rulee, ii) => {
                      if (i === ii) {
                        let temp_rule = {...rulee, pars: v};
                        new_rules.push(temp_rule);
                      } else {
                        new_rules.push(rulee);
                      }
                    });
    
                    setSegmentDetail({
                      ...(segmentDetail || {}),
                      extra_data: {
                        ...((segmentDetail || {}).extra_data || {}),
                        rules: new_rules
                      }
                    });
                  }}
                  list={[
                    {id: '', name: 'None selected'},
                    ...cities
                  ]}
                />
              </>}

              {rule.selection === 'gender' && <>
                <StyledSelect
                  label={t("objects.segment_rule.gender")}
                  value={rule.pars || 'M'}
                  id='gender'
                  onChange={(v) => {
                    let new_rules:{
                      selection?: string;
                      pars?: string;
                    }[] = [];
                    ((segmentDetail || {}).extra_data?.rules || []).forEach((rulee, ii) => {
                      if (i === ii) {
                        let temp_rule = {...rulee, pars: v};
                        new_rules.push(temp_rule);
                      } else {
                        new_rules.push(rulee);
                      }
                    });
    
                    setSegmentDetail({
                      ...(segmentDetail || {}),
                      extra_data: {
                        ...((segmentDetail || {}).extra_data || {}),
                        rules: new_rules
                      }
                    });
                  }}
                  list={[
                    {id: 'M', name: t("public.profile.fields.genders.male")},
                    {id: 'F', name: t("public.profile.fields.genders.female")},
                    {id: 'X', name: t("public.profile.fields.genders.other")}
                  ]}
                />
              </>}

              {rule.selection === 'zip' && <>
                <StyledTextField
                  label={t("objects.segment_rule.zip")}
                  value={rule.pars || ''}
                  id="zip"
                  onChange={(v) => {
                    let new_rules:{
                      selection?: string;
                      pars?: string;
                    }[] = [];
                    ((segmentDetail || {}).extra_data?.rules || []).forEach((rulee, ii) => {
                      if (i === ii) {
                        let temp_rule = {...rulee, pars: v};
                        new_rules.push(temp_rule);
                      } else {
                        new_rules.push(rulee);
                      }
                    });
    
                    setSegmentDetail({
                      ...(segmentDetail || {}),
                      extra_data: {
                        ...((segmentDetail || {}).extra_data || {}),
                        rules: new_rules
                      }
                    });
                  }}
                  fullWidth
                />
              </>}

              {rule.selection === 'province' && <>
                <StyledSelect
                  label={t("objects.segment_rule.province")}
                  value={rule.pars || ''}
                  id='province'
                  onChange={(v) => {
                    let new_rules:{
                      selection?: string;
                      pars?: string;
                    }[] = [];
                    ((segmentDetail || {}).extra_data?.rules || []).forEach((rulee, ii) => {
                      if (i === ii) {
                        let temp_rule = {...rulee, pars: v};
                        new_rules.push(temp_rule);
                      } else {
                        new_rules.push(rulee);
                      }
                    });
    
                    setSegmentDetail({
                      ...(segmentDetail || {}),
                      extra_data: {
                        ...((segmentDetail || {}).extra_data || {}),
                        rules: new_rules
                      }
                    });
                  }}
                  list={[
                    {id: 'all', name: 'Belgium'},
                    {id: 'flanders', name: 'Flanders'},
                    {id: 'wallonia', name: 'Wallonia'},
                    {id: '---', name: '---'},
                    {id: 'Brussels Capital Region', name: 'Brussels Capital Region'},
                    {id: 'Walloon Brabant', name: 'Walloon Brabant'},
                    {id: 'Flemish Brabant', name: 'Flemish Brabant'},
                    {id: 'Antwerp', name: 'Antwerp'},
                    {id: 'Limburg', name: 'Limburg'},
                    {id: 'Liège', name: 'Liège'},
                    {id: 'Namur', name: 'Namur'},
                    {id: 'Hainaut', name: 'Hainaut'},
                    {id: 'Luxembourg', name: 'Luxembourg'},
                    {id: 'West Flanders', name: 'West Flanders'},
                    {id: 'East Flanders', name: 'East Flanders'}
                  ]}
                />
              </>}

              {rule.selection === 'answered_project' && <>
              </>}

              {rule.selection?.indexOf('q') === 0 && <>
                <StyledSelect
                  label={t("objects.segment_rule.answer_option")}
                  value={rule.pars || ''}
                  id='answer_option'
                  onChange={(v) => {
                    let new_rules:{
                      selection?: string;
                      pars?: string;
                    }[] = [];
                    ((segmentDetail || {}).extra_data?.rules || []).forEach((rulee, ii) => {
                      if (i === ii) {
                        let temp_rule = {...rulee, pars: v};
                        new_rules.push(temp_rule);
                      } else {
                        new_rules.push(rulee);
                      }
                    });
    
                    setSegmentDetail({
                      ...(segmentDetail || {}),
                      extra_data: {
                        ...((segmentDetail || {}).extra_data || {}),
                        rules: new_rules
                      }
                    });
                  }}
                  list={[
                    {id: 'answered_1', name: 'Answered'},
                    {id: 'answered_0', name: 'Unanswered'},
                    ...((myQuestions.filter(q => q.id === parseInt((rule.selection || '').replace('question_', ''), 10))[0] || {}).simple_answer_options || []).map(ao => {
                      return {id: ao.id.toString() || '', name: ao.body?.nl || ''};
                    })
                  ]}
                />
              </>}
            </>}
          </Grid>
        </Grid>)}

        <Grid item xs={12}>
          <StyledButton
            label={t("segments.rules.add_more_cta")}
            id='add_rule'
            contained
            onClick={(v) => {
              setSegmentDetail({
                ...(segmentDetail || {}),
                extra_data: {
                  ...((segmentDetail || {}).extra_data || {}),
                  rules: [
                    ...((segmentDetail || {}).extra_data?.rules || []),
                    {
                      selection: '',
                      pars: ''
                    }
                  ]
                }
              });
            }}
            sx={{marginTop: 1}}
          />
        </Grid>




        <Grid item xs={12} sx={{alignContent: 'baseline', textAlign: 'center', position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 1201}}>
          <Divider sx={{marginTop: 1, marginBottom: 2}} />

          {!loader && <StyledButton
            label={t("public.buttons.cancel")}
            id='cancel'
            // contained
            onClick={(v) => {
              navigate(`/segments`);
            }}
            sx={{marginLeft: 1}}
          />}
          {/* {checkAllowed(login, null, projectId?.toString() || '0', "ProjectSegments", 'u', true) && !loader && <StyledButton
            label={t("public.buttons.save")}
            id='save'
            contained
            onClick={(v) => {
              saveSetting();
            }}
            sx={{marginLeft: 1}}
          />} */}
          {!loader && <StyledButton
            label={t("public.buttons.save")}
            id='save'
            disabled={(segmentDetail?.name || '') === ''}
            contained
            onClick={(v) => {
              saveSetting();
            }}
            sx={{marginLeft: 1}}
          />}
        </Grid>
      </Grid>

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
      {!!errorSnackOpen && <AlertCheckInputs open={errorSnackOpen} setOpen={(b) => {setErrorSnackOpen(b);}} />}
      {!!exitSavedSnackOpen && <AlertSaved open={exitSavedSnackOpen} setOpen={(b) => {setDividendSavedSnackOpen(b);}} />}
    </Page>
  );
}
