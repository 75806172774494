import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Snackbar
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';

type AlertSavedProps = {
  open: boolean;
  setOpen: (f:boolean) => void;
};

export const AlertSaved: FunctionComponent<AlertSavedProps> = ({open, setOpen}) => {
  const {t, i18n} = useTranslation(['translations']);
  const [snackOpen, setSnackOpen] = useState<boolean>(open);

  return (<>
    <Snackbar
      open={snackOpen}
      onClose={() => {setSnackOpen(false); if (!!setOpen) setOpen(false);}}
      autoHideDuration={2000}
    >
      <MuiAlert elevation={6} variant="filled" severity="success">
        {t("alerts.saved").replace(/<[^>]*>?/gm, '')}
      </MuiAlert>
    </Snackbar>
  </>);
}