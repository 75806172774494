import React, { FunctionComponent, useState } from 'react';

import {
  Snackbar
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';

type AlertCheckInputsProps = {
  open: boolean;
  setOpen: (f:boolean) => void;
};

export const AlertCheckInputs: FunctionComponent<AlertCheckInputsProps> = ({open, setOpen}) => {
  const [snackOpen, setSnackOpen] = useState<boolean>(open);

  return (<>
    <Snackbar
      open={snackOpen}
      onClose={() => {setSnackOpen(false); if (!!setOpen) setOpen(false);}}
      autoHideDuration={6000}
    >
      <MuiAlert elevation={6} variant="filled" severity="warning">
        There were problems with your form. Please check your inputs.
      </MuiAlert>
    </Snackbar>
  </>);
}