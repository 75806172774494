import { FunctionComponent, useState, useEffect } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useAtom, useAtomValue } from 'jotai';
// import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next'

import { checkConditionsForAnswerOption } from '../../../lib/poll';
import { currentProjectAtom } from '../../../lib/project';

import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@mui/material';

import { Answer } from '../../../models/Answer';
import { AnswerOption } from '../../../models/AnswerOption';
import { User } from '../../../models/User';

type Props = {
  currentQuestion: {
    body: {[x:string]: string};
    question_type: string;
    id: number;
    is_optional: boolean;
    is_extended_profile: boolean;
    show_outro_type: string;
    answer_options: AnswerOption[];
    extra_data: {
      alignment: string;
      layout?: string;
    };
  };
  pollAnswers: Answer[];
  setPollAnswers: (a:Answer[]) => void;
  step: number;
  userProfile?: User;
  // setStep: (n:number) => void;
};

export const SlideLayoutSingleNormal: FunctionComponent<Props> = ({currentQuestion, pollAnswers, setPollAnswers, step, userProfile}) => {
  // const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const currentProject = useAtomValue(currentProjectAtom);


  const fb_lang:string = currentProject?.fallback_language|| 'nl';
  const lang:string = i18n.resolvedLanguage || fb_lang;

  return (<>
    <FormControl>
      <RadioGroup
        aria-labelledby="answergroup"
        name="answergroup"
        value={!!pollAnswers[step] ? (pollAnswers[step].answer_option_id || '') : ''}
        onChange={(e) => {
          // setSelectedAnswers([parseInt(e.target.value, 10)]);
          let new_poll_answers = [...pollAnswers];

          new_poll_answers[step] = {
            ...pollAnswers[step],
            question_id: !!currentQuestion ? currentQuestion.id : undefined,
            iteration: currentProject?.iteration || 1,
            answer_option_id: parseInt(e.target.value, 10)
          };

          setPollAnswers(new_poll_answers);
        }}
      >
        {currentQuestion.answer_options.map((ao, i) => <>
          {!!ao.id && !!currentProject && checkConditionsForAnswerOption(ao.id, step, currentProject, pollAnswers, userProfile) && <FormControlLabel value={ao.id} control={<Radio />} label={!!ao.body ? ao.body[lang] || ao.body[fb_lang] : ''} />}
        </>)}
      </RadioGroup>
    </FormControl>
  </>);
}
