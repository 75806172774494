import { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

import { fetch_all, fetch_one } from '../../lib/server_helper';
import { checkAllowed } from '../../lib/permission';

import {
  CircularProgress,
  Grid,
  Snackbar
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { GridColDef } from '@mui/x-data-grid-pro';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';

import { AlertSomethingWentWrong } from '../../components/alerts/SomethingWentWrong';

import {
  DataGrid,
  Page,
  SearchBox,
  StyledButton,
  StyledIconButton,
  StyledTextField,
  Typography
} from '../../components/interface';

import { UserInvite } from '../../models/UserInvite';
import { loginAtom } from '../../lib/auth';

type Props = {};

export const AdminUserInvites: FunctionComponent<Props> = () => {
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  const [login, setLogin] = useAtom(loginAtom);

  const params = useParams();
  const projectId: string | undefined = params.project_id;

  const [objects, setObjects] = useState<UserInvite[]>([]);
  const [objectsCount, setObjectsCount] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(true);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [removeLoader, setRemoveLoader] = useState<boolean>(false);
  const [handleLoader, setHandleLoader] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("id DESC");
  const [serverErrorSnackOpen, setServerErrorSnackOpen] = useState<boolean>(false);
  const [importList, setImportList] = useState<string>("");
  const [importListEmail, setImportListEmail] = useState<string>("");

  // Settings


  useEffect(() => {
    onSearch('');
  }, [page, order]);

  const onRemove = (id: number) => {
    // if (checkAllowed(login, null, projectId?.toString() || '0', "ProjectSegments", 'd', true)) {
      setRemoveLoader(true);
      fetch_one<UserInvite>('/nl/v3/objects/remove', {
        object: 'user_invite',
        ob: {
          id: id
        }
      }, (obs, complete_data) => {
        onSearch('');
        setRemoveLoader(false);
      }, (z) => {

      }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
    // }
  };

  const onSearch = (s: string) => {
    // if (checkAllowed(login, null, projectId?.toString() || '0', "ProjectSegments", 'r', true)) {
      // console.log(filters);
      setLoader(true);
      fetch_all<UserInvite>('/nl/v3/objects/fetch_all', {
        object: 'user_invite',
        fields: ['id', 'created_at', 'email', 'phone', 'invited', 'registered', 'user_id'],
        per_page: 20,
        page: page,
        order: order,
        filter: {
          search: s,
          advanced: {
            project_id: projectId,
            fallback: s
          }
        }
      }, (obs, complete_data) => {
        setObjects(obs);

        let count:number = 0;
        if (typeof(complete_data?.data) === 'object' && 'count' in complete_data?.data) count = complete_data?.data?.count;
        setObjectsCount(count);

        setLoader(false);
      }, (z) => {
        setServerErrorSnackOpen(true);
      }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
    // } else {
    //   setLoader(false);
    // }
  };

  const handleImport = () => {
    if (!!login) {
      setHandleLoader(true);
      fetch_one<UserInvite>('/nl/v3/objects/custom_action', {
        object: 'user_invite',
        class_action: 'custom_api_import_list',
        handler_id: login.id,
        phone_list: importList,
        email_list: importListEmail,
        project_id: projectId
      }, (obs, complete_data) => {
        setImportList('');
        setImportListEmail('');
        setShowEdit(false);
        onSearch('');
        setHandleLoader(false);
        setOpen(true);
      }, (z) => {
        setHandleLoader(false);
        setServerErrorSnackOpen(true);
      }, login);
    }
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 100, sortable: true },
    { field: 'email', headerName: 'Email', width: 250, sortable: true },
    { field: 'phone', headerName: 'Phone', width: 250, sortable: true },
    { field: 'invited', headerName: 'Invited', width: 100, sortable: false, renderCell: params => <>
      {params.row.invited && <CheckCircleIcon sx={{color: theme.palette.primary.main}} />}
      {!params.row.invited && <ClearIcon sx={{color: 'red'}} />}
    </> },
    { field: 'registered', headerName: 'Registered', width: 100, sortable: false, renderCell: params => <>
      {params.row.registered && <CheckCircleIcon sx={{color: theme.palette.primary.main}} />}
      {!params.row.registered && <ClearIcon sx={{color: 'red'}} />}
    </> },
    { field: 'user_id', headerName: 'Exists', width: 100, sortable: false, renderCell: params => <>
      {!!params.row.user_id && <CheckCircleIcon sx={{color: theme.palette.primary.main}} />}
      {!params.row.user_id && <ClearIcon sx={{color: 'red'}} />}
    </> },
  ];

  return (
    <Page sx={{}} title={t("objects.user_invites.title")} actions={<>
      {/* {checkAllowed(login, null, projectId?.toString() || '0', "ProjectSegments", 'c', true) && <StyledButton
        label="Create new"
        id='create_new'
        contained
        path={`/segment/${projectId}/new`}
        sx={{marginRight: 3}}
        startIcon={<AddCircleOutlineIcon />}
      />} */}
      <StyledButton
        label="Import"
        id='create_new'
        contained
        onClick={() => {
          setShowEdit(true);
        }}
        sx={{marginRight: 3}}
        startIcon={<AddCircleOutlineIcon />}
      />
    </>}>
      {!!showEdit && <>
        <Grid container sx={{position: 'relative', marginBottom: 3}} spacing={1}>
          <Grid item xs={6}>
            <StyledTextField
              value={importListEmail}
              id="importlistfieldemail"
              multiline
              label="Email List"
              onChange={(v) => {setImportListEmail(v);}}
              sx={{
                width: '100%',
                padding: 0,
                paddingTop: 1,
                paddingBottom: 1,
                'input': {
                  padding: 1
                },
                'label[data-shrink=true]': {
                  left: 0,
                  top: 8
                },
                'label[data-shrink=false]': {
                  opacity: 0.4
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <CloseIcon sx={{position: 'absolute', top: 0, right: 0, cursor: 'pointer'}} onClick={() => {setShowEdit(false);}} />
            <StyledTextField
              value={importList}
              id="importlistfield"
              multiline
              label="Phone List (format: 32412345678)"
              onChange={(v) => {setImportList(v);}}
              sx={{
                width: '100%',
                paddingTop: 1,
                paddingBottom: 1,
                'input': {
                  padding: 1
                },
                'label[data-shrink=true]': {
                  left: 0,
                  top: 8
                },
                'label[data-shrink=false]': {
                  opacity: 0.4
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledButton
              label="Save"
              id='save'
              contained
              onClick={() => {
                handleImport();
              }}
              sx={{marginRight: 1}}
              startIcon={<AddCircleOutlineIcon />}
            />
          </Grid>
        </Grid>
      </>}

      <SearchBox
        simple={true}
        onSearch={(s) => {
          setPage(0);
          onSearch(s);
        }}
      />
      <DataGrid
        action_count={2}
        actions={(params) => {
          return (<>
            {!!removeLoader && <CircularProgress />}
            {/* {checkAllowed(login, null, projectId?.toString() || '0', "ProjectSegments", 'd', true) && !removeLoader && <StyledIconButton title="Destroy" destroyBtn color="primary" onClick={(e) => {
              onRemove(params.row.id);
            }}>
              <DeleteForeverIcon />
            </StyledIconButton>} */}
            {!removeLoader && <StyledIconButton title="Destroy" destroyBtn color="primary" onClick={(e) => {
              onRemove(params.row.id);
            }}>
              <DeleteForeverIcon />
            </StyledIconButton>}
          </>);
        }}
        getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
        objectCount={objectsCount}
        objects={(objects || []).map(u => ({
          ...u
        }))}
        columns={columns}
        loader={loader}
        onSortModelChange={(a, b) => {
          let a_0_field:string = a[0].field;
          setOrder([a_0_field, a[0].sort].join(' '));
        }}
        page={page}
        setPage={(p) => {setPage(p);}}
      />

      {!!serverErrorSnackOpen && <AlertSomethingWentWrong open={serverErrorSnackOpen} setOpen={(b) => {setServerErrorSnackOpen(b);}} />}
      {!!open && <Snackbar
      open={open}
        onClose={() => {setOpen(false);}}
        autoHideDuration={2000}
      >
        <MuiAlert elevation={6} variant="filled" severity="success">Imported!</MuiAlert>
      </Snackbar>}
    </Page>
  );
}
