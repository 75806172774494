import { FunctionComponent, useState } from 'react';
import { useAtom } from 'jotai';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next'

import {
  Avatar,
  Box
} from '@mui/material';

import { currentProjectAtom } from '../lib/project';

import { Link } from '../components/interface/Link';
import { Typography } from '../components/interface/Typography';

type Props = {
  
};

export const GenericSuccess: FunctionComponent<Props> = ({}) => {
  const [currentProject, setCurrentProject] = useAtom(currentProjectAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const params = useParams();
  
  const {t, i18n} = useTranslation(['translations']);
  const [pageKey, setPageKey] = useState<string | undefined>(params.key);

  let detailed_explanation = '';
  let cta_label = 'Take me back';
  let cta_link = '/';
  if (pageKey === 'saved') {
    detailed_explanation = t("general_successpage.messages.save_success", 'Your preferences have been saved successfully!');
    cta_label = t("general_successpage.button.return_to_home", 'Click here to return to home');
    cta_link = '/';
  }

  return (
    <Box sx={{
      width: 'auto',
      maxWidth: '600px',
      margin: 'auto',
      display: 'flex',
      flexDirection: 'column'
    }}>
      <Avatar sx={{alignSelf: 'center', width: '200px', height: '200px', marginBottom: 4, marginTop: 4}} src={!!currentProject && !!currentProject.theme_info?.logo ? currentProject.theme_info?.logo : "https://via.placeholder.com/200x200"} />

      <Typography sx={{color: theme.palette.primary.main, textAlign: 'center'}}>{detailed_explanation}</Typography>

      <Link sx={{textAlign: 'center', marginTop: 4}} label={cta_label.replace(/<[^>]*>?/gm, '')} onClick={() => {navigate(cta_link);}} />
    </Box>
  );
}
